import React, { useContext, useState } from 'react';
import {
	makeStyles,
	TableContainer,
	Table as MuiTable,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import NumberInput from 'components/Form/NumberInput';
import ResearchTaskContext from 'context/ResearchTaskContext';

import Estimates from './Estimates';
import EstimateSum from './EstimateSum';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
}));

const Table = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const classes = useStyles();
	const { formData, onChange, errors } = useContext(ResearchTaskContext);
	const [flatRate, setFlatRate] = useState(formData.general_info.flat_rate);

	const handleChange = e => {
		let { value } = e.target;
		if(value > 15) value = 15;
		setFlatRate(value);
		onChange('general_info.flat_rate', value || 0);
	};

	return (
		<TableContainer className={classes.root}>
			<MuiTable>
				<TableHead>
					<TableRow>
						<TableCell rowSpan={3}>
							<TableLabel>{t('Podmiot Lidera/Konsorcjant')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={3}>
							<TableLabel>{t('Nazwa podmiotu')}</TableLabel>
						</TableCell>
						<TableCell colSpan={6}>
							<TableLabel align="center">{t('Koszty bezpośrednie')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>{t('Koszty pośrednie')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={3}>
							<TableLabel>{t('Finansowanie z Funduszu ogółem dla Podmiotu(PLN)')}</TableLabel>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell colSpan={3}>
							<TableLabel align="center">{t('Wynagrodzenia (PLN)')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Koszty aparatury naukowo-badawczej*,środków trwałych i wartości niematerialnych i prawnych (WNiP)')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Podwykonawstwo i usługi obce (PLN)')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Inne koszty bezpośrednie')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Stawka (%) ryczałtu kosztów pośrednich - max 15%')}</TableLabel>
							<NumberInput
								value={flatRate}
								onChange={handleChange}
								style={{ marginTop: 10 }}
								error={errors?.general_info?.flat_rate}
								disabled={isPreview}
							/>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TableLabel>{t('Zespół badawczy')}</TableLabel>
						</TableCell>
						<TableCell colSpan={2}>
							<TableLabel>{t('Personel administracyjny')}</TableLabel>
							<Typography variant="inputLabel">{t('W tym zarządzający Zadaniem Badawczym')}</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<Estimates disabled={isPreview} />
					<EstimateSum />
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default Table;
