import React, { useContext, useEffect, useState } from 'react';
import { TableBody } from '@material-ui/core';
import _ from 'lodash';

import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import EntityTable from './components/EntityTable';
import { CategorySumRow } from './components/SumRow';

const CategoryTable = props => {
	const { categoryTitle, categorySumTitle, tableKey, showMember } = props;
	const { formData, onChange, selectedLanguage } = useContext(ResearchTaskReportBContext);
	const expensesStatements = formData[selectedLanguage]?.expenses_statements;
	const [data, setData] = useState(expensesStatements?.[tableKey]);

	useEffect(() => {
		if(_.isEqual(data, expensesStatements?.[tableKey])) return;
		setData(expensesStatements?.[tableKey]);
	}, [selectedLanguage, expensesStatements?.[tableKey]]);

	useEffect(() => {
		if(_.isEqual(data, expensesStatements?.[tableKey])) return;

		onChange(`${selectedLanguage}.expenses_statements.${tableKey}`, _.cloneDeep(data), null, selectedLanguage === 'en');
		if (selectedLanguage === 'pl') {
			//onChange(`en.expenses_statements.${tableKey}`, _.cloneDeep(data), null, selectedLanguage === 'en');
		}

		if(selectedLanguage === 'pl') {
			updateSum();
		}
	}, [data]);

	const updateSum = () => {
		let _expensesStatements = {
			..._.omit(expensesStatements, 'sum'),
			[tableKey]: data,
		};
		let _sum = _.cloneDeep(expensesStatements.sum);
		_sum.total_direct_cost = _.sumBy(_.values(_expensesStatements), item => item.sum.eligible_amount);
		_sum.total_indirect_cost = ((_expensesStatements.salary.sum.eligible_amount + _expensesStatements.other_direct_costs.sum.eligible_amount) * _sum.flat_rate) / 100;
		_sum.total = _sum.total_direct_cost + _sum.total_indirect_cost;
		onChange('pl.expenses_statements.sum', _.cloneDeep(_sum));
		onChange('en.expenses_statements.sum', _.cloneDeep(_sum));
	};

	const handleChange = (i, entity) => {
		setData(prev => {
			let entities = prev.entities;
			entities[i] = entity;
			prev = {
				...prev,
				entities,
				sum: {
					netto_amount: parseFloat(_.sumBy(entities, 'sum.netto_amount')),
					vat_amount: parseFloat(_.sumBy(entities, 'sum.vat_amount')),
					eligible_amount: parseFloat(_.sumBy(entities, 'sum.eligible_amount')),
				}
			};
			return { ...prev };
		});
	};

	return (
		<TableBody>
			{data.entities[0] &&
				<EntityTable
					categoryTitle={categoryTitle}
					entity={data.entities[0]}
					leader
					showMember={showMember}
					index={0}
					onChange={entity => handleChange(0, entity)}
					category={tableKey}
				/>
			}
			{(data.entities.slice(1, data.entities.length)).map((entity, i) => (
				<EntityTable
					key={i}
					entity={entity}
					showMember={showMember}
					index={i + 1}
					onChange={entity => handleChange(i + 1, entity)}
					category={tableKey}
				/>
			))}
			<CategorySumRow
				title={categorySumTitle}
				sum={data.sum}
			/>
		</TableBody>
	);
};

export default CategoryTable;
