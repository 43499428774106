import React, { useContext, useEffect, useState } from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import NumberText from 'components/Main/NumberText';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';

import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import EditingRow from '../EditingRow';

const TableWithoutMembers = ({ entity, category, onChange }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const reportBContext = useContext(ResearchTaskReportBContext);
	const { formData, selectedLanguage, disabledInput } = reportBContext;
	const enEntities = formData.en.expenses_statements[category].entities;
	const [enEntity, setEnEntity] = useState({});
	const [data, setData] = useState(entity.data);
	const [editingIndex, setEditingIndex] = useState(null);

	useEffect(() => {
		setEnEntity(() => enEntities.find(e => e.entity_id === entity.entity_id));
	}, [selectedLanguage]);

	useEffect(() => {
		let index = enEntities.findIndex(e => e.entity_id === entity.entity_id);
		if (selectedLanguage === 'en' || _.isEqual(enEntity, enEntities[index])) return;
		reportBContext.onChange(`en.expenses_statements${category}.entities[${index}]`, enEntity);
	}, [enEntity]);

	useEffect(() => {
		if (_.isEqual(data, entity.data)) return;
		setData(entity.data);
	}, [entity.data]);

	useEffect(() => {
		let sum = {
			netto_amount: parseFloat(_.sumBy(data, 'netto_amount')),
			vat_amount: parseFloat(_.sumBy(data, 'vat_amount')),
			eligible_amount: parseFloat(_.sumBy(data, 'eligible_amount')),
		};
		let _data = {
			...entity,
			sum: _.cloneDeep(sum),
			data,
		};
		if (_.isEqual(_data, entity)) return;
		setEnEntity(prev => ({ ...prev, sum: _.cloneDeep(sum) }));
		onChange(_data);
	}, [data]);

	const handleAdd = () => {
		if (editingIndex !== null) return;
		let newItem = {
			document_number: '',
			registration_number: '',
			document_date: '',
			payment_date: '',
			netto_amount: 0,
			vat_amount: 0,
			eligible_amount: 0,
			service_description: '',
			document_issuer: '',
		};
		setData(prev => {
			prev.push(_.cloneDeep(newItem));
			setEditingIndex(prev.length - 1);
			return [...prev];
		});
		setEnEntity(prev => {
			prev.data.push(_.cloneDeep(newItem));
			return { ...prev };
		});
	};

	const handleDelete = i => () => {
		setData(prev => {
			prev.splice(i, 1);
			return [...prev];
		});

		setEnEntity(prev => {
			prev.data.splice(i, 1);
			return { ...prev };
		});

		setEditingIndex(prev => {
			if (prev === i) return null;
			if (prev > i) return i - 1;
			return prev;
		});
	};

	const handleSave = item => {
		setData(prev => {
			prev[editingIndex] = item;
			setEditingIndex(null);
			return [...prev];
		});

		setEnEntity(prev => {
			let enItem = prev.data[editingIndex];

			if (!enItem) {
				enItem = _.cloneDeep(item);
			} else {
				enItem = { ..._.cloneDeep(item), service_description: enItem.service_description || item.service_description };
			}

			prev.data[editingIndex] = _.cloneDeep(enItem);
			return { ...prev };
		});
	};

	return (
		<>
			{data?.map((item, i) => (
				i === editingIndex
					? <EditingRow
						key={i}
						index={i}
						item={item}
						onSave={handleSave}
						onDelete={handleDelete}
						selectedLanguage={selectedLanguage}
					/>

					: <TableRow key={i}>
						<TableCell>{i + 1}.</TableCell>
						<TableCell>{item.document_number}</TableCell>
						<TableCell>{item.registration_number}</TableCell>
						<TableCell>{item.document_date}</TableCell>
						<TableCell>{item.payment_date}</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={item.netto_amount} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={item.vat_amount} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={item.eligible_amount} />
						</TableCell>
						<TableCell>{item.service_description}</TableCell>
						<TableCell>{item.document_issuer}</TableCell>
						<TableCell>
							<Box display="flex">
								{!disabledInput &&
									<EditIconButton
										tooltip={t('Edytuj')}
										onClick={() => setEditingIndex(i)}
										disabled={disabledInput}
									/>
								}
								{!disabledInput && selectedLanguage !== 'en' &&
									<DeleteIconButton
										tooltip={t('Usuń')}
										onClick={handleDelete(i)}
										disabled={disabledInput || selectedLanguage === 'en'}
									/>
								}
							</Box>
						</TableCell>
					</TableRow>
			))}
			<TableRow>
				<TableCell colSpan={11}>
					<PrimaryButton onClick={handleAdd} disabled={disabledInput || selectedLanguage === 'en'}>
						{t('Dodaj kolejny wiersz')}
					</PrimaryButton>
				</TableCell>
			</TableRow>
		</>
	);
};

export default TableWithoutMembers;
