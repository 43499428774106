import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
	category: {
		background: '#FBE6E9 !important',
		verticalAlign: 'middle !important',
		borderBottomColor: '#17253F !important',
	},
	head: {
		background: '#EEEEEE',
	},
	sum: {
		background: '#E3EAF8'
	},
}));

const CustomCell = React.forwardRef(({ type, className, children, ...rest }, ref) => {
	const classes = useStyles();

	return (
		<TableCell className={clsx(classes[type], className)} {...rest} ref={ref}>{children}</TableCell>
	);
});
CustomCell.displayName = 'CustomCell';

const CategoryCell = props => <CustomCell type="category" {...props} />;
const HeadCell = props => <CustomCell type="head" {...props} />;
const SumCell = props => <CustomCell type="sum" {...props} />;

export default CustomCell;
export { CategoryCell, HeadCell, SumCell };