import React from 'react';
import { Box } from '@material-ui/core';
import FormContainer from './FormContainer';

const Commercializations = () => {
	return (
		<Box>
			<FormContainer />
		</Box>
	);
};

export default Commercializations;