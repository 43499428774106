import React, { useContext, useEffect, useState } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import DateInput from 'components/Form/DateInput';
import ResearchTaskContext from 'context/ResearchTaskContext';

const useStyles = makeStyles(() => ({
	dateInput: {
		width: window.isMobile ? '100%' : 150,
		marginRight: 10
	}
}));

const YearRangePicker = ({ onChange }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { formData } = useContext(ResearchTaskContext);
	const classes = useStyles();
	const [data, setData] = useState({
		start_date: moment(),
		end_date: moment(),
	});

	useEffect(() => {
		let years = _.keys(formData.payments_schedule.values);
		let startDate = moment(_.min(years));
		let endDate = moment(_.max(years));
		setData({
			start_date: startDate,
			end_date: endDate
		});
		onChange(startDate.year(), endDate.year());
	}, []);

	const handleChange = e => {
		const { name, value } = e.target;
		let _data = { ...data };
		_data[name] = moment(value);
		if (_data.start_date > _data.end_date) {
			if (name === 'start_date') {
				_data.end_date = _data.start_date;
			} else {
				_data.start_date = _data.end_date;
			}
		}
		setData(_data);
		onChange(_data.start_date.year(), _data.end_date.year());
	};

	return (
		<Box display={window.isMobile ? 'block' : 'flex'} alignItems="center" mb={2}>
			<Typography style={{ marginRight: 50 }}>{t('Zakres lat')}</Typography>
			{_.map(_.keys(data), key => (
				<DateInput
					fullWidth
					key={key}
					name={key}
					value={data[key]}
					onChange={handleChange}
					gutterBottom={false}
					format="YYYY"
					views={['year']}
					containerClassName={classes.dateInput}
					inputProps={{ 'aria-required': true }}
				/>
			))}
		</Box>
	);
};

export default YearRangePicker;