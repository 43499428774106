import React, { useEffect, useState, useCallback, useContext } from 'react';
import { Grid } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';

import BaseInput from 'components/Form/BaseInput';
import Select from 'components/Form/Select';
import { useTranslation } from 'react-i18next';
import IpCardContext from 'context/IpCardContext';

const FilterRow = ({ onChange }) => {
	const [filters, setFilters] = useState({
		type: '',
		type_of_tool: '',
		legal_form: '',
		name: '',
		ip_source: '',
		owner: '',
	});

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { selectedLanguage } = useContext(IpCardContext);

	const types = [
		{ value: 'Prototyp', label: t('Prototyp') },
		{ value: 'Narzędzie badawcze', label: t('Narzędzie badawcze') },
	];

	useEffect(() => {
		let _filters = Object.fromEntries(
			Object.entries(filters).filter(([value]) => !!value));
		handleChangeSearch(_filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleChangeSearch = useCallback(_debounce((filters) => onChange(filters), 500), []);

	return (
		<Grid container spacing={2}>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<Select
					name="type"
					options={types}
					displayEmpty
					emptyLabel={t('Wybierz typ')}
					value={filters.type}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder={selectedLanguage === 'pl' ? 'Wpisz rodzaj' : 'Enter type'}
					name="type_of_tool"
					value={filters.type_of_tool}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder={selectedLanguage === 'pl' ? 'Wpisz formę prawną' : 'Enter the legal form'}
					name="legal_form"
					value={filters.legal_form}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder={selectedLanguage === 'pl' ? 'Wpisz nazwę' : 'Enter name'}
					name="name"
					value={filters.name}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder={selectedLanguage === 'pl' ? 'Wpisz źródło' : 'Enter source'}
					name="ip_source"
					value={filters.ip_source}
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder={selectedLanguage === 'pl' ? 'Wpisz dane właściciela' : 'Enter owner details'}
					name="owner"
					value={filters.owner}
					onChange={handleChange}
				/>
			</Grid>
		</Grid>
	);
};

FilterRow.propTypes = {
	onChange: PropTypes.func,
};

export default FilterRow;
