import React from 'react';
import { makeStyles, TableContainer, Table as MuiTable } from '@material-ui/core';

import Table1 from './Table1';
import Table2 from './Table2';
import Table3 from './Table3';
import Table4 from './Table4';

const useStyles = makeStyles(() => ({
	root: {
		maxHeight: 'calc(100vh - 240px)',
		marginTop: 30,
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
}));

const Table = () => {
	const classes = useStyles();
	return (
		<TableContainer className={classes.root}>
			<MuiTable stickyHeader>
				<Table1 />
				<Table2 />
				<Table3 />
				<Table4 />
			</MuiTable>
		</TableContainer>
	);
};

export default Table;