import React, { useContext, useEffect, useState } from 'react';
import {
	makeStyles,
	TableRow,
	TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import ResearchTaskContext from 'context/ResearchTaskContext';
import NumberText from 'components/Main/NumberText';

const useStyles = makeStyles(() => ({
	grayBackground: {
		background: '#EEEEEE',
	}
}));

const EstimateSum = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();
	const { formData, onChange } = useContext(ResearchTaskContext);
	const [estimateSum, setEstimateSum] = useState(formData.task_estimate_sum);

	useEffect(() => {
		let sum = _.mapValues(estimateSum, (val, key) => {
			return _.sumBy(formData.task_estimates, item => item[key]);
		});
		sum.col_3b = _.floor((sum.col_3 / sum.col_2) || 0, 4);
		sum.col_8 = parseFloat(sum?.col_8?.toFixed(2));
		setEstimateSum(sum);
	}, [formData.task_estimates]);

	useEffect(() => onChange('task_estimate_sum', estimateSum), [estimateSum]);

	return (
		<>
			<TableRow>
				<TableCell rowSpan={2} colSpan={2} className={classes.grayBackground}>
					<TableLabel>{t('Sumy wydatków w poszczególnych kategoriach wydatków (PLN)')}</TableLabel>
				</TableCell>
				<TableCell rowSpan={2}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_2} />
				</TableCell>
				<TableCell rowSpan={2} width={100}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_3} />
				</TableCell>
				<TableCell width={100}>
					<Typography>{t('% łącznej wartości wynagrodzenia Członków Zespołu Badawczego')}</Typography>
				</TableCell>
				<TableCell rowSpan={2}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_4} />
				</TableCell>
				<TableCell rowSpan={2}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_5} />
				</TableCell>
				<TableCell rowSpan={2}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_6} />
				</TableCell>
				<TableCell rowSpan={2}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_7} />
				</TableCell>
				<TableCell className={classes.grayBackground}>
					<TableLabel>{t('Ogółem')}</TableLabel>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_3b * 100} suffix="%" />
					<Typography variant="inputStatus" color="error">
						{estimateSum.col_3b > 0.1 ? 'UWAGA !Wartość nie może przekraczać 10%' : ''}
					</Typography>
				</TableCell>
				<TableCell className={classes.grayBackground}>
					<NumberText fixedDecimalScale={true} value={estimateSum.col_8} />
				</TableCell>
			</TableRow>
		</>
	);
};

export default EstimateSum;
