import React, { useContext } from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import IpCardContext from 'context/IpCardContext';

const Detail = (props) => {
	const { subject } = props;
	const classes = useStyles();
	const { selectedLanguage } = useContext(IpCardContext);

	return (
		<Box className={classes.root}>
			<Typography variant="h4" className={classes.subHeader}>
				Twórca (imię i nazwisko) Background IP
			</Typography>
			{subject.authors?.map((item, index) => (
				<Typography key={index}>{(index + 1) + '.'} {item?.name}</Typography>
			))}

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Dokument w bazie UPRP, EPO, innej właściwej
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{subject.document_in_urp_epo_database?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Etap postępowania przed urzędem PL/EPO, USA, inne
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{subject.processing_stage_in_pl_epo?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Czy Background IP będzie stanowiło przeszkodę w komercjalizacji efektów Zadania Badawczego?
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{subject.is_background_ip_an_obstacle ? 'TAK' : 'Nie'}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				W jaki sposób background IP zostanie wykorzystane w Projekcie? Czy będzie stanowić element IP w komercjalizacji? Czy będzie jedynie narzędziem do wyników badań?
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{subject.usage_in_project?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Powiązane dokumenty prawne (przekierowanie do bazy/rejestru/magazynu)
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{subject.connected_legal_documents?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Komentarz
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{subject.comment?.[selectedLanguage]}</Typography>
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: 100,
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	divider: {
		marginBlock: theme.spacing(2),
	},
	subHeader: {
		marginBottom: 10
	},
	fontStyle: {
		fontSize: 16,
	}
}));

export default Detail;