import React, { useContext, useEffect, useState } from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import IconButton, { DeleteIconButton, EditIconButton, NewsIconButton, MessageButton } from 'components/Buttons/IconButtons';
import PaginatedTable from 'components/PaginatedTable';
import Progress from 'components/Main/Progress';
import { useTranslation } from 'react-i18next';
import useCheckPermissions from 'utils/useCheckPermissions';
import AuthContext from 'context/AuthContext';
import { VisibleIcon } from 'assets/svg';
import FilterRow from './FilterRow';

const IpCards = () => {
	const { user } = useContext(AuthContext);
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });

	const canViewAllIpCard = useCheckPermissions('can_view_all_ip_card', true);
	const canViewAssignedIpCard = useCheckPermissions('can_view_assigned_ip_card', true);
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);
	const canDeleteAllIpCards = useCheckPermissions('can_delete_all_ip_cards', true);
	const canDeleteOwnIpCards = useCheckPermissions('can_delete_own_ip_cards', true);
	const canFillComercializationIpCard = useCheckPermissions('can_fill_comercialization_ip_card', true);
	const canFullyManageEntities = useCheckPermissions('can_fully_manage_entities', true);

	const breadcrumbs = [
		{ title: 'Własności Intelektualne WIB' },
		{ title: 'Lista Własności Intelektualnych' },
	];

	const [data, setData] = useState(null);

	const columns = [
		{ title: t('NAZWA WŁASNOŚCI INTELEKTUALNEJ'), name: 'name', width: 350 },
		{ title: t('AKRONIM WI'), name: 'acronym' },
		{ title: t('NR EWIDENCYJNY WI'), name: 'registration_number' },
		{ title: t('Nazwa Zadania Badawczego'), name: 'research_task_title', width: 300, disableSort: true },
		{ title: t('AKRONIM ZB'), name: 'research_task_acronime', width: 300, disableSort: true },
		{ title: 'Status', name: 'status', width: 100 },
		{ title: t('AKCJE'), name: 'action', width: 140, disableSort: true },
	];

	const [totalPagesCount, setTotalPagesCount] = useState(0);
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
		column: 'id',
		direction: 'desc',
		filters: {}
	});

	useEffect(() => document.title = 'WIB - Własności Intelektualne WIB', []);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => loadFilteredIpCards(), [params]);

	const handleChangeParams = _params => {
		const picked = _.pick(params, ['column', 'direction', 'page']);
		if (_.isEqual(picked, _params)) return;

		setParams({ ...params, ..._params });
	};

	const handleChangePerPage = e => {
		setParams(prev => ({ ...prev, limit: e.target.value }));
	};

	const handleChangeFilters = filters => {
		setParams(prev => {
			if (_.isEqual(prev.filters, filters)) return prev;

			return { ...prev, filters };
		});
	};

	const loadFilteredIpCards = () => {
		API.ipCards.index(params).then(res => {
			setData(res?.data?.data);
			setTotalPagesCount(res?.data?.last_page);
		});
	};

	const handleDelete = id => () => {
		API.ipCards.delete(id).then(res => {
			if (res?.data?.code === 200) {
				loadFilteredIpCards();
				return toast.success('Karta została usunięta!');
			}

			toast.error(res?.data?.message);
		});
	};

	const renderRows = () => (
		data.map((row, index) => (
			<TableRow key={index}>
				<TableCell>{row.name}</TableCell>
				<TableCell>{row.acronym}</TableCell>
				<TableCell>{row.registration_number}</TableCell>
				<TableCell>{row.research_task?.title}</TableCell>
				<TableCell>{row.research_task?.acronym}</TableCell>
				<TableCell style={{ color: row.status === 'draft' ? '#898989' : '' }}>{row.status === 'draft' ? 'Szkic' : 'Zapisany'}</TableCell>
				< TableCell >
					<Box display="flex">
						{(canViewAllIpCard || canViewAssignedIpCard) &&
							<Link to={Routes.IpCards.Edit(row.id, true)}>
								<IconButton tooltip="Podgląd">
									<VisibleIcon alt="Ikona podglądu wiersza" />
								</IconButton>
							</Link>
						}
						{(((canCreateIpCard && row.user_id === user.id) && row.status === 'draft')
							|| canFullyManageEntities) &&
							<Link to={Routes.IpCards.Edit(row.id)}>
								<EditIconButton tooltip={t('Edytuj')} />
							</Link>
						}
						{(canFullyManageEntities || canFillComercializationIpCard) &&
							<Link to={`/ip_cards/${row.id}/edit?tab=commercializations`}>
								<MessageButton
									tooltip="Ochrona i komercjalizacja"
									alt="Ikona informacji dotyczących komercjalizacji"
								/>
							</Link>
						}

						{(((canCreateIpCard && row.user_id === user.id) && row.status === 'draft')
							|| canFullyManageEntities) &&

							<Link to={`/ip_cards/${row.id}/edit?tab=publications`}>
								<NewsIconButton
									tooltip="Lista publikacji"
									alt="Ikona listy publikacji"
								/>
							</Link>
						}

						{(((canDeleteAllIpCards || (canDeleteOwnIpCards && row.user_id === user.id)) && row.status === 'draft')
							|| canFullyManageEntities) &&
							<DeleteIconButton
								tooltip={t('Usuń')}
								onClick={handleDelete(row.id)}
								title={t('Czy na pewno chcesz usunąć tę kartę?')}
							/>
						}
					</Box>
				</TableCell>
			</TableRow >
		))
	);

	return (
		<>
			<Header
				title="Własności Intelektualne WIB"
				perPageCount={params.limit}
				onChangeCount={handleChangePerPage}
				createTitle={t('Dodaj Kartę')}
				createPath={canCreateIpCard ? Routes.IpCards.Create : ''}
			/>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			{!data
				? <Progress status={true} />
				: <PaginatedTable
					columns={columns}
					exportEndpoint={API.ipCards.exportList}
					filename={t('Własności Intelektualne WIB')}
					totalPagesCount={totalPagesCount}
					onChangeFilters={handleChangeParams}
					renderRows={renderRows}
					filterRow={<FilterRow onChange={handleChangeFilters} />}
				/>}
		</>
	);
};

export default IpCards;
