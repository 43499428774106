import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import Table from './Table';

const PaymentSchedule = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	return (
		<Box width="100%">
			{window.isMobile &&
				<Typography variant="h3" style={{ marginBottom: '10px' }}>
					{t('Harmonogram płatności (zapotrzebowanie na środki finansowe w okresie realizacji Zadania Badawczego)')}
				</Typography>
			}

			<Typography variant="subtitle1" style={{ marginBottom: 30 }}>
				{t('Źródło finansowania - Fundusz Polskiej Nauki')}
			</Typography>

			<Table />
		</Box>
	);
};

export default PaymentSchedule;