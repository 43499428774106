import React from 'react';

import { IpCardContextProvider } from 'context/IpCardContext';
import FormContainer from './FormContainer';

const Form = () => (
	<IpCardContextProvider>
		<FormContainer />
	</IpCardContextProvider>
);

export default Form;
