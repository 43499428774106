import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, makeStyles } from '@material-ui/core';
import Accordions from 'components/Main/Accordions';
import Typography from 'components/Main/Typography';
import Table from './Table';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Form from './Form';
import useCheckPermissions from 'utils/useCheckPermissions';
import IpCardContext from 'context/IpCardContext';
import { useTranslation } from 'react-i18next';

const Subjects = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const classes = useStyles();
	const [showForm, setShowForm] = useState(false);

	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const handleAddSubject = () => {
		setShowForm(false);
	};

	const { selectedLanguage } = useContext(IpCardContext);
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });

	const items = [
		{
			title: t('Background IP'),
			child:
				<Box className={classes.root}>
					<Typography variant="h3" className={classes.fontWeight_500}>
						{t('Lista przedmiotów praw powiązanych z Własnością Intelektualną')}
					</Typography>

					<Table />

				</Box>, defaultExpanded: true
		},
	];

	return (
		<Box>
			<Accordions className={classes.borderNone} items={items} />

			<Box className={classes.marginTop_10}>
				{!showForm && !isPreview && canCreateIpCard &&
					<PrimaryButton
						onClick={() => setShowForm(!showForm)}
						disabled={selectedLanguage === 'en'}
					>
						{t('Dodaj Background IP')}
					</PrimaryButton>
				}
			</Box>

			{showForm && !isPreview && canCreateIpCard &&
				<div id="background-form">
					<Form handleAddSubject={handleAddSubject} />
				</div>
			}

			<Divider className={classes.divider} />
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	divider: {
		marginBlock: theme.spacing(3),
	},
	gutterBottom: {
		marginBottom: 10,
	},
	fontWeight_500: {
		fontWeight: 500
	},
	marginTop_10: {
		marginTop: 10
	},
	borderNone: {
		borderBottom: 'none'
	}
}));

export default Subjects;