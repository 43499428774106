import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Routes from 'router/Routes';
import useCheckPermissions from 'utils/useCheckPermissions';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';

import ResearchTaskReportContext, { ResearchTaskReportContextProvider } from 'context/ResearchTaskReportContext';
import ReportsList from './ReportsList';

const ResearchTaskReportsContainer = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const { researchTask } = useContext(ResearchTaskReportContext);
	const title = `${t('Raportowanie')} - ${researchTask?.acronym}`;
	
	const canCreateA = useCheckPermissions('can_create_report_a', true);
	const canManageA = useCheckPermissions([
		'can_create_report_a',
		'can_send_report_a_for_acceptance',
		'can_accept_reject_report_a'
	], true);
	const canRemoveAllReportA = useCheckPermissions('can_remove_all_reports_a', true);
	const canRemoveOwnReportA = useCheckPermissions('can_remove_own_reports_a', true);

	const canCreateB = useCheckPermissions('can_create_report_b', true);
	const canManageB = useCheckPermissions([
		'can_create_report_b',
		'can_send_report_b_for_acceptance',
		'can_accept_reject_report_b'
	], true);
	const canRemoveAllReportB = useCheckPermissions('can_remove_all_reports_b', true);
	const canRemoveOwnReportB = useCheckPermissions('can_remove_own_reports_b', true);

	const breadcrumbs = [
		{ title: t('Zadania badawcze'), to: Routes.ResearchTasks.List },
		{ title },
	];

	useEffect(() => document.title = 'WIB - Raporty', []);

	return (
		<>
			<Header title={title} />
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<ReportsList
				type="type_a"
				description={t('RAPORT A - Raport Okresowy/Końcowy z postępów merytorycznych działalności Zespołu Badawczego WIB')}
				canCreate={canCreateA}
				canManage={canManageA}
				canRemoveAll={canRemoveAllReportA}
				canRemoveOwn={canRemoveOwnReportA}
			/>
			<ReportsList
				type="type_b"
				description={t('Raport Okresowy/Końcowy Postęp finansowy')}
				canCreate={canCreateB}
				canManage={canManageB}
				canRemoveAll={canRemoveAllReportB}
				canRemoveOwn={canRemoveOwnReportB}
			/>
		</>
	);
};

const ResearchTaskReports = () => (
	<ResearchTaskReportContextProvider>
		<ResearchTaskReportsContainer />
	</ResearchTaskReportContextProvider>
);

export default ResearchTaskReports;

