import React, { useEffect, useState, useCallback } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import _, { debounce } from 'lodash';

import BaseInput from 'components/Form/BaseInput';
import Select from 'components/Form/Select';

const FilterRow = ({ onChange, modules }) => {
	const [data, setData] = useState({
		module: '',
		slug: '',
		pl: '',
		en: '',
	});

	useEffect(() => {
		handleChangeSearch(_.pickBy(data));
	}, [data]);

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	const handleChangeSearch = useCallback(debounce((filters) => onChange(filters), 500), []);

	return (
		<TableRow>
			<TableCell>
				<Select
					fullWidth
					options={modules}
					valueField="module"
					labelField="module"
					displayEmpty
					name="module"
					value={data.module}
					onChange={handleChange}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					name="pl"
					value={data.pl}
					onChange={handleChange}
					fullWidth
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					name="en"
					value={data.en}
					onChange={handleChange}
					fullWidth
				/>
			</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};

export default FilterRow;
