import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Box, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Routes from 'router/Routes';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import Typography from 'components/Main/Typography';
import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';

import ManagementDetailedInformationsContext, { ManagementDetailedInformationsContextProvider } from 'context/ManagementDetailedInformationsContext';
import Table from './Table';
import Exports from './Exports';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%',
	}
}));

const FormContainer = () => {
	const { id } = useParams();
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const breadcrumbs = [
		{ title: t('Zarządzanie WIB'), to: Routes.Management.List('detailed_informations') },
		{ title: t(id ? 'Edytuj informacje' : 'Dodaj informacje') }
	];
	const { onChange, onSave, onPublish, onCancel, saving, formData, errors, isPreview } = useContext(ManagementDetailedInformationsContext);

	useEffect(() => {
		if(id) return document.title = 'WIB - Edycja szczegółowych informacji o sposobie wydatkowania środków';
		
		document.title = 'WIB - Dodawanie szczegółowych informacji o sposobie wydatkowania środków';
	}, []);

	const handleChange = e => {
		if(isPreview) return;
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<>
			<Box className={classes.content}>
				<Header title={t(id ? 'Edytuj informacje' : 'Dodaj informacje')} />
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				<Box p={window.isMobile ? 2 : 4} bgcolor="#fff">
					<Typography variant="h3" style={{ marginBottom: 16 }}>
						{t('Szczegółowe informacje o sposobie wydatkowania środków z Funduszu Polskiej Nauki')}
					</Typography>
					<Grid container alignItems="center" spacing={2}>
						<Grid item>
							<Typography>
								{t('Okres sprawozdawczy')}
							</Typography>
						</Grid>
						<Grid item>
							<DateInput
								fullWidth
								name="date_from"
								value={formData.date_from}
								onChange={handleChange}
								gutterBottom={false}
								inputProps={{ 'aria-required': true }}
								disabled={isPreview}
							/>
						</Grid>
						<Grid item>
							<DateInput
								fullWidth
								name="date_to"
								value={formData.date_to}
								onChange={handleChange}
								gutterBottom={false}
								inputProps={{ 'aria-required': true }}
								disabled={isPreview}
							/>
						</Grid>
					</Grid>
					<Table />
					<Divider style={{ marginBlock: 30 }} />
					<FormInput
						title={t('Uzasadnienie')}
						placeholder={t('Wpisz uzasadnienie')}
						alignItems="flex-start"
						name="justification"
						value={formData.justification}
						onChange={handleChange}
						multiline
						minRows={10}
						maxRows={10}
						error={errors?.justification}
						inputProps={{ 'aria-required': true }}
						disabled={isPreview}
					/>
					<Divider style={{ marginBlock: 30 }} />
					<Grid container spacing={2}>
						<Grid item md={4}>
							<Typography variant="h3">{t('Informacje Końcowe')}</Typography>
						</Grid>
						<Grid item md={5}>
							<FormInput
								title={t('Sporządził')}
								value={formData.author_name}
								vertical
								disabled
								inputProps={{ 'aria-required': true }}
							/>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<FormInput
										title={t('Podpis osoby reprezentującej Podmiot Zarządzający')}
										name="filling_place"
										value={formData.filling_place}
										onChange={handleChange}
										error={errors?.filling_place}
										gutterBottom={false}
										vertical
										fullWidth
										inputProps={{ 'aria-required': true }}
										disabled={isPreview}
									/>
								</Grid>
								<Grid item xs={12}>
									<DateInput
										title={t('Data wypełnienia')}
										name="filling_date"
										value={formData.filling_date}
										onChange={handleChange}
										fullWidth
										vertical
										inputProps={{ 'aria-required': true }}
										disabled={isPreview}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Box>

				{id &&
					<Exports id={id} />
				}
			</Box>

			<ControlButtonGroup
				primaryTitle={t('Zatwierdź')}
				onSave={onSave}
				onClickPrimary={onPublish}
				onCancel={onCancel}
				saving={saving || isPreview}
				disabledSaveButton={formData?.status === 'published'}
			/>
		</>
	);
};

const Form = () => (
	<ManagementDetailedInformationsContextProvider>
		<FormContainer />
	</ManagementDetailedInformationsContextProvider>
);

export default Form;