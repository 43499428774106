import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	category: {
		background: '#FBE6E9 !important',
		verticalAlign: 'middle !important',
		borderBottomColor: '#17253F !important',
	},
	entity: {
		background: '#E3EAF8'
	},
	member: {
		background: '#F6F9FF'
	},
	total: {
		background: '#EEEEEE',
	}
}));

const CustomCell = ({ type, children, ...rest }) => {
	const classes = useStyles();

	return (
		<TableCell className={classes[type]} {...rest}>{children}</TableCell>
	);
};

const CategoryCell = props => <CustomCell type="category" {...props} />;
const EntityCell = props => <CustomCell type="entity" {...props} />;
const MemberCell = props => <CustomCell type="member" {...props} />;

export default CustomCell;
export { CategoryCell, EntityCell, MemberCell };