import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import {
	EditIconButton,
	ExpandCircleIconButton,
	CollapseCircleIconButton
} from 'components/Buttons/IconButtons';
import PaginatedTable from 'components/PaginatedTable';
import Progress from 'components/Main/Progress';
import IpCardContext from 'context/IpCardContext';
import useCheckPermissions from 'utils/useCheckPermissions';

import EditingRow from './EditingRow';

const ListData = () => {
	const { selectedLanguage, formData, onChange, authors } = useContext(IpCardContext);
	const classes = useStyles();
	const columns = [
		{ title: 'LP.', name: 'id', disableSort: true },
		{ title: 'Nazwa Jednostki', name: 'name_of_unit', disableSort: true },
		{ title: 'proporcja naliczenia ', name: 'accrual_proportion', width: 220, disableSort: true },
		{ title: 'LP. OKRESU', name: 'subentry_index', width: 100, disableSort: true },
		{ title: 'Okres wypłaty OD', name: 'payment_period_from', width: 200, disableSort: true },
		{ title: 'Okres wypłaty DO', name: 'payment_period_to', disableSort: true, width: 200 },
		{ title: 'Kwota wypłaty', name: 'payment_amount', disableSort: true, width: 200 },
		{ title: 'Razem wypłacone środki', name: 'total_payment_amount', disableSort: true, width: 200 },
		{ title: 'AKCJE', name: 'action', width: 100, disableSort: true },
	];

	const canFillComercializationIpCard = useCheckPermissions('can_fill_comercialization_ip_card', true);

	const [data, setData] = useState(null);
	const [editingData, setEditingData] = useState(null);
	const [expandIndex, setExpandIndex] = useState(null);

	useEffect(() => {
		let result = [];
		_.uniqBy(authors, 'unit_name["pl"]').forEach(row => {
			result.push(
				{
					author_index: row.index,
					author_id: row.id,
					accrual_proportion: formData.ip_card_commercialization?.distribution_of_funds_b?.find(item => item.author_id === row.id)?.accrual_proportion,
					subentries: formData.ip_card_commercialization?.distribution_of_funds_b?.find(item => item.author_id === row.id)?.subentries
				}
			);
		});
		setData(JSON.parse(JSON.stringify(result)));
	}, [authors, formData.ip_card_commercialization?.distribution_of_funds_b]);

	const handleClickReadMore = (index) => {
		setExpandIndex(index);
		setEditingData(null);
	};

	const handleEdit = index => () => {
		if (editingData) return;
		setExpandIndex(null);
		const newData = { ...data[index], index, subentries: data[index]?.subentries || [] };
		setEditingData(newData);
	};

	const handleSave = (editingRow) => {
		setData(prev => {
			prev[editingData.index] = editingRow;
			onChange('ip_card_commercialization', { ...formData.ip_card_commercialization, distribution_of_funds_b: prev });
			return [...prev];
		});

		setEditingData(null);
	};

	const renderRows = () => {
		return (
			data?.map((item, index) => {
				let paymentTotalAmount = item?.subentries?.map(s => s.payment_amount).reduce((accumVariable, curValue) => {
					return Number(accumVariable) + Number(curValue);
				}, 0);

				if (index === editingData?.index) {
					return (
						<EditingRow
							key={index}
							row={editingData}
							order={index + 1}
							authors={authors}
							onSave={handleSave}
							expandIndex={expandIndex}
							handleClickReadMore={handleClickReadMore}
						/>
					);
				}

				return (
					<React.Fragment key={index}>
						<TableRow>
							<TableCell rowSpan={index === expandIndex ? item.subentries?.length : 1}>{index + 1}</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.subentries?.length : 1}>
								{/* {authors?.find(author => author.id === item?.author_id)?.unit_name?.[selectedLanguage]} */}
								{authors?.find(author => item?.author_id === undefined ? author.index === item?.author_index : author.id === item?.author_id)?.unit_name?.[selectedLanguage]}
							</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.subentries?.length : 1}>{item.accrual_proportion}</TableCell>
							<TableCell>{item.subentries?.[0] ? 1 : ''}</TableCell>
							<TableCell>{item.subentries?.[0]?.payment_period_from}</TableCell>
							<TableCell>{item.subentries?.[0]?.payment_period_to}</TableCell>
							<TableCell>{item.subentries?.[0]?.payment_amount}</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.subentries?.length : 1}>{paymentTotalAmount}</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.subentries?.length : 1}>
								<Box display="flex">
									{expandIndex !== index
										? <ExpandCircleIconButton
											disabled={(!item?.subentries || item.subentries?.length) < 2}
											component={(!item?.subentries || item.subentries?.length) < 2 ? 'div' : undefined}
											className={(!item?.subentries || item.subentries?.length) < 2 ? classes.disabled : ''}
											tooltip="Rozwiń"
											onClick={() => handleClickReadMore(index)}
										/>
										: <CollapseCircleIconButton
											tooltip="Zwiń"
											onClick={() => handleClickReadMore(null)}
										/>
									}
									{canFillComercializationIpCard &&
										<EditIconButton tooltip="Edytuj" onClick={handleEdit(index)} />
									}
								</Box>
							</TableCell>
						</TableRow>

						{index === expandIndex &&
							item.subentries?.length > 1 &&
							item.subentries?.slice(1).map((entry, entryIndex) => (
								<TableRow key={entryIndex + '_entry'}>
									<TableCell>{entryIndex + 2}</TableCell>
									<TableCell>{entry.payment_period_from}</TableCell>
									<TableCell>{entry.payment_period_to}</TableCell>
									<TableCell>{entry.payment_amount}</TableCell>
								</TableRow>
							))
						}
					</React.Fragment>
				);
			}));
	};

	return (
		<Box>
			{!data
				? <Progress status={true} />
				: (
					<div id="commercialization-tableB">
						<PaginatedTable
							containerClassName={classes.table}
							columns={columns}
							className={classes.tbody}
							renderRows={renderRows}
						/>
					</div>
				)
			}
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	table: {
		paddingTop: 15,
		paddingLeft: 0,
		paddingRight: 0
	},
	tbody: {
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
		},
	},
	disabled: {
		'&.Mui-disabled': {
			'& path': {
				fill: 'gray'
			}
		}
	}
}));

export default ListData;
