import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import {
	DeleteIconButton,
	EditIconButton,
	CollapseCircleIconButton,
	ExpandCircleIconButton
} from 'components/Buttons/IconButtons';
import PaginatedTable from 'components/PaginatedTable';
import Progress from 'components/Main/Progress';
import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import IpCardContext from 'context/IpCardContext';
import useCheckPermissions from 'utils/useCheckPermissions';

import Validator, { Required, validate } from 'utils/Validator';

import FilterRow from './FilterRow';
import EditingRow from './EditingRow';
import Filter from './Filter';

const ListPublications = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const { formData, onChange } = useContext(IpCardContext);
	const classes = useStyles();
	const columns = [
		{ title: 'LP.', name: 'id', disableSort: true },
		{ title: 'Tytuł publikacji', name: 'title', disableSort: true },
		{ title: 'Data publikacji', name: 'publication_date', width: 220, disableSort: true },
		{ title: 'Lp. twórcy', name: 'author_index', width: 100, disableSort: true },
		{ title: 'Imię i nazwisko Twórcy', name: 'author_name', disableSort: true },
		{ title: 'ROLA', name: 'author_role', disableSort: true, width: 200 },
		{ title: 'AKCJE', name: 'action', width: 100, disableSort: true },
	];
	const [clickAdd, setClickAdd] = useState(false);

	const [data, setData] = useState([]);
	const [editingData, setEditingData] = useState(null);
	const [errors, setErrors] = useState(null);
	const [expandIndex, setExpandIndex] = useState(null);
	const [totalPagesCount, setTotalPagesCount] = useState(0);
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
	});

	const Validators = {
		title: new Validator(Required),
		publication_date: new Validator(Required),
	};

	useEffect(() => {
		setTotalPagesCount(Math.ceil(formData.ip_card_publications?.length / params.limit));
		loadData();
	}, [formData.ip_card_publications]);

	useEffect(() => {
		setErrors(null);
	}, [clickAdd]);

	useEffect(() => {
		setEditingData(null);
		loadData();
	}, [params]);

	const loadData = () => {
		const list = formData.ip_card_publications?.slice(params.page * params.limit - params.limit, params.page * params.limit);
		setData(list);
	};

	const handleChangeParams = _params => {
		const picked = _.pick(params, ['page']);
		if (_.isEqual(picked, _params)) return;

		setParams({ ...params, ..._params });
	};

	const handleAdd = (addData) => {
		let _errors = validate(addData, Validators);
		setErrors(_errors);

		if (editingData || _errors) return;

		const newItem = { ...addData, index: data.length };

		setData(prev => {
			const arr = [...prev, newItem];
			onChange('ip_card_publications', arr);
			return arr;
		});

		setClickAdd(false);
	};

	const handleClickReadMore = (index) => {
		setExpandIndex(index);
		setEditingData(null);
	};

	const handleEdit = index => () => {
		if (editingData && errors) return;
		setExpandIndex(null);
		const newData = { ...data[index], index, authors: data[index]?.authors || [] };
		setEditingData(newData);
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('ip_card_publications', prev);
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleSave = (editingRow) => {
		if (errors) return;

		setData(prev => {
			prev[editingData.index] = editingRow;
			onChange('ip_card_publications', prev);
			return [...prev];
		});

		setEditingData(null);
	};

	const handleSearchResult = (result) => {
		setData(result);
	};

	const renderRows = () => {
		return (
			data?.map((item, index) => {
				if (index === editingData?.index) {
					return (
						<EditingRow
							key={index}
							row={editingData}
							order={(params.page - 1) * params.limit + index + 1}
							onSave={handleSave}
							expandIndex={expandIndex}
							onDelete={handleDelete}
							handleClickReadMore={handleClickReadMore}
						/>
					);
				}

				return (
					<React.Fragment key={index}>
						<TableRow>
							<TableCell rowSpan={index === expandIndex ? item.authors?.length : item.authors?.slice(0, 3)?.length}>{(params.page - 1) * params.limit + index + 1}</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.authors?.length : item.authors?.slice(0, 3)?.length}>{item.title}</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.authors?.length : item.authors?.slice(0, 3)?.length}>{item.publication_date}</TableCell>
							<TableCell>{item.authors?.[0] ? 1 : ''}</TableCell>
							<TableCell>{item.authors?.[0]?.name}</TableCell>
							<TableCell>{item.authors?.[0]?.role}</TableCell>
							<TableCell rowSpan={index === expandIndex ? item.authors?.length : item.authors?.slice(0, 3)?.length}>
								<Box display="flex">
									{expandIndex !== index
										? <ExpandCircleIconButton
											disabled={item.authors?.length < 4}
											component={item.authors?.length < 4 ? 'div' : undefined}
											className={item.authors?.length < 4 ? classes.disabled : ''}
											tooltip="Rozwiń"
											onClick={() => handleClickReadMore(index)}
										/>
										: <CollapseCircleIconButton
											tooltip="Zwiń"
											onClick={() => handleClickReadMore(null)}
										/>
									}
									{(!isPreview && canCreateIpCard) &&
										<>
											<EditIconButton tooltip="Edytuj" onClick={handleEdit(index)} />
											<DeleteIconButton tooltip="Usuń" hideModal onClick={handleDelete(index)} />
										</>
									}
								</Box>
							</TableCell>
						</TableRow>

						{item.authors?.length > 1 &&
							item.authors?.slice(1, 3).map((author, authorIndex) => (
								<TableRow key={authorIndex + '_author'}>
									<TableCell>{authorIndex + 2}</TableCell>
									<TableCell>{author.name}</TableCell>
									<TableCell>{author.role}</TableCell>
								</TableRow>
							))
						}

						{index === expandIndex &&
							item.authors?.length > 3 &&
							item.authors?.slice(3).map((author, authorIndex) => (
								<TableRow key={authorIndex + '_author'}>
									<TableCell>{authorIndex + 4}</TableCell>
									<TableCell>{author.name}</TableCell>
									<TableCell>{author.role}</TableCell>
								</TableRow>
							))
						}
					</React.Fragment>
				);
			}));
	};

	return (
		<Box className={classes.section}>
			<Box display={window.isMobile ? 'block' : 'flex'} alignItems="center" mb={4}>
				<Typography variant="h3">Lista publikacji</Typography>
				{(!isPreview && canCreateIpCard) &&
					<PrimaryButton
						style={{ margin: window.isMobile ? '10px 0 0 0' : '0 0 0 20px' }}
						variant="outlined"
						onClick={() => setClickAdd(!clickAdd)}
					>
						{clickAdd ? 'Anuluj dodawanie' : 'Dodaj publikację'}
					</PrimaryButton>
				}
			</Box>

			<Filter data={formData.ip_card_publications} onSearchResult={handleSearchResult} />
			{!data
				? <Progress status={true} />
				: (
					<div id="publications">
						<PaginatedTable
							containerClassName={classes.table}
							columns={columns}
							className={classes.tbody}
							renderRows={renderRows}
							totalPagesCount={totalPagesCount}
							onChangeFilters={handleChangeParams}
							filterRow={
								clickAdd ? <FilterRow errors={errors} onAddClick={handleAdd} /> : <></>
							}
						/>
					</div>
				)
			}
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	table: {
		paddingTop: 15,
		paddingLeft: 0,
		paddingRight: 0
	},
	section: {
		background: theme.palette.white,
		padding: theme.spacing(4),
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2)
		},
	},
	tbody: {
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
		},
	},
	disabled: {
		'&.Mui-disabled': {
			'& path': {
				fill: 'gray'
			}
		}
	}
}));

export default ListPublications;
