import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Divider, makeStyles, Grid } from '@material-ui/core';
import Accordions from 'components/Main/Accordions';
import IpCardContext from 'context/IpCardContext';
import FormInput from 'components/Form/FormInput';
import FilePickModal from 'components/Main/FilePickModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Typography from 'components/Main/Typography';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import { useTranslation } from 'react-i18next';
import API from 'apis/API';
import useCheckPermissions from 'utils/useCheckPermissions';

const DetailedDescription = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const canFillComercializationIpCard = useCheckPermissions('can_fill_comercialization_ip_card', true);
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const classes = useStyles();
	const { selectedLanguage, formData, onChange, errors } = useContext(IpCardContext);
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności - opis' });

	const formInputs = [
		{
			name: 'current_phase_description',
			title: `1. ${t('Opis WI w obecnej fazie rozwoju')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'current_phase_description_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'solved_problem_description',
			title: `2. ${t('Jaki problem techniczny rozwiązuje WI')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'solved_problem_description_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'benefits_description',
			title: `3. ${t('Jakie są zalety WI')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'benefits_description_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'additional_research_description',
			title: `4. ${t('Planowane dodatkowe badania, mające na celu potwierdzenie uzyskanych wyników, rozwój lub/i zastosowanie wyników badań w praktyce')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'additional_research_description_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'intended_use_description',
			title: `5. ${t('Przewidywane zastosowanie WI')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'intended_use_description_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'competitive_solutions',
			title: `6. ${t('Kto posiada rozwiązania konkurencyjne (firma, instytucja, uczelnia)?')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'competitive_solutions_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'competitive_advantage',
			title: `7. ${t('Jakie przewagi posiada WI nad rozwiązaniami konkurencyjnymi (w punktach)?')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'competitive_advantage_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'competitive_advantage_over_ip',
			title: `8. ${t('Jakie przewagi posiadają konkurencyjne rozwiązania nad zgłaszaną WI?')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'competitive_advantage_over_ip_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'earlier_rights',
			title: `9. ${t('Proszę podać wszelkie wcześniejsze prawa do WI, jeżeli dotyczy.')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'earlier_rights_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'closest_partners',
			title: `10. ${t('Lista 5 najbliższych patentów.')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'closest_partners_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'nearest_publications',
			title: `11. ${t('Lista 5 najbliższych publikacji.')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'nearest_publications_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'all_publications_and_patents',
			title: `12. ${t('Lista wszystkich publikacji i patentów Członków Zespołu Badawczego związanych z WI.')}`,
			rows: 9,
			placeholder: t('Wpisz opis'),
			semiBold: true
		},
		{
			name: 'all_publications_and_patents_comment',
			title: t('Komentarz Podmiotu Zarządzającego'),
			rows: 6,
			placeholder: t('Wpisz opis'),
			comment: true
		},
		{
			name: 'all_publications_and_patents_attachments',
			title: t('Załączniki'),
			buttonTitle: t('DODAJ ZałącznikI'),
			type: 'file_picker'
		}
	];
	const [openModal, setOpenModal] = useState(false);
	const [attachments, setAttachments] = useState([]);

	const handleChange = e => {
		const { name, value } = e.target;
		formData.ip_card_description[name][selectedLanguage] = value;
		onChange('ip_card_description', { ...formData.ip_card_description });
	};

	const handleDownload = (data) => (e) => {
		e.preventDefault();

		API.files.view(data?.uuid).then(res => {
			const url = window.URL.createObjectURL(
				new Blob([res.data])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.name);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// // Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	};

	useEffect(() => {
		setAttachments(formData?.ip_card_description?.all_publications_and_patents_attachments || []);
	}, [formData]);

	const renderForm = (item, index) => {
		return (
			<Box key={index}>
				{item.type === 'file_picker'
					? (
						<Grid
							container
							alignItems="flex-start"
							spacing={1}
							className={classes.gutterBottom}
						>
							{item.title &&
								<Grid item xs={12} sm={4}>
									<Box display="flex" alignItems="center">
										<Typography variant="bodyM" component="p">
											{item.title}
										</Typography>
									</Box>
								</Grid>
							}
							<Grid item xs={12} sm={!item.title ? 12 : 8}>
								{attachments?.map((a, index) => {
									return (
										<Box key={index}>
											<Box display="flex" alignItems="center" justifyContent="space-between">
												<Typography>
													<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
														{index + 1}. {a.name}
													</a>
												</Typography>

												{!isPreview && canCreateIpCard &&
													<DeleteIconButton
														hideModal
														tooltip="Usuń"
														onClick={() => handleDelete(a.id)}
														title="Czy na pewno chcesz usunąć tę kartę?"
													/>
												}
											</Box>

											<Divider className={classes.divider} />
										</Box>
									);
								})}
								{!isPreview && canCreateIpCard &&
									<PrimaryButton onClick={() => setOpenModal(true)} disabled={selectedLanguage === 'en'}>
										{item.buttonTitle}
									</PrimaryButton>
								}
							</Grid>
						</Grid>
					)
					: (
						<FormInput
							key={index}
							title={item?.title}
							helperText={item?.helperText}
							name={item.name}
							value={(selectedLanguage === 'en' && formData?.ip_card_description?.[item.name]?.[selectedLanguage] === null) ? formData?.ip_card_description?.[item.name]?.pl : formData?.ip_card_description?.[item.name]?.[selectedLanguage]}
							onChange={handleChange}
							placeholder={item.placeholder}
							multiline={true}
							rows={item.rows}
							alignItems="flex-start"
							error={errors?.ip_card_description?.[item.name]}
							titleClassName={item?.semiBold ? classes.fontWeight600 : ''}
							disabled={(isPreview && !item?.comment && !canCreateIpCard) || (item?.comment && !canFillComercializationIpCard)}
						/>
					)
				}

				{(index + 1) !== formInputs.length &&
					<Divider className={classes.divider} />
				}
			</Box>
		);
	};

	const items = [
		{
			variant: 'h4',
			title: t('Opis szczegółowy'),
			child:
				<Box className={classes.root}>
					{formInputs.map(renderForm)}
				</Box>, defaultExpanded: true
		},
	];

	const selectedFile = (file) => {
		const prevData = [...attachments];
		const fileIndex = attachments.findIndex(a => a.id === file.id);

		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1, file);
		} else {
			prevData.push(file);
		}

		setAttachments(prevData);

		onChange('ip_card_description', {
			...formData.ip_card_description,
			all_publications_and_patents_attachments: prevData
		});
	};

	const handleDelete = id => {
		const prevData = [...attachments];
		const fileIndex = attachments.findIndex(a => a.id === id);
		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1);
		}

		setAttachments(prevData);
		onChange('ip_card_description', {
			...formData.ip_card_description,
			all_publications_and_patents_attachments: prevData
		});
	};

	return (
		<>
			<Accordions items={items} />
			<FilePickModal
				openModal={openModal}
				handleClose={() => setOpenModal(false)}
				selectedFile={selectedFile}
			/>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	divider: {
		marginBlock: theme.spacing(2),
	},
	gutterBottom: {
		marginBottom: 10,
	},
	fontWeight600: {
		fontWeight: 600
	},
	downloadLink: {
		color: '#002852',
		textDecoration: 'underline'
	}
}));

export default DetailedDescription;