import React, { useContext } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/Form/FormInput';
import RadioButton from 'components/Form/RadioButton';
import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: window.isMobile ? theme.spacing(0) : theme.spacing(0, 45, 0, 4),
		[theme.breakpoints.down('md')]: {
			paddingRight: theme.spacing(window.isMobile ? 0 : 4),
		},
	},
	charCount: {
		textAlign: 'right',
	},
}));

const Results = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	const data = formData[selectedLanguage];
	const achievedLevelList = [
		{ name: 'technology_outline', label: t('zarys technologii') },
		{ name: 'micro_technology', label: t('mikrotechnologia') },
		{ name: 'non_advanced_technology', label: t('technologia niezaawansowana') },
		{ name: 'intermediate_technology', label: t('technologia średniozaawansowana') },
		{ name: 'advanced_technology', label: t('technologia zaawansowana') },
		{ name: 'large_scale_technology', label: t('technologia dużej skali') },
	];

	const handleChange = e => {
		const { name, value } = e.target;
		onChange(`${selectedLanguage}.results.${name}`, value);

		if (name === 'achieved_level') {
			onChange(`en.results.${name}`, value);
		}
	};

	return (
		<Box className={classes.root}>
			<FormInput
				title={t('1.1. Opis wyników o charakterze aplikacyjnym uzyskanych w wyniku realizacji Zadania Badawczego (maks. 3000 znaków ze spacjami)')}
				name="description"
				value={data.results.description}
				onChange={handleChange}
				multiline
				minRows={10}
				maxRows={10}
				alignItems="flex-start"
				inputProps={{ maxLength: 3000, 'aria-required': true }}
				FormHelperTextProps={errors?.results?.description
					? null
					: {
						error: false,
						classes: { root: classes.charCount }
					}
				}
				errorState={!!errors?.results?.description}
				error={errors?.results?.description || `${data.results.description?.length || 0}/3000`}
				disabled={disabledInput}
			/>
			<RadioButton
				title={t('1.2. Osiągnięty poziom dojrzałości technologicznej')}
				alignItems="flex-start"
				name="achieved_level"
				list={achievedLevelList}
				value={data.results.achieved_level}
				onChange={handleChange}
				disabled={disabledInput || selectedLanguage === 'en'}
			/>
		</Box>
	);
};

export default Results;