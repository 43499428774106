import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';
import Routes from 'router/Routes';
import API from 'apis/API';
import moment from 'moment-timezone';
import { useAutosave } from 'react-autosave';

const IpCardContext = React.createContext(null);

const defaultData = {
	general_info: {
		acronym: '',
		name: '',
		sent_date: moment().format('YYYY-MM-DD'),
		received_date: '',
		registration_number: '',
		created_period: '',
		research_task_id: '',
		name_of_authors_representive: '',
		status: 'draft',
		ip_card_authors: [],
		attachments: [],
	},
	ip_card_authors: [],
	ip_card_publications: [],
	ip_card_description: {
		id: null,
		trl_opt: '',
		trl: {en: null, pl: ''},
		application_areas: {en: null, pl: ''},
		pl_keywords: {en: null, pl: ''},
		en_keywords: {en: null, pl: ''},
		current_phase_description: {en: null, pl: ''},
		current_phase_description_comment: {en: null, pl: ''},
		solved_problem_description: {en: null, pl: ''},
		solved_problem_description_comment: {en: null, pl: ''},
		benefits_description: {en: null, pl: ''},
		benefits_description_comment: {en: null, pl: ''},
		additional_research_description: {en: null, pl: ''},
		additional_research_description_comment: {en: null, pl: ''},
		intended_use_description: {en: null, pl: ''},
		intended_use_description_comment: {en: null, pl: ''},
		competitive_solutions: {en: null, pl: ''},
		competitive_solutions_comment: {en: null, pl: ''},
		competitive_advantage: {en: null, pl: ''},
		competitive_advantage_comment: {en: null, pl: ''},
		competitive_advantage_over_ip: {en: null, pl: ''},
		competitive_advantage_over_ip_comment: {en: null, pl: ''},
		earlier_rights: {en: null, pl: ''},
		earlier_rights_comment: {en: null, pl: ''},
		closest_partners: {en: null, pl: ''},
		closest_partners_comment: {en: null, pl: ''},
		nearest_publications: {en: null, pl: ''},
		nearest_publications_comment: {en: null, pl: ''},
		all_publications_and_patents: {en: null, pl: ''},
		all_publications_and_patents_comment: {en: null, pl: ''},
		all_publications_and_patents_attachments: [],
		swot_analysis: {en: null, pl: ''},
		swot_analysis_comment: {en: null, pl: ''},
		swot_analysis_attachments: [],
		pfoc_sell_rights: false,
		pfoc_license: false,
		pfoc_spinoff: false,
		pfoc_others: false,
		pfoc_others_description: {en: null, pl: ''},
	},
	ip_card_background: {
		id: null,
		other_ip_requires_additional_rights: true,
		other_ip_requires_additional_rights_description: {en: '', pl: ''},
		other_ip_legal_form: {en: '', pl: ''},
		other_ip_obstacles: {en: '', pl: ''}
	},
	ip_card_background_subjects: [],
	ip_card_background_others: [],
	ip_card_commercialization: {
		sota_report: '',
		sota_report_attachments: [],
		property_analysis: '',
		property_analysis_attachments: [],
		results_scientific_evaluation: '',
		results_scientific_evaluation_attachments: [],
		copyright_info: '',
		copyright_info_attachments: [],
		strategy: '',
		strategy_attachments: [],
		process_flow: [{'description': '', 'date': '', 'attachments': []}],
		infringements: '',
		infringements_attachments: [],
		claims_information: '',
		claims_information_attachments: [],
		feature_wi: '',
		recommend_intention: '',
		decision: '',
		decision_attachments: [],
		distribution_of_funds_a: [],
		distribution_of_funds_b: [],
		distribution_of_funds_c: [],
	}
};

export const Provider = ({ children }) => {
	const history = useHistory();
	const { i18n } = useTranslation();
	const { id } = useParams();

	const [formData, setFormData] = useState(defaultData);
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	const [researchTasks, setResearchTasks] = useState([]);
	const [userRoles, setUserRoles] = useState([]);
	const [authors, setAuthors] = useState([]);

	const [selectedLanguage, setSelectedLanguage] = useState('pl');

	useEffect(() => {
		i18n.changeLanguage(selectedLanguage);
		return () => {
			i18n.changeLanguage('pl');
		};
	}, [selectedLanguage]);

	useEffect(() => {
		API.researchTasks.getAssignments().then(res => {
			setResearchTasks(res?.data?.data);
		});
		// API.researchTasks.all().then(res => {
		// 	setResearchTasks(res?.data?.data);
		// });

		API.roles.all().then(res => {
			setUserRoles(res?.data?.data);
		});

		if (!id) {
			setFormData(JSON.parse(JSON.stringify(defaultData)));
			return;
		}
		API.ipCards.show(id).then(res => {
			if (res.data?.code === 400) {
				toast.error('Nie znaleziono');
				return;
			}

			setAuthors(res.data?.ipCardAuthors);
			setFormData({
				general_info: res.data?.ipCard,
				ip_card_authors: res.data?.ipCardAuthors || [],
				ip_card_publications: res.data?.ipCardPublications || [],
				ip_card_description: res.data?.ipCardDescription || defaultData.ip_card_description,
				ip_card_background: res.data?.ipCardBackground || defaultData.ip_card_background,
				ip_card_background_subjects: res.data?.ipCardBackgroundSubjects || [],
				ip_card_background_others: res.data?.ipCardBackgroundOthers || [],
				ip_card_commercialization: res.data?.ipCardCommercialization || defaultData.ip_card_commercialization,
			});
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChange = (key, value, customizer) => {
		setFormData(prev => {
			return { ..._.setWith(prev, key, value, customizer) };
		});
		if (key === 'ip_card_authors') {
			setAuthors(formData.ip_card_authors);
		}
	};

	const onSave = () => {
		if (saving) return;

		const authorInputElems = document.querySelectorAll('#authors input');
		const backgroundTableInputElems = document.querySelectorAll('#background-table input');
		const backgroundFormInputElems = document.querySelectorAll('#background-form input');
		const othersTableInputElems = document.querySelectorAll('#others-table input');
		const othersFormInputElems = document.querySelectorAll('#others-form input');
		const publicationsInputElems = document.querySelectorAll('#publications input');
		const commercializationTableAInputElems = document.querySelectorAll('#commercialization-tableA input');
		const commercializationTableBInputElems = document.querySelectorAll('#commercialization-tableB input');
		const commercializationTableCInputElems = document.querySelectorAll('#commercialization-tableC input');
		
		if (
			authorInputElems.length > 0 ||
			backgroundTableInputElems.length > 0 ||
			backgroundFormInputElems.length > 0 ||
			othersTableInputElems.length > 0 ||
			othersFormInputElems.length > 0 ||
			publicationsInputElems.length > 0 ||
			commercializationTableAInputElems.length > 0 ||
			commercializationTableBInputElems.length > 0 ||
			commercializationTableCInputElems.length > 0
		) {
			const authorBorderElems = document.querySelectorAll('#authors fieldset');
			
			var authorIndex = 0, authorLength = authorBorderElems.length;
			for ( ; authorIndex < authorLength; authorIndex++) {
				authorBorderElems[authorIndex].style.borderColor = '#E51A1A';
			}

			const backgroundTableBorderElems = document.querySelectorAll('#background-table fieldset');
			
			var backgroundTableindex = 0, backgroundTablelength = backgroundTableBorderElems.length;
			for ( ; backgroundTableindex < backgroundTablelength; backgroundTableindex++) {
				backgroundTableBorderElems[backgroundTableindex].style.borderColor = '#E51A1A';
			}
			
			const backgroundFormBorderElems = document.querySelectorAll('#background-form fieldset');
			
			var backgroundFormindex = 0, backgroundFormlength = backgroundFormBorderElems.length;
			for ( ; backgroundFormindex < backgroundFormlength; backgroundFormindex++) {
				backgroundFormBorderElems[backgroundFormindex].style.borderColor = '#E51A1A';
			}

			const othersTableBorderElems = document.querySelectorAll('#others-table fieldset');
			
			var othersTableindex = 0, othersTablelength = othersTableBorderElems.length;
			for ( ; othersTableindex < othersTablelength; othersTableindex++) {
				othersTableBorderElems[othersTableindex].style.borderColor = '#E51A1A';
			}
			
			const othersFormBorderElems = document.querySelectorAll('#others-form fieldset');
			
			var othersFormindex = 0, othersFormlength = othersFormBorderElems.length;
			for ( ; othersFormindex < othersFormlength; othersFormindex++) {
				othersFormBorderElems[othersFormindex].style.borderColor = '#E51A1A';
			}

			const publicationsBorderElems = document.querySelectorAll('#publications fieldset');
			
			var publicationsFormindex = 0, publicationsFormlength = publicationsBorderElems.length;
			for ( ; publicationsFormindex < publicationsFormlength; publicationsFormindex++) {
				publicationsBorderElems[publicationsFormindex].style.borderColor = '#E51A1A';
			}

			const commercializationTableABorderElems = document.querySelectorAll('#commercialization-tableA fieldset');
			
			var commercializationTableAFormindex = 0, commercializationTableAFormlength = commercializationTableABorderElems.length;
			for ( ; commercializationTableAFormindex < commercializationTableAFormlength; commercializationTableAFormindex++) {
				commercializationTableABorderElems[commercializationTableAFormindex].style.borderColor = '#E51A1A';
			}

			const commercializationTableBBorderElems = document.querySelectorAll('#commercialization-tableB fieldset');
			
			var commercializationTableBFormindex = 0, commercializationTableBFormlength = commercializationTableBBorderElems.length;
			for ( ; commercializationTableBFormindex < commercializationTableBFormlength; commercializationTableBFormindex++) {
				commercializationTableBBorderElems[commercializationTableBFormindex].style.borderColor = '#E51A1A';
			}

			const commercializationTableCBorderElems = document.querySelectorAll('#commercialization-tableC fieldset');
			
			var commercializationTableCFormindex = 0, commercializationTableCFormlength = commercializationTableCBorderElems.length;
			for ( ; commercializationTableCFormindex < commercializationTableCFormlength; commercializationTableCFormindex++) {
				commercializationTableCBorderElems[commercializationTableCFormindex].style.borderColor = '#E51A1A';
			}

			toast.error('Dane powinny zostać zatwierdzone.');

			return;
		}

		setSaving(true);

		(id ? API.ipCards.update : API.ipCards.store)(formData, id).then(saveCallback());
	};

	const saveCallback = (isPublish = false) => res => {
		setSaving(false);

		if (!_.isEmpty(res.data?.errors)) {
			if (isPublish) {
				toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
			} else {
				setFormData(prev => ({ ...prev, general_info: res.data?.data }));
				history.push(Routes.IpCards.Edit(res.data?.data?.id));
				toast.success('Wersja robocza została zapisana!');
			}
			setErrors(prev => {
				prev = {};
				_.mapKeys(res.data?.errors, (value, key) => {
					_.set(prev, key, value);
				});
				return prev;
			});

			return;
		}

		// Handle server errors:
		if (res?.response?.status && res?.response?.status !== 200) {
			toast.error(res?.message || 'Nieoczekiwany błąd.');
			return;
		}

		setFormData(prev => ({ ...prev, general_info: res.data?.data }));

		setErrors(null);
		toast.success('Karta została zapisana!');
		if (isPublish) onCancel();
	};

	const onPublish = () => {
		if (saving) return;
		setSaving(true);

		API.ipCards.publish(formData, id).then(saveCallback(true));
	};

	const onAutoSave = useCallback((data, creating) => {
		// When unmount component, react-autosave send request.
		// So implement to prevent saving as draft what published task in the backend.
		if (saving || data?.general_info?.status === 'published' || (!id && !creating)) return;
		setSaving(true);
		(id ? API.ipCards.update : API.ipCards.store)(data, id).then(res => {
			setSaving(false);
			if (res.data?.code !== 200 || id) return;
			history.push(Routes.IpCards.Edit(res.data?.data?.id));
		});
	}, []);

	useAutosave({ data: formData, onSave: onAutoSave, interval: 300000 });

	// When creating new row, save empty data and edit it.
	useEffect(() => {
		if (id) return;
		onAutoSave(formData, true);
	}, []);

	const onCancel = () => {
		history.push(Routes.IpCards.List);
	};

	const value = {
		researchTasks,
		setSelectedLanguage,
		selectedLanguage,
		formData,
		errors,
		saving,
		userRoles,
		authors,
		onChange,
		onSave,
		onCancel,
		onPublish
	};

	return (
		<IpCardContext.Provider value={value}>
			{children}
		</IpCardContext.Provider>
	);
};

export const IpCardContextProvider = withRouter(Provider);

export default IpCardContext;
