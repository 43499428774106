import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput from 'components/Form/FormInput';

const FormGroup = props => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { data, errors, ...rest } = props;
	const formInputs = [
		{
			name: 'name',
			title: t('Imię i nazwisko')
		},
		{
			name: 'phone_number',
			title: t('Telefon / fax')
		},
		{
			name: 'email',
			title: t('E-mail')
		},
	];

	return (
		formInputs.map((item, index) => (
			<FormInput
				key={index}
				title={item.title}
				name={item.name}
				value={data?.[item.name]}
				error={errors?.[item.name]}
				inputProps={{ 'aria-required': true }}
				{...rest}
			/>
		))
	);
};

export default FormGroup;