import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import Messages from 'utils/Messages';
import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';
import Select from 'components/Form/Select';

const useStyles = makeStyles(() => ({
	select: {
		maxWidth: 300
	}
}));

const FilterRow = ({ onChange }) => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - rozliczenie' });
	const statuses = [
		{ value: 'draft', label: t('Szkic') },
		{ value: 'published', label: t('Zapisany') },
	];
	const [filters, setFilters] = useState({
		settlement_date: '',
		date_from: '',
		date_to: '',
		status: '',
		controlsum: '',
	});
	const [dateError, setDateError] = useState(null);

	useEffect(() => {
		if ((!!filters.date_from && !!filters.date_to) && (filters.date_from > filters.date_to)) {
			setDateError(prev => ({ ...prev, date_to: Messages.AfterStartDate }));
			return;
		}
		setDateError(null);
		handleChangeSearch(filters);
	}, [filters]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	const handleChangeSearch = useCallback(_debounce((filters) => onChange(filters), 500), []);

	return (
		<TableRow>
			<TableCell>
				<DateInput
					name="settlement_date"
					value={filters.settlement_date}
					onChange={handleChange}
					error={dateError?.settlement_date}
					placeholder={t('Zakres od')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					name="date_from"
					value={filters.date_from}
					onChange={handleChange}
					error={dateError?.date_from}
					placeholder={t('Zakres od')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					name="date_to"
					value={filters.date_to}
					onChange={handleChange}
					error={dateError?.date_to}
					placeholder={t('Zakres do')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<Select
					containerClassName={classes.select}
					options={statuses}
					name="status"
					value={filters.status}
					onChange={handleChange}
					displayEmpty
					emptyLabel={t('Wszystkie')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<FormInput
					name="controlsum"
					value={filters.controlsum}
					onChange={handleChange}
					placeholder={t('Wpisz nazwę')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};

export default FilterRow;
