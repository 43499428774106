import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BaseInput from 'components/Form/BaseInput';
import NumberInput from 'components/Form/NumberInput';

const Row = ({ onChange, values, lastColumn, errors, className, selectedLanguage }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });

	return (
		<TableRow className={className}>
			<TableCell></TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz nazwę')}
					name="research_apparatus_name"
					value={values.research_apparatus_name}
					onChange={onChange}
					error={errors?.research_apparatus_name}
					fullWidth
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz nr pozycji')}
					name="position_nr"
					value={values.position_nr}
					onChange={onChange}
					error={errors?.position_nr}
					hideErrorText
					fullWidth
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<NumberInput
					placeholder={t('Wpisz koszt')}
					name="total_cost"
					value={values.total_cost}
					onChange={onChange}
					error={errors?.total_cost}
					hideErrorText
					fullWidth
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				{lastColumn}
			</TableCell>
		</TableRow>
	);
};

export default Row;
