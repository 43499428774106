import React, { useEffect, useState, useCallback } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BaseInput from 'components/Form/BaseInput';
import Select from 'components/Form/Select';

const FilterRow = ({ onChange }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const [filters, setFilters] = useState({
		name: '',
		acronym: '',
		registration_number: '',
		research_task_title: '',
		research_task_acronym: '',
		status: '',
	});
	const statuses = [
		{ value: 'draft', label: 'Szkic' },
		{ value: 'published', label: 'Zapisany' },
	];

	useEffect(() => {
		let _filters = Object.fromEntries(
			Object.entries(filters).filter(([value]) => !!value));
		handleChangeSearch(_filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const handleChange = e => {
		const target = e.target;
		setFilters(prev => ({ ...prev, [target.name]: target.value }));
	};

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleChangeSearch = useCallback(_debounce((filters) => onChange(filters), 500), []);

	return (
		<TableRow>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz nazwę własności intelektualnej')}
					name="name"
					value={filters.name}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz akronim')}
					name="acronym"
					value={filters.acronym}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz nr')}
					name="registration_number"
					value={filters.registration_number}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz nazwę zadania badawczego')}
					name="research_task_title"
					value={filters.research_task_title}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz akronim')}
					name="research_task_acronym"
					value={filters.research_task_acronym}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<Select
					options={statuses}
					name="status"
					valueField="value"
					labelField="label"
					value={filters?.status}
					onChange={handleChange}
					displayEmpty
					emptyLabel="Wszystkie"
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};

FilterRow.propTypes = {
	onChange: PropTypes.func,
};

export default FilterRow;
