import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

import Select from 'components/Form/Select';
import FormInput from 'components/Form/FormInput';
import DateInput from 'components/Form/DateInput';
import IpCardContext from 'context/IpCardContext';
import Accordions from 'components/Main/Accordions';
import Typography from 'components/Main/Typography';
import { useTranslation } from 'react-i18next';
import useCheckPermissions from 'utils/useCheckPermissions';

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	gutterBottom: {
		marginBottom: 10,
	},
	dateInput: {
		background: theme.palette.white,
		'& .MuiInputBase-root': {
			borderRadius: 0,
			paddingRight: 0,
			marginRight: 10,
			'& .MuiOutlinedInput-input': {
				padding: 10,
			},
			'& .MuiInputAdornment-root .MuiButtonBase-root': {
				padding: 10,
			},
			'& fieldset': {
				border: '1px solid #898989',
			},
			'&:not(.Mui-disabled)': {
				'&.Mui-focused, &:hover': {
					'& .MuiOutlinedInput-notchedOutline': {
						borderWidth: 1,
						borderColor: theme.palette.info.dark,
					},
				},
			},
		},
	}
}));

const IpCardForm = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);
	const canFillFieldDateOfReceiptIpCard = useCheckPermissions('can_fill_field_date_of_receipt_ip_card', true);
	const canFillFieldRegistrationNumberIpCard = useCheckPermissions('can_fill_field_registration_number_ip_card', true);

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { selectedLanguage, formData, errors, onChange, researchTasks } = useContext(IpCardContext);
	const classes = useStyles();

	const [data, setData] = useState({
		acronym: '',
		name: '',
		sent_date: '',
		received_date: '',
		registration_number: '',
		created_period: {
			start_date: '',
			end_date: ''
		},
		research_task_id: ''
	});
	const [researchTasksArr, setResearchTasksArr] = useState([]);

	const firstFormInputs = [
		{
			name: 'acronym',
			title: t('Akronim Własności Intelektualnej'),
			disable: isPreview || !canCreateIpCard || selectedLanguage === 'en'
		},
		{
			name: 'name',
			title: t('Pełna nazwa'),
			disable: isPreview || !canCreateIpCard || selectedLanguage === 'en'
		},
		{
			name: 'sent_date',
			title: t('Data wysłania Karty'),
			type: 'date',
			disable: isPreview || !canCreateIpCard || selectedLanguage === 'en'
		},
		{
			name: 'received_date',
			title: t('Data wpływu do Łukasiewicz - PORT'),
			type: 'date',
			disable: !canFillFieldDateOfReceiptIpCard || selectedLanguage === 'en'
		},
		{
			name: 'registration_number',
			title: t('Numer ewidencyjny'),
			disable: !canFillFieldRegistrationNumberIpCard || selectedLanguage === 'en'
		},
	];

	const secondFormInputs = [
		{
			name: 'research_task_id',
			title: t('Akronim Zadania Badawczego'),
			type: 'select',
			options: researchTasksArr,
			helperText: 'Akronim Zadania Badawczego, z którego pochodzi Własność Intelektualna',
			label: 'acronym',
			disable: isPreview || !canCreateIpCard || selectedLanguage === 'en'
		},
		{
			name: 'research_task_id',
			title: t('Nazwa Zadania Badawczego'),
			type: 'select',
			options: researchTasksArr,
			helperText: 'Nazwa Zadania Badawczego, z którego pochodzi Własność Intelektualna',
			label: 'title',
			disable: isPreview || !canCreateIpCard || selectedLanguage === 'en'
		},
	];

	useEffect(() => {
		setData(formData.general_info);
	}, [formData.general_info]);

	useEffect(() => {
		setResearchTasksArr(researchTasks);
	}, [researchTasks]);

	const handleChange = e => {
		const { name, value } = e.target;

		setData(prev => {
			if (name === 'created_period_start') {
				let createdPeriodStart = { ...prev.created_period, start_date: value };
				prev = { ...prev, created_period: createdPeriodStart };
			} else if (name === 'created_period_end') {
				let createdPeriodEnd = { ...prev.created_period, end_date: value };
				prev = { ...prev, created_period: createdPeriodEnd };
			} else {
				prev = { ...prev, [name]: value };
			}

			onChange('general_info', prev);
			return prev;
		});
	};

	const renderForm = (item, index) => {
		let input = <></>;

		switch (item?.type) {
		case 'select':
			input = <Select
				key={index}
				title={item?.title}
				name={item.name}
				options={item.options}
				value={data?.[item.name]}
				onChange={handleChange}
				valueField="id"
				labelField={item.label}
				displayEmpty
				emptyLabel={t('Wybierz z listy')}
				helperText={item?.helperText}
				error={errors?.general_info?.[item.name]}
				disabled={item?.disable}
			/>;
			break;
		case 'date':
			input = <DateInput
				fullWidth
				key={index}
				title={item?.title}
				name={item.name}
				value={data?.[item.name] || null}
				onChange={handleChange}
				error={errors?.general_info?.[item.name]}
				disabled={item?.disable}
			/>;
			break;
		default:
			input = <FormInput
				key={index}
				title={item?.title}
				helperText={item?.helperText}
				name={item.name}
				value={data?.[item.name]}
				onChange={handleChange}
				error={errors?.general_info?.[item.name]}
				disabled={item?.disable}
			/>;
		}
		return input;
	};

	const items = [
		{
			title: t('Dane podstawowe własności intelektualnej'),
			child:
				<Box className={classes.root}>
					{firstFormInputs.map(renderForm)}
					<Grid
						container
						alignItems="center"
						spacing={1}
						className={classes.gutterBottom}
					>
						<Grid item xs={window.isMobile ? 12 : 4}>
							<Box display="flex" alignItems="center">
								<Typography variant="bodyM" component="p">
									{t('Okres powstania Własności Intelektualnej')}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={window.isMobile ? 12 : 8} style={{ display: window.isMobile ? 'block' : 'flex' }}>
							<Box mr={2}>
								<KeyboardDatePicker
									fullWidth
									key="created_period_start"
									maxDate={data?.['created_period']?.end_date}
									value={data?.['created_period']?.start_date || null}
									invalidDateMessage={null}
									onChange={(momentObject) => handleChange({
										target: {
											name: 'created_period_start',
											value: !!momentObject && momentObject.isValid() ? momentObject.format('YYYY-MM-DD') : '',
										},
									})}
									inputVariant="outlined"
									variant="inline"
									className={classes.dateInput}
									format="DD/MM/YYYY"
									error={errors?.general_info?.['created_period']?.start_date}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview || !canCreateIpCard || selectedLanguage === 'en'}
									autoOk
									style={{ marginBottom: window.isMobile ? 15 : 0 }}
								/>
								{errors?.general_info?.['created_period']?.start_date &&
									<Typography variant="inputStatus" color="secondary" style={{ marginTop: 5 }}>
										{errors?.general_info?.['created_period']?.start_date}
									</Typography>
								}
							</Box>

							<Box>
								<KeyboardDatePicker
									fullWidth
									key="created_period_end"
									minDate={data?.['created_period']?.start_date}
									value={data?.['created_period']?.end_date || null}
									invalidDateMessage={null}
									onChange={(momentObject) => handleChange({
										target: {
											name: 'created_period_end',
											value: !!momentObject && momentObject.isValid() ? momentObject.format('YYYY-MM-DD') : '',
										},
									})}
									inputVariant="outlined"
									variant="inline"
									className={classes.dateInput}
									format="DD/MM/YYYY"
									error={errors?.general_info?.['created_period']?.end_date}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview || !canCreateIpCard || selectedLanguage === 'en'}
									autoOk
								/>
								{errors?.general_info?.['created_period']?.end_date &&
									<Typography variant="inputStatus" color="secondary" style={{ marginTop: 5 }}>
										{errors?.general_info?.['created_period']?.end_date}
									</Typography>
								}
							</Box>
						</Grid>
					</Grid>
					{secondFormInputs.map(renderForm)}
				</Box>, defaultExpanded: true
		},
	];

	return (
		<Accordions items={items} style={{
			paddingRight: window.isMobile ? 15 : 30,
			paddingLeft: window.isMobile ? 15 : 30,
			marginBottom: window.isMobile ? 20 : 0
		}} />
	);
};

export default IpCardForm;
