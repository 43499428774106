import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
	Modal,
	Backdrop,
	Fade,
	Grid,
	Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import API from 'apis/API';
import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import MultipleSelect from 'components/Form/MultipleSelect';
import FolderContext from 'context/FolderContext';
import Checkbox from 'components/Form/Checkbox';

const defaultData = {
	folder_id: '',
	role_ids: [],
	user_ids: [],
	read_only: true
};

const ShareModal = (props) => {
	const { openModal, handleClose, fId } = props;
	const { users, roles } = useContext(FolderContext);

	const classes = useStyles();
	const [data, setData] = useState(defaultData);
	const [saving, setSaving] = useState(false);

	const handleSubmit = () => {
		(API.sharedFolders.store)(data, fId).then(res => {
			setSaving(false);

			if (res?.data?.code === 400) {
				toast.error('Error');
				return;
			}

			if (res?.data?.code === 200) {
				handleCloseModal();
				return toast.success('Success!');
			}
		});
	};

	const handleCloseModal = () => {
		handleClose();
		setData(defaultData);
	};

	const fetchFolderDetail = (fId) => {
		API.sharedFolders.index({ folder_id: fId }).then(res => {
			if (res.data?.code === 200) {
				const { roleIds, userIds, readOnly } = res.data;
				setData({
					folder_id: fId,
					role_ids: roleIds,
					user_ids: userIds,
					read_only: readOnly
				});
			}
		});
	};

	const handleChange = (e) => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		setData(prev => ({ ...prev, [target.name]: value }));
	};

	const handleRemoveItem = ({ name, value }) => {
		setData(prev => {
			const dataIndex = prev[name]?.findIndex(i => i === value);
			prev[name].splice(dataIndex, 1);
			return { ...prev };
		});
	};

	useEffect(() => {
		if (openModal && fId) {
			fetchFolderDetail(fId);
		}

		if (!openModal) {
			setData(defaultData);
		}
	}, [openModal, fId]);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={openModal}
			onClose={handleCloseModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}} >
			<Fade in={openModal}>
				<Card className={classes.paper}>
					<Grid container>
						<Grid item xs={12} style={{ marginBottom: 25 }}>
							<Typography variant="h3">
								Udostępnij folder
							</Typography>
						</Grid>
						<Grid item xs={12} style={{ marginBottom: 20 }}>
							<MultipleSelect
								vertical={true}
								title="Udostępnij dla użytkowników o roli"
								name="role_ids"
								options={roles}
								value={data?.role_ids}
								onChange={handleChange}
								valueField="id"
								labelField="name"
								multiple
								handleRemoveItem={handleRemoveItem}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs={12}>
							<MultipleSelect
								vertical={true}
								title="Udostępnij dla użytkowników z listy"
								name="user_ids"
								options={users}
								value={data?.user_ids}
								onChange={handleChange}
								valueField="id"
								labelField="email"
								multiple
								handleRemoveItem={handleRemoveItem}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>

						<Grid item xs={12}>
							<Checkbox
								title="Tylko do odczytu"
								name="read_only"
								value={data?.read_only}
								onChange={handleChange}
							/>
						</Grid>

						<Grid item xs={12} container justifyContent='flex-end'>
							<Grid item xs={2} style={{ marginRight: 15 }}>
								<PrimaryButton
									disabled={saving}
									variant="outlined"
									fullWidth
									onClick={handleCloseModal}
								>
									ANULUJ
								</PrimaryButton>
							</Grid>
							<Grid item xs={4}>
								<PrimaryButton fullWidth onClick={handleSubmit}
								>
									UDOSTĘPNIJ FOLDER
								</PrimaryButton>
							</Grid>
						</Grid>
					</Grid>
				</Card>
			</Fade>
		</Modal>
	);
};

ShareModal.propTypes = {
	openModal: PropTypes.bool,
	handleClose: PropTypes.func,
	fId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: 700,
		margin: 'auto'
	},
	paper: {
		boxShadow: theme.shadows[5],
		padding: '25px',
		outline: 'none',
	}
}));

export default ShareModal;
