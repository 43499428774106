import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAutosave } from 'react-autosave';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import useCheckPermissions from 'utils/useCheckPermissions';
import AuthContext from './AuthContext';
import ResearchTaskReportContext from './ResearchTaskReportContext';

const ResearchTaskReportAContext = React.createContext(null);

export const Provider = ({ children }) => {
	const history = useHistory();
	const { task_id, id } = useParams();
	const { logOut } = useContext(AuthContext);
	const { isPreview, selectedLanguage, mainInfo, setMainInfo } = useContext(ResearchTaskReportContext);
	const canCreate = useCheckPermissions('can_create_report_a', true);
	const canSendAcceptance = useCheckPermissions('can_send_report_a_for_acceptance', true);
	const canAccept = useCheckPermissions('can_accept_reject_report_a', true);
	const canView = useCheckPermissions('can_view_all_reports_a', true);
	const disabledInput = isPreview || (!canCreate && canAccept);

	const [status, setStatus] = useState('draft');
	const defaultData = {
		research_team: {
			tasks_plan: [],
			phase_implementation_period: [],
			stage_descriptions: [],
			product_indicator: '',
			research_team: {
				data: [],
				reporting_description: '',
			}
		},
		results: {
			description: '',
			achieved_level: 'technology_outline',
			achieved_description: '',
			quaestor: '',
			leader: '',
			representative: '',
			complete_date: new Date(),
			relevant_information: '',
		}
	};
	const [formData, setFormData] = useState({
		pl: _.cloneDeep(defaultData),
		en: _.cloneDeep(defaultData),
		attachments: [],
	});
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		if (!canCreate && !canSendAcceptance && !canAccept && !canView) {
			return logOut();
		}
		if (!id) return;
		API.researchTasks.report.show(id).then(res => {
			let _data = res.data.data;
			setMainInfo(_.omit(_data, 'data'));
			setFormData(prev => {
				if (!_.isEmpty(_data.data?.pl)) prev.pl = _data.data.pl;
				if (!_.isEmpty(_data.data?.en)) prev.en = _data.data.en;
				if (!_.isEmpty(_data.data?.attachments)) prev.attachments = _data.data.attachments;
				return { ...prev };
			});
			setStatus(_data.status);
		});
	}, [id]);

	useEffect(() => {
		if (selectedLanguage === 'pl' || formData.en?.disable_grab_all) return;
		setFormData(prev => ({ ...prev, en: _.cloneDeep(prev.pl) }));
	}, [selectedLanguage]);

	const onChange = (key, value) => {
		setFormData(prev => {
			_.set(prev, key, value);
			if(selectedLanguage === 'en') {
				prev.en.disable_grab_all = true;
			}
			return { ...prev };
		});
	};

	const onCancel = () => {
		history.push(Routes.ResearchTasks.Reports.List(task_id));
	};

	const saveCallback = isDraft => res => {
		try {
			setSaving(false);
			if (!_.isEmpty(res.data?.errors)) {
				if (isDraft) {
					toast.success('Wersja robocza została zapisana!');
				} else {
					toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
				}
				setErrors(prev => {
					prev = {};
					_.mapKeys(res.data.errors, (value, key) => {
						_.set(prev, key, value);
					});

					return prev;
				});
				return;
			}
			setErrors(null);
			toast.success('Raport został zapisany pomyślnie');
			if (!isDraft) onCancel();
		} catch (error) {
			setSaving(false);
			toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
		}
	};

	const onSave = comments => {
		const inputElems = document.querySelectorAll('#research-task-a input');

		if (inputElems.length > 0) {
			const borderElems = document.querySelectorAll('#research-task-a fieldset');
			var index = 0, length = borderElems.length;
			for ( ; index < length; index++) {
				borderElems[index].style.borderColor = '#E51A1A';
			}
			toast.error('Dane powinny zostać zatwierdzone.');

			return;
		}

		setSaving(true);

		(status === 'waiting-for-acceptance' ? API.researchTasks.report.reject : API.researchTasks.report.update)
		// eslint-disable-next-line no-unexpected-multiline
		({ ...mainInfo, comments, data: formData }, id)
			.then(saveCallback(status !== 'waiting-for-acceptance'));
	};

	const onClickPrimary = comments => {
		setSaving(true);
		(status === 'draft' ? API.researchTasks.report.sendAcceptance : API.researchTasks.report.accept)
		// eslint-disable-next-line no-unexpected-multiline
		({ ...mainInfo, comments, data: formData }, id).then(saveCallback());
	};

	const onAutoSave = useCallback((data, creating) => {

		if (
			isPreview ||
			(data.status && data.status !== 'draft') ||
			!canCreate ||
			(!id && !creating) ||
			saving ||
			status !== 'draft' ||
			(!canCreate && status === 'draft')
		)
			return;


		setSaving(true);

		(id ? API.researchTasks.report.update : API.researchTasks.report.store)
		// eslint-disable-next-line no-unexpected-multiline
		(data, id).then(res => {
			setSaving(false);
			if (id) return;
			history.push(Routes.ResearchTasks.Reports.Edit(task_id, 'type_a', res.data.data.id));
		});
	}, []);

	const autoSaveData = useMemo(() => ({ ...mainInfo, data: formData }), [mainInfo, formData]);
	useAutosave({ data: autoSaveData, onSave: onAutoSave, interval: 300000, saveOnUnmount: false });

	// When creating new row, save empty data and edit it.
	useEffect(() => {
		if (id) return;
		onAutoSave({ ...mainInfo, data: formData }, true);
	}, []);

	const value = {
		formData: formData,
		errors: {
			period_from: errors?.period_from,
			period_to: errors?.period_to,
			results: errors?.data?.[selectedLanguage]?.results,
			research_team: errors?.data?.[selectedLanguage]?.research_team
		},
		isPreview,
		selectedLanguage,
		saving,
		status,
		disabledInput,
		onChange,
		onSave,
		onClickPrimary,
		onCancel,
	};

	return (
		<ResearchTaskReportAContext.Provider value={value}>
			{children}
		</ResearchTaskReportAContext.Provider>
	);
};

export const ResearchTaskReportAContextProvider = withRouter(Provider);

export default ResearchTaskReportAContext;
