import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordions from 'components/Main/Accordions';

import PhaseImplementationPeriod from './PhaseImplementationPeriod';
import AchievementsWorkDescription from './AchievementsWorkDescription';
import ResearchTeam from './ResearchTeam';
import AchievedIndicators from './AchievedIndicators';

const ResearchTeamTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const items = [
		{
			title: t('1. Okres realizacji etapów (od początku realizacji Zadania Badawczego)'),
			child: <PhaseImplementationPeriod />,
			defaultExpanded: true
		},
		{
			title: t('2. Dotychczasowe osiągnięcia i opis prac w etapie'),
			child: <AchievementsWorkDescription />,
		},
		{
			title: t('3. Zespół badawczy'),
			child: <ResearchTeam />,
		},
		{
			title: t('4. Osiągnięte wskaźniki realizacji Zadania Badawczego'),
			child: <AchievedIndicators />,
		},
	];
	return (
		<Accordions items={items} />
	);
};

export default ResearchTeamTab;