import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Routes from 'router/Routes';
import useCheckPermissions from 'utils/useCheckPermissions';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import LanguageButton from 'components/Buttons/LanguageButton';
import Tabs from 'components/Main/Tabs';
import Accordions from 'components/Main/Accordions';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';
import MainInfo from '../MainInfo';
import CommentModal from '../CommentModal';
import GeneralInfoTab from './GeneralInfoTab';
import ResearchTeamTab from './ResearchTeamTab';
import FinalInfoTab from './FinalInfoTab';
import AttachmentsTab from './AttachmentsTab';
import Exports from './Exports';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%',
	},
	accordionBG: {
		padding: '0 15px',
	}
}));

const ReportA = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const { task_id } = useParams();
	const classes = useStyles();
	const { researchTask, selectedLanguage, setSelectedLanguage, isPreview } = useContext(ResearchTaskReportContext);
	const { onCancel, onSave, onClickPrimary, saving, errors, status, disabledInput } = useContext(ResearchTaskReportAContext);
	const canFullyManage = useCheckPermissions('can_fully_manage_entities', true);

	const title = t('Dodaj raport A');
	const breadcrumbs = [
		{ title: t('Zadania badawcze'), to: Routes.ResearchTasks.List },
		{ title: `${t('Raportowanie')} - ${researchTask?.acronym}`, to: Routes.ResearchTasks.Reports.List(task_id) },
		{ title },
	];
	const tabs = [
		{ value: 'general_info', label: t('A informacje ogólne'), children: <GeneralInfoTab /> },
		{ value: 'research_team', label: t('B realizacja zadania badawczego i zespół badawczy'), children: <ResearchTeamTab /> },
		{ value: 'final_info', label: t('C informacje końcowe'), children: <FinalInfoTab /> },
		{ value: 'attachments', label: t('D załączniki'), children: <AttachmentsTab /> },
	];

	const items = [
		{ title: t('A informacje ogólne').toUpperCase(), child: <GeneralInfoTab /> },
		{ title: t('B realizacja zadania badawczego i zespół badawczy').toUpperCase(), child: <ResearchTeamTab /> },
		{ title: t('C informacje końcowe').toUpperCase(), child: <FinalInfoTab /> },
		{ title: t('D załączniki').toUpperCase(), child: <AttachmentsTab /> },
	];

	const [openModal, setOpenModal] = useState(false);
	const [isClickedPrimary, setIsClickedPrimary] = useState(false);

	useEffect(() => {
		if (isPreview) return document.title = 'WIB - Podgląd Raportu A';

		document.title = 'WIB - Edycja Raportu A';
	}, []);

	const handleClick = (callback, isPrimary) => () => {
		if (isPreview) return;
		if (status === 'draft' || (status === 'accepted' && canFullyManage)) return callback();

		setIsClickedPrimary(isPrimary);
		setOpenModal(true);
	};

	const handleCloseModal = () => setOpenModal(false);

	const handleSaveComments = comments => {
		(isClickedPrimary ? onClickPrimary : onSave)(comments);
		handleCloseModal();
	};

	return (
		<>
			<Box className={classes.content}>
				<Header title={title}>
					<LanguageButton onChange={setSelectedLanguage} />
				</Header>
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				<MainInfo errors={errors} disabled={disabledInput} />

				{window.isMobile
					? <Accordions items={items} TransitionProps={{ unmountOnExit: true }} className={classes.accordionBG} />
					: <Tabs tabs={tabs} />
				}

				<Exports locale={selectedLanguage} />
			</Box>
			<ControlButtonGroup
				primaryTitle={status === 'draft' ? 'Wyślij do akceptacji' : 'Zaakceptuj raport'}
				saveTitle={status === 'waiting-for-acceptance' ? 'ODRZUĆ' : undefined}
				onClickPrimary={handleClick(onClickPrimary, true)}
				onSave={handleClick(onSave)}
				onCancel={onCancel}
				saving={isPreview || saving}
				hidePrimaryButton={status === 'accepted'}
				hideSaveButton={status === 'accepted' && !canFullyManage}
			/>
			<CommentModal
				openModal={openModal}
				onClose={handleCloseModal}
				onSave={handleSaveComments}
			/>
		</>
	);
};

export default withRouter(ReportA);
