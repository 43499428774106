import React from 'react';
import { Box } from '@material-ui/core';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Routes from 'router/Routes';

import IpCardForm from '../IpCardForm';
import List from './List';

const breadcrumbs = [
	{ title: 'Rejestr Własności Intelektualnej', to: Routes.IpCards.List },
	{ title: 'Własność Intelektualna WI 01' },
	{ title: 'Lista publikacji' },
];

const Publications = () => {
	return (
		<Box>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<Box>
				<IpCardForm />

				<List />
			</Box>
		</Box>
	);
};

export default Publications;
