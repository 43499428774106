import React from 'react';
import FolderTree from './FolderTree';
import { Box, Grid } from '@material-ui/core';
import { FolderContextProvider } from 'context/FolderContext';
import FilesList from 'pages/Files/FilesList';

const FileManager = (props) => {
	const { onFileSelected, refreshFileManager } = props;

	return (
		<FolderContextProvider>
			<Grid container>
				<Box mr={3}>
					<FolderTree />
				</Box>

				<Box style={{ flex: '1 1' }}>
					<FilesList onFileSelected={onFileSelected} refreshFileManager={refreshFileManager} />
				</Box>
			</Grid>
		</FolderContextProvider>
	);
};

export default FileManager;
