import React, { useContext, useEffect, useState } from 'react';
import Typography from 'components/Main/Typography';
import { Divider, Grid, makeStyles, Box } from '@material-ui/core';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Select from 'components/Form/Select';
import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import IpCardContext from 'context/IpCardContext';
import { useTranslation } from 'react-i18next';

const Form = ({ handleAddSubject, values, onSave }) => {
	const CHARACTER_LIMIT = 4000;

	const { selectedLanguage, onChange, formData } = useContext(IpCardContext);
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const [data, setData] = useState({
		id: null,
		type: '',
		priority_date: null,
		number: '',
		title_pl: '',
		title_en: '',
		comment: {en: null, pl: ''},
		document_in_urp_epo_database: {en: null, pl: ''},
		processing_stage_in_pl_epo: {en: null, pl: ''},
		authors: [{
			name: '',
		}],
		owners: [{
			name: '',
			acceptance: true,
			legal_form: ''
		}],
		is_background_ip_an_obstacle: 1,
		usage_in_project: {en: null, pl: ''},
		connected_legal_documents: {en: null, pl: ''}
	});
	const subjectTypes = [
		{ value: 'Zgłoszenie patentowe', label: t('Zgłoszenie patentowe') },
		{ value: 'Patent', label: t('Patent') },
		{ value: 'Wzór użytkowy', label: t('Wzór użytkowy') },
		{ value: 'Wzór przemysłowy', label: t('Wzór przemysłowy') },
		{ value: 'Udokumentowane know-how', label: t('Udokumentowane know-how') },
		{ value: 'Inne', label: t('Inne') },
	];
	const booleanOptions = [
		{ value: true, label: t('Tak') },
		{ value: false, label: t('Nie') },
	];
	const classes = useStyles();

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'comment' || name === 'document_in_urp_epo_database' || name === 'processing_stage_in_pl_epo' || name === 'usage_in_project' || name === 'connected_legal_documents') {
			let prevData = {...data};
			prevData[name][selectedLanguage] = value;
			setData(() => ({ ...prevData }));
		} else {
			setData(prev => ({ ...prev, [name]: value }));
		}
	};

	const handleAddOwner = () => {
		const newItem = {
			name: '',
			acceptance: true,
			legal_form: ''
		};

		setData(prev => ({ ...prev, owners: [...prev.owners, newItem] }));
	};

	const handleChangeOwner = index => e => {
		const { name, value } = e.target;
		if (name === 'legal_form') {
			setData(prev => {
				const owners = [...prev.owners];
				typeof(owners[index][name]) === 'string' && (owners[index][name] = {'en': null, 'pl': ''});
				owners[index][name][selectedLanguage] = value;
				return { ...prev, owners };
			});
		} else {
			setData(prev => {
				const owners = [...prev.owners];
				owners[index][name] = value;
				return { ...prev, owners };
			});
		}
	};

	const handleDeleteOwner = index => {
		setData(prev => {
			prev.owners.splice(index, 1);
			return { ...prev };
		});
	};

	const handleAddAuthor = () => {
		const newItem = {
			name: '',
		};

		setData(prev => ({ ...prev, authors: [...prev.authors, newItem] }));
	};

	const handleChangeAuthor = index => e => {
		const { name, value } = e.target;

		setData(prev => {
			const authors = [...prev.authors];
			authors[index][name] = value;
			return { ...prev, authors };
		});
	};

	const handleDeleteAuthor = index => {
		setData(prev => {
			prev.authors.splice(index, 1);
			return { ...prev };
		});
	};

	const handleAdd = () => {
		const newItem = { ...data, index: formData.ip_card_background_subjects?.length };
		onChange('ip_card_background_subjects', [...formData.ip_card_background_subjects, newItem]);
		handleAddSubject();
	};

	useEffect(() => {
		if (values) {
			const editingData = JSON.parse(JSON.stringify(values));
			setData(
				{
					...editingData,
					owners: editingData?.owners || [{
						name: '',
						acceptance: true,
						legal_form: ''
					}],
					authors: editingData?.authors || [{
						name: '',
					}],
				}
			);
		}
	}, [values]);

	return (
		<Grid container>
			<Grid item xs={window.isMobile ? 12 : 2} style={{ marginRight: 100 }}>
				<Typography variant="h3">
					{values ? t('Edytuj') : t('Dodaj')} {t('Background IP')}
				</Typography>

				<Typography style={{ fontSize: 16 }}>
					{t('Przedmiot praw powiązanych z Własnością Intelektualną')}
				</Typography>
			</Grid>

			<Grid item xs={window.isMobile ? 12 : 7}>
				<Grid container spacing={3}>
					<Grid item xs={window.isMobile ? 12 : 5}>
						<Select
							vertical={true}
							title={t('Typ')}
							gutterBottom={false}
							name="type"
							options={subjectTypes}
							hideErrorText
							displayEmpty
							emptyLabel={t('Wybierz typ')}
							value={data.type}
							onChange={handleChange}
							disabled={selectedLanguage === 'en'}
						/>
					</Grid>
					<Grid item xs={window.isMobile ? 12 : 4}>
						<DateInput
							title={t('Data pierwszeństwa')}
							name="priority_date"
							value={data.priority_date}
							onChange={handleChange}
							vertical={true}
							disabled={selectedLanguage === 'en'}
						/>
					</Grid>
					<Grid item xs={window.isMobile ? 12 : 2}>
						<FormInput
							title={t('Numer')}
							name="number"
							value={data.number || ''}
							onChange={handleChange}
							vertical={true}
							disabled={selectedLanguage === 'en'}
						/>
					</Grid>
				</Grid>
				<Grid item xs={window.isMobile ? 12 : 11}>
					<FormInput
						title={t('Tytuł w języku polskim')}
						name="title_pl"
						value={data.title_pl || ''}
						onChange={handleChange}
						vertical={true}
						multiline={true}
						rows={4}
						disabled={selectedLanguage === 'en'}
					/>
					<FormInput
						title={t('Tytuł w języku angielskim')}
						name="title_en"
						value={data.title_en || ''}
						onChange={handleChange}
						vertical={true}
						multiline={true}
						rows={4}
						disabled={selectedLanguage === 'en'}
					/>
					<FormInput
						title={t('Dokument w bazie UPRP, EPO, innej właściwej')}
						name="document_in_urp_epo_database"
						value={(selectedLanguage === 'en' && data.document_in_urp_epo_database[selectedLanguage] === null) ? data.document_in_urp_epo_database.pl : data.document_in_urp_epo_database[selectedLanguage]}
						onChange={handleChange}
						vertical={true}
					/>
					<FormInput
						title={t('Etap postępowania przed urzędem PL/EPO, USA, inne')}
						name="processing_stage_in_pl_epo"
						value={(selectedLanguage === 'en' && data.processing_stage_in_pl_epo[selectedLanguage] === null) ? data.processing_stage_in_pl_epo.pl : data.processing_stage_in_pl_epo[selectedLanguage]}
						onChange={handleChange}
						vertical={true}
					/>
				</Grid>

				{data?.owners?.map((item, index) => {
					return (
						<Box key={index}>
							<Grid container spacing={3} alignItems="flex-start">
								<Grid item xs={window.isMobile ? 12 : 1} style={{ marginTop: 4 }}>{index + 1}.</Grid>
								<Grid item xs={window.isMobile ? 12 : 6}>
									<FormInput
										gutterBottom={false}
										title={t('Właściciel (imię i nazwisko) Background IP')}
										name="name"
										value={item.name}
										onChange={handleChangeOwner(index)}
										vertical={true}
										disabled={selectedLanguage === 'en'}
									/>
								</Grid>
								<Grid item xs={window.isMobile ? 10 : 4}>
									<Select
										gutterBottom={false}
										name="acceptance"
										title={t('Zgoda właściciela')}
										options={booleanOptions}
										value={item.acceptance}
										onChange={handleChangeOwner(index)}
										vertical={true}
										disabled={selectedLanguage === 'en'}
									/>
								</Grid>
								<Grid item xs={1} style={{ marginTop: 42 }}>
									{index !== 0 &&
										<DeleteIconButton
											tooltip="Usuń"
											hideModal
											onClick={() => handleDeleteOwner(index)}
										/>
									}
								</Grid>
							</Grid>
							<Grid container spacing={3}>
								{!window.isMobile &&
									<Grid item xs={1}></Grid>
								}
								<Grid item xs={window.isMobile ? 12 : 10}>
									<FormInput
										title={t('Forma prawna udostepnienia przez Własciciela Background IP')}
										name="legal_form"
										value={(selectedLanguage === 'en' && item.legal_form[selectedLanguage] === null) ? item.legal_form.pl : item.legal_form[selectedLanguage]}
										onChange={handleChangeOwner(index)}
										vertical={true}
									/>
								</Grid>
							</Grid>

							{index !== (data.owners.length - 1) &&
								<Grid item xs={window.isMobile ? 12 : 11}>
									<Divider className={classes.divider} />
								</Grid>
							}
						</Box>
					);
				})}

				<PrimaryButton onClick={handleAddOwner} style={{marginBottom: 15}} disabled={selectedLanguage === 'en'}>
					{t('DODAJ Kolejnego właściciela')}
				</PrimaryButton>

				{data?.authors?.map((item, index) => {
					return (
						<Box key={index}>
							<Grid container spacing={3} alignItems={window.isMobile ? 'center' : 'flex-start'}>
								<Grid item xs={window.isMobile ? 12 : 1} style={{ marginTop: 4 }}>{index + 1}.</Grid>
								<Grid item xs={10}>
									<FormInput
										title={t('Twórca (imię i nazwisko) Background IP')}
										name="name"
										value={item.name}
										onChange={handleChangeAuthor(index)}
										vertical={true}
										disabled={selectedLanguage === 'en'}
									/>
								</Grid>
								<Grid item xs={1} style={{ marginTop: 42 }}>
									{index !== 0 &&
										<DeleteIconButton
											tooltip="Usuń"
											hideModal
											onClick={() => handleDeleteAuthor(index)}
										/>
									}
								</Grid>
							</Grid>

							{index !== (data.authors.length - 1) &&
								<Grid item xs={window.isMobile ? 12 : 11}>
									<Divider className={classes.divider} />
								</Grid>
							}
						</Box>
					);
				})}

				<PrimaryButton onClick={handleAddAuthor} style={{ marginTop: 5 }} disabled={selectedLanguage === 'en'}>
					{t('DODAJ Kolejnego TWÓRCĘ')}
				</PrimaryButton>

				<Grid item xs={window.isMobile ? 12 : 11} className={classes.marginTop}>
					<Select
						name="is_background_ip_an_obstacle"
						title={t('Czy Background IP będzie stanowiło przeszkodę w komercjalizacji efektów Zadania Badawczego?')}
						options={booleanOptions}
						value={data.is_background_ip_an_obstacle}
						onChange={handleChange}
						vertical={true}
						disabled={selectedLanguage === 'en'}
					/>
				</Grid>

				<Grid item xs={window.isMobile ? 12 : 11} className={classes.marginTop}>
					<Box position="relative">
						<FormInput
							title={t('W jaki sposób background IP zostanie wykorzystane w Projekcie? Czy będzie stanowić element IP w komercjalizacji? Czy będzie jedynie narzędziem do wyników badań?')}
							name="usage_in_project"
							value={(selectedLanguage === 'en' && data.usage_in_project[selectedLanguage] === null) ? data.usage_in_project.pl : data.usage_in_project[selectedLanguage]}
							onChange={handleChange}
							vertical={true}
							multiline={true}
							inputProps={{
								maxLength: CHARACTER_LIMIT
							}}
							rows={15}
							errorState={false}
							error={`${data?.usage_in_project?.length || 0}/${CHARACTER_LIMIT}`}
							FormHelperTextProps={{
								error: false,
								classes: { root: classes.charCount }
							}}
						/>
					</Box>

					<FormInput
						title={t('Powiązane dokumenty prawne (przekierowanie do bazy/rejestru/magazynu)')}
						name="connected_legal_documents"
						value={(selectedLanguage === 'en' && data.connected_legal_documents[selectedLanguage] === null) ? data.connected_legal_documents.pl : data.connected_legal_documents[selectedLanguage]}
						onChange={handleChange}
						vertical={true}
						multiline={true}
						rows={4}
					/>

					<FormInput
						title={t('Komentarz zgłaszającego')}
						name="comment"
						value={(selectedLanguage === 'en' && data.comment[selectedLanguage] === null) ? data.comment.pl : data.comment[selectedLanguage]}
						onChange={handleChange}
						vertical={true}
						multiline={true}
						rows={4}
					/>
				</Grid>

				<PrimaryButton onClick={values ? () => onSave(data) : () => handleAdd()}>
					{values ? t('ZAPISZ ZMIANY') : t('ZAPISZ')}
				</PrimaryButton>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles(theme => ({
	divider: {
		marginBlock: theme.spacing(1),
	},
	marginTop: {
		marginTop: 15
	},
	charCount: {
		textAlign: 'right'
	}
}));

export default Form;