import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import API from 'apis/API';
import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { DownloadIcon } from 'assets/svg';

const useStyles = makeStyles((theme) => ({
	button: {
		paddingBlock: theme.spacing(0.5),
		marginRight: theme.spacing(1),
		background: theme.palette.white
	},
}));

const Exports = ({locale}) => {
	const { id } = useParams();
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const [isExporting, setIsExporting] = useState({});

	const exportXlsx = async (type) => {
		setIsExporting(prev => ({...prev, [type]: true}));
		try {
			const res = await API.researchTasks.report.exportA(type, id, locale);

			// If you want to download file automatically using link attribute.
			const link = document.createElement('a');
			const url = URL.createObjectURL(new Blob([res.data]));
			link.href = url;
			link.setAttribute('download', `${t('Raport A')}.${type}`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toast.error('Sorry, something went wrong.');
		}
		setIsExporting(prev => ({...prev, [type]: false}));
	};

	return (
		<Box display={window.isMobile ? 'block' : 'flex'} alignItems="center" mt={4}>
			<Typography variant="bodyS" style={{ marginRight: 10 }}>Pobierz formularz jako:</Typography>

			<PrimaryButton
				variant="outlined"
				startIcon={<DownloadIcon />}
				className={classes.button}
				onClick={id ? () => exportXlsx('pdf') : null}
				tooltip={!id ? 'Aby wygenerować plik pdf należy najpierw zapisać szkic' : ''}
				disabled={Boolean(isExporting?.pdf)}
			>
				pdf
			</PrimaryButton>
			<PrimaryButton
				variant="outlined"
				startIcon={<DownloadIcon />}
				className={classes.button}
				onClick={id ? () => exportXlsx('xlsx') : null}
				tooltip={!id ? 'Aby wygenerować plik xlsx należy najpierw zapisać szkic' : ''}
				disabled={Boolean(isExporting?.xlsx)}
			>
				xlsx
			</PrimaryButton>
			<PrimaryButton
				variant="outlined"
				startIcon={<DownloadIcon />}
				className={classes.button}
				onClick={id ? () => exportXlsx('html') : null}
				tooltip={!id ? 'Aby wygenerować plik html należy najpierw zapisać szkic' : ''}
				disabled={Boolean(isExporting?.html)}
			>
				html
			</PrimaryButton>

		</Box>
	);
};

export default Exports;
