import React, { useState, useContext, useEffect } from 'react';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import {
	DeleteIconButton,
	EditIconButton,
	SaveIconButton
} from 'components/Buttons/IconButtons';
import PaginatedTable from 'components/PaginatedTable';
import Progress from 'components/Main/Progress';
import IpCardContext from 'context/IpCardContext';
import DateInput from 'components/Form/DateInput';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useCheckPermissions from 'utils/useCheckPermissions';
import NumberInput from 'components/Form/NumberInput';
import NumberText from 'components/Main/NumberText';

const defaultData = {
	payment_period_from: null,
	payment_period_to: null,
	payment_amount: ''
};

const ListData = () => {
	const { formData, onChange } = useContext(IpCardContext);
	const classes = useStyles();
	const columns = [
		{ title: 'Fundusz Polskiej Nauki', name: 'polish_fund', disableSort: true },
		{ title: 'LP. OKRESU', name: 'data_index', width: 100, disableSort: true },
		{ title: 'Okres wypłaty OD', name: 'payment_period_from', width: 200, disableSort: true },
		{ title: 'Okres wypłaty DO', name: 'payment_period_to', disableSort: true, width: 200 },
		{ title: 'Kwota wypłaty', name: 'payment_amount', disableSort: true, width: 200 },
		{ title: 'Razem wypłacone środki', name: 'total_payment_amount', disableSort: true, width: 200 },
	];
	const canFillComercializationIpCard = useCheckPermissions('can_fill_comercialization_ip_card', true);

	const [data, setData] = useState([]);
	const [editingData, setEditingData] = useState(null);

	const [addData, setAddData] = useState(defaultData);
	const [clickAdd, setClickAdd] = useState(false);

	const handleChange = e => {
		const { name, value } = e.target;

		setAddData(prev => {
			return { ...prev, [name]: value };
		});
	};

	const handleChangeEditingData = e => {
		const { name, value } = e.target;

		setEditingData(prev => {
			return { ...prev, [name]: value };
		});
	};

	const handleAdd = () => {
		if (editingData) return;

		const newItem = { ...addData, index: data.length };

		setData(prev => {
			const arr = [...prev, newItem];
			onChange('ip_card_commercialization', { ...formData.ip_card_commercialization, distribution_of_funds_c: arr });
			return arr;
		});

		setAddData(defaultData);
		setClickAdd(false);
	};

	const handleEdit = index => () => {
		if (editingData) return;

		const newData = { ...data[index], index };
		setEditingData(newData);
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('ip_card_commercialization', { ...formData.ip_card_commercialization, distribution_of_funds_c: prev });
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleSave = () => {
		setData(prev => {
			prev[editingData.index] = editingData;
			onChange('ip_card_commercialization', { ...formData.ip_card_commercialization, distribution_of_funds_c: prev });
			return [...prev];
		});

		setEditingData(null);
	};

	useEffect(() => {
		setData(formData.ip_card_commercialization?.distribution_of_funds_c);
	}, [formData.ip_card_commercialization?.distribution_of_funds_c]);

	useEffect(() => {
		setAddData(defaultData);
	}, [clickAdd]);

	const FilterRow = () => {
		return (
			<TableRow>
				<TableCell></TableCell>
				<TableCell></TableCell>
				<TableCell>
					<DateInput
						fullWidth
						name="payment_period_from"
						value={addData.payment_period_from || null}
						onChange={handleChange}
						hideErrorText
						gutterBottom={false}
						disabled={!canFillComercializationIpCard}
					/>
				</TableCell>
				<TableCell>
					<DateInput
						fullWidth
						name="payment_period_to"
						value={addData.payment_period_to || null}
						onChange={handleChange}
						hideErrorText
						gutterBottom={false}
						disabled={!canFillComercializationIpCard}
					/>
				</TableCell>
				<TableCell>
					<NumberInput
						hideErrorText
						fullWidth
						placeholder="Wpisz kwotę"
						name="payment_amount"
						value={addData.payment_amount}
						onChange={handleChange}
						autoFocus={true}
						disabled={!canFillComercializationIpCard}
					/>
				</TableCell>
				<TableCell>
					{canFillComercializationIpCard &&
						<SaveIconButton tooltip="Zatwierdź" onClick={handleAdd} />
					}
				</TableCell>
			</TableRow>
		);
	};

	const renderRows = () => {
		let paymentTotalAmount = data?.map(s => s.payment_amount).reduce((accumVariable, curValue) => {
			return Number(accumVariable) + Number(curValue);
		}, 0);

		return (
			data?.slice(0, 1)?.map((item, index) => {
				return (
					<React.Fragment key={index}>
						{index === editingData?.index
							? (
								<TableRow>
									<TableCell rowSpan={data?.length ? (data.length + 1) : 1}></TableCell>
									<TableCell>{index + 1}</TableCell>
									<TableCell>
										<DateInput
											fullWidth
											name="payment_period_from"
											value={editingData?.payment_period_from || null}
											onChange={handleChangeEditingData}
											hideErrorText
											gutterBottom={false}
											disabled={!canFillComercializationIpCard}
										/>
									</TableCell>
									<TableCell>
										<DateInput
											fullWidth
											name="payment_period_to"
											value={editingData?.payment_period_to || null}
											onChange={handleChangeEditingData}
											hideErrorText
											gutterBottom={false}
											disabled={!canFillComercializationIpCard}
										/>
									</TableCell>
									<TableCell>
										<Box display="flex" justifyContent="space-between">
											<Box mr={2}>
												<NumberInput
													hideErrorText
													fullWidth
													placeholder="Wpisz kwotę"
													name="payment_amount"
													value={editingData?.payment_amount}
													onChange={handleChangeEditingData}
													disabled={!canFillComercializationIpCard}
												/>
											</Box>

											{canFillComercializationIpCard &&
												<Box display="flex">
													<SaveIconButton tooltip="Zatwierdź" onClick={handleSave} />
													<DeleteIconButton tooltip="Usuń" hideModal onClick={handleDelete(index)} />
												</Box>
											}
										</Box>
									</TableCell>
									<TableCell rowSpan={data?.length || 1}>
										<NumberText value={paymentTotalAmount} fixedDecimalScale={true} />
									</TableCell>
								</TableRow>
							)
							: (
								<TableRow>
									<TableCell rowSpan={data?.length ? (data.length + 1) : 1}></TableCell>
									<TableCell>{index + 1}</TableCell>
									<TableCell>{item?.payment_period_from}</TableCell>
									<TableCell>{item?.payment_period_to}</TableCell>
									<TableCell>
										<Box display="flex" justifyContent="space-between">
											<Box>
												<NumberText value={item?.payment_amount} fixedDecimalScale={true} />
											</Box>

											{canFillComercializationIpCard &&
												<Box>
													<EditIconButton tooltip="Edytuj" onClick={handleEdit(index)} />
													<DeleteIconButton tooltip="Usuń" hideModal onClick={handleDelete(index)} />
												</Box>
											}
										</Box>
									</TableCell>
									<TableCell rowSpan={data?.length || 1}>
										<NumberText value={paymentTotalAmount} fixedDecimalScale={true} />
									</TableCell>
								</TableRow>
							)
						}

						{data?.length > 1 &&
							data?.slice(1).map((entry, entryIndex) => (
								(entryIndex + 1) === editingData?.index
									? (
										<TableRow key={entryIndex + '_entry'}>
											<TableCell>{entryIndex + 2}</TableCell>
											<TableCell>
												<DateInput
													fullWidth
													name="payment_period_from"
													value={editingData?.payment_period_from || null}
													onChange={handleChangeEditingData}
													hideErrorText
													gutterBottom={false}
												/>
											</TableCell>
											<TableCell>
												<DateInput
													fullWidth
													name="payment_period_to"
													value={editingData?.payment_period_to || null}
													onChange={handleChangeEditingData}
													hideErrorText
													gutterBottom={false}
												/>
											</TableCell>
											<TableCell>
												<Box display="flex" justifyContent="space-between">
													<Box mr={2}>														
														<NumberInput
															hideErrorText
															fullWidth
															placeholder="Wpisz kwotę"
															name="payment_amount"
															value={editingData?.payment_amount}
															onChange={handleChangeEditingData}
														/>
													</Box>

													{canFillComercializationIpCard &&
														<Box display="flex">
															<SaveIconButton tooltip="Zatwierdź" onClick={handleSave} />
															<DeleteIconButton tooltip="Usuń" hideModal onClick={handleDelete(entryIndex + 1)} />
														</Box>
													}
												</Box>
											</TableCell>
										</TableRow>
									)
									: (
										<TableRow key={entryIndex + '_entry'}>
											<TableCell>{entryIndex + 2}</TableCell>
											<TableCell>{entry.payment_period_from}</TableCell>
											<TableCell>{entry.payment_period_to}</TableCell>
											<TableCell>
												<Box display="flex" justifyContent="space-between">
													<Box>
														<NumberText value={entry?.payment_amount} fixedDecimalScale={true} />
													</Box>

													{canFillComercializationIpCard &&
														<Box>
															<EditIconButton tooltip="Edytuj" onClick={handleEdit(entryIndex + 1)} />
															<DeleteIconButton tooltip="Usuń" hideModal onClick={handleDelete(entryIndex + 1)} />
														</Box>
													}
												</Box>
											</TableCell>
										</TableRow>
									)
							))
						}
					</React.Fragment>
				);
			}));
	};

	return (
		<Box>
			<Box display="flex" alignItems="center">
				{canFillComercializationIpCard &&
					<PrimaryButton
						style={{ marginTop: 20 }}
						variant="outlined"
						onClick={() => setClickAdd(!clickAdd)}
					>
						{clickAdd ? 'Anuluj dodawanie' : 'Dodaj okres'}
					</PrimaryButton>
				}
			</Box>

			{!data
				? <Progress status={true} />
				: (
					<div id="commercialization-tableC">
						<PaginatedTable
							containerClassName={classes.table}
							columns={columns}
							className={classes.tbody}
							renderRows={renderRows}
							filterRow={
								clickAdd ? <FilterRow /> : <></>
							}
						/>
					</div>
				)
			}
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	table: {
		paddingTop: 15,
		paddingLeft: 0,
		paddingRight: 0,
		paddingBottom: 5,
	},
	tbody: {
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
		},
	},
}));

export default ListData;
