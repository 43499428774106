import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/Form/FormInput';

import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';
import Table from './Table';
import Typography from 'components/Main/Typography';

const ResearchTeam = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	
	return (
		<Box>
			{errors?.research_team?.research_team?.data &&
				<Typography variant="inputStatus" color="secondary" style={{ marginBottom: 16 }}>
					{errors?.research_team?.research_team?.data}
				</Typography>
			}
			<Table />
			<Box mt={4}>
				<FormInput
					title={t('Opis zmian w Zespole Badawczym wprowadzonych w okresie sprawozdawczym (jeśli dotyczy)')}
					value={formData[selectedLanguage].research_team.research_team.reporting_description}
					onChange={e =>
						onChange(`${selectedLanguage}.research_team.research_team.reporting_description`, e.target.value)
					}
					multiline
					minRows={10}
					maxRows={10}
					alignItems="flex-start"
					error={errors?.research_team?.research_team?.reporting_description}
					disabled={disabledInput}
					inputProps={{ 'aria-required': true }}
				/>
			</Box>
		</Box>
	);
};

export default ResearchTeam;