import React, { useContext, useEffect, useState } from 'react';
import {
	Modal,
	Backdrop,
	Fade,
	Grid,
	Card
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FormInput from 'components/Form/FormInput';
import { toast } from 'react-toastify';
import API from 'apis/API';
import FolderContext from 'context/FolderContext';
import Validator, { Required, validate } from 'utils/Validator';

const FormModal = (props) => {
	const { openModal, handleClose, fileId } = props;
	const { fetchFoldersAndFiles, filters } = useContext(FolderContext);
	
	const classes = useStyles();
	const [data, setData] = useState({
		name: ''
	});
	const [errors, setErrors] = useState('');
	const [saving, setSaving] = useState(false);
	const Validators = {
		name: new Validator(Required),
	};

	const handleSubmit = () => {
		let _errors = validate(data, Validators);
		setErrors(_errors);
		if (_errors) return;

		API.files.update(data, fileId).then(res => {
			setSaving(false);
			
			if (res?.data?.code === 400) {
				toast.error(res.data?.message);
				return;
			}

			if (res?.data?.code === 200) {
				fetchFoldersAndFiles(res.data.file.folder_id, filters, true);
				handleCloseModal();
				return toast.success('Zmieniono nazwę pliku!');
			}
		});
	};

	const handleCloseModal = () => {
		handleClose();
		setErrors(null);
		setData({
			name: '',
			parent_id: null
		});
	};

	const fetchFileDetail = (fileId) => {
		API.files.getOne(fileId).then(res => {
			if (res.data.code === 200) {
				const { name } = res.data.data;
				setData({ name });
			}
		});
	};

	const handleChange = (e) => {
		setData(prev => ({ ...prev, name: e.target.value }));
	};

	useEffect(async () => {
		if (fileId) {
			await fetchFileDetail(fileId);
		}
	}, [openModal]);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={openModal}
			onClose={handleCloseModal}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}} >
			<Fade in={openModal}>
				<Card className={classes.paper}>
					<Grid container>
						<Grid item xs={12} style={{ marginBottom: 25 }}>
							<Typography variant="h3">
								Edytuj file
							</Typography>
						</Grid>
						<Grid item xs={12} style={{ marginBottom: 20 }}>
							<FormInput
								title="Wpisz nazwę folderu"
								placeholder="Nazwa folderu"
								value={data.name}
								onChange={handleChange}
								onKeyPress={(e) => {
									if (e.key === 'Enter') {
										handleSubmit();
									}
								}}
								error={errors?.name}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs={12} container justifyContent="flex-end">
							<Grid item xs={2} style={{ marginRight: 15 }}>
								<PrimaryButton
									disabled={saving}
									variant="outlined"
									fullWidth
									onClick={handleCloseModal}
								>
									ANULUJ
								</PrimaryButton>
							</Grid>
							<Grid item xs={3}>
								<PrimaryButton fullWidth onClick={handleSubmit}>
									{fileId ? 'Zmień nazwę' : 'DODAJ FOLDER'}
								</PrimaryButton>
							</Grid>

						</Grid>
					</Grid>
				</Card>
			</Fade>
		</Modal>
	);
};

FormModal.propTypes = {
	openModal: PropTypes.bool,
	isCreate: PropTypes.bool,
	handleClose: PropTypes.func,
	fileId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		boxShadow: theme.shadows[5],
		padding: '25px',
		outline: 'none',
	}
}));

export default FormModal;
