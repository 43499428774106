import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const FilterRow = ({ onAddClick, errors, entities }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	
	const [data, setData] = useState({
		member_type_id: '',
		name: '',
		role_id: '',
		is_active: '',
		pesel: '',
		entity_id: '',
		position_name: '',
		science_discipline: '',
		orcid_number: '',
		phone_number: '',
		email: '',
	});

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	return (
		<Row
			onChange={handleChange}
			values={data}
			entities={entities}
			errors={errors}
			lastColumn={
				<IconButton onClick={() => onAddClick(data)} tooltip={t('Zatwierdź')}>
					<CircleCheckIcon />
				</IconButton>
			}
		/>
	);
};

export default FilterRow;
