import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, makeStyles, Grid } from '@material-ui/core';
import Routes from 'router/Routes';
import Accordions from 'components/Main/Accordions';
import IpCardContext from 'context/IpCardContext';
import FormInput from 'components/Form/FormInput';
import DateInput from 'components/Form/DateInput';
import FilePickModal from 'components/Main/FilePickModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Typography from 'components/Main/Typography';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import TablesContainer from './TablesContainer';
import API from 'apis/API';
import useCheckPermissions from 'utils/useCheckPermissions';
import Exports from '../Exports';
import Select from 'components/Form/Select';

const breadcrumbs = [
	{ title: 'Rejestr Własności Intelektualnej', to: Routes.IpCards.List },
	{ title: 'Własność Intelektualna WI 01' },
	{ title: 'Komercjalizacja Własności Intelektualnej' },
];

const FormContainer = () => {
	const { id } = useParams();
	const classes = useStyles();
	const { formData, onChange, errors } = useContext(IpCardContext);

	const canFillComercializationIpCard = useCheckPermissions('can_fill_comercialization_ip_card', true);

	const formInputs = [
		{
			name: 'sota_report',
			title: '1. Raport stanu techniki oraz opinia o zdolności patentowej Własności Intelektualnej oraz potencjalne bariery we wdrożeniu',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'sota_report_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'property_analysis',
			title: '2. Rynkowa analiza Własności Intelektualnej',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'property_analysis_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'results_scientific_evaluation',
			title: '3. Wyniki Ewaluacji naukowej',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'results_scientific_evaluation_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'copyright_info',
			title: '4. Informacja o ochronie Własności Intelektualnej, w tym szczegółowe informacje o prowadzonych procedurach ochrony Własności Intelektualnej (zawierającą całość korespondencji urzędowej prowadzonej w sprawie ochrony)',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'copyright_info_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'select_field',
		},
		{
			name: 'strategy',
			title: '6. Informacja o sposobie i strategii Komercjalizacji',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'strategy_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'decision',
			title: '7. Decyzja ws. komercjalizacji i ochrony WI',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'decision_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'process_flow',
			title: '8. Opis przebiegu Komercjalizacji Własności Intelektualnej',
			rows: 9,
			placeholder: 'Wpisz opis',
			helperText: 'Opis przebiegu Komercjalizacji Własności Intelektualnej (w tym informacje o podjętych działaniach celem identyfikacji potencjalnych Odbiorców Własności Intelektualnej; nawiązywaniu kontaktów z potencjalnymi Odbiorcami Własności Intelektualnej i jej prezentacji podczas bezpośrednich spotkań, targów branżowych, konferencji i seminariów; przygotowaniu materiałów promocyjnych oraz informacyjnych dot. Własności Intelektualnej, przebiegu negocjacji, zawartych umowach)',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'infringements',
			title: '9. Informacja o zgłoszonych lub potencjalnych naruszeniach praw osób trzecich na skutek korzystania z Własności Intelektualnej lub naruszeniach Własności Intelektualnej przez osoby trzecie',
			rows: 9,
			placeholder: 'Wpisz opis',
			disable: !canFillComercializationIpCard
		},
		{
			name: 'infringements_attachments',
			title: 'Załączniki',
			buttonTitle: 'DODAJ ZałącznikI',
			type: 'file_picker',
			disable: !canFillComercializationIpCard
		},
	];
	const [openModal, setOpenModal] = useState(false);
	const [nameField, setNameField] = useState('');

	const selOptions = [
		{ value: 'invention', label: 'wynalazek *' },
		{ value: 'utility_model', label: 'wzór użytkowy' },
		{ value: 'industrial_design', label: 'wzór przemysłowy' },
		{ value: 'trademark', label: 'znak towarowy' },
		{ value: 'know_how', label: 'tajemnica przedsiębiorstwa (know-how)' },
		{ value: 'database', label: 'baza danych' },
		{ value: 'science_piece', label: 'utwór naukowy' },
		{ value: 'track', label: 'utwór' },
	];

	const handleChange = (e) => {
		const { name, value } = e.target;

		onChange('ip_card_commercialization', { ...formData.ip_card_commercialization, [name]: value });
	};

	const changeProcessFlow = function (ind, attr, value) {
		formData.ip_card_commercialization.process_flow[ind][attr] = value;
		onChange('ip_card_commercialization', { ...formData.ip_card_commercialization });
	};

	const addProcessFlow = function() {
		formData.ip_card_commercialization.process_flow.push({'description' : '', 'date' : '', 'attachments' : []});
		onChange('ip_card_commercialization', { ...formData.ip_card_commercialization });
	};

	const handleDownload = (data) => (e) => {
		e.preventDefault();

		API.files.view(data?.uuid).then(res => {
			const url = window.URL.createObjectURL(
				new Blob([res.data])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.name);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// // Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	};

	const renderForm = (item, index) => {
		return (
			<Box key={index}>
				{item.type === 'file_picker'
					? (
						<>
							<Grid
								container
								alignItems="flex-start"
								spacing={1}
								className={classes.gutterBottom}
							>
								{item.title &&
									<Grid item xs={12} sm={4}>
										<Box display="flex" alignItems="center">
											<Typography variant="bodyM" component="p">
												{item.title}
											</Typography>
										</Box>
									</Grid>
								}
								<Grid item xs={12} sm={!item.title ? 12 : 8}>
									{formData?.ip_card_commercialization?.[item.name]?.map((a, index) => {
										return (
											<Box key={index}>
												<Box display="flex" alignItems="center" justifyContent="space-between">
													<Typography>
														<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
															{index + 1}. {a.name}
														</a>
													</Typography>

													{!item?.disable &&
														<DeleteIconButton
															hideModal
															tooltip="Usuń"
															onClick={() => handleDelete(item.name, a.id)}
															title="Czy na pewno chcesz usunąć tę kartę?"
														/>
													}
												</Box>

												<Divider className={classes.divider} />
											</Box>
										);
									})}

									{!item?.disable &&
										<PrimaryButton
											onClick={() => {
												setOpenModal(true);
												setNameField(item.name);
											}}
										>
											{item.buttonTitle}
										</PrimaryButton>
									}
								</Grid>
							</Grid>

							<Divider className={classes.divider} />
						</>
					)
					: (
						item.name === 'process_flow'
							? (
								<>
									{
										formData?.ip_card_commercialization?.[item.name].map((childItem, childIndex) => {
											return <>
												<DateInput
													fullWidth
													key={index + '-' + childIndex + '-date'}
													title={childIndex === 0 ? item?.title : ' '}
													name={item.name + '['+ childIndex +'][date]'}
													value={childItem.date || null}
													onChange={(e) => changeProcessFlow(childIndex, 'date', e.target.value)}
													error={errors?.ip_card_commercialization?.[item.name]}
													disabled={item?.disable}
												/>
												<Grid container>
													<Grid item xs={12} sm={4}></Grid>
													<Grid item xs={12} sm={8}>
														<FormInput
															key={index + '-' + childIndex + '-description'}
															name={item.name + '['+ childIndex +'][description]'}
															value={childItem.description}
															onChange={(e) => changeProcessFlow(childIndex, 'description', e.target.value)}
															placeholder={item.placeholder}
															multiline={true}
															rows={item.rows}
															alignItems="flex-start"
															className={classes.gutterBottom}
															gutterBottom={false}
															error={errors?.ip_card_commercialization?.[item.name]}
															disabled={item?.disable}
														/>
													</Grid>
												</Grid>
												<Grid
													container
													alignItems="flex-start"
													spacing={1}
													className={classes.gutterBottom}
												>
													<Grid item xs={12} sm={4}>
														<Box display="flex" alignItems="center">
															<Typography variant="bodyM" component="p">
																Załączniki
															</Typography>
														</Box>
													</Grid>
													<Grid item xs={12} sm={8}>
														{childItem?.attachments?.map((a, attachIndex) => {
															return (
																<Box key={attachIndex}>
																	<Box display="flex" alignItems="center" justifyContent="space-between">
																		<Typography>
																			<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
																				{attachIndex + 1}. {a.name}
																			</a>
																		</Typography>

																		{!item?.disable &&
																			<DeleteIconButton
																				hideModal
																				tooltip="Usuń"
																				onClick={() => handleDelete(childIndex, a.id)}
																				title="Czy na pewno chcesz usunąć tę kartę?"
																			/>
																		}
																	</Box>

																	<Divider className={classes.divider} />
																</Box>
															);
														})}

														{!item?.disable &&
															<PrimaryButton
																onClick={() => {
																	setOpenModal(true);
																	setNameField(childIndex);
																}}
															>
																DODAJ ZałącznikI
															</PrimaryButton>
														}
													</Grid>
												</Grid>
											</>;
										})
									}
									<Grid container className={classes.gutterBottom}>
										<Grid item xs={12} sm={4}></Grid>
										<Grid item xs={12} sm={8}>
											<PrimaryButton
												onClick={() => {
													addProcessFlow();
												}}
											>
												dodaj kolejny
											</PrimaryButton>
										</Grid>
									</Grid>
									<Divider className={classes.divider} />
								</>
							)
							: (
								item.name === 'select_field'
									? (
										<>
											<Select
												title={'5. Cechy WI'}
												name={'feature_wi'}
												options={selOptions}
												value={formData?.ip_card_commercialization?.feature_wi}
												onChange={handleChange}
												displayEmpty={false}
												// helperText={'10. Cechy WI'}
												error={errors?.ip_card_commercialization?.feature_wi}
												disabled={!canFillComercializationIpCard}
											/>
											{
												formData?.ip_card_commercialization?.feature_wi === 'invention' &&
												<FormInput
													title={'Rekomendowane terytorium ochrony wynalazku'}
													// helperText={'Rekomendowane terytorium ochrony wynalazku'}
													name={'recommend_intention'}
													value={formData?.ip_card_commercialization?.recommend_intention}
													onChange={handleChange}
												/>
											}
											<Divider className={classes.divider} />
										</>
									)
									: (
										<FormInput
											key={index}
											title={item?.title}
											helperText={item?.helperText}
											name={item.name}
											value={formData?.ip_card_commercialization?.[item.name]}
											onChange={handleChange}
											placeholder={item.placeholder}
											multiline={true}
											rows={item.rows}
											alignItems="flex-start"
											className={classes.gutterBottom}
											gutterBottom={false}
											error={errors?.ip_card_commercialization?.[item.name]}
											disabled={item?.disable}
										/>
									)
							)
					)
				}
			</Box>
		);
	};

	const items = [
		{
			variant: 'h3',
			title: 'Opis szczegółowy',
			child:
				<>
					<Box className={classes.root}>
						{formInputs.map(renderForm)}
					</Box>
					<TablesContainer />
					<Box className={classes.root}>
						<Divider className={classes.divider} />

						<FormInput
							title="11. Informacja o roszczeniach uprawnionych do Środków z Komercjalizacji"
							name="claims_information"
							value={formData?.ip_card_commercialization?.claims_information}
							onChange={handleChange}
							placeholder="Wpisz opis"
							multiline={true}
							rows={9}
							alignItems="flex-start"
							className={classes.gutterBottom}
							gutterBottom={false}
							error={errors?.ip_card_commercialization?.claims_information}
							disabled={!canFillComercializationIpCard}
						/>

						<Grid
							container
							alignItems="flex-start"
							spacing={1}
							className={classes.gutterBottom}
						>
							<Grid item xs={12} sm={4}>
								<Box display="flex" alignItems="center">
									<Typography variant="bodyM" component="p">
										Załączniki
									</Typography>
								</Box>
							</Grid>

							<Grid item xs={12} sm={8}>
								{formData?.ip_card_commercialization?.claims_information_attachments?.map((a, index) => {
									return (
										<Box key={index}>
											<Box display="flex" alignItems="center" justifyContent="space-between">
												<Typography>
													<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
														{index + 1}. {a.name}
													</a>
												</Typography>


												{canFillComercializationIpCard &&
													<DeleteIconButton
														hideModal
														tooltip="Usuń"
														onClick={() => handleDelete('claims_information_attachments', a.id)}
														title="Czy na pewno chcesz usunąć tę kartę?"
													/>
												}
											</Box>

											<Divider className={classes.divider} />
										</Box>
									);
								})}

								{canFillComercializationIpCard &&
									<PrimaryButton
										onClick={() => {
											setOpenModal(true);
											setNameField('claims_information_attachments');
										}}
									>
										DODAJ ZałącznikI
									</PrimaryButton>
								}
							</Grid>
						</Grid>
					</Box>
				</>,
			defaultExpanded: true
		},
	];

	const selectedFile = (file) => {
		let prevData;
		if (!isNaN(nameField)) {
			prevData = [...(formData?.ip_card_commercialization?.process_flow[nameField].attachments || [])];
		} else {
			prevData = [...(formData?.ip_card_commercialization?.[nameField] || [])];
		}
		const fileIndex = prevData.findIndex(a => a.id === file.id);

		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1, file);
		} else {
			prevData.push(file);
		}

		if (!isNaN(nameField)) {
			formData.ip_card_commercialization.process_flow[nameField].attachments = [...prevData];
			onChange('ip_card_commercialization', {
				...formData.ip_card_commercialization
			});
		} else {
			onChange('ip_card_commercialization', {
				...formData.ip_card_commercialization,
				[nameField]: prevData
			});
		}
	};

	const handleDelete = (name, id) => {
		let prevData;
		if (!isNaN(name)) {
			prevData = [...(formData?.ip_card_commercialization?.process_flow[name].attachments || [])];
		} else {
			prevData = [...(formData?.ip_card_commercialization?.[name] || [])];
		}
		const fileIndex = prevData.findIndex(a => a.id === id);
		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1);
		}

		if (!isNaN(name)) {
			formData.ip_card_commercialization.process_flow[name].attachments = [...prevData];
			onChange('ip_card_commercialization', {
				...formData.ip_card_commercialization
			});
		} else {
			onChange('ip_card_commercialization', {
				...formData.ip_card_commercialization,
				[name]: prevData
			});
		}
	};

	return (
		<>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			<Accordions items={items} className={classes.padding} />

			{id &&
				<Exports tab="commercialization" fileName="Komercjalizacja Raport" />
			}

			<FilePickModal
				openModal={openModal}
				handleClose={() => setOpenModal(false)}
				selectedFile={selectedFile}
			/>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	padding: {
		padding: window.isMobile ? '0 15px' : '0 30px'
	},
	divider: {
		marginBlock: theme.spacing(3),
	},
	gutterBottom: {
		marginBottom: 20,
	},
	downloadLink: {
		color: '#002852',
		textDecoration: 'underline'
	}
}));

export default FormContainer;
