import React, { useContext } from 'react';
import { makeStyles, TableBody, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TableLabel from 'components/Main/TableLabel';
import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';

import { CategoryCell, HeadCell } from './CustomCell';
import PairCell from './PairCell';

const useStyles = makeStyles(() => ({
	categoryCell: {
		'&.MuiTableCell-root': {
			borderBottomColor: '#D0D0D0 !important',
		}
	}
}));

const Table4 = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const { isPreview } = useContext(ManagementDetailedInformationsContext);

	return (
		<TableBody>
			<TableRow>
				<CategoryCell className={classes.categoryCell} rowSpan={0}>
					<TableLabel>C</TableLabel>
				</CategoryCell>
				<CategoryCell className={classes.categoryCell} rowSpan={0}>
					<TableLabel>4</TableLabel>
				</CategoryCell>
				<HeadCell>
					<TableLabel>{t('Wysokość i kategoria środków zwróconych do Funduszu')}</TableLabel>
				</HeadCell>
				<HeadCell>
					<TableLabel>{t('Łącznie')}</TableLabel>
				</HeadCell>
				<HeadCell>
					<TableLabel>{t('Bieżący okres')}</TableLabel>
				</HeadCell>
			</TableRow>
			<TableRow>
				<TableCell>{t('odsetki')}</TableCell>
				<PairCell
					sumName="table4.interest.together"
					inputName="table4.interest.current_period"
					disabled={isPreview}
				/>
			</TableRow>
			<TableRow>
				<TableCell>{t('kary umowne')}</TableCell>
				<PairCell
					sumName="table4.contractual_penalties.together"
					inputName="table4.contractual_penalties.current_period"
					disabled={isPreview}
				/>
			</TableRow>
		</TableBody>
	);
};

export default Table4;