import React, { useContext } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import Tabs from 'components/Main/Tabs';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Routes from 'router/Routes';

import IpCardForm from './IpCardForm';
import AuthorsTab from './Authors';
import Description from './Description';
import BackgroundIp from './BackgroundIp';
import Exports from './Exports';
import { useTranslation } from 'react-i18next';
import Accordions from 'components/Main/Accordions';

import Header from 'components/Main/Header';
import LanguageButton from 'components/Buttons/LanguageButton';
import IpCardContext from 'context/IpCardContext';

const useStyles = makeStyles(() => ({
	accordionBG: {
		padding: '0 15px',
	}
}));

const IpCard = () => {
	const { selectedLanguage, setSelectedLanguage } = useContext(IpCardContext);
	const { id } = useParams();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const classes = useStyles();

	const breadcrumbs = [
		{ title: 'Własności Intelektualne WIB', to: Routes.IpCards.List },
		{ title: `${id ? (isPreview ? 'Podgląd' : 'Edytuj') : 'Dodaj'} Kartę Własności` },
	];

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { t: t1 } = useTranslation(null, { keyPrefix: 'Karty własności - opis' });
	const tabs = [
		{ value: 'ip_authors', children: <AuthorsTab />, label: t('LISTA TWÓRCÓW') },
		{ value: 'ip_description', children: <Description />, label: t1('Opis Własności Intelektualnej') },
		{ value: 'ip_background', children: <BackgroundIp />, label: t('BACKGROUND IP/INNE IP') },
	];

	const items = [
		{ child: <AuthorsTab />, title: t('LISTA TWÓRCÓW') },
		{ child: <Description />, title: t1('Opis Własności Intelektualnej') },
		{ child: <BackgroundIp />, title: t('BACKGROUND IP/INNE IP') },
	];

	return (
		<Box>
			<Header title={''}>
				<LanguageButton onChange={setSelectedLanguage} />
			</Header>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<Box>
				<IpCardForm />

				{window.isMobile
					? <Accordions items={items} TransitionProps={{ unmountOnExit: true }} className={classes.accordionBG} />
					: <Tabs tabs={tabs} />
				}
			</Box>

			{id &&
				<Exports tab="kata-wi" fileName="Karta WI" locale={selectedLanguage} />
			}
		</Box>
	);
};

export default withRouter(IpCard);
