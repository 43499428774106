import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';

import API from 'apis/API';
import Routes from 'router/Routes';
import RoutesRouter from 'router/RoutesRouter';
import Storage from 'utils/Storage';
import { toast } from 'react-toastify';
import Progress from 'components/Main/Progress';

const AuthContext = React.createContext(null);
const authPaths = ['/login', '/register', '/forgot_password', '/reset_password', '/privacy-policy', '/regulations', '/accessibility-declaration', '/information-clause'];

const useStyles = makeStyles({
	container: {
		display: 'flex',
		width: '100%',
		height: '100vh',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export const AuthContextProvider = () => {
	const history = useHistory();
	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState(null);
	const [gFooterClosed, setGFooterClosed] = useState(false);

	useEffect(() => {
		API.interceptors.response.use(res => {
			if (res?.data?.code === 401) logOut();

			return res;
		}, err => {
			if (err.response?.status === 401) logOut();
			if (err.response?.status === 429) {
				toast.error('Too Many Requests!');
			}
			return err;
		});

		API.auth.validateToken().then(res => {
			if (res.data?.code === 200 && res.data?.user) {
				setUser(res.data?.user);
				if (window.location.pathname === '/login') {
					history.push(Routes.Home);
				}
			} else {
				logOut();
			}
		}).catch(() => {
			logOut();
		}).finally(() => {
			setLoading(false);
		});

		// check if footer is closed previously
		let isFooterClosed = Storage.get('accept_privacy_policy');
		if (isFooterClosed) setGFooterClosed(true);
	}, []);

	useEffect(() => {
		if (gFooterClosed) {
			Storage.set('accept_privacy_policy', 'YES');
		}
	}, [gFooterClosed]);

	useEffect(() => {
		if (authPaths.find(path => path === window.location.pathname)) {
			Storage.remove('access_token');
			Storage.remove('user');
			setUser(null);
		}
	}, [window.location.pathname]);

	const logIn = data => {
		if (!data?.access_token || !data?.user) {
			return logOut();
		}

		Storage.set('access_token', data.access_token);
		setUser(data.user);
		history.push(Routes.Home);
	};

	const logOut = () => {
		// Sometimes react-router-dom location is empty so used window location.
		if (authPaths.find(path => path === window.location.pathname)) return;

		Storage.remove('access_token');
		Storage.remove('user');
		setUser(null);
		// window.location.href = Routes.Auth.Login;
		history.push(Routes.Auth.Login);
	};

	return (
		<AuthContext.Provider value={{ logIn, logOut, user, gFooterClosed, setGFooterClosed }}>
			{loading ? (
				<div className={classes.container}>
					<Progress status={true} thickness={4} size="12rem"/>
				</div>
			) : (
				<RoutesRouter/>
			)}
		</AuthContext.Provider>
	);
};

export default AuthContext;
