import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
	makeStyles,
	TableContainer,
	Table as MuiTable,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import NumberInput from 'components/Form/NumberInput';
import { numberRound } from 'utils/formatters';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
	headerCell: {
		background: '#EEEEEE'
	}
}));

const keys = ['entity_name', 'salary', 'equipment_costs', 'external_service_costs', 'other_direct_costs', 'total_indirect_cost', 'flat_rate', 'indirect_cost', 'total_eligible_cost'];

const Table = ({ disabled }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const { researchTask } = useContext(ResearchTaskReportContext);
	const { formData, errors, onChange, selectedLanguage } = useContext(ResearchTaskReportBContext);
	const data = formData[selectedLanguage];
	const [manageEntityCost, setManageEntityCost] = useState({
		salary: 0,
		equipment_costs: 0,
		external_service_costs: 0,
		other_direct_costs: 0,
		total_indirect_cost: 0,
		flat_rate: 0,
		indirect_cost: 0,
		total_eligible_cost: 0,
	});

	useEffect(() => {
		let flatRate = researchTask.flat_rate || 0;

		if (_.isEqual(data.expenses.data.manage_entity_cost, manageEntityCost)) {
			setManageEntityCost(prev => ({ ...prev, flat_rate: flatRate }));
			return;
		}

		setManageEntityCost(prev => ({
			...prev,
			...data.expenses.data.manage_entity_cost,
			flat_rate: flatRate
		}));
	}, [data.expenses.data.manage_entity_cost, researchTask]);

	const getEligibleAmount = (entities, entity_id) => {
		let entity = _.find(entities, { entity_id });
		return entity?.sum?.eligible_amount || 0;
	};

	const entities = useMemo(() => {
		let expensesStatements = data.expenses_statements;
		return data.expenses.data.entities.map(entity => {
			let _entity = {
				...entity,
				salary: getEligibleAmount(expensesStatements.salary.entities, entity.entity_id),
				equipment_costs: getEligibleAmount(expensesStatements.equipment_costs.entities, entity.entity_id),
				external_service_costs: getEligibleAmount(expensesStatements.external_service_costs.entities, entity.entity_id),
				other_direct_costs: getEligibleAmount(expensesStatements.other_direct_costs.entities, entity.entity_id),
			};
			_entity.total_indirect_cost = _entity.salary + _entity.equipment_costs + _entity.external_service_costs + _entity.other_direct_costs;
			_entity.flat_rate = researchTask.flat_rate;
			_entity.indirect_cost = (_entity.salary + _entity.other_direct_costs) * _entity.flat_rate / 100;

			_entity.total_eligible_cost = _entity.total_indirect_cost + _entity.indirect_cost;
			return _entity;
		});
	}, [data.expenses_statements, data.expenses.data.entities]);

	const sum = useMemo(() => {
		let _sum = _.omit(_.mapValues(entities[0], (val, key) => _.sumBy(entities, item => item[key])),
			['entity_id', 'entity_name']
		);

		_sum.flat_rate = researchTask.flat_rate;

		for( let key in _sum){
			_sum[key] = !isNaN(_sum[key]) ? numberRound(_sum[key]) : _sum[key];
		}

		return _sum;
	}, [entities]);

	useEffect(() => {
		if (selectedLanguage === 'en'
			|| _.isEqual(manageEntityCost, data.expenses.data.manage_entity_cost)
		) return;

		onChange('pl.expenses.data.manage_entity_cost', _.cloneDeep(manageEntityCost));
		onChange('en.expenses.data.manage_entity_cost', _.cloneDeep(manageEntityCost));
	}, [manageEntityCost]);

	useEffect(() => {
		if (selectedLanguage === 'en' || _.isEqual(sum, data.expenses.data.sum)) return;

		let expensesData = {
			..._.cloneDeep(data.expenses.data),
			entities: _.cloneDeep(entities),
			sum: _.cloneDeep(sum),
		};

		onChange('pl.expenses.data', _.cloneDeep(expensesData));
		onChange('en.expenses.data', _.cloneDeep(expensesData));
	}, [sum]);

	const handleChange = e => {
		const { name, value } = e.target;

		setManageEntityCost(prev => {
			prev[name] = parseFloat(value);

			// if we accept 100% of costs then we need to copy the values from summary - otherwise there is a chance that will be a difference
			if (numberRound(prev.salary) == numberRound(sum.salary) &&
				numberRound(prev.equipment_costs) == numberRound(sum.equipment_costs) &&
				numberRound(prev.external_service_costs) == numberRound(sum.external_service_costs) &&
				numberRound(prev.other_direct_costs) == numberRound(sum.other_direct_costs) &&
				numberRound(prev.indirect_cost) == numberRound(sum.indirect_cost)) {

				prev.total_indirect_cost = sum.total_indirect_cost;
				//prev.indirect_cost = sum.indirect_cost;
				prev.total_eligible_cost = sum.total_eligible_cost;
			} else {
				prev.total_indirect_cost = (prev.salary || 0) + (prev.equipment_costs || 0) + (prev.external_service_costs || 0) + (prev.other_direct_costs || 0);
				//prev.indirect_cost = ((prev.salary || 0) + (prev.other_direct_costs || 0)) * (prev.flat_rate || 0) / 100;
				prev.total_eligible_cost = (prev.total_indirect_cost || 0) + (prev.indirect_cost || 0);
			}

			return { ...prev };
		});
	};

	return (
		<TableContainer className={classes.root}>
			<MuiTable>
				<TableHead>
					<TableRow>
						<TableCell rowSpan={2}>
							<TableLabel>
								{t('Nazwa Podmiotu Lidera (Wnioskodawcy) i Innych Jednostek')}
							</TableLabel>
						</TableCell>
						<TableCell colSpan={8}>
							<TableLabel align="center">
								{t('Koszty kwalifikowane zadania badawczego')}
							</TableLabel>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TableLabel>
								{t('Wynagrodzenia')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Koszty aparatury naukowo-badawczej i WNiP (w tym leasing, odpisy amortyzacyjne i koszty odpłatnego korzystania)')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Podwykonawstwo i usługi obce')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Inne koszty bezpośrednie')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Razem koszty bezpośrednie')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Stopa ryczałtu')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Koszty pośrednie (bez podwykonawstwa/usług obcych, aparatury naukowo badawczej, środków trwałych i WNiP)')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Razem koszty kwalifikowane (Finansowanie z Funduszu)')}
							</TableLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{_.map(entities, (entity, i) => (
						<TableRow key={i}>
							{_.map(keys, key => (
								<TableCell key={key}>
									{key === 'entity_name'
										? entity[key]
										: <NumberText fixedDecimalScale={true} value={entity[key] || 0} />
									}
								</TableCell>
							))}
						</TableRow>
					))}
					<TableRow style={{ height: 10 }} />
					<TableRow>
						<TableCell className={classes.headerCell}>
							<TableLabel>
								{t('Budżet Zadania Badawczego (zgodnie z aktualną wersją Wniosku o finansowanie)')}
							</TableLabel>
						</TableCell>
						{_.map(_.drop(keys, 1), key => (
							<TableCell key={key}>
								<NumberText fixedDecimalScale={true} value={sum?.[key] || 0} />
							</TableCell>
						))}
					</TableRow>
					<TableRow>
						<TableCell className={classes.headerCell}>
							<TableLabel>
								{t('Koszty uznane za kwalifikowane przez podmiot zarządzający')}
							</TableLabel>
						</TableCell>
						{['salary', 'equipment_costs', 'external_service_costs', 'other_direct_costs'].map(key => (
							<TableCell key={key}>
								<NumberInput
									name={key}
									value={manageEntityCost[key]}
									onChange={handleChange}
									disabled={disabled}
									error={!!errors?.expenses?.data?.manage_entity_cost?.[key]}
								/>
								{manageEntityCost[key] === 0 &&
									<Typography variant="inputStatus" color="secondary" style={{ marginTop: 5 }}>
										Uwaga! Kwota uznanych kosztów kwalifikowalnych wynosi 0!
									</Typography>
								}
							</TableCell>
						))}
						{['total_indirect_cost', 'flat_rate', 'indirect_cost', 'total_eligible_cost'].map(key => (
							<TableCell key={key}>
								{key === 'indirect_cost' ? (
									<NumberInput
										name={key}
										value={manageEntityCost[key]}
										onChange={handleChange}
										disabled={disabled}
										error={!!errors?.expenses?.data?.manage_entity_cost?.[key]}
									/>
								) : (
									<NumberText fixedDecimalScale={true} value={manageEntityCost[key]}/>
								)}
							</TableCell>
						))}
					</TableRow>
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default Table;
