import React, { useContext } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BaseInput from 'components/Form/BaseInput';
import IpCardContext from 'context/IpCardContext';


const Row = ({ onChange, values, lastColumn, errors }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { selectedLanguage } = useContext(IpCardContext);

	return (
		<TableRow>
			<TableCell>{values.id || ''}</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder={t('Wpisz imię i nazwisko')}
					name="name"
					value={values.name}
					onChange={onChange}
					error={errors?.name}
					disabled={selectedLanguage === 'en'}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder={t('Wpisz adres')}
					name="address"
					value={values.address}
					onChange={onChange}
					error={errors?.address}
					disabled={selectedLanguage === 'en'}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder="Wpisz numer"
					name="phone_number"
					value={values.phone_number}
					onChange={onChange}
					error={errors?.phone_number}
					disabled={selectedLanguage === 'en'}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder={t('Wpisz adres')}
					name="email"
					value={values.email}
					onChange={onChange}
					error={errors?.email}
					disabled={selectedLanguage === 'en'}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder="Wpisz udział"
					name="percent_of_participation"
					value={values.percent_of_participation}
					onChange={onChange}
					error={errors?.percent_of_participation}
					disabled={selectedLanguage === 'en'}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder="Wpisz rodzaj"
					name="agreement_type"
					value={(selectedLanguage === 'en' && values.agreement_type?.[selectedLanguage] === null) ? values.agreement_type.pl : values.agreement_type?.[selectedLanguage]}
					onChange={onChange}
					error={errors?.agreement_type}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder="Wpisz nazwę jednostki"
					name="unit_name"
					value={(selectedLanguage === 'en' && values.unit_name?.[selectedLanguage] === null) ? values.unit_name.pl : values.unit_name?.[selectedLanguage]}
					onChange={onChange}
					error={errors?.unit_name}
				/>
			</TableCell>
			<TableCell>
				{lastColumn}
			</TableCell>
		</TableRow>
	);
};

export default Row;
