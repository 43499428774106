import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from '@material-ui/core';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import NumberInput from 'components/Form/NumberInput';

import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import Table from './Table';

const CumulativeExpenses = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const { acceptedReports, formData, selectedLanguage } = useContext(ResearchTaskReportBContext);
	const expenses = formData[selectedLanguage].expenses;

	// Sum of manageEntityTotalEligibleCost of all accepted report and current one.
	const manageEntityTotalEligibleCost = useMemo(() => {
		let sum = _.sumBy(acceptedReports, report => report.data[selectedLanguage]?.expenses?.manage_entity_total_eligible_cost ?? 0);

		sum += expenses?.manage_entity_total_eligible_cost;

		return sum;
	}, [expenses]);

	// Sum of ineligibleCost of all accepted report and current one.
	const ineligibleCost = useMemo(() => {
		//console.log(acceptedReports);
		let sum = _.sumBy(acceptedReports, report => Math.round((report.data[selectedLanguage]?.expenses?.ineligible_cost + Number.EPSILON) * 100) / 100);

		sum += expenses?.ineligible_cost;

		return sum;
	}, [expenses]);
	
	// Sum of userEligibileCosts of all accepted report and current one.
	const userIneligibleCosts = useMemo(() => {
		let sum = _.sumBy(acceptedReports, report => Math.round((report.data[selectedLanguage]?.expenses?.user_ineligible_costs ?? 0 + Number.EPSILON) * 100) / 100);

		sum += expenses?.user_ineligible_costs;

		return sum;
	}, [expenses]);

	return (
		<>
			<Typography variant="h4" color="primary" style={{ marginBottom: 16 }}>
				{t('Tabela nr 2 - Poniesione wydatki narastająco od początku realizacji Zadania Badawczego')}
			</Typography>
			<Table />
			<Divider style={{ marginBlock: 16 }} />
			<NumberInput
				title={t('Poniesione koszty niekwalifikowalne')}
				value={userIneligibleCosts}
				inputType="form"
				disabled
				vertical
			/>
			<NumberInput
				title={t('Koszty uznane przez podmiot zarządzający za kwalifikowalne')}
				value={manageEntityTotalEligibleCost}
				inputType="form"
				disabled
				vertical
			/>
			<NumberInput
				title={t('Suma kosztów niekwalifikowalnych')}
				value={ineligibleCost}
				inputType="form"
				disabled
				vertical
			/>

		</>
	);
};

export default CumulativeExpenses;
