import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import pl from 'utils/pl';
import Routes from 'router/Routes';
import API from 'apis/API';
import AuthContext from './AuthContext';
import { useAutosave } from 'react-autosave';

const ManagementApplicationsContext = React.createContext(null);

export const Provider = ({ children }) => {
	const { user } = useContext(AuthContext);

	const defaultData = {
		status: 'draft',
		application_number: '',
		application_date: new Date(),
		management_costs_amount: 0,
		management_costs_amount_words: 'zero',
		management_costs_iban: '',
		teams_costs_amount: 0,
		teams_costs_amount_words: 'zero',
		teams_costs_iban: '',
		payment_date: new Date(),
		filling_date: new Date(),
		costs: {
			table1: [],
			table2: [],
			table3: []
		},
		author_user_id: user.id,
		author_name: `${user.firstname} ${user.surname}`
	};

	const history = useHistory();
	const { id } = useParams();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const [researchTasks, setResearchTasks] = useState([]);
	const [researchTaskEntities, setResearchTaskEntities] = useState([]);
	const [formData, setFormData] = useState(defaultData);
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		API.management.applications.getResearchTasksAndEntities().then(res => {
			setResearchTasks(res.data?.research_tasks || []);
			setResearchTaskEntities(res.data?.research_task_entities || []);
		});

		if (!id) return;
		API.management.applications.show(id).then(res => {
			if (res.data?.code === 400) {
				toast.error('Nie znaleziono');
			}

			setFormData(res.data?.data);
		});
	}, [id]);

	const onChange = (key, value, customizer) => {
		setFormData(prev => ({ ..._.setWith(prev, key, value, customizer) }));
	};

	const onCancel = () => history.push(Routes.Management.List());

	const saveCallback = (isPublish = false) => res => {
		setSaving(false);
		if (!_.isEmpty(res.data?.errors)) {
			if (isPublish) {
				toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
			} else {
				history.push(Routes.Management.Applications.Edit(res.data?.data?.id));
				toast.success('Wersja robocza została zapisana!');
			}
			setErrors(prev => {
				prev = {};
				_.mapKeys(res.data?.errors, (value, key) => {
					_.set(prev, key, value);
				});
				return prev;
			});

			return;
		}

		if (isPublish) onCancel();

		setErrors(null);
		toast.success('Aplikacja została zapisana!');
	};

	// get form request data from original formdata
	const getFormRequestData = (data = formData) => {
		const sendData = {
			...data,
			management_costs_amount: ((_.sumBy(data.costs?.table2, 'requested_amount') + _.sumBy(data.costs?.table3, 'requested_amount')) || 0),
			management_costs_amount_words: (pl((_.sumBy(data.costs?.table2, 'requested_amount') + _.sumBy(data.costs?.table3, 'requested_amount')) || 0) || 'zero'),
			teams_costs_amount: (_.sumBy(data.costs?.table1, 'requested_amount') || 0),
			teams_costs_amount_words: (pl(_.sumBy(data.costs?.table1, 'requested_amount') || 0) || 'zero'),
		};
		return sendData;
	};

	const onSave = () => {
		if (saving || isPreview) return;

		const inputElems = document.querySelectorAll('#management input');
		
		if (inputElems.length > 0) {
			const borderElems = document.querySelectorAll('#management fieldset');
			var index = 0, length = borderElems.length;
			for ( ; index < length; index++) {
				borderElems[index].style.borderColor = '#E51A1A';
			}
			toast.error('Dane powinny zostać zatwierdzone.');

			return;
		}

		setSaving(true);
		const sendData = getFormRequestData();

		(id ? API.management.applications.update : API.management.applications.store)(sendData, id).then(saveCallback());
	};

	const onPublish = () => {
		if (saving || isPreview) return;

		const inputElems = document.querySelectorAll('#management input');
		
		if (inputElems.length > 0) {
			const borderElems = document.querySelectorAll('#management fieldset');
			var index = 0, length = borderElems.length;
			for ( ; index < length; index++) {
				borderElems[index].style.borderColor = '#E51A1A';
			}
			toast.error('Dane powinny zostać zatwierdzone.');

			return;
		}
		
		setSaving(true);
		const sendData = getFormRequestData();
		API.management.applications.publish(sendData, id).then(saveCallback(true));
	};

	const onAutoSave = useCallback((data, creating) => {
		// When unmount component, react-autosave send request.
		// So implement to prevent saving as draft what published task in the backend.
		if (saving || isPreview || data?.status === 'published' || (!id && !creating)) return;
		setSaving(true);
		const sendData = getFormRequestData(data);

		(id ? API.management.applications.update : API.management.applications.store)(sendData, id).then(res => {
			setSaving(false);
			if (res.data?.code !== 200 || id) return;
			history.push(Routes.Management.Applications.Edit(res.data?.data?.id));
		});
	}, []);

	useAutosave({ data: formData, onSave: onAutoSave, interval: 300000 });

	// When creating new row, save empty data and edit it.
	useEffect(() => {
		if (id) return;
		onAutoSave(formData, true);
	}, []);

	const value = {
		researchTasks,
		researchTaskEntities,
		formData,
		errors,
		saving,
		isPreview,
		onChange,
		onSave,
		onPublish,
		onCancel,
	};

	return (
		<ManagementApplicationsContext.Provider value={value}>
			{children}
		</ManagementApplicationsContext.Provider>
	);
};

export const ManagementApplicationsContextProvider = withRouter(Provider);

export default ManagementApplicationsContext;
