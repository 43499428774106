import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Box, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import FormInput from 'components/Form/FormInput';
import AddressForm from 'components/Main/AddressForm';
import ContactForm from 'components/Main/ContactForm';
import Typography from 'components/Main/Typography';

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	subTitle: {
		color: '#25272B',
		marginBottom: theme.spacing(2),
	},
}));

const GeneralInfo = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const { researchTask, mainInfo } = useContext(ResearchTaskReportContext);
	const [leader, setLeader] = useState(researchTask?.entities?.leader);

	useEffect(() => {
		setLeader(researchTask?.entities?.leader);
	}, [researchTask?.entities?.leader]);

	return (
		<Box className={classes.root}>
			<Grid container>
				<Grid item xs={4}></Grid>
				<Grid item xs={window.isMobile ? 12 : 8}>
					<Typography className={classes.subTitle}>{t('I.1. Podmiot Zarządzający WIB')}</Typography>
					<FormInput
						title={t('Podmiot Zarządzający WIB')}
						value={researchTask.wib_manager.name}
						vertical
						disabled
					/>
					<AddressForm
						title={t('Adres siedziby głównej')}
						prefix="headquarters"
						data={researchTask.wib_manager}
						vertical
						disabled
					/>
					<Divider style={{ marginBlock: 16 }} />
					<Typography className={classes.subTitle}>
						{t('I.2. Wnioskodawca - Podmiot, w którym działalność naukową prowadzi Lider Zespołu Badawczego')}
					</Typography>
					<FormInput
						title={t('Pełna nazwa podmiotu')}
						value={leader?.entity_name}
						disabled
						vertical
					/>
					<AddressForm
						title={t('Adres siedziby głównej')}
						prefix="headquarters"
						data={leader}
						disabled
						vertical
					/>
					<ContactForm
						title={t('Osoba uprawniona do reprezentowania - Lidera Konsorcjum/Wnioskodawcy')}
						data={researchTask.representative}
					/>
					<FormInput
						title={t('Aktualny numer rachunku bankowego')}
						value={researchTask?.account_number || ''}
						disabled
						vertical
					/>
					<Divider style={{ marginBlock: 16 }} />
					<ContactForm
						title={t('Lider zespołu badawczego')}
						data={researchTask.team_leader}
					/>
					<ContactForm
						title={t('Osoba odpowiedzialna za sporządzenie raportu')}
						data={mainInfo?.author}
						inputs={[['firstname', 'surname'], 'phone_number', 'email']}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default GeneralInfo;
