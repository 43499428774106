import React, { useContext } from 'react';
import clsx from 'clsx';
import { withRouter, Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, Box, Drawer, Button } from '@material-ui/core';
import AuthContext from 'context/AuthContext';
import IconButton from 'components/Buttons/IconButtons';
import { VisibleIcon, NewsIcon } from 'assets/svg';

import {
	Accounts,
	Laptop,
	Logo,
	Logs,
	Options,
	Folder,
	FolderPlus,
	Translation,
	CloudStorage,
	NewsNormalIcon,
	NewsPlusIcon,
	ManagementIcon,
	LeftArrow,
	TimesBlack
} from 'assets/svg';

import Routes from 'router/Routes';
import SidebarNav from './SidebarNav';
import { useEffect } from 'react';
import API from 'apis/API';
import { useState } from 'react';
import _ from 'lodash';
import useCheckPermissions from 'utils/useCheckPermissions';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%'
	},
	logo: {
		display: 'flex',
		alignItems: 'center',
		height: window.isMobile ? 'auto' : 85,
		padding: window.isMobile ? '20px 25px 10px 10px' : '30px 0 20px 40px',
		borderBottom: window.isMobile ? 'none' : '1px solid #D0D0D0',
		'& a': {
			width: '100%',
			'& svg': {
				width: window.isMobile ? 154 : 120,
				height: window.isMobile ? 68 : 37,
			}
		},
	},
	collapse: {
		position: 'fixed',
		top: 0,
		bottom: 0,
		zIndex: 200,
		'& .MuiButton-root': {
			minWidth: 20,
			height: '100%',
			padding: 4,
			background: '#fff',
			borderRadius: 0,
			borderTop: 0,
			borderBottom: 0,
		}
	},
	expandIcon: {
		'& .MuiButton-label svg': {
			transform: 'rotate(180deg)'
		}
	},
}));

const Sidebar = props => {
	const { open, variant, className, setOpenSidebar } = props;

	const classes = useStyles();
	const authContext = useContext(AuthContext);

	const canAccessReport = useCheckPermissions([
		'can_view_all_reports_a',
		'can_view_assigned_reports_a',
		'can_view_assigned_reports_b',
		'can_view_all_reports_b',
		'can_create_report_a',
		'can_send_report_a_for_acceptance',
		'can_accept_reject_report_a',
		'can_create_report_b',
		'can_send_report_b_for_acceptance',
		'can_accept_reject_report_b',
		'can_remove_own_reports_a',
		'can_remove_own_reports_b',
		'can_remove_all_reports_a',
		'can_remove_all_reports_b',
	], true);
	
	const assignedTasks = (authContext?.user?.assigned_tasks || []);

	const [researchTasksSubList, setResearchTasksSubList] = useState([]);
	
	const checkCanViewReport = task => {
		// draft tasks can't have reports.
		if(task.status === 'draft' || _.size(canAccessReport || []) < 1) return false;
		
		// If can view or remove all reports.
		if(_.size(_.intersection(canAccessReport || [], [
			'can_view_all_reports_a',
			'can_view_all_reports_b',
			'can_remove_all_reports_a',
			'can_remove_all_reports_b',
		])) > 0) return true;

		if(_.indexOf(assignedTasks, task.id) > -1) return true;
		return false;
	};

	useEffect(() => {
		API.researchTasks.index({limit: 1000000}).then(res => {
			const assignedTasksSubList = res.data?.data?.filter(task => {
				if (_.includes(authContext?.user?.permissions, 'can_view_all_research_tasks')) {
					return task.status !== 'draft';
				}

				return _.includes(assignedTasks, task.id) && task.status !== 'draft';
			})?.map(task => {
				const item = {
					title: task.acronym,
					href: '/research_tasks/' + task.id + '/edit?preview=true',
					icon: <Folder />,
					ellipsis: true,
					tooltip: false,
					isSubList: true,
					permission: [
						'can_view_assigned_research_tasks',
						'can_view_all_research_tasks'
					],
					subList: [
						{
							title: 'Metryka',
							href: '/research_tasks/' + task.id + '/edit?preview=true',
							icon: <IconButton>
								<VisibleIcon alt="Ikona podglądu wiersza" />
							</IconButton>,
							isSubList: true,
							permission: [
								'can_view_assigned_research_tasks',
								'can_view_all_research_tasks'
							],
						},
					]
				};

				if (checkCanViewReport(task)) {
					item['subList'].push({
						title: 'Raportowanie',
						href: '/research_tasks/' + task.id + '/reports',
						icon: <IconButton>
							<NewsIcon alt="Ikona raportowania" />
						</IconButton>,
						isSubList: true,
					});
				}

				return item;
			});
			setResearchTasksSubList(assignedTasksSubList);
		});
	}, []);

	const items = [
		{
			title: 'Home',
			href: Routes.Home,
			icon: <Laptop />,
		},
		{
			title: 'Użytkownicy',
			children: [
				{
					title: 'Zarządzaj użytkownikami',
					href: Routes.Users.List,
					icon: <Accounts />,
					permission: 'can_manage_users'
				},
				{
					title: 'Role',
					href: Routes.Role.List,
					icon: <Options />,
					permission: 'can_manage_roles'
				},
			]
		},
		{
			title: 'SYSTEM',
			children: [
				{
					title: 'Logi',
					href: Routes.Logs.List,
					icon: <Logs />,
					permission: 'can_view_logs'
				}
			]
		},
		{
			title: 'ZADANIA BADAWCZE',
			children: [
				{
					title: 'Lista zadań badawczych',
					href: Routes.ResearchTasks.List,
					icon: <Folder />,
					withoutForm: true,
					permission: [
						'can_view_all_research_tasks',
						'can_view_assigned_research_tasks'
					],
					subList: researchTasksSubList
				},
				{
					title: 'Dodaj zadanie badawcze',
					href: Routes.ResearchTasks.Create,
					icon: <FolderPlus />,
					isForm: true,
					permission: [
						'can_add_research_task_drafts',
						'can_publish_research_task'
					],
				},
			]
		},
		{
			title: 'ZARZĄDZANIE WIB',
			children: [
				{
					title: 'Koszty zarządzania',
					href: Routes.Management.List(),
					icon: <ManagementIcon />,
					exactMatch: true,
					permission: [
						'can_manage_finances',
						'can_view_finances'
					],
					subList: [
						{
							title: 'Wnioski o środki',
							href: Routes.Management.List('request_transfer'),
							icon: <ManagementIcon />,
							exactMatch: true,
							isSubList: true,
							permission: [
								'can_manage_finances',
								'can_view_finances'
							],
						},
						{
							title: 'Rozliczenia',
							href: Routes.Management.List('settlements'),
							icon: <ManagementIcon />,
							isSubList: true,
							exactMatch: true,
							permission: [
								'can_manage_finances',
								'can_view_finances'
							],
						},
						{
							title: 'Szczegółowe informacje',
							href: Routes.Management.List('detailed_informations'),
							icon: <ManagementIcon />,
							isSubList: true,
							exactMatch: true,
							permission: [
								'can_manage_finances',
								'can_view_finances'
							],
						},
					]
				},
			]
		},
		{
			title: 'REJESTR WŁASNOŚCI INTELEKTUALNEJ',
			children: [
				{
					title: 'Karty Własności',
					href: Routes.IpCards.List,
					icon: <NewsNormalIcon />,
					withoutForm: true,
					permission: [
						'can_view_all_ip_card',
						'can_view_assigned_ip_card',
					]
				},
				{
					title: 'Dodaj Kartę',
					href: Routes.IpCards.Create,
					icon: <NewsPlusIcon />,
					isForm: true,
					permission: 'can_create_ip_card'
				},

			]
		},
		{
			title: 'DOKUMENTY',
			children: [
				{
					title: 'Lista dokumentów',
					href: Routes.Folders.List,
					icon: <CloudStorage />,
					withoutForm: true,
					permission: ['can_list_all_files', 'can_list_own_files']
				},
			]
		},
		{
			title: 'ZARZĄDZANIE TREŚCIĄ',
			children: [
				{
					title: 'Tłumaczenia',
					href: Routes.Translations.List,
					icon: <Translation />,
					permission: 'can_manage_content',
				},
				{
					title: 'Treści stron',
					href: Routes.Pages.List,
					icon: <NewsNormalIcon />,
					permission: 'can_manage_content',
				}
			],
		},
	];

	return (
		<>
			<Drawer
				anchor='left'
				open={open}
				variant={variant}
				PaperProps={{
					style: {
						width: 323,
						height: authContext.gFooterClosed ? '100vh' : 'calc(100vh - 75px)'
					},
					'aria-label': 'Menu'
				}}
			>
				<div className={clsx(classes.root, className)}>
					<div className={classes.logo}>
						<RouterLink to={Routes.Home}>
							<Logo alt="Logo Wirtualnego Instytutu Badawczego" />
						</RouterLink>

						{window.isMobile &&
							<TimesBlack onClick={() => setOpenSidebar(false)} />
						}
					</div>

					<SidebarNav pages={items} />
				</div>
			</Drawer>

			{!window.isMobile &&
				<Box className={clsx(classes.collapse, { [classes.expandIcon]: !open })}>
					<Button variant="outlined" onClick={() => setOpenSidebar(!open)}>
						<LeftArrow />
					</Button>
				</Box>
			}
		</>
	);
};

Sidebar.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	variant: PropTypes.string.isRequired,
};

export default withRouter(Sidebar);
