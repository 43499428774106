import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Tabs from 'components/Main/Tabs';
import Typography from 'components/Main/Typography';

import Expenses from './Expenses';
import CumulativeExpenses from './CumulativeExpenses';
import ChangedInformation from './ChangedInformation';
import ExpensesStatements from './ExpensesStatements';
import Accordions from 'components/Main/Accordions';

const useStyles = makeStyles(() => ({
	tabs: {
		'& .MuiTabs-flexContainer': {
			justifyContent: 'unset'
		},
		'& .MuiTab-root': {
			maxWidth: 350,
		}, '& .MuiTabs-indicator': {
			display: 'block',
		},
	},
	accordionBG: {
		backgroundColor: '#FAFAFA',
		padding: '0 15px',
	}
}));

const EligibleList = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const tabs = [
		{
			value: 'expenses',
			label: (
				<>
					<Typography>{t('Tabela 1 Wydatki poniesione w okresie sprawozdawczym')}</Typography>
				</>
			),
			children: <Expenses />
		},
		{
			value: 'cumulative_expenses',
			label: (
				<>
					<Typography>{t('Tabela 2 Poniesione wydatki narastająco od początku realizacji Zadania Badawczego')}</Typography>
				</>
			),
			children: <CumulativeExpenses />
		},
		{
			value: 'changed_information',
			label: (
				<>
					<Typography>{t('Tabela 3 Informacja o zmianach dokonanych w okresie sprawozdawczym')}</Typography>
				</>
			),
			children: <ChangedInformation />
		},
		{
			value: 'expenses_statements',
			label: (
				<>
					<Typography>{t('Tabela 4 Zestawienie poniesionych wydatków związanych z realizacją Zadania Badawczego')}</Typography>
				</>
			),
			children: <ExpensesStatements />
		},
	];

	const items = [
		{
			title: t('Tabela 1 Wydatki poniesione w okresie sprawozdawczym'),
			child: <Expenses />
		},
		{
			title: t('Tabela 2 Poniesione wydatki narastająco od początku realizacji Zadania Badawczego'),
			child: <CumulativeExpenses />
		},
		{
			title: t('Tabela 3 Informacja o zmianach dokonanych w okresie sprawozdawczym'),
			child: <ChangedInformation />
		},
		{
			title: t('Tabela 4 Zestawienie poniesionych wydatków związanych z realizacją Zadania Badawczego'),
			child: <ExpensesStatements />
		},
	];

	return (
		<Box width="100%">
			{window.isMobile
				? <Accordions
					items={items}
					TransitionProps={{ unmountOnExit: true }}
					className={classes.accordionBG}
				/>
				: <Tabs
					className={classes.tabs}
					variant="scrollable"
					tabs={tabs}
				/>
			}

		</Box>
	);
};

export default EligibleList;