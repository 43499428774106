import React, { useContext, useEffect, useState } from 'react';
import Typography from 'components/Main/Typography';
import { Box, Divider, makeStyles, Grid } from '@material-ui/core';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FilePickModal from 'components/Main/FilePickModal';
import { useTranslation } from 'react-i18next';
import API from 'apis/API';
import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const Attachments = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { formData, onChange, selectedLanguage, isPreview } = useContext(ResearchTaskReportAContext);
	const [attachments, setAttachments] = useState([]);
	const [openModal, setOpenModal] = useState(false);

	const selectedFile = (file) => {
		const prevData = [...attachments];
		const fileIndex = attachments.findIndex(a => a.id === file.id);

		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1, file);
		} else {
			prevData.push(file);
		}

		setAttachments(prevData);
		onChange('attachments', prevData);
	};

	const handleDelete = id => {
		const prevData = [...attachments];
		const fileIndex = attachments.findIndex(a => a.id === id);
		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1);
		}

		setAttachments(prevData);
		onChange('attachments', prevData);
	};

	const handleDownload = (data) => (e) => {
		e.preventDefault();

		API.files.view(data?.uuid).then(res => {
			const url = window.URL.createObjectURL(
				new Blob([res.data])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.name);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// // Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	};

	useEffect(() => {
		setAttachments(formData?.attachments || []);
	}, [formData]);

	return (
		<Box mt={4}>
			<Divider className={classes.divider} />
			<Box className={classes.root}>
				<Grid item>
					{attachments?.map((a, index) => {
						return (
							<Box key={index}>
								<Box display="flex" alignItems="center" justifyContent="space-between">
									<Typography>
										<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
											{index + 1}. {a.name}
										</a>
									</Typography>

									{!isPreview &&
										<DeleteIconButton
											tooltip="Usuń"
											onClick={() => handleDelete(a.id)}
											title="Czy na pewno chcesz usunąć ten załącznik?"
											disabled={selectedLanguage === 'en'}
										/>
									}
								</Box>

								<Divider className={classes.divider} />
							</Box>
						);
					})}

					{!isPreview &&
						<PrimaryButton onClick={() => setOpenModal(true)} disabled={selectedLanguage === 'en'}>
							{t('Dodaj załącznik')}
						</PrimaryButton>
					}
				</Grid>
			</Box>
			<FilePickModal
				openModal={openModal}
				handleClose={() => setOpenModal(false)}
				selectedFile={selectedFile}
			/>
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	divider: {
		marginBlock: theme.spacing(4),
	},
	header: {
		background: '#EEEEEE',
		padding: theme.spacing(2),
	},
	row: {
		borderBottom: '1px solid #d0d0d0',
	},
	input: {
		'& .MuiOutlinedInput-input': {
			paddingRight: '0 !important',
		},
	},
	downloadLink: {
		color: '#002852',
		textDecoration: 'underline'
	}
}));

export default Attachments;
