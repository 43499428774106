import React from 'react';
import { useParams } from 'react-router-dom';

import { ResearchTaskContextProvider } from 'context/ResearchTaskContext';
import FormContainer from './FormContainer';

const Form = () => {
	const { id } = useParams();
	return (
		<ResearchTaskContextProvider key={id}>
			<FormContainer />
		</ResearchTaskContextProvider>
	);
};

export default Form;
