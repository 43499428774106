import React, { useContext, useEffect } from 'react';
import { makeStyles, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import NumberInput from 'components/Form/NumberInput';

import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';
import { CategoryCell, HeadCell, SumCell } from '../CustomCell';
import PairCell from '../PairCell';

const useStyles = makeStyles(() => ({
	lastRow: {
		'& .MuiTableCell-body': {
			borderBottom: '1px solid #17253F !important',
		}
	}
}));

const Table2 = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const { formData, onChange, isPreview } = useContext(ManagementDetailedInformationsContext);

	useEffect(() => {
		if (formData?.status === 'published') return;
		onChange('table2.financial.funds_use', (formData.table2.financial.total_approved / (formData.table2.financial.contracted_funds_amount || 1)) * 100);
	}, [formData.table2.financial.contracted_funds_amount, formData.table2.financial.total_approved]);

	const handleChange = e => {
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<>
			<TableHead>
				<TableRow>
					<CategoryCell style={{ borderBottomWidth: 0 }}></CategoryCell>
					<CategoryCell style={{ borderBottomWidth: 0 }}></CategoryCell>
					<HeadCell>
						<TableLabel>{t('Zarządzanie, w tym komercjalizacja')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Wysokość zakontraktowa')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Środki ZAWNIOSKOWANE ŁĄCZNIE')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Środki ZAWNIOSKOWANE W OKR. BIEŻĄCYM')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('WYDATKI PONIESIONE ŁĄCZNIE')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('WYDATKI PONIESIONE W BIEŻĄCYM OKRESIE')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Wydatki zatwierdzone łącznie')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('Wydatki zatwierdzone w bieżącym Okresie')}</TableLabel>
					</HeadCell>
					<HeadCell>
						<TableLabel>{t('% wykorzystania środków')}</TableLabel>
					</HeadCell>
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					<CategoryCell rowSpan={0} style={{ borderBlockWidth: 0 }}></CategoryCell>
					<CategoryCell rowSpan={0} style={{ borderTopWidth: 0 }}>
						<TableLabel>2</TableLabel>
					</CategoryCell>
					<SumCell>
						<TableLabel>{t('Środki finansowe przeznaczone na pokrycie Kosztów zarządzania przez Podmiot zarządzający, z wyszczególnionymi środkami na Komercjalizację')}</TableLabel>
					</SumCell>
					<SumCell>
						<NumberInput
							name="table2.financial.contracted_funds_amount"
							value={formData.table2.financial.contracted_funds_amount}
							onChange={handleChange}
							disabled={isPreview}
						/>
					</SumCell>
					<PairCell
						sumName="table2.financial.total_application_measures"
						inputName="table2.financial.applied_measures"
						cellType="sum"
						disabled={isPreview}
					/>
					<PairCell
						sumName="table2.financial.total_expenses"
						inputName="table2.financial.expenditure_incurred"
						cellType="sum"
						disabled={isPreview}
					/>
					<PairCell
						sumName="table2.financial.total_approved"
						inputName="table2.financial.approved_expenses"
						cellType="sum"
						disabled={isPreview}
					/>
					<SumCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.financial.funds_use} />
					</SumCell>
				</TableRow>
				<TableRow className={classes.lastRow}>
					<TableCell>{t('w tym Komercjalizacja')}</TableCell>
					<TableCell>
						<NumberInput
							name="table2.including_commercialization.contracted_funds_amount"
							value={formData.table2.including_commercialization.contracted_funds_amount}
							onChange={handleChange}
							disabled={isPreview}
						/>
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.including_commercialization.total_application_measures} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.including_commercialization.applied_measures} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.including_commercialization.total_expenses} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.including_commercialization.expenditure_incurred} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.including_commercialization.total_approved} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table2.including_commercialization.approved_expenses} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true}
							value={formData.table2.including_commercialization.funds_use}
							suffix="%"
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</>
	);
};

export default Table2;