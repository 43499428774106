import React, { useContext } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Select from 'components/Form/Select';
import BaseInput from 'components/Form/BaseInput';
import NumberInput from 'components/Form/NumberInput';
import TableLabel from 'components/Main/TableLabel';

import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const Row = ({ onChange, values, lastColumn, errors, className, adding }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const { selectedLanguage } = useContext(ResearchTaskReportAContext);
	const disabled = selectedLanguage === 'en';
	const booleanOptions = [
		{ value: true, label: t('Tak') },
		{ value: false, label: t('Nie') },
	];

	return (
		<>
			<TableRow className={className}>
				<TableCell></TableCell>
				<TableCell>
					<BaseInput
						placeholder={t('Wpisz imię i nazwisko')}
						name="name"
						value={values.name}
						onChange={onChange}
						error={errors?.name}
						hideErrorText
						disabled={disabled}
						inputProps={{ 'aria-required': true }}
					/>
				</TableCell>
				<TableCell>
					<BaseInput
						placeholder={t('Wpisz podmiot')}
						name="entity"
						value={values.entity}
						onChange={onChange}
						error={errors?.entity}
						hideErrorText
						disabled={disabled}
						inputProps={{ 'aria-required': true }}
					/>
				</TableCell>
				<TableCell>
					<Select
						fullWidth
						options={booleanOptions}
						displayEmpty
						name="planned_employee"
						value={values.planned_employee}
						onChange={onChange}
						gutterBottom={false}
						error={errors?.planned_employee}
						hideErrorText
						disabled={disabled}
						inputProps={{ 'aria-required': true }}
					/>
				</TableCell>
				<TableCell>
					<BaseInput
						placeholder={t('Wpisz stanowisko')}
						name="position_type"
						value={values.position_type}
						onChange={onChange}
						error={errors?.position_type}
						hideErrorText
						inputProps={{ 'aria-required': true }}
					/>
				</TableCell>
				<TableCell>
					<Grid container alignItems="center" spacing={1}>
						<Grid item xs>
							<NumberInput
								placeholder={t('Wpisz')}
								name="involvement_dimension_planned"
								value={values.involvement_dimension_planned}
								onChange={onChange}
								error={errors?.involvement_dimension_planned}
								hideErrorText
								disabled={disabled}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs>
							<NumberInput
								placeholder={t('Wpisz')}
								name="involvement_dimension_actual"
								value={values.involvement_dimension_actual}
								onChange={onChange}
								error={errors?.involvement_dimension_actual}
								hideErrorText
								disabled={disabled}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
					</Grid>
				</TableCell>
				<TableCell>
					{lastColumn}
				</TableCell>
			</TableRow>
			<TableRow className={className}>
				<TableCell></TableCell>
				{!adding &&
					<TableCell>
						<TableLabel>{t('Główne obowiązki w Zadaniu Badawczym')}</TableLabel>
					</TableCell>
				}
				<TableCell colSpan={adding ? 5 : 4}>
					<BaseInput
						name="main_responsibilities"
						placeholder={adding ? 'Opisz główne obowiazki w zadaniu badawczym' : ''}
						value={values.main_responsibilities}
						onChange={onChange}
						multiline
						minRows={10}
						maxRows={10}
						fullWidth
						error={errors?.main_responsibilities}
						hideErrorText
						inputProps={{ 'aria-required': true }}
					/>
				</TableCell>
				<TableCell></TableCell>
			</TableRow>
		</>
	);
};

export default Row;
