import React, { useContext } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Select from 'components/Form/Select';
import DateInput from 'components/Form/DateInput';
import { Accordion } from 'components/Main/Accordions';
import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';

const useStyles = makeStyles(theme => ({
	root: {
		padding: window.isMobile ? 16 : 30,
		background: theme.palette.white,
	},
	content: {
		width: '100%',
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
}));

const MainInfo = ({ errors, disabled }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const classes = useStyles();
	const { researchTask, mainInfo, onChangeMainInfo, selectedLanguage } = useContext(ResearchTaskReportContext);

	const periodTypes = [
		{ label: t('Okresowy'), value: 'periodic' },
		{ label: t('Końcowy'), value: 'final' },
	];
	const taskInputs = [
		{
			title: t('Umowa nr'),
			name: 'financial_agreement_number',
		},
		{
			title: t('Tytuł Zadania Badawczego'),
			name: 'title',
		},
		{
			title: t('Akronim Zadania'),
			name: 'acronym',
		},
		{
			title: t('Dziedzina nauki i dyscyplina naukowa'),
			name: 'science_field',
		},
	];

	return (
		<Box className={classes.root}>
			<Accordion defaultExpanded>
				<Box className={classes.content}>
					<Select
						options={periodTypes}
						title={t('Rodzaj raportu')}
						name="period_type"
						value={mainInfo.period_type}
						onChange={onChangeMainInfo}
						disabled={disabled || selectedLanguage === 'en'}
						inputProps={{ 'aria-required': true }}
					/>
					<Grid container spacing={1}>
						<Grid item xs={window.isMobile ? 12 : 4}>
							<Typography>{t('Za okres')}</Typography>
						</Grid>
						<Grid item xs={window.isMobile ? 12 : 4}>
							<DateInput
								name="period_from"
								value={mainInfo.period_from || null}
								onChange={onChangeMainInfo}
								error={errors?.period_from}
								fullWidth
								disabled={disabled || selectedLanguage === 'en'}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs={window.isMobile ? 12 : 4}>
							<DateInput
								name="period_to"
								value={mainInfo.period_to || null}
								onChange={onChangeMainInfo}
								error={errors?.period_to}
								fullWidth
								disabled={disabled || selectedLanguage === 'en'}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
					</Grid>

					{taskInputs.map((item, i) => (
						<FormInput
							key={i}
							title={item?.title}
							value={researchTask?.[item.name] || ''}
							disabled
							inputProps={{ 'aria-required': true }}
						/>
					))}
				</Box>
			</Accordion>
		</Box>
	);
};

export default MainInfo;
