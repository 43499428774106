import React, { useEffect, useState, useContext } from 'react';
import Typography from 'components/Main/Typography';
import { Grid } from '@material-ui/core';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Select from 'components/Form/Select';
import FormInput from 'components/Form/FormInput';
import IpCardContext from 'context/IpCardContext';
import { useTranslation } from 'react-i18next';

const Form = ({ values, onSave }) => {
	const [data, setData] = useState({
		id: null,
		index: null,
		type: '',
		type_of_tool: '',
		legal_form: {en: null, pl: ''},
		name: {en: null, pl: ''},
		ip_source: {en: null, pl: ''},
		owner: {en: null, pl: ''},
		usage_in_project: {en: null, pl: ''},
		is_only_for_results: {en: null, pl: ''},
		is_ip_element: {en: null, pl: ''},
		required_efforts: {en: null, pl: ''}
	});
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const types = [
		{ value: 'Prototyp', label: t('Prototyp') },
		{ value: 'Narzędzie badawcze', label: t('Narzędzie badawcze') },
	];

	const { selectedLanguage } = useContext(IpCardContext);

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (name === 'legal_form' || name === 'name' || name === 'ip_source' || name === 'usage_in_project' || name === 'is_only_for_results' || name === 'is_ip_element' || name === 'required_efforts' || name === 'owner') {
			let prevData = {...data};
			typeof(prevData[name]) === 'string' && (prevData[name] = {'en': null, 'pl': ''});
			prevData[name][selectedLanguage] = value;
			setData(() => ({ ...prevData }));
		} else {
			setData(prev => ({ ...prev, [name]: value }));
		}
	};

	useEffect(() => {
		if (values) {
			setData(values);
		}
	}, [values]);

	return (
		<Grid container>
			<Grid item xs={window.isMobile ? 12 : 2} style={{ marginRight: 100 }}>
				<Typography variant="h3">
					{values ? t('Edytuj') : t('Dodaj')} {t('inne IP')}
				</Typography>
			</Grid>

			<Grid item xs={window.isMobile ? 12 : 7}>
				<Grid container spacing={3}>
					<Grid item xs={window.isMobile ? 12 : 5}>
						<Select
							vertical={true}
							title={t('Typ')}
							gutterBottom={false}
							name="type"
							options={types}
							hideErrorText
							displayEmpty
							emptyLabel={'Wybierz'}
							value={data.type}
							onChange={handleChange}
							disabled={selectedLanguage === 'en'}
						/>
					</Grid>
					<Grid item xs={window.isMobile ? 12 : 5}>
						<FormInput
							title={t('Rodzaj narzędzia')}
							name="type_of_tool"
							value={data.type_of_tool || ''}
							onChange={handleChange}
							vertical={true}
							disabled={selectedLanguage === 'en'}
						/>
					</Grid>
				</Grid>

				<FormInput
					title={t('Forma prawna IP')}
					name="legal_form"
					value={(selectedLanguage === 'en' & data.legal_form?.[selectedLanguage] === null) ? data.legal_form?.pl : data.legal_form?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
				/>

				<FormInput
					title={t('Nazwa')}
					name="name"
					value={(selectedLanguage === 'en' && data.name?.[selectedLanguage] === null) ? data.name?.pl : data.name?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
				/>

				<FormInput
					title={t('Źródło IP')}
					name="ip_source"
					value={(selectedLanguage === 'en' && data.ip_source?.[selectedLanguage] === null) ? data.ip_source?.pl : data.ip_source?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
				/>

				<FormInput
					title={t('Dane właściciela')}
					name="owner"
					value={(selectedLanguage === 'en' && data.owner?.[selectedLanguage] === null) ? data.owner.pl : data.owner?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
				/>

				<FormInput
					title={t('W jaki sposób narzędzie zostanie wykorzystane w Projekcie?')}
					name="usage_in_project"
					value={(selectedLanguage === 'en' && data.usage_in_project?.[selectedLanguage] === null) ? data.usage_in_project?.pl : data.usage_in_project?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
					multiline={true}
					rows={4}
				/>

				<FormInput
					title={t('Czy będzie jedynie narzędziem do uzyskania wyników badań?')}
					name="is_only_for_results"
					value={(selectedLanguage === 'en' && data.is_only_for_results?.[selectedLanguage] === null) ? data.is_only_for_results?.pl : data.is_only_for_results?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
					multiline={true}
					rows={4}
				/>

				<FormInput
					title={t('Czy będzie stanowić element IP i wejdzie w proces komercjalizacji?')}
					name="is_ip_element"
					value={(selectedLanguage === 'en' && data.is_ip_element?.[selectedLanguage] === null) ? data.is_ip_element?.pl : data.is_ip_element?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
					multiline={true}
					rows={4}
				/>

				<FormInput
					title={t('Jakie starania należy poczynić, aby IP nie stanowiło przeszkody w komercjalizacji i kiedy?')}
					name="required_efforts"
					value={(selectedLanguage === 'en' && data.required_efforts?.[selectedLanguage] === null) ? data.required_efforts?.pl : data.required_efforts?.[selectedLanguage]}
					onChange={handleChange}
					vertical={true}
					multiline={true}
					rows={4}
				/>

				<PrimaryButton onClick={() => onSave(data)}>
					{values ? t('ZAPISZ ZMIANY') : t('ZAPISZ')}
				</PrimaryButton>
			</Grid>
		</Grid>
	);
};

export default Form;