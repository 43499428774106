import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ResearchTaskContext from 'context/ResearchTaskContext';
import Typography from 'components/Main/Typography';
import Form from './Form';
import ConsortiumMember from './ConsortiumMember';

const useStyles = makeStyles(theme => ({
	root: {
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(2),
		},
	},
}));

const EntitiesTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { formData, errors, onChange } = useContext(ResearchTaskContext);
	const classes = useStyles();
	const [leader, setLeader] = useState(formData.entities.leader);

	useEffect(() => onChange('entities.leader', leader), [leader]);

	const handleChangeLeader = e => {
		const { name, value } = e.target;
		setLeader(prev => {
			prev = { ...prev, [name]: value };
			if(name === 'is_correspondence_address_different' && !value) {
				prev.correspondence_apartment_number = '';
				prev.correspondence_building_number = '';
				prev.correspondence_city = '';
				prev.correspondence_postcode = '';
				prev.correspondence_street = '';
			}
			return prev;
		});
	};

	return (
		<Box className={classes.root}>
			<Typography>{t('Dane identyfikacyjne podmiotów, w których działalność naukową prowadzą Lider i Członkowie Zespołu badawczego')}</Typography>
			<Typography variant="h3">{t('Jednostka naukowa')}</Typography>
			<Typography>{t('Podmiot (określony w art. 7 ust. 1 pkt 1, 3-6 i 8 Ustawy PSW), w którym działalność naukową prowadzi Lider')}</Typography>
			<Form
				data={leader}
				onChange={handleChangeLeader}
				errors={errors?.entities?.leader}
			/>
			<Divider style={{ margin: '100px 0 30px' }} />
			{formData.general_info.is_consortium &&
				<ConsortiumMember />
			}
		</Box>
	);
};

export default EntitiesTab;

