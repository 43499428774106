import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Routes from 'router/Routes';
import useCheckPermissions from 'utils/useCheckPermissions';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import LanguageButton from 'components/Buttons/LanguageButton';
import Accordions from 'components/Main/Accordions';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import MainInfo from '../MainInfo';
import CommentModal from '../CommentModal';
import GeneralInfo from './GeneralInfo';
import EligibleList from './EligibleList';
import ResearchEquipmentList from './ResearchEquipmentList';
import FinalInfo from './FinalInfo';
import Exports from './Exports';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%',
	},
	accordions: {
		background: theme.palette.white,
		marginTop: theme.spacing(4),
		padding: theme.spacing(window.isMobile ? 2 : 4)
	}
}));

const ReportB = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const { task_id, id } = useParams();
	const classes = useStyles();
	const { researchTask, selectedLanguage } = useContext(ResearchTaskReportContext);
	const canFullyManage = useCheckPermissions('can_fully_manage_entities', true);

	const title = t('Dodaj raport B');
	const breadcrumbs = [
		{ title: t('Zadania badawcze'), to: Routes.ResearchTasks.List },
		{ title: `${t('Raportowanie')} - ${researchTask?.acronym}`, to: Routes.ResearchTasks.Reports.List(task_id) },
		{ title },
	];

	const { setSelectedLanguage, isPreview } = useContext(ResearchTaskReportContext);
	const { onCancel, onSave, onClickPrimary, saving, errors, status, disabledInput } = useContext(ResearchTaskReportBContext);
	const items = [
		{
			title: t('I Informacje ogólne'),
			child: <GeneralInfo />,
			defaultExpanded: true
		},
		{
			title: t('II Wykaz poniesionych wydatków kwalifikowanych'),
			child: <EligibleList />,
		},
		{
			title: t('III Wykaz aparatury naukowo-badawczej i wartości niematerialnych i prawnych (WNiP) pozyskanej w okresie sprawozdawczym (bez kosztów amortyzacji i odpowiedniego korzystania)'),
			child: <ResearchEquipmentList />,
		},
		{
			title: t('IV Informacje Końcowe'),
			child: <FinalInfo />,
		},
	];

	const [openModal, setOpenModal] = useState(false);
	const [isClickedPrimary, setIsClickedPrimary] = useState(false);

	useEffect(() => {
		if (isPreview) return document.title = 'WIB - Podgląd Raportu B';

		document.title = 'WIB - Edycja Raportu B';
	}, []);

	const handleClick = (callback, isPrimary) => () => {
		if (status === 'draft' || (status === 'accepted' && canFullyManage)) return callback();

		setIsClickedPrimary(isPrimary);
		setOpenModal(true);
	};

	const handleCloseModal = () => setOpenModal(false);

	const handleSaveComments = comments => {
		(isClickedPrimary ? onClickPrimary : onSave)(comments);
		handleCloseModal();
	};

	return (
		<>
			<Box className={classes.content}>
				<Header title={title}>
					<LanguageButton onChange={setSelectedLanguage} />
				</Header>
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				<MainInfo errors={errors} disabled={disabledInput} />

				<Box className={classes.accordions}>
					<Accordions items={items} />
				</Box>

				{task_id &&
					<Exports taskId={task_id} reportId={id} locale={selectedLanguage} />
				}
			</Box>
			<ControlButtonGroup
				primaryTitle={status === 'draft' ? 'Wyślij do akceptacji' : 'Zaakceptuj raport'}
				saveTitle={status === 'waiting-for-acceptance' ? 'ODRZUĆ' : undefined}
				onClickPrimary={handleClick(onClickPrimary, true)}
				onSave={handleClick(onSave)}
				onCancel={onCancel}
				saving={isPreview || saving}
				hidePrimaryButton={status === 'accepted'}
				hideSaveButton={status === 'accepted' && !canFullyManage}
			/>
			<CommentModal
				openModal={openModal}
				onClose={handleCloseModal}
				onSave={handleSaveComments}
			/>
		</>
	);
};

export default withRouter(ReportB);
