import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import PairCell from '../PairCell';

const SimpleRow = React.forwardRef(({ row, disabled }, ref) => {
	return (
		<TableRow key={row.name} ref={ref}>
			<TableCell>{row.label}</TableCell>
			<PairCell
				sumName={`table3.${row.name}.total_expenses`}
				inputName={`table3.${row.name}.expenditure_incurred`}
				disabled={disabled}
			/>
			<PairCell
				sumName={`table3.${row.name}.total_approved`}
				inputName={`table3.${row.name}.approved_expenses`}
				disabled={disabled}
			/>
		</TableRow>
	);
});
SimpleRow.displayName = 'SimpleRow';

export default SimpleRow;