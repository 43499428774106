import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import TableA from './TableA/List';
import TableB from './TableB/List';
import TableC from './TableC';

const SecondSection = () => {
	const classes = useStyles();

	return (
		<Box>
			<Box>
				<Typography variant="bodyM" className={classes.marginBottom30}>
					10. Informacja o podziale Środków z Komercjalizacji
				</Typography>

				<Typography variant="bodyM">
					{'a) 65% Środków z Komercjalizacji przekazane Twórcom zgodnie z ich udziałem twórczym zadeklarowanym w Karcie Własności Intelektualnej'}
				</Typography>
			</Box>

			<TableA />

			<Typography variant="bodyM">
				{'b) 15% Środków z Komercjalizacji przekazane Jednostce Naukowej lub Innym Jednostkom.'}
			</Typography>

			<Typography variant="bodyM">
				{'Środki z Komercjalizacji przysługujące danej Jednostce Naukowej lub Innym Jednostkom obliczone w proporcji zgodnej z proporcją wynikającą z udziału twórczego danego Twórcy (lub Twórców) z Jednostki Naukowej lub Innej Jednostki, zadeklarowaną w Karcie Własności Intelektualnej;'}
			</Typography>

			<TableB />

			<Typography variant="bodyM">
				{'c) Pozostałe Środki (20%) z Komercjalizacji przekazane na rzecz Funduszu Polskiej Nauki'}
			</Typography>

			<Box className={classes.root}>
				<TableC />
			</Box>
		</Box>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		paddingRight: theme.spacing(55),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	marginBottom30: {
		marginBottom: 30
	},
}));

export default SecondSection;