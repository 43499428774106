import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Select from 'components/Form/Select';
import ResearchTaskContext from 'context/ResearchTaskContext';
import BaseInput from 'components/Form/BaseInput';

const useStyles = makeStyles(() => ({
	select: {
		width: 200,
	},
	activeSelect: {
		width: 110
	}
}));

const Row = ({ entities, onChange, values, lastColumn, errors, className }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();
	const booleanOptions = [
		{ value: true, label: t('Tak') },
		{ value: false, label: t('Nie') },
	];
	const { researchTeamMemberTypes, researchTeamMemberRoles } = useContext(ResearchTaskContext);
	const [roles, setRoles] = useState(researchTeamMemberRoles);

	useEffect(() => {
		if (!values.member_type_id) {
			setRoles(researchTeamMemberRoles);
			return;
		}

		setRoles(() => _.filter(researchTeamMemberRoles, role =>
			_.includes(role?.type_ids, values.member_type_id)
		));
	}, [values.member_type_id]);

	useEffect(() => {
		if(_.find(roles, role => role.id === values.role_id)) return;
		onChange({ target: { name: 'role_id', value: '' } });
	}, [roles]);

	return (
		<TableRow className={className}>
			<TableCell>
				<BaseInput
					placeholder="Wpisz Ordinal"
					name="ordinal"
					value={values.ordinal}
					onChange={onChange}
					error={errors?.ordinal}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<Select
					formControlClassName={classes.select}
					options={researchTeamMemberTypes}
					displayEmpty
					name="member_type_id"
					valueField="id"
					labelField="name"
					value={values.member_type_id}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.member_type_id}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz imię i nazwisko')}
					name="name"
					value={values.name}
					onChange={onChange}
					error={errors?.name}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<Select
					formControlClassName={classes.select}
					options={roles}
					displayEmpty
					emptyLabel={t('Wybierz rolę')}
					name="role_id"
					valueField="id"
					labelField="name"
					value={values.role_id}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.role_id}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<Select
					formControlClassName={classes.activeSelect}
					options={booleanOptions}
					displayEmpty
					name="is_active"
					value={values.is_active}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.is_active}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz PESEL')}
					name="pesel"
					value={values.pesel}
					onChange={onChange}
					error={errors?.pesel}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<Select
					formControlClassName={classes.select}
					options={entities}
					emptyLabel={t('Wybierz podmiot')}
					displayEmpty
					name="entity_id"
					value={values.entity_id}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.entity_id}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz stanowisko')}
					name="position_name"
					value={values.position_name}
					onChange={onChange}
					error={errors?.position_name}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz')}
					name="science_discipline"
					value={values.science_discipline}
					onChange={onChange}
					error={errors?.science_discipline}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz')}
					name="orcid_number"
					value={values.orcid_number}
					onChange={onChange}
					error={errors?.orcid_number}
					hideErrorText
					inputProps={{ 'aria-required': false }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz nr')}
					name="phone_number"
					value={values.phone_number}
					onChange={onChange}
					error={errors?.phone_number}
					hideErrorText
					inputProps={{ 'aria-required': false }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz e-mail')}
					name="email"
					value={values.email}
					onChange={onChange}
					error={errors?.email}
					hideErrorText
					inputProps={{ 'aria-required': false }}
				/>
			</TableCell>
			<TableCell>
				{lastColumn}
			</TableCell>
		</TableRow>
	);
};

export default Row;
