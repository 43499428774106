import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import _debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';
import Select from 'components/Form/Select';

const useStyles = makeStyles(() => ({
	select: {
		maxWidth: 300
	}
}));

const FilterRow = ({ onChange }) => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Wniosek' });
	const statuses = [
		{ value: 'draft', label: t('Szkic') },
		{ value: 'published', label: t('Zapisany') },
	];
	const [filters, setFilters] = useState({
		application_date: '',
		application_number: '',
		status: '',
		controlsum: '',
	});

	useEffect(() => {
		handleChangeSearch(filters);
	}, [filters]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	const handleChangeSearch = useCallback(_debounce((filters) => onChange(filters), 500), []);

	return (
		<TableRow>
			<TableCell>
				<DateInput
					name="application_date"
					value={filters.application_date}
					onChange={handleChange}
					placeholder={t('Data od')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<FormInput
					name="application_number"
					value={filters.application_number}
					onChange={handleChange}
					placeholder={t('Wpisz nazwę')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<Select
					containerClassName={classes.select}
					options={statuses}
					name="status"
					value={filters.status}
					onChange={handleChange}
					displayEmpty
					emptyLabel={t('Wszystkie')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<FormInput
					name="controlsum"
					value={filters.controlsum}
					onChange={handleChange}
					placeholder={t('Wpisz nazwę')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};

export default FilterRow;
