import React from 'react';
import { useTranslation } from 'react-i18next';
import Attachments from './Attachments';
import { Box, makeStyles } from '@material-ui/core';
import Typography from 'components/Main/Typography';

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	divider: {
		marginBlock: theme.spacing(2),
	},
}));

const AttachmentsTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();

	return (
		<Box className={classes.root}>
			<Typography variant="h3">{t('Załączniki')}</Typography>
			<Attachments />
		</Box>
	);
};

export default AttachmentsTab;
