import React, { useContext } from 'react';
import { Box, Divider, makeStyles } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import IpCardContext from 'context/IpCardContext';

const Detail = (props) => {
	const { row } = props;
	const classes = useStyles();

	const { selectedLanguage } = useContext(IpCardContext);

	return (
		<Box className={classes.root}>
			<Typography variant="h4" className={classes.subHeader}>
				W jaki sposób narzędzie zostanie wykorzystane w Projekcie?
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{row.usage_in_project?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Czy będzie jedynie narzędziem do uzyskania wyników badań?
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{row.is_only_for_results?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Czy będzie stanowić element IP i wejdzie w proces komercjalizacji?
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{row.is_ip_element?.[selectedLanguage]}</Typography>

			<Divider className={classes.divider} />

			<Typography variant="h4" className={classes.subHeader}>
				Jakie starania należy poczynić, aby IP nie stanowiło przeszkody w komercjalizacji i kiedy?
			</Typography>
			<Typography variant="inputStatus" className={classes.fontStyle}>{row.required_efforts?.[selectedLanguage]}</Typography>
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: 100,
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	divider: {
		marginBlock: theme.spacing(2),
	},
	subHeader: {
		marginBottom: 10
	},
	fontStyle: {
		fontSize: 16,
	}
}));

export default Detail;