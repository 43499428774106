/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, TableContainer, Table as MuiTable, TableHead, TableRow, TableBody, TableCell, Box } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import TableLabel from 'components/Main/TableLabel';
import {
	DeleteIconButton,
	EditIconButton,
	CollapseCircleIconButton,
	ExpandCircleIconButton,
} from 'components/Buttons/IconButtons';
import Validator, { Required, validate } from 'utils/Validator';

import ManagementApplicationsContext from 'context/ManagementApplicationsContext';

import { HeadCell, SumCell } from './CustomCell';

import EditingRow from './EditingRow';
import Row from './Row';
import NumberText from 'components/Main/NumberText';

const useStyles = makeStyles(() => ({
	root: {
		border: '1px solid #D0D0D0',
		borderBottom: 'none',
		marginTop: 30,
		'& .MuiTableCell-root': {
			borderBottom: '1px solid #D0D0D0',
			verticalAlign: 'center',
		},
	},
	charCount: {
		textAlign: 'right',
	},
}));

const defaultDataTable1 = {
	research_task_id: '',
	requested_amount: 0,
	additional_information: ''
};

const defaultDataTable2 = {
	entity_id: '',
	requested_amount: 0,
	additional_information: ''
};

const defaultDataTable3 = {
	entity_id: '',
	requested_amount: 0,
	additional_information: ''
};

const Table = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Wniosek' });

	const { formData, researchTasks, onChange, isPreview } = useContext(ManagementApplicationsContext);


	// state for table1
	const [editingDataTable1, setEditingDataTable1] = useState(null);
	const [table1, setTable1] = useState(formData.costs?.table1 || []);
	const [addDataToTable1, setAddDataToTable1] = useState(defaultDataTable1);
	const [expandIndexTable1, setExpandIndexTable1] = useState(null);

	const [editErrorsTable1, setEditErrorsTable1] = useState(null);
	const [addErrorsTable1, setAddErrorsTable1] = useState(null);

	// state for table2
	const [editingDataTable2, setEditingDataTable2] = useState(null);
	const [table2, setTable2] = useState(formData.costs?.table2 || []);
	const [addDataToTable2, setAddDataToTable2] = useState(defaultDataTable2);
	const [expandIndexTable2, setExpandIndexTable2] = useState(null);

	const [editErrorsTable2, setEditErrorsTable2] = useState(null);
	const [addErrorsTable2, setAddErrorsTable2] = useState(null);

	// state for table3
	const [editingDataTable3, setEditingDataTable3] = useState(null);
	const [table3, setTable3] = useState(formData.costs?.table3 || []);
	const [addDataToTable3, setAddDataToTable3] = useState(defaultDataTable3);
	const [expandIndexTable3, setExpandIndexTable3] = useState(null);

	const [editErrorsTable3, setEditErrorsTable3] = useState(null);
	const [addErrorsTable3, setAddErrorsTable3] = useState(null);

	const ValidatorsTable1 = {
		research_task_id: new Validator(Required),
	};

	const ValidatorsTable2 = {
		entity_id: new Validator(Required),
	};

	const ValidatorsTable3 = {
		entity_id: new Validator(Required),
	};

	const totalSum = (_.sumBy(formData.costs?.table1, 'requested_amount') + _.sumBy(formData.costs?.table2, 'requested_amount') + _.sumBy(formData.costs?.table3, 'requested_amount') || 0);

	const handleEdit = (index, model) => {
		eval(`setEditingData${model}`)({ ...eval(model.toLowerCase())[index], index });
	};

	const handleDelete = (index, model) => () => {
		eval(`set${model}`)(prev => {
			prev.splice(index, 1);
			onChange('costs', { ...formData.costs, [model.toLowerCase()]: prev });
			return [...prev];
		});

		eval(`setEditingData${model}`)(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleClickReadMore = (index, model) => {
		eval(`setExpandIndex${model}`)(index);
		eval(`setEditingData${model}`)(null);
	};

	const handleChangeToAdd = (model) => (e) => {
		let { name, value } = e.target;
		if (name === 'requested_amount') value = value ? Number(value) : 0;

		eval(`setAddDataTo${model}`)(prev => ({ ...prev, [name]: value }));
	};

	const handleAdd = (model) => () => {
		let _errors = validate(eval(`addDataTo${model}`), eval(`Validators${model}`));
		eval(`setAddErrors${model}`)(_errors);

		if (eval(`editingData${model}`) || _errors) return;

		eval(`set${model}`)(prev => {
			prev.push(eval(`addDataTo${model}`));
			onChange('costs', { ...formData.costs, [model.toLowerCase()]: prev });
			return [...prev];
		});

		eval(`setAddDataTo${model}`)(eval(`defaultData${model}`));
	};

	const handleSave = (model) => () => {
		let _errors = validate(eval(`editingData${model}`), eval(`Validators${model}`));
		eval(`setEditErrors${model}`)(_errors);

		if (_errors) return;

		eval(`set${model}`)(prev => {
			prev[eval(`editingData${model}`).index] = eval(`editingData${model}`);
			onChange('costs', { ...formData.costs, [model.toLowerCase()]: prev });
			return [...prev];
		});
		eval(`setEditingData${model}`)(null);
	};

	const handleChangeEditing = (model) => e => {
		let { name, value } = e.target;
		if (name === 'requested_amount') value = value ? Number(value) : 0;

		eval(`setEditingData${model}`)(prev => ({ ...prev, [name]: value }));
	};

	useEffect(() => {
		setTable1(formData.costs?.table1);
	}, [formData.costs?.table1]);

	useEffect(() => {
		if (!editingDataTable1) return setEditErrorsTable1(null);

		let _errors = validate(editingDataTable1, ValidatorsTable1);
		setEditErrorsTable1(_errors);
	}, [editingDataTable1]);

	useEffect(() => {
		setTable2(formData.costs?.table2);
	}, [formData.costs?.table2]);

	useEffect(() => {
		if (!editingDataTable2) return setEditErrorsTable2(null);

		let _errors = validate(editingDataTable2, ValidatorsTable2);
		setEditErrorsTable2(_errors);
	}, [editingDataTable2]);

	useEffect(() => {
		setTable3(formData.costs?.table3);
	}, [formData.costs?.table3]);

	useEffect(() => {
		if (!editingDataTable3) return setEditErrorsTable3(null);

		let _errors = validate(editingDataTable3, ValidatorsTable3);
		setEditErrorsTable3(_errors);
	}, [editingDataTable3]);

	return (
		<div id="management">
			<TableContainer className={classes.root}>
				<MuiTable stickyHeader>
					<TableHead>
						<TableRow>
							<HeadCell colSpan={2} style={{ textAlign: 'right' }}>
								<TableLabel>
									{t('Kwota Wnioskowana')}
								</TableLabel>
							</HeadCell>

							<HeadCell colSpan={2}>
								<NumberText fixedDecimalScale={true} value={totalSum} />
							</HeadCell>
						</TableRow>
					</TableHead>

					<TableBody>
						<TableRow>
							<TableCell colSpan={4}>{t('w tym')}</TableCell>
						</TableRow>

						<TableRow>
							<HeadCell colSpan={4} style={{ textAlign: 'center' }}>
								<TableLabel>
									{t('I Środki finansowe - zespoły badawcze')}
								</TableLabel>
							</HeadCell>
						</TableRow>

						<TableRow>
							<HeadCell style={{ width: 70 }}>
								<TableLabel>{t('LP.')}</TableLabel>
							</HeadCell>

							<HeadCell style={{ width: 450 }}>
								<TableLabel>{t('Zespół Badawczy')}</TableLabel>
							</HeadCell>

							<HeadCell>
								<TableLabel>{t('Kwota Wnioskowana')}</TableLabel>
							</HeadCell>

							<HeadCell style={{ width: 60 }}></HeadCell>
						</TableRow>

						{table1?.map((item, index) => (
							!isPreview && index === editingDataTable1?.index
								? <EditingRow
									key={index}
									table="Table1"
									model={table1}
									handleClickReadMore={handleClickReadMore}
									options={researchTasks}
									option_label="title"
									option_name="research_task_id"
									item={editingDataTable1}
									onChange={handleChangeEditing('Table1')}
									onSave={handleSave('Table1')}
									onDelete={handleDelete(index, 'Table1')}
									errors={editErrorsTable1}
								/>
								: <React.Fragment key={index}>
									<TableRow>
										<TableCell>
											{index + 1}
										</TableCell>

										<TableCell>
											{researchTasks?.find(r => r.id === item?.research_task_id)?.title}
										</TableCell>

										<TableCell>
											<NumberText fixedDecimalScale={true} value={item?.requested_amount} />
										</TableCell>

										<TableCell>
											<Box display="flex">
												{expandIndexTable1 !== index
													? <ExpandCircleIconButton
														tooltip={t('Rozwiń')}
														onClick={() => handleClickReadMore(index, 'Table1')}
													/>
													: <CollapseCircleIconButton
														tooltip={t('Zwiń')}
														onClick={() => handleClickReadMore(null, 'Table1')}
													/>
												}

												{!isPreview && editingDataTable1?.index !== index &&
													< EditIconButton tooltip={t('Edytuj')} onClick={() => handleEdit(index, 'Table1')} />
												}

												{!isPreview &&
													<DeleteIconButton
														hideModal
														tooltip={t('Usuń')}
														title={t('Czy jesteś pewien, że chcesz usunąć ten wpis')}
														onClick={handleDelete(index, 'Table1')}
													/>
												}
											</Box>
										</TableCell>
									</TableRow>

									{expandIndexTable1 === index &&
										<TableRow>
											<TableCell></TableCell>

											<TableCell colSpan={2}>
												<TableLabel style={{ marginBottom: 10, fontSize: 12 }}>{t('Dodatkowe informacje')}</TableLabel>
												{item?.additional_information}
											</TableCell>

											<TableCell></TableCell>
										</TableRow>
									}
								</React.Fragment>
						))}

						<Row
							model={table1}
							options={researchTasks}
							option_label="title"
							option_name="research_task_id"
							onChange={handleChangeToAdd('Table1')}
							values={addDataToTable1}
							errors={addErrorsTable1}
							onAdd={handleAdd('Table1')}
							disabled={isPreview}
						/>

						<TableRow>
							<SumCell colSpan={2} style={{ textAlign: 'right' }}>
								<TableLabel>{t('Razem')}</TableLabel>
							</SumCell>

							<SumCell colSpan={2}>
								<NumberText fixedDecimalScale={true} value={_.sumBy(formData.costs?.table1, 'requested_amount')} />
							</SumCell>
						</TableRow>

						<TableRow>
							<HeadCell colSpan={4} style={{ textAlign: 'center' }}>
								<TableLabel>
									{t('II  Środki finansowe - zarządzanie WIB z wyłączeniem komercjalizacji')}
								</TableLabel>
							</HeadCell>
						</TableRow>

						<TableRow>
							<HeadCell style={{ width: 70 }}>
								<TableLabel>{t('LP.')}</TableLabel>
							</HeadCell>

							<HeadCell style={{ width: 450 }}>
								<TableLabel>{t('Podmiot zarządzający')}</TableLabel>
							</HeadCell>

							<HeadCell>
								<TableLabel>{t('Kwota Wnioskowana')}</TableLabel>
							</HeadCell>

							<HeadCell style={{ width: 60 }}></HeadCell>
						</TableRow>

						{table2?.map((item, index) => (
							!isPreview && index === editingDataTable2?.index
								? <EditingRow
									key={index}
									table="Table2"
									model={table2}
									handleClickReadMore={handleClickReadMore}
									option_label="entity_name"
									option_name="entity_id"
									item={editingDataTable2}
									onChange={handleChangeEditing('Table2')}
									onSave={handleSave('Table2')}
									onDelete={handleDelete(index, 'Table2')}
									errors={editErrorsTable2}
								/>
								: <React.Fragment key={index}>
									<TableRow>
										<TableCell>
											{index + 1}
										</TableCell>

										<TableCell>
											{item?.entity_id}
										</TableCell>

										<TableCell>
											<NumberText fixedDecimalScale={true} value={item?.requested_amount} />
										</TableCell>

										<TableCell>
											<Box display="flex">
												{expandIndexTable2 !== index
													? <ExpandCircleIconButton
														tooltip={t('Rozwiń')}
														onClick={() => handleClickReadMore(index, 'Table2')}
													/>
													: <CollapseCircleIconButton
														tooltip={t('Zwiń')}
														onClick={() => handleClickReadMore(null, 'Table2')}
													/>
												}

												{!isPreview && editingDataTable2?.index !== index &&
													< EditIconButton tooltip={t('Edytuj')} onClick={() => handleEdit(index, 'Table2')} />
												}

												{!isPreview &&
													<DeleteIconButton
														hideModal
														tooltip={t('Usuń')}
														title={t('Czy jesteś pewien, że chcesz usunąć ten wpis')}
														onClick={handleDelete(index, 'Table2')}
													/>
												}
											</Box>
										</TableCell>
									</TableRow>

									{expandIndexTable2 === index &&
										<TableRow>
											<TableCell></TableCell>

											<TableCell colSpan={2}>
												<TableLabel style={{ marginBottom: 10, fontSize: 12 }}>{t('Dodatkowe informacje')}</TableLabel>
												{item?.additional_information}
											</TableCell>

											<TableCell></TableCell>
										</TableRow>
									}
								</React.Fragment>
						))}

						<Row
							model={table2}
							option_label="entity_name"
							option_name="entity_id"
							onChange={handleChangeToAdd('Table2')}
							values={addDataToTable2}
							errors={addErrorsTable2}
							onAdd={handleAdd('Table2')}
							disabled={isPreview}
						/>

						<TableRow>
							<SumCell colSpan={2} style={{ textAlign: 'right' }}>
								<TableLabel>{t('Razem')}</TableLabel>
							</SumCell>

							<SumCell colSpan={2}>
								<NumberText fixedDecimalScale={true} value={_.sumBy(formData.costs?.table2, 'requested_amount')} />
							</SumCell>
						</TableRow>

						<TableRow>
							<HeadCell colSpan={4} style={{ textAlign: 'center' }}>
								<TableLabel>
									{t('III  Środki finansowe - komercjalizacja')}
								</TableLabel>
							</HeadCell>
						</TableRow>

						<TableRow>
							<HeadCell style={{ width: 70 }}>
								<TableLabel>{t('LP.')}</TableLabel>
							</HeadCell>

							<HeadCell style={{ width: 450 }}>
								<TableLabel>{t('Podmiot zarządzający')}</TableLabel>
							</HeadCell>

							<HeadCell>
								<TableLabel>{t('Kwota Wnioskowana')}</TableLabel>
							</HeadCell>

							<HeadCell style={{ width: 60 }}></HeadCell>
						</TableRow>

						{table3?.map((item, index) => (
							!isPreview && index === editingDataTable3?.index
								? <EditingRow
									key={index}
									table="Table3"
									model={table3}
									handleClickReadMore={handleClickReadMore}
									option_label="entity_name"
									option_name="entity_id"
									item={editingDataTable3}
									onChange={handleChangeEditing('Table3')}
									onSave={handleSave('Table3')}
									onDelete={handleDelete(index, 'Table3')}
									errors={editErrorsTable3}
								/>
								: <React.Fragment key={index}>
									<TableRow>
										<TableCell>
											{index + 1}
										</TableCell>

										<TableCell>
											{item?.entity_id}
										</TableCell>

										<TableCell>
											<NumberText fixedDecimalScale={true} value={item?.requested_amount} />
										</TableCell>

										<TableCell>
											<Box display="flex">
												{expandIndexTable3 !== index
													? <ExpandCircleIconButton
														tooltip={t('Rozwiń')}
														onClick={() => handleClickReadMore(index, 'Table3')}
													/>
													: <CollapseCircleIconButton
														tooltip={t('Zwiń')}
														onClick={() => handleClickReadMore(null, 'Table3')}
													/>
												}

												{!isPreview && editingDataTable3?.index !== index &&
													< EditIconButton tooltip={t('Edytuj')} onClick={() => handleEdit(index, 'Table3')} />
												}

												{!isPreview &&
													<DeleteIconButton
														hideModal
														tooltip={t('Usuń')}
														title={t('Czy jesteś pewien, że chcesz usunąć ten wpis')}
														onClick={handleDelete(index, 'Table3')}
													/>
												}
											</Box>
										</TableCell>
									</TableRow>

									{expandIndexTable3 === index &&
										<TableRow>
											<TableCell></TableCell>

											<TableCell colSpan={2}>
												<TableLabel style={{ marginBottom: 10, fontSize: 12 }}>{t('Dodatkowe informacje')}</TableLabel>
												{item?.additional_information}
											</TableCell>

											<TableCell></TableCell>
										</TableRow>
									}
								</React.Fragment>
						))}

						<Row
							model={table3}
							option_label="entity_name"
							option_name="entity_id"
							onChange={handleChangeToAdd('Table3')}
							values={addDataToTable3}
							errors={addErrorsTable3}
							onAdd={handleAdd('Table3')}
							disabled={isPreview}
						/>

						<TableRow>
							<SumCell colSpan={2} style={{ textAlign: 'right' }}>
								<TableLabel>{t('Razem')}</TableLabel>
							</SumCell>

							<SumCell colSpan={2}>
								<NumberText fixedDecimalScale={true} value={_.sumBy(formData.costs?.table3, 'requested_amount')} />
							</SumCell>
						</TableRow>
					</TableBody>
				</MuiTable>
			</TableContainer>
		</div>
	);
};

export default Table;
