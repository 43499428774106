import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import IconButton, { DeleteIconButton } from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const EditingRow = ({ item, onChange, onSave, onDelete, errors, selectedLanguage }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });

	return (
		<Row
			onChange={onChange}
			values={item}
			errors={errors}
			lastColumn={
				<Box display="flex">
					<IconButton onClick={onSave} tooltip={t('Zatwierdź')}>
						<CircleCheckIcon />
					</IconButton>
					<DeleteIconButton onClick={onDelete} disabled={selectedLanguage === 'en'} tooltip={t('Usuń')} />
				</Box>
			}
			selectedLanguage={selectedLanguage}
		/>
	);
};

export default EditingRow;
