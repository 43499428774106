import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';
import IpCardContext from 'context/IpCardContext';

const FilterRow = ({ onAddClick, errors }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { selectedLanguage } = useContext(IpCardContext);

	const [addData, setAddData] = useState({
		name: '',
		address: '',
		phone_number: '',
		email: '',
		percent_of_participation: '',
		agreement_type: {en: null, pl: ''},
		unit_name: {en: null, pl: ''},
	});

	const handleChange = e => {
		const { name, value } = e.target;
		if (name === 'agreement_type' || name === 'unit_name') {
			let prevData = {...addData};
			prevData[name][selectedLanguage] = value;
			setAddData(() => ({ ...prevData }));
		} else {
			setAddData(prev => ({ ...prev, [name]: value }));
		}
	};

	return (
		<Row
			onChange={handleChange}
			values={addData}
			errors={errors}
			lastColumn={
				<IconButton onClick={() => onAddClick(addData)} tooltip={t('Zatwierdź')}>
					<CircleCheckIcon />
				</IconButton>
			}
		/>
	);
};

export default FilterRow;
