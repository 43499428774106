import React, { useContext, useEffect } from 'react';
import _ from 'lodash';

import NumberText from 'components/Main/NumberText';
import NumberInput from 'components/Form/NumberInput';
import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';
import CustomCell from './CustomCell';

const PairCell = React.forwardRef((props, ref) => {
	const { sumName, inputName, cellType = '', disabled } = props;
	const { formData, publishedInformations, onChange } = useContext(ManagementDetailedInformationsContext);

	useEffect(() => {
		calculateSum(inputName, sumName);
	}, [publishedInformations, _.get(formData, inputName)]);

	const calculateSum = (sourceName, targetName) => {
		//if (formData?.status === 'published') return;

		let sum = _.sumBy(publishedInformations, item => +_.get(item, sourceName));
		sum += +_.get(formData, sourceName);

		onChange(targetName, sum);
	};

	const handleChange = e => {
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<React.Fragment ref={ref}>
			<CustomCell type={cellType}>
				<NumberText fixedDecimalScale={true} value={_.get(formData, sumName)} />
			</CustomCell>
			<CustomCell type={cellType}>
				<NumberInput
					name={inputName}
					value={_.get(formData, inputName)}
					onChange={handleChange}
					disabled={disabled}
				/>
			</CustomCell>
		</React.Fragment>
	);
});
PairCell.displayName = 'PairCell';

export default PairCell;
