import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/Form/FormInput';
import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const AchievedIndicators = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	
	return (
		<FormInput
			title={t('Wskaźniki produktu')}
			value={formData[selectedLanguage].research_team.product_indicator || ''}
			onChange={e => onChange(`${selectedLanguage}.research_team.product_indicator`, e.target.value)}
			multiline
			minRows={10}
			maxRows={10}
			alignItems="flex-start"
			error={errors?.research_team?.product_indicator}
			disabled={disabledInput}
			inputProps={{ 'aria-required': true }}
		/>
	);
};

export default AchievedIndicators;