import React, { useContext } from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Select from 'components/Form/Select';
import ResearchTaskContext from 'context/ResearchTaskContext';
import DateInput from 'components/Form/DateInput';
import BaseInput from 'components/Form/BaseInput';

const useStyles = makeStyles(() => ({
	select: {
		width: 200,
	},
}));

const Row = ({ onChange, values, lastColumn, errors, className }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();
	const { planTypes } = useContext(ResearchTaskContext);

	return (
		<TableRow className={className}>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz nr')}
					name="stage_number"
					type="number"
					value={values.stage_number}
					onChange={onChange}
					error={errors?.stage_number}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					name="start_date"
					placeholder={t('Wybierz datę')}
					value={values.start_date}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.start_date}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					name="end_date"
					placeholder={t('Wybierz datę')}
					value={values.end_date}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.end_date}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz liczbę')}
					name="number_of_months"
					type="number"
					value={values.number_of_months}
					onChange={onChange}
					error={errors?.number_of_months}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder={t('Wpisz tytuł')}
					name="title"
					value={values.title}
					onChange={onChange}
					error={errors?.title}
					hideErrorText
					inputProps={{ 'aria-required': true, maxLength: 1000 }}
				/>
			</TableCell>
			<TableCell>
				<Select
					fullWidth
					formControlClassName={classes.select}
					options={planTypes}
					displayEmpty
					name="plan_type_id"
					valueField="id"
					labelField="name"
					value={values.plan_type_id}
					onChange={onChange}
					gutterBottom={false}
					error={errors?.plan_type_id}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				{lastColumn}
			</TableCell>
		</TableRow>
	);
};

export default Row;
