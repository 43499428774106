import _ from 'lodash';

export const getNewTask = (researchTask, publishedInformations) => {
	let data = {
		sum: {
			contract_amount: 0,
			total_fund_paid: 0,
			funds_paid: 0,
			total_expenditure_reported: 0,
			expenditure_reported: 0,
			total_approved_expenditure: 0,
			district_approved_expenditure: 0,
			funds_use_percent: 0,
		},
		salary: {
			contract_amount: getContrastAmount(researchTask, ['col_2', 'col_3']),
			total_expenditure_reported: getTotalExpenditureReported(researchTask, 'salary'),
			expenditure_reported: 0,
			total_approved_expenditure: getTotalApprovedExpenditure(researchTask, 'salary'),
			district_approved_expenditure: 0,
			funds_use_percent: 0,
		},
		equipment_costs: {
			contract_amount: getContrastAmount(researchTask, 'col_4'),
			total_expenditure_reported: getTotalExpenditureReported(researchTask, 'equipment_costs'),
			expenditure_reported: 0,
			total_approved_expenditure: getTotalApprovedExpenditure(researchTask, 'equipment_costs'),
			district_approved_expenditure: 0,
			funds_use_percent: 0,
		},
		external_service_costs: {
			contract_amount: getContrastAmount(researchTask, 'col_5'),
			total_expenditure_reported: getTotalExpenditureReported(researchTask, 'external_service_costs'),
			expenditure_reported: 0,
			total_approved_expenditure: getTotalApprovedExpenditure(researchTask, 'external_service_costs'),
			district_approved_expenditure: 0,
			funds_use_percent: 0,
		},
		other_direct_costs: {
			contract_amount: getContrastAmount(researchTask, 'col_6'),
			total_expenditure_reported: getTotalExpenditureReported(researchTask, 'other_direct_costs'),
			expenditure_reported: 0,
			total_approved_expenditure: getTotalApprovedExpenditure(researchTask, 'other_direct_costs'),
			district_approved_expenditure: 0,
			funds_use_percent: 0,
		},
		indirect_costs: {
			contract_amount: getContrastAmount(researchTask, 'col_7'),
			total_expenditure_reported: getTotalExpenditureReported(researchTask, 'indirect_cost'),
			expenditure_reported: 0,
			total_approved_expenditure: getTotalApprovedExpenditure(researchTask, 'indirect_cost'),
			district_approved_expenditure: 0,
			funds_use_percent: 0,
		},
		comment: '',
		research_task_id: researchTask.id,
		research_task_acronym: researchTask.acronym,
	};
	// Get sum of row.
	data.salary.funds_use_percent = getFundsUsePercent(data.salary);
	data.equipment_costs.funds_use_percent = getFundsUsePercent(data.equipment_costs);
	data.external_service_costs.funds_use_percent = getFundsUsePercent(data.external_service_costs);
	data.other_direct_costs.funds_use_percent = getFundsUsePercent(data.other_direct_costs);
	data.indirect_costs.funds_use_percent = getFundsUsePercent(data.indirect_costs);

	// Get sum of column.
	data.sum.contract_amount = getSum(data, 'contract_amount');
	data.sum.total_fund_paid = getTotalFundsPaid(data, publishedInformations);
	data.sum.total_expenditure_reported = getSum(data, 'total_expenditure_reported');
	data.sum.total_approved_expenditure = getSum(data, 'total_approved_expenditure');
	data.sum.funds_use_percent = getFundsUsePercent(data.sum);
	return data;
};

const getContrastAmount = (task, fields) => {
	if (_.isString(fields)) {
		return task?.task_estimate_sum?.[fields] || 0;
	}

	let value = 0;
	_.map(fields, field => value += +task?.task_estimate_sum?.[field] || 0);
	return value;
};
const getTotalExpenditureReported = (task, field) => {
	const value = _.sumBy(task.accepted_report_b, `data.pl.expenses.data.sum.${field}`);
	return value || 0;
};
const getTotalApprovedExpenditure = (task, field) => {
	const value = _.sumBy(task.accepted_report_b, `data.pl.expenses.data.manage_entity_cost.${field}`);
	return value || 0;
};

const getTotalFundsPaid = (data, publishedInformations) => {
	let value = 0;
	_.map(publishedInformations, item => {
		let task = _.find(item.table1.research_tasks, { research_task_id: data.research_task_id });
		value += +task?.sum?.funds_paid || 0;
	});
	return value;
};

// funds percentage of research task.
const getFundsUsePercent = data => {
	return (data.total_approved_expenditure / (data.contract_amount || 1)) * 100;
};

// Sum of column of research task
export const getSum = (data, field) => {
	const keys = ['salary', 'equipment_costs', 'external_service_costs', 'other_direct_costs', 'indirect_costs'];
	return _.sumBy(keys, key => +data[key][field]);
};

export const getSumOfResearchTasks = tasks => {
	let data = {
		contract_amount: _.sumBy(tasks, task => +task.sum.contract_amount),
		total_fund_paid: _.sumBy(tasks, task => +task.sum.total_fund_paid),
		funds_paid: _.sumBy(tasks, task => +task.sum.funds_paid),
		total_expenditure_reported: _.sumBy(tasks, task => +task.sum.total_expenditure_reported),
		expenditure_reported: _.sumBy(tasks, task => +task.sum.expenditure_reported),
		total_approved_expenditure: _.sumBy(tasks, task => +task.sum.total_approved_expenditure),
		district_approved_expenditure: _.sumBy(tasks, task => +task.sum.district_approved_expenditure),
		funds_use_percent: 0,
	};
	data.funds_use_percent = getFundsUsePercent(data);
	return data;
};
