import React, { useState } from 'react';
import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import TableLabel from 'components/Main/TableLabel';
import FormInput from 'components/Form/FormInput';
import Select from 'components/Form/Select';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import NumberInput from 'components/Form/NumberInput';
import { CircleCheckIcon } from 'assets/svg';
import IconButton from 'components/Buttons/IconButtons';

const Row = ({ model, values, onChange, errors, options, option_name, option_label, lastColumn, onAdd, disabled }) => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Wniosek' });
	const [showAddRow, setShowAddRow] = useState(false);

	const handleAdd = () => {
		setShowAddRow(false);
		onAdd();
	};

	return (
		<>
			{(!disabled && (showAddRow || _.has(values, 'index'))) &&
				<TableRow>
					<TableCell>
						{(values?.index + 1) || (model?.length + 1)}
					</TableCell>

					<TableCell>
						{option_name === 'entity_id' ? (
							<FormInput
								name="entity_id"
								value={values?.entity_id}
								onChange={onChange}
								placeholder={t('Wpisz')}
								error={errors?.entity_id}
								gutterBottom={false}
								hideErrorText
								inputProps={{ 'aria-required': true }}
							/>
						) : (
							<Select
								name={option_name}
								options={options}
								value={values?.[option_name]}
								onChange={onChange}
								valueField="id"
								labelField={option_label}
								displayEmpty
								hideErrorText
								error={errors?.[option_name]}
								gutterBottom={false}
								inputProps={{ 'aria-required': true }}
							/>
						)}

					</TableCell>

					<TableCell>
						<NumberInput
							fullWidth
							name="requested_amount"
							value={values?.requested_amount}
							onChange={onChange}
							placeholder={t('Wpisz')}
						/>
					</TableCell>

					<TableCell>
						{!_.has(values, 'index')
							? (
								<IconButton onClick={handleAdd} tooltip={t('Zatwierdź')}>
									<CircleCheckIcon />
								</IconButton>
							)
							: lastColumn
						}
					</TableCell>
				</TableRow>
			}

			{(!disabled && _.has(values, 'index')) &&
				<TableRow>
					<TableCell></TableCell>

					<TableCell colSpan={2}>
						<TableLabel style={{ marginBottom: 10, fontSize: 12 }}>{t('Dodatkowe informacje')}</TableLabel>

						<FormInput
							name="additional_information"
							value={values?.additional_information || ''}
							onChange={onChange}
							placeholder={t('Wpisz dodatkowe informacje')}
							gutterBottom={false}
							multiline={true}
							rows={5}
							inputProps={{ maxLength: 2000, 'aria-required': true }}
							errorState={!!errors?.additional_information}
							error={`${values?.additional_information?.length || 0}/2000`}
							FormHelperTextProps={{
								error: false,
								classes: { root: classes.charCount }
							}}
						/>
					</TableCell>

					<TableCell>
					</TableCell>
				</TableRow>
			}

			{!_.has(values, 'index') &&
				<TableRow>
					<TableCell></TableCell>
					<TableCell colSpan={3}>
						<PrimaryButton
							variant={showAddRow ? 'outlined' : 'contained'}
							onClick={() => setShowAddRow(!showAddRow)}
							disabled={disabled}
						>
							{!showAddRow
								? (option_label === 'entity_name' ? t('Dodaj wiersz') : t('Dodaj zespół badawczy'))
								: t('Anuluj dodawanie')
							}
						</PrimaryButton>
					</TableCell>
				</TableRow>

			}
		</>
	);
};

const useStyles = makeStyles(() => ({
	charCount: {
		textAlign: 'right',
	},
}));

export default Row;