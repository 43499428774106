import React from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	head: {
		background: '#EEEEEE',
	},
	sum: {
		background: '#F6F9FF'
	},
}));

const CustomCell = React.forwardRef(({ type, children, ...rest }, ref) => {
	const classes = useStyles();

	return (
		<TableCell className={classes[type]} {...rest} ref={ref}>{children}</TableCell>
	);
});
CustomCell.displayName = 'CustomCell';

const HeadCell = props => <CustomCell type="head" {...props} />;
const SumCell = props => <CustomCell type="sum" {...props} />;

export default CustomCell;
export { HeadCell, SumCell };