import React, { useContext } from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/Form/FormInput';
import RadioButton from 'components/Form/RadioButton';
import Typography from 'components/Main/Typography';

import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import PaymentSchedule from './PaymentSchedule';
import BudgetTable from './BudgetTable';

const useStyles = makeStyles(() => ({
	gutter: {
		marginBlock: 30,
	}
}));

const ChangedInformation = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const booleanList = [
		{ name: 1, label: t('Tak') },
		{ name: 0, label: t('Nie') },
	];
	const classes = useStyles();

	const { formData, onChange, errors, selectedLanguage, disabledInput } = useContext(ResearchTaskReportBContext);
	const data = formData[selectedLanguage];

	const handleChange = e => {
		let { name, value } = e.target;
		if (name === 'made_changes_required_acceptance') {
			value = +value;
			onChange(`pl.changed_information.${name}`, value);
			onChange(`en.changed_information.${name}`, value);
		}
		onChange(`${selectedLanguage}.changed_information.${name}`, value, null, true);
	};

	return (
		<>
			<Typography variant="h4" color="primary">
				{t('Tabela nr 3 - Informacja o zmianach dokonanych w okresie sprawozdawczym')}
			</Typography>
			<Typography className={classes.gutter}>
				{t('I. Wprowadzono zmiany w Zadaniu Badawczym wymagające poinformowania i/lub uprzedniej zgody Podmiotu Zarządzającego')}
			</Typography>
			<RadioButton
				title={('Zmiany')}
				name="made_changes_required_acceptance"
				list={booleanList}
				value={data.changed_information.made_changes_required_acceptance}
				onChange={handleChange}
				row
				disabled={disabledInput || selectedLanguage === 'en'}
			/>
			{!!data.changed_information.made_changes_required_acceptance &&
				<>
					<FormInput
						title={t('Opis zmian')}
						name="description_of_changes"
						value={data.changed_information.description_of_changes}
						onChange={handleChange}
						multiline
						minRows={10}
						maxRows={10}
						alignItems="flex-start"
						error={errors?.changed_information?.description_of_changes}
						disabled={disabledInput}
						inputProps={{ 'aria-required': true }}
					/>

					<PaymentSchedule />
					<FormInput
						title={t('Uzasadnienie')}
						name="schedule_reason_of_change"
						value={data.changed_information.schedule_reason_of_change}
						onChange={handleChange}
						multiline
						minRows={10}
						maxRows={10}
						alignItems="flex-start"
						error={errors?.changed_information?.schedule_reason_of_change}
						disabled={disabledInput}
						inputProps={{ 'aria-required': true }}
					/>
					<Typography>
						{t('III. Zmianą budżetu wymagająca poinformowania* lub uprzedniej zgody** Podmiotu Zarządzającego')}
					</Typography>
					<Typography>
						{t('* w związku z koniecznością dokonania przesunięć środków pomiędzy poszczególnymi kategoriami wydatków do 10% wartości kwoty danej kategorii wydatków, do której następuje przesunięcie')}
					</Typography>
					<Typography>
						{t('** dotycząca przesunięcia środków finansowych, pomiędzy poszczególnymi kategoriami wydatków powyżej 10% wartości kwoty danej kategorii wydatków, do której następuje przesunięcie')}
					</Typography>
					<Divider className={classes.gutter} />

					<BudgetTable title={t('Budżet przed zmianą')} dataKey="budget_before" readOnly />
					<Divider className={classes.gutter} />

					<BudgetTable title={t('Przesunięcia w budżecie')} dataKey="budget_offset" readOnly={disabledInput || selectedLanguage === 'en'} />
					<FormInput
						title={t('Opis przesunięć wraz z uzasadnieniem')}
						name="budget_offset_description"
						value={data.changed_information.budget_offset_description}
						onChange={handleChange}
						multiline
						minRows={10}
						maxRows={10}
						alignItems="flex-start"
						error={errors?.changed_information?.budget_offset_description}
						containerClassName={classes.gutter}
						disabled={disabledInput}
						inputProps={{ 'aria-required': true }}
					/>
					<Divider className={classes.gutter} />

					<BudgetTable title={t('Budżet po zmianie')} dataKey="budget_after" readOnly />
				</>
			}
		</>
	);
};

export default ChangedInformation;