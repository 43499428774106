import React, { useContext, useEffect, useState } from 'react';
import { Divider, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { LinkIcon } from 'assets/svg';
import Select from 'components/Form/Select';
import FormInput from 'components/Form/FormInput';
import { DeleteIconButton } from 'components/Buttons/IconButtons';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';

const Item = ({ data, errors, onChange, selectedPlans, onDelete, disabled, selectedLanguage }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const { researchTask } = useContext(ResearchTaskReportContext);
	const [taskPlans, setTaskPlans] = useState([]);

	useEffect(() => {
		setTaskPlans(prev => {
			prev = researchTask?.task_plans.filter(plan =>
				plan.id === data.research_task_plan_id || !selectedPlans.includes(plan.id)
			);
			return [...prev];
		});
	}, [researchTask?.task_plans, selectedPlans]);

	return (
		<>
			<Grid container alignItems="center" spacing={1}>
				<Grid item xs={window.isMobile ? 11 : 2}>
					<Select
						title={t('Nr etapu')}
						name="research_task_plan_id"
						valueField="id"
						labelField="stage_number"
						displayEmpty
						options={taskPlans}
						value={data.research_task_plan_id}
						onChange={onChange}
						vertical
						error={errors?.research_task_plan_id}
						disabled={disabled || selectedLanguage === 'en'}
						inputProps={{ 'aria-required': true }}
					/>
				</Grid>
				<Grid item xs={1} container justifyContent="center" style={{ marginTop: 14 }}>
					<LinkIcon />
				</Grid>
				<Grid item xs={window.isMobile ? 11 : 8}>
					<Select
						title={t('Tytuł etapu')}
						name="research_task_plan_id"
						valueField="id"
						labelField="title"
						displayEmpty
						options={taskPlans}
						value={data.research_task_plan_id}
						onChange={onChange}
						vertical
						fullWidth
						error={errors?.research_task_plan_id}
						disabled={disabled || selectedLanguage === 'en'}
						inputProps={{ 'aria-required': true }}
					/>
				</Grid>

				{!disabled && selectedLanguage !== 'en' &&
					<Grid item xs={1}>
						<DeleteIconButton
							tooltip={t('Usuń etap')}
							onClick={onDelete}
							style={{ marginTop: 24 }}
							disabled={disabled || selectedLanguage === 'en'}
						/>
					</Grid>
				}
			</Grid>
			<FormInput
				title={t('Opis prac prowadzonych w etapie')}
				name="stage_description"
				value={data.stage_description}
				onChange={onChange}
				vertical
				multiline
				minRows={10}
				maxRows={10}
				error={errors?.stage_description}
				disabled={disabled}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Opis osiągniętych mierzalnych efektów i rezultatowi rezultatów realizacji etapu (produkty/kamienie milowe)')}
				name="achieved_description"
				value={data.achieved_description}
				onChange={onChange}
				vertical
				multiline
				minRows={10}
				maxRows={10}
				error={errors?.achieved_description}
				disabled={disabled}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Opis wprowadzonych zmian oraz ich wpływ na realizację etapu (jeśli dotyczy)')}
				name="impact_description"
				value={data.impact_description}
				onChange={onChange}
				vertical
				multiline
				minRows={10}
				maxRows={10}
				error={errors?.impact_description}
				disabled={disabled}
				inputProps={{ 'aria-required': true }}
			/>
			<Divider style={{ marginBlock: 30 }} />
		</>
	);
};

export default Item;