import React from 'react';
import Typography from 'components/Main/Typography';
import { Box, Divider, makeStyles } from '@material-ui/core';
import DevelopmentDirections from './DevelopmentDirections';
import DetailedDescription from './DetailedDescription';
import OtherData from './OtherData';
import { useTranslation } from 'react-i18next';

const Description = () => {
	const classes = useStyles();
	const {t} = useTranslation(null, {keyPrefix: 'Karty własności - opis'});

	return (
		<Box>
			<Typography
				variant="h3"
				style={{ marginBottom: 20 }}
			>
				{t('Opis Własności Intelektualnej')}
			</Typography>

			<DevelopmentDirections />
			
			<Divider className={classes.divider} />
			
			<DetailedDescription />

			<OtherData />
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	divider: {
		marginBlock: theme.spacing(4),
	}
}));

export default Description;