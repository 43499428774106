import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

import Routes from 'router/Routes';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useCheckPermissions from 'utils/useCheckPermissions';
import API from 'apis/API';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(theme => ({
	section: {
		background: theme.palette.white,
		padding: theme.spacing(window.isMobile ? 2 : 4),
	},
	item: {
		alignItems: 'center',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	img: {
		width: 'fit-content',
		marginBottom: 35,
	},
	text: {
		textAlign: 'center',
		marginBottom: theme.spacing(2),
	}
}));

const Home = () => {
	const classes = useStyles();
	const canAccessResearchTask = useCheckPermissions([
		'can_add_research_task_drafts',
		'can_publish_research_task',
		'can_view_all_research_tasks',
		'can_view_assigned_research_tasks',
		'can_view_assigned_reports_a',
		'can_view_all_reports_a',
		'can_view_assigned_reports_b',
		'can_view_all_reports_b',
		'can_create_report_a',
		'can_send_report_a_for_acceptance',
		'can_accept_reject_report_a',
		'can_create_report_b',
		'can_send_report_b_for_acceptance',
		'can_accept_reject_report_b',
		'can_remove_own_reports_a',
		'can_remove_own_reports_b',
		'can_remove_all_reports_a',
		'can_remove_all_reports_b',
	], true);
	const canAccessIpCards = useCheckPermissions([
		'can_create_ip_card',
		'can_view_all_ip_card',
		'can_view_assigned_ip_card',
		'can_delete_all_ip_cards',
		'can_delete_own_ip_cards',
		'can_fill_field_date_of_receipt_ip_card',
		'can_fill_field_registration_number_ip_card',
		'can_fill_field_swot_ip_card',
		'can_fill_comercialization_ip_card',
	], true);
	const canManageFinances = useCheckPermissions([
		'can_manage_finances',
		'can_view_finances'
	], true);

	const [items, setItems] = useState([]);
	const [welcome, setWelcome] = useState({});

	useEffect(() => {
		document.title = 'WIB - Home';
		API.pages.get(6).then(({ data }) => {
			setWelcome({
				title: data?.page?.title,
				content: data?.page?.content
			});
			setItems([
				{
					title: 'ZADANIA BADAWCZE',
					image: '/images/research_tasks.png',
					alt: 'Ikona symbolizująca moduł “Zadania Badawcze”',
					link_label: 'ZOBACZ ZADANIA',
					href: Routes.ResearchTasks.List,
					description: data?.page?.content1,
					hidden: !canAccessResearchTask,
				},
				{
					title: 'ZARZĄDZANIE WIB',
					image: '/images/wib_management.png',
					alt: 'Ikona symbolizująca moduł “Zarządzanie WIB”',
					link_label: 'PRZEGLĄDAJ KOSZTY',
					href: Routes.Management.List(),
					description: data?.page?.content2,
					hidden: !canManageFinances,
				},
				{
					title: 'REJESTR WŁASNOŚCI INTELEKTUALNEJ',
					image: '/images/intellectual_property_register.png',
					alt: 'Ikona symbolizująca moduł “Rejestr Własności Intelektualnej“',
					link_label: 'ZOBACZ KARTY',
					href: Routes.IpCards.List,
					description: data?.page?.content3,
					hidden: !canAccessIpCards,
				},
			]);
		});
	}, []);

	return (
		<>
			<Header title="Home" />
			<Breadcrumbs isHome />
			<Box className={classes.section} mb={2.5}>
				<Typography variant="h3" paragraph>
					<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(welcome.title) }} />
				</Typography>
				<Typography>
					<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(welcome.content) }} />
				</Typography>
				{/* <Box display={'flex'} sx={{justifyContent: 'space-between', marginTop: '15px'}}>
					<Link to="/privacy-policy" component={RouterLink} color="primary"><Typography variant="link">Polityka Prywatności</Typography></Link>
					<Link to="/regulations" component={RouterLink} color="primary"><Typography variant="link">Regulamin korzystania z serwisu</Typography></Link>
					<Link to="/information-clause" component={RouterLink} color="primary"><Typography variant="link">Klauzula Informacyjna</Typography></Link>
				</Box> */}
			</Box>
			<Grid container spacing={2} alignItems="stretch">
				{items.map((item, i) => (
					<React.Fragment key={i}>
						{item?.hidden
							? <></>
							: <Grid item sm={4} xs={12}>
								<Box className={clsx(classes.section, classes.item)}>
									<Box display="flex" flexDirection="column" alignItems="center">
										<img src={item.image} alt={item.alt} className={classes.img} />
										<Typography variant="h4" color="primary" className={classes.text}>{item.title}</Typography>
										<Typography className={classes.text}>
											<span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.description) }} />
										</Typography>
									</Box>
									<PrimaryButton to={item.href} component={RouterLink}>{item.link_label}</PrimaryButton>
								</Box>
							</Grid>
						}
					</React.Fragment>
				))}
			</Grid>
		</>
	);
};

export default Home;
