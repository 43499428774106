import React, { useContext, useEffect, useState } from 'react';
import {
	makeStyles,
	Modal,
	Backdrop,
	Fade,
	Card,
	Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import Select from 'components/Form/Select';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 600,
		boxShadow: theme.shadows[5],
		padding: '25px',
		outline: 'none',
	},
}));

const SelectMemberModal = props => {
	const { openModal, onClose, onSave, editingMember, entityId } = props;
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const { researchTask } = useContext(ResearchTaskReportContext);
	const [memberId, setMemberId] = useState('');

	useEffect(() => setMemberId(editingMember?.id || ''), [openModal]);

	const handleSave = () => {
		if (!memberId) return;
		let member = researchTask.team_members.find(member => member.id === memberId);

		let data = editingMember || {
			data: [],
			sum: {
				netto_amount: 0,
				vat_amount: 0,
				eligible_amount: 0,
			}
		};

		onSave({
			...data,
			id: member.id,
			name: member.name,
			member_type_id: member.member_type_id
		});
		onClose();
	};

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={openModal}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}} >
			<Fade in={openModal}>
				<Card className={classes.paper}>
					<Typography variant="h3" style={{ marginBottom: 16 }}>
						{t(editingMember ? 'Edytuj członka zespołu badawczego' : 'Dodaj członka zespołu badawczego')}
					</Typography>
					<Select
						title={t('Wybierz członka')}
						options={researchTask.team_members.filter(member => member.entity_id === entityId)}
						value={memberId}
						onChange={e => setMemberId(e.target.value)}
						valueField="id"
						labelField="name"
						displayEmpty
						hideErrorText
						error={!memberId}
						inputProps={{ 'aria-required': true }}
					/>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						<PrimaryButton onClick={onClose} variant="outlined">
							{t('Anuluj')}
						</PrimaryButton>
						<PrimaryButton onClick={handleSave} style={{ marginLeft: 16 }}>
							{t('Zapisz')}
						</PrimaryButton>
					</Box>
				</Card>
			</Fade>
		</Modal>
	);
};

export default SelectMemberModal;
