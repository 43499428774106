import React, { useContext, useEffect } from 'react';
import { makeStyles, Box, Grid, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Routes from 'router/Routes';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import Typography from 'components/Main/Typography';
import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';

import ManagementSettlementsContext, { ManagementSettlementsContextProvider } from 'context/ManagementSettlementsContext';
import GeneralSettlements from './GeneralSettlements';
import DetailedSettlements from './DetailedSettlements';
import Exports from '../../DetailedInformations/Form/Exports';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%',
	}
}));

const FormContainer = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - rozliczenie' });
	const breadcrumbs = [
		{ title: t('Zarządzanie WIB'), to: Routes.Management.List('settlements') },
		{ title: t('Dodaj rozliczenie środków finansowych') }
	];
	const { formData, onChange, onSave, onPublish, onCancel, errors, saving, isPreview } = useContext(ManagementSettlementsContext);

	useEffect(() => document.title = 'WIB - Edycja rozliczenia środków finansowych', []);

	const handleChange = e => {
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<>
			<Box className={classes.content}>
				<Header title={t('Dodaj rozliczenie środków finansowych')} />
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				<Box p={window.isMobile ? 2 : 4} bgcolor="#fff">
					<DateInput
						title={t('Rozliczenie środków finansowych przekazanych w ramach Funduszu Polskiej Nauki z dnia')}
						titleVariant="h4"
						name="settlement_date"
						value={formData.settlement_date}
						onChange={handleChange}
						sm={6}
						inputProps={{ 'aria-required': true }}
						disabled={isPreview}
					/>
					<Grid container alignItems="center" spacing={2}>
						<Grid item sm={3}>
							<Typography>{t('Okres sprawozdawczy')}</Typography>
						</Grid>
						<Grid item>
							<DateInput
								fullWidth
								name="date_from"
								value={formData.date_from}
								gutterBottom={false}
								disabled
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item>
							<DateInput
								fullWidth
								name="date_to"
								value={formData.date_to}
								gutterBottom={false}
								disabled
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
					</Grid>

					<GeneralSettlements />
					<Divider style={{ marginBlock: 30 }} />
					<DetailedSettlements />

					<Divider style={{ marginBlock: 30 }} />
					<Grid container spacing={2}>
						<Grid item md={3}>
							<Typography variant="h3">{t('Informacje Końcowe')}</Typography>
						</Grid>
						<Grid item md={5}>
							<FormInput
								title={t('Osoba sporządzająca Raport')}
								name="author_name"
								value={formData.author_name}
								onChange={handleChange}
								error={errors?.author_name}
								vertical
								inputProps={{ 'aria-required': true }}
								disabled={isPreview}
							/>
							<DateInput
								title={t('Data wypełnienia')}
								name="filled_date"
								value={formData.filled_date}
								onChange={handleChange}
								fullWidth
								vertical
								inputProps={{ 'aria-required': true }}
								disabled={isPreview}
							/>
						</Grid>
					</Grid>
				</Box>

				{formData?.id &&
					<Exports id={formData?.information_id} />
				}
			</Box>

			<ControlButtonGroup
				primaryTitle={t('Zatwierdź')}
				disabledSaveButton={formData?.status === 'published'}
				onSave={onSave}
				onClickPrimary={onPublish}
				onCancel={onCancel}
				saving={saving || isPreview}
			/>
		</>
	);
};

const Form = () => (
	<ManagementSettlementsContextProvider>
		<FormContainer />
	</ManagementSettlementsContextProvider>
);

export default Form;