import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Box, Grid, Divider } from '@material-ui/core';
import pl from 'utils/pl';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Routes from 'router/Routes';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import Typography from 'components/Main/Typography';
import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';
import ManagementApplicationsContext, { ManagementApplicationsContextProvider } from 'context/ManagementApplicationsContext';

import Table from './Table';
import Exports from './Exports';
import { formatNumber } from 'utils/formatters';

const useStyles = makeStyles(theme => ({
	root: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%'
	},
	content: {
		paddingRight: theme.spacing(window.isMobile ? 0 : 35),
	},
	divider: {
		marginBlock: theme.spacing(3),
	},
}));

const FormContainer = () => {
	const { id } = useParams();
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Wniosek' });
	const breadcrumbs = [
		{ title: t('Zarządzanie WIB'), to: Routes.Management.List('request_transfer') },
		{ title: t(id ? 'Edytuj wniosek o przekazanie środków finansowych' : 'Dodaj wniosek o przekazanie środków finansowych') }
	];
	const { onChange, onSave, onCancel, saving, formData, errors, onPublish, isPreview } = useContext(ManagementApplicationsContext);

	useEffect(() => {
		if (id) return document.title = 'WIB - Edycja wniosku o przekazanie środków finansowych';

		document.title = 'WIB - Dodawanie wniosku o przekazanie środków finansowych';
	}, []);

	const handleChange = e => {
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<>
			<Box className={classes.root}>
				<Header title={t(id ? 'Edytuj wniosek o przekazanie środków finansowych' : 'Dodaj wniosek o przekazanie środków finansowych')} />
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				<Box p={window.isMobile ? 2 : 4} bgcolor="#fff">
					<Box className={classes.content}>
						<Grid container alignItems="center" spacing={2}>
							<Grid item xs={window.isMobile ? 12 : 7}>
								<Typography style={{ fontWeight: 600 }}>
									{t('Wniosek o przekazanie środków finansowych z Funduszu Polskiej Nauki nr')}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 3}>
								<FormInput
									name="application_number"
									value={formData.application_number}
									onChange={handleChange}
									error={errors?.application_number}
									gutterBottom={false}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview}
								/>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 7}>
								<Typography>
									{t('z dnia')}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 3}>
								<DateInput
									fullWidth
									name="application_date"
									value={formData.application_date}
									onChange={handleChange}
									error={errors?.application_date}
									gutterBottom={false}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview}
								/>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 10}>
								<Typography>
									{t('Zgodnie z postanowieniami art')}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={2} alignItems="center">
							<Grid item xs={window.isMobile ? 12 : 2}>
								<Typography style={{ fontWeight: 600 }}>
									{formatNumber(_.sumBy(formData.costs?.table2, 'requested_amount') + _.sumBy(formData.costs?.table3, 'requested_amount'), 2) || 0}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 1}>
								<Typography>{t('słownie')}:</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 4}>
								<Typography style={{ fontWeight: 600 }}>
									{pl((_.sumBy(formData.costs?.table2, 'requested_amount') + _.sumBy(formData.costs?.table3, 'requested_amount')) || 0) || 'zero'}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 2}>
								<Typography>({t('Koszty zarządzania')})</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 10}>
								<FormInput
									vertical={true}
									title={t('na numer konta')}
									name="management_costs_iban"
									value={formData.management_costs_iban}
									error={errors?.management_costs_iban}
									onChange={handleChange}
									gutterBottom={false}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview}
								/>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 10}>
								<Typography>{t('oraz')}</Typography>
							</Grid>
						</Grid>

						<Grid container spacing={2} alignItems="center">
							<Grid item xs={window.isMobile ? 12 : 2}>
								<Typography style={{ fontWeight: 600 }}>
									{formatNumber(_.sumBy(formData.costs?.table1, 'requested_amount'), 2) || 0}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 1}>
								<Typography>{t('słownie')}:</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 4}>
								<Typography style={{ fontWeight: 600 }}>
									{pl(_.sumBy(formData.costs?.table1, 'requested_amount') || 0) || 'zero'}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 2}>
								<Typography>({t('Zespoły Badawcze')})</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 10}>
								<FormInput
									vertical={true}
									title={t('na numer konta')}
									name="teams_costs_iban"
									value={formData.teams_costs_iban}
									error={errors?.teams_costs_iban}
									onChange={handleChange}
									gutterBottom={false}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview}
								/>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 10}>
								<DateInput
									fullWidth
									vertical={true}
									title={t('termin płatności')}
									name="payment_date"
									value={formData.payment_date}
									onChange={handleChange}
									error={errors?.payment_date}
									gutterBottom={false}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview}
								/>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 10}>
								<Table />
							</Grid>
						</Grid>
					</Box>

					<Divider className={classes.divider} />

					<Box className={classes.content}>
						<Grid container space={2}>
							<Grid item xs={window.isMobile ? 12 : 3}>
								<Typography variant="h3">
									{t('Informacje Końcowe')}
								</Typography>
							</Grid>
							<Grid item xs={window.isMobile ? 12 : 7}>
								<FormInput
									vertical={true}
									title={t('Osoba sporządzająca Raport')}
									name="author_name"
									value={formData.author_name}
									error={errors?.author_name}
									gutterBottom={false}
									disabled
									inputProps={{ 'aria-required': true }}
								/>

								<DateInput
									vertical={true}
									fullWidth
									title={t('Data wypełnienia')}
									name="filling_date"
									value={formData.filling_date}
									error={errors?.filling_date}
									onChange={handleChange}
									gutterBottom={false}
									inputProps={{ 'aria-required': true }}
									disabled={isPreview}
								/>
							</Grid>
						</Grid>
					</Box>
				</Box>

				{id &&
					<Exports id={id} />
				}
			</Box>

			<ControlButtonGroup
				onSave={onSave}
				onClickPrimary={onPublish}
				primaryTitle={t('Zatwierdź')}
				onCancel={onCancel}
				disabledSaveButton={formData?.status === 'published'}
				saving={saving || isPreview}
			/>
		</>
	);
};

const Form = () => (
	<ManagementApplicationsContextProvider>
		<FormContainer />
	</ManagementApplicationsContextProvider>
);

export default Form;
