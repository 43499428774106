import React, { useContext, useEffect, useState } from 'react';
import {
	TableRow,
	TableCell,
	Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import NumberInput from 'components/Form/NumberInput';
import ResearchTaskContext from 'context/ResearchTaskContext';
import NumberText from 'components/Main/NumberText';

// const getCol7Val = (row, flat_rate) => {
// 	return (row.col_2 + row.col_3 + row.col_6) * flat_rate / 100;
// };
const getCol8Val = (row) => {
	return row.col_2 + row.col_3 + row.col_4 + row.col_5 + row.col_6 + row.col_7;
};

const hiddenKeys = ['id', 'temp_id', 'created_at', 'updated_at', 'entity_id', 'research_task_id'];
const staticKeys = ['title', 'entity_name', /*'col_7',*/ 'col_8'];

const Estimates = ({ disabled }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { formData, onChange } = useContext(ResearchTaskContext);
	const [estimates, setEstimates] = useState([]);

	useEffect(() => {
		if (_.isEqual(formData.task_estimates, estimates)) return;
		setEstimates(() => {
			let data = [
				{
					title: t('Podmiot Lidera'),
					entity_name: formData.entities.leader.entity_name,
					...formData.task_estimates[0],
				}
			];
			formData.entities.consortium_member.map((entity, i) => {
				let matchedItem = _.find(formData.task_estimates, item => item?.temp_id === entity?.temp_id);
				if (matchedItem) {
					data.push({
						title: `${t('Konsorcjant')} ${i + 1}`,
						entity_name: entity.entity_name,
						...matchedItem,
					});
				} else {
					data.push({
						title: `${t('Konsorcjant')} ${i + 1}`,
						entity_name: entity.entity_name,
						temp_id: entity.temp_id,
						col_2: 0,
						col_3: 0,
						col_4: 0,
						col_5: 0,
						col_6: 0,
						col_7: 0,
						col_8: 0,
					});
				}
			});

			return data;
		});
	}, [formData.task_estimates]);

	useEffect(() => {
		onChange('task_estimates', estimates);
	}, [estimates]);

	useEffect(() => {
		setEstimates(prev => {
			prev = _.map(prev, item => {
				// item.col_7 = getCol7Val(item, formData.general_info.flat_rate);
				item.col_8 = getCol8Val(item);
				return item;
			});

			return prev;
		});
	}, [formData.general_info.flat_rate]);

	const handleChange = (rowIndex, key) => e => {
		setEstimates(prev => {
			let editingRow = prev[rowIndex];
			editingRow[key] = e.target.value;
			// editingRow.col_7 = getCol7Val(editingRow, formData.general_info.flat_rate);
			editingRow.col_8 = getCol8Val(editingRow);

			prev[rowIndex] = editingRow;
			return [...prev];
		});
	};

	return (
		estimates.map((item, rowIndex) => (
			<TableRow key={rowIndex}>
				{_.keys(item).map((key, i) => {
					if (hiddenKeys.includes(key)) return;
					return (
						<TableCell
							key={i}
							colSpan={key === 'col_3' ? 2 : 1}
						>
							{_.includes(staticKeys, key)
								? <Box minHeight={41} display="flex" alignItems="center">
									{_.includes([/*'col_7',*/ 'col_8'], key)
										? <NumberText fixedDecimalScale={true} value={item[key]} />
										: <Typography>{item[key]}</Typography>
									}

								</Box>
								: <NumberInput
									value={item[key]}
									onChange={handleChange(rowIndex, key)}
									disabled={disabled}
								/>
							}
						</TableCell>
					);
				})}
			</TableRow>
		))
	);
};

export default Estimates;
