import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';
import { Box, makeStyles } from '@material-ui/core';
import IpCardContext from 'context/IpCardContext';
import { useTranslation } from 'react-i18next';
import useCheckPermissions from 'utils/useCheckPermissions';
import Select from 'components/Form/Select';

const DevelopmentDirections = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności - opis' });
	const formInputs = [
		{ name: 'trl', title: t('TRL (1-9) z uzasadnieniem'), placeholder: t('Wpisz opis') },
		{ name: 'application_areas', title: t('Obszary zastosowania'), placeholder: t('Wpisz opis') },
		{ name: 'pl_keywords', title: t('Słowa kluczowe w j. polskim'), placeholder: t('Wpisz opis') },
		{ name: 'en_keywords', title: t('Słowa kluczowe w j. angielskim'), placeholder: t('Wpisz opis') },
	];
	const trlOptions = [
		{ value: 9, label: t('TRL 9 - Technologia gotowa do wdrożenia') },
		{ value: 8, label: t('TRL 8 - Demonstracja ostatecznej formy technologii') },
		{ value: 7, label: t('TRL 7 - Testy prototypu w warunkach operacyjnych') },
		{ value: 6, label: t('TRL 6 - Testy prototypu w warunkach zbliżonych do rzeczywistych') },
		{ value: 4, label: t('TRL 5 - Walidacja technologii w warunkach zbliżonych do rzeczywistych') },
		{ value: 4, label: t('TRL 4 - Weryfikacja laboratoryjna technologii') },
		{ value: 3, label: t('TRL 3 - Badania w celu potwierdzenia koncepcji') },
		{ value: 2, label: t('TRL 2 - Znalezienie możliwości zastosowania technologii') },
		{ value: 1, label: t('TRL 1 - Rozpoczęcie badań naukowych') },
	];
	const classes = useStyles();
	const { selectedLanguage, formData, onChange, errors } = useContext(IpCardContext);
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const handleChange = (e, target = null) => {
		const { name, value } = e.target;
		if (target !== null && target === 'select') {
			onChange('ip_card_description', { ...formData.ip_card_description, [name]: value });
		} else {
			formData.ip_card_description[name][selectedLanguage] = value;
			onChange('ip_card_description', { ...formData.ip_card_description });
		}
	};

	const renderForm = (item, index) => {
		return (
			<FormInput
				key={index}
				title={item?.title}
				helperText={item?.helperText}
				name={item.name}
				value={(selectedLanguage === 'en' && formData?.ip_card_description?.[item.name]?.[selectedLanguage] === null) ? formData?.ip_card_description?.[item.name]?.pl : formData?.ip_card_description?.[item.name]?.[selectedLanguage]}
				onChange={handleChange}
				placeholder={item.placeholder}
				multiline={true}
				rows={9}
				alignItems="flex-start"
				error={errors?.ip_card_description?.[item.name]}
				disabled={isPreview || !canCreateIpCard}
			/>
		);
	};

	return (
		<>
			<Typography variant="h4" style={{ marginBottom: 10 }}>
				{t('Opis Własności Intelektualnej wraz z oceną jej słabych i mocnych stron oraz kierunków rozwoju')}
			</Typography>
			<Box className={classes.root}>
				<Select
					title={'TRL'}
					name={'trl_opt'}
					options={trlOptions}
					value={formData?.ip_card_description?.['trl_opt']}
					onChange={(e) => handleChange(e, 'select')}
					displayEmpty={false}
					helperText={'TRL (1-9) z uzasadnieniem'}
					disabled={selectedLanguage === 'en'}
				/>
				{formInputs.map(renderForm)}
			</Box>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
}));

export default DevelopmentDirections;