/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import PrimaryButton from 'components/Buttons/PrimaryButton';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import MemberTable from './MemberTable';
import SelectMemberModal from './SelectMemberModal';

const TableWithMembers = ({ entity, onChange, category }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const reportBContext = useContext(ResearchTaskReportBContext);
	const { formData, disabledInput, selectedLanguage } = reportBContext;
	const enEntities = formData.en.expenses_statements[category].entities;
	const [enEntity, setEnEntity] = useState({});
	const [members, setMembers] = useState(entity.members || []);

	const [openModal, setOpenModal] = useState(false);
	const [editingMemberIndex, setEditingMemberIndex] = useState(null);

	useEffect(() => {
		if (_.isEqual(members, entity.members)) return;
		setMembers(entity.members);
	}, [entity.members]);

	useEffect(() => {
		setEnEntity(() => enEntities.find(e => e.entity_id === entity.entity_id));
	}, [selectedLanguage]);

	useEffect(() => {
		let index = enEntities.findIndex(e => e.entity_id === entity.entity_id);
		if(selectedLanguage === 'en' || _.isEqual(enEntity, enEntities[index])) return;
		reportBContext.onChange(`en.expenses_statements${category}.entities[${index}]`, enEntity);
	}, [enEntity]);

	useEffect(() => {

		let sum = {
			netto_amount: parseFloat(_.sumBy(members, 'sum.netto_amount')),
			vat_amount: parseFloat(_.sumBy(members, 'sum.vat_amount')),
			eligible_amount: parseFloat(_.sumBy(members, 'sum.eligible_amount')),
		};
		let data = {
			..._.cloneDeep(entity),
			members: _.cloneDeep(members),
			sum: _.cloneDeep(sum)
		};

		if (_.isEqual(entity.members, members)) return;

		setEnEntity(prev => ({ ...prev, sum: _.cloneDeep(sum) }));
		onChange(_.cloneDeep(data));
	}, [members]);

	const handleSaveMember = member => {
		setMembers(prev => {
			if (_.isNull(editingMemberIndex)) {
				prev.push(_.cloneDeep(member));
			} else {
				prev[editingMemberIndex] = _.cloneDeep(member);
			}
			return [...prev];
		});

		setEnEntity(prev => {
			if (_.isNull(editingMemberIndex)) {
				prev.members.push(_.cloneDeep(member));
			} else {
				prev.members[editingMemberIndex] = _.cloneDeep(member);
			}
			return { ...prev };
		});
		setEditingMemberIndex(null);
	};

	const handleChangeMember = memberIndex => (member, deletedIndex) => {
		setMembers(prev => {
			if(deletedIndex > -1) {
				prev[memberIndex].data.splice(deletedIndex, 1);
			}
			prev[memberIndex] = member;
			return [...prev];
		});

		setEnEntity(prev => {
			let enMember = prev.members?.[memberIndex];
			if (!enMember) return prev;
			if(deletedIndex > -1) {
				enMember.data.splice(deletedIndex, 1);
			}

			member.data.map((item, index) => {
				let enItem = enMember.data?.[index];
				if(!enItem) {
					enItem = _.cloneDeep(item);
				} else {
					enItem = {..._.cloneDeep(item), service_description: enItem.service_description || item.service_description};
				}
				enMember.data[index] = _.cloneDeep(enItem);
			});

			prev.members[memberIndex] = _.cloneDeep(enMember);
			return {...prev};
		});
	};

	const handleEditMember = memberIndex => () => {
		setEditingMemberIndex(memberIndex);
		setOpenModal(true);
	};

	const handleDeleteMember = memberIndex => () => {
		setMembers(prev => {
			prev.splice(memberIndex, 1);
			return [...prev];
		});

		setEnEntity(prev => {
			prev.members.splice(memberIndex, 1);
			return { ...prev };
		});
	};

	return (
		<>
			{members?.map((member, i) =>
				<MemberTable
					key={i}
					member={member}
					onChange={handleChangeMember(i)}
					onEdit={handleEditMember(i)}
					onDelete={handleDeleteMember(i)}
					disabled={disabledInput}
					selectedLanguage={selectedLanguage}
				/>
			)}
			<TableRow>
				<TableCell colSpan={11}>
					<PrimaryButton
						color="secondary"
						onClick={() => setOpenModal(true)}
						disabled={disabledInput || selectedLanguage === 'en'}
					>
						{t('Dodaj kolejna osobę')}
					</PrimaryButton>
				</TableCell>
			</TableRow>
			<SelectMemberModal
				editingMember={members[editingMemberIndex]}
				entityId={entity?.entity_id}
				openModal={openModal && selectedLanguage === 'pl'}
				onSave={handleSaveMember}
				onClose={() => setOpenModal(false)}
			/>
		</>
	);
};

export default TableWithMembers;
