import React, { useContext } from 'react';
import { Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';
import BaseInput from 'components/Form/BaseInput';
import NumberText from 'components/Main/NumberText';
import NumberInput from 'components/Form/NumberInput';

import ManagementSettlementsContext from 'context/ManagementSettlementsContext';
import { CategoryCell, HeadCell } from '../CustomCell';

const useStyles = makeStyles(() => ({
	table: {
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
	charCount: {
		textAlign: 'right',
	},
}));

const GeneralSettlements = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - rozliczenie' });
	const rows = [
		{ name: 'research_team', label: t('Zespoły badawcze') },
		{ name: 'commercialization', label: t('Komercjalizacja') },
		{ name: 'management_costs', label: t('Koszty zarządzania, inne niż Koszty Komercjalizacji') }
	];
	const { formData, onChange, errors, isPreview } = useContext(ManagementSettlementsContext);
	const data = formData.general_settlements;

	const handleChange = e => {
		const { name, value } = e.target;
		onChange(name, value);
	};

	return (
		<>
			<Grid container spacing={2} style={{ marginBlock: 16 }}>
				<Grid item sm={3}>
					<Typography variant="h3">{t('ROZLICZENIE OGÓLNE WIB - PORT')}</Typography>
				</Grid>
				<Grid item sm={9}>
					<TableContainer className={classes.table}>
						<Table>
							<TableHead>
								<TableRow>
									<CategoryCell colSpan={4}>
										<Typography variant="tableLabel">{t('PODMIOT ZARZĄDZAJĄCY')}</Typography>
									</CategoryCell>
								</TableRow>
								<TableRow>
									<HeadCell>
										<Typography variant="tableLabel">{t('Kwota orzymana ogółem')}</Typography>
										<Typography variant="inputText">{t('(suma wypłat z wniosków przez cały okres finasowania)')}</Typography>
									</HeadCell>
									<HeadCell>
										<NumberText fixedDecimalScale={true} value={data.total_amount} />
									</HeadCell>
									<HeadCell>
										<Typography variant="tableLabel">{t('Wydatki ogółem')}</Typography>
										<Typography>{t('(wydatki faktycznie poniesione przez cały okres finansowania)')}</Typography>
									</HeadCell>
									<HeadCell>
										<Typography variant="tableLabel">{t('% wydatków')}</Typography>
									</HeadCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell colSpan={4}>{t('w tym')}</TableCell>
								</TableRow>
								{rows.map(row => (
									<TableRow key={row.name}>
										<TableCell>{row.label}</TableCell>
										<TableCell>
											<NumberText fixedDecimalScale={true} value={data.table1[row.name].col_1} />
										</TableCell>
										<TableCell>
											<NumberText fixedDecimalScale={true} value={data.table1[row.name].col_2} />
										</TableCell>
										<TableCell>
											<NumberText fixedDecimalScale={true} value={data.table1[row.name].col_3 * 100} suffix="%" />
										</TableCell>
									</TableRow>
								))}
								<TableRow>
									<HeadCell />
									<HeadCell>
										<Typography variant="tableLabel">{t('Maksymalna kwota kosztów zarządzania i komercjalizacji WIB')}</Typography>
										<Typography variant="inputText">{t('(kwota zgodnie z Umową)')}</Typography>
									</HeadCell>
									<HeadCell>
										<Typography variant="tableLabel">{t('Bieżące koszty WIB')}</Typography>
										<Typography variant="inputText">{t('(faktycznie poniesione koszty w całym okresie finansowania)')}</Typography>
									</HeadCell>
									<HeadCell>
										<Typography variant="tableLabel">{t('% KOSZTÓW WIB')}</Typography>
									</HeadCell>
								</TableRow>
								<TableRow>
									<TableCell />
									<TableCell>
										<NumberInput
											fullWidth
											name="general_settlements.max_amount"
											value={data.max_amount}
											onChange={handleChange}
											disabled={isPreview}
										/>
									</TableCell>
									<TableCell>
										<NumberText fixedDecimalScale={true} value={data.running_amount} />
									</TableCell>
									<TableCell>
										<NumberText fixedDecimalScale={true} value={data.amount_percentage * 100} suffix="%" />
									</TableCell>
								</TableRow>
								<TableRow>
									<HeadCell>
										<Typography variant="tableLabel">{t('Kwota orzymana ZGODNIE Z WNIOSKIEM NR')}</Typography>
										<BaseInput
											fullWidth
											name="general_settlements.application_number"
											value={data.application_number}
											onChange={handleChange}
											style={{ marginTop: 10 }}
											disabled={isPreview}
										/>
									</HeadCell>
									<HeadCell>
										<NumberText fixedDecimalScale={true} value={data.received_amount} />
									</HeadCell>
									<HeadCell>
										<Typography variant="tableLabel">{t('Wydatki w okresie sprawozdawczym')}</Typography>
									</HeadCell>
									<HeadCell>
										<Typography variant="tableLabel">{t('Procent wydatków w okresie sprawozdawczym (%)')}</Typography>
									</HeadCell>
								</TableRow>
								<TableRow>
									<TableCell colSpan={4}>{t('w tym')}</TableCell>
								</TableRow>
								{rows.map(row => (
									<TableRow key={row.name}>
										<TableCell>{row.label}</TableCell>
										<TableCell>
											<NumberInput
												name={`general_settlements.table2.${row.name}.col_1`}
												value={data.table2[row.name].col_1}
												onChange={handleChange}
											/>
										</TableCell>
										<TableCell>
											<NumberText fixedDecimalScale={true} value={data.table2[row.name].col_2} />
										</TableCell>
										<TableCell>
											<NumberText fixedDecimalScale={true} value={data.table2[row.name].col_3 * 100} suffix="%" />
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
			</Grid>
			<FormInput
				title={t('Komentarz Podmiotu Zarządzającego')}
				name="general_settlements_comment"
				value={formData.general_settlements_comment}
				onChange={handleChange}
				multiline
				minRows={10}
				maxRows={10}
				sm={3}
				alignItems="flex-start"
				inputProps={{ maxLength: 8000, 'aria-required': true }}
				FormHelperTextProps={errors?.general_settlements_comment
					? null
					: {
						error: false,
						classes: { root: classes.charCount }
					}
				}
				errorState={!!errors?.general_settlements_comment}
				error={errors?.general_settlements_comment || `${formData.general_settlements_comment?.length || 0}/8000(max)`}
				disabled={isPreview}
			/>
		</>
	);
};

export default GeneralSettlements;