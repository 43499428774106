import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const AddingRow = ({ errors, onAdd }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });

	const [data, setData] = useState({
		name: '',
		entity: '',
		planned_employee: '',
		position_type: '',
		involvement_dimension_planned: '',
		involvement_dimension_actual: '',
	});

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	return (
		<Row
			onChange={handleChange}
			values={data}
			errors={errors}
			adding
			lastColumn={
				<IconButton onClick={onAdd(data)} tooltip={t('Zatwierdź')}>
					<CircleCheckIcon />
				</IconButton>
			}
		/>
	);
};

export default AddingRow;
