import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { makeStyles, Box, Card, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import { formatDate } from 'utils/formatters';
import Header from 'components/Main/Header';
import Progress from 'components/Main/Progress';
import Typography from 'components/Main/Typography';
import PaginatedTable from 'components/PaginatedTable';
import IconButton, { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import { Information, VisibleIcon } from 'assets/svg';

import FilterRow from './FilterRow';
import CommentModal from './CommentModal';

const useStyles = makeStyles(() => ({
	card: {
		marginBottom: 30,
		padding: window.isMobile ? 16 : 30,
	},
	header: {
		borderBottom: 'none',
		'& .MuiTypography-body1': {
			textTransform: 'uppercase'
		},
	},
	description: {
		marginBottom: 30,
	},
	table: {
		padding: 0,
	},
}));

const ReportsList = ({ type, description, canCreate, canManage, canRemoveAll, canRemoveOwn }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const { task_id } = useParams();
	const classes = useStyles();
	const columns = [
		{ title: t('Zakres od'), name: 'period_from' },
		{ title: t('Zakres do'), name: 'period_to' },
		{ title: t('Status'), name: 'status' },
		{ title: t('Suma kontrolna'), name: 'controlsum' },
		{
			title: t('Akcje'),
			name: 'action',
			width: 100,
			disableSort: true
		},
	];
	const statuses = {
		draft: t('Szkic'),
		accepted: t('Zaakceptowany'),
		rejected: t('Zwrócony'),
		'waiting-for-acceptance': t('Wysłany'),
	};
	const [params, setParams] = useState({
		limit: 10,
		page: 1,
		column: 'period_from',
		direction: 'desc',
		filters: {}
	});
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [totalPagesCount, setTotalPagesCount] = useState(0);
	const [comments, setComments] = useState(null);

	useEffect(() => loadData(), [params]);

	const loadData = () => {
		if (loading) return;
		setLoading(true);
		API.researchTasks.report.index({ ...params, task_id, type }).then(res => {
			setLoading(false);
			setData(res.data.data);
			setTotalPagesCount(res.data.last_page);
		});
	};

	const handleChangeParams = _params => {
		let picked = _.pick(params, ['column', 'direction', 'page']);
		if (_params.column === 'id') _params.column = 'period_from';
		if (_.isEqual(picked, _params)) return;

		setParams({ ...params, ..._params });
	};

	const handleChangePerPage = e => {
		setParams(prev => ({ ...prev, limit: e.target.value }));
	};

	const handleChangeFilters = filters => {
		setParams(prev => {
			if (_.isEqual(prev.filters, _.pickBy(filters))) return prev;
			return { ...prev, filters };
		});
	};

	const handleDelete = id => () => {
		API.researchTasks.report.delete(id).then(res => {
			if (res.data.code === 200) {
				loadData();
				return toast.success('Raport został usunięty!');
			}

			toast.error('Nie znaleziono');
		});
	};

	const checkManageable = status => {
		let manageable = false;
		let canCreate = 'can_create_report_a';
		let canSendAcceptance = 'can_send_report_a_for_acceptance';
		let canAccept = 'can_accept_reject_report_a';

		if (type === 'type_b') {
			canCreate = 'can_create_report_b';
			canSendAcceptance = 'can_send_report_b_for_acceptance';
			canAccept = 'can_accept_reject_report_b';
		}

		if (status === 'draft' &&
			(_.includes(canManage, canCreate)
				|| _.includes(canManage, canSendAcceptance))
		) manageable = true;

		if (status === 'waiting-for-acceptance' && _.includes(canManage, canAccept)) manageable = true;

		if (_.includes(canManage, 'can_fully_manage_entities')) manageable = true;
		return manageable;
	};

	const formatStatus = row => {
		if (row.status === 'draft' && row.comments_count > 0) {
			return statuses.rejected;
		}
		return statuses[row.status];
	};

	const renderRows = () => (
		data?.map((row, index) => (
			<TableRow key={index}>
				<TableCell>{formatDate(row.period_from)}</TableCell>
				<TableCell>{formatDate(row.period_to)}</TableCell>
				<TableCell>{formatStatus(row)}</TableCell>
				<TableCell>{row.controlsum || ''}</TableCell>
				<TableCell>
					<Box display="flex" justifyContent="flex-end">
						{canManage && row.comments_count > 0 &&
							<IconButton
								onClick={() => setComments(row.comments)}
								tooltip={t('Zobacz adnotacje')}
							>
								<Information alt="Ikona podglądu adnotacji" />
							</IconButton>
						}
						{_.includes(['waiting-for-acceptance', 'accepted'], row.status) &&
							<Link to={Routes.ResearchTasks.Reports.Edit(task_id, type, row.id, true)}>
								<IconButton tooltip={t('Zobacz raport')}>
									<VisibleIcon alt="Ikona podglądu wiersza" />
								</IconButton>
							</Link>
						}
						{checkManageable(row.status) &&
							<Link to={Routes.ResearchTasks.Reports.Edit(task_id, type, row.id)}>
								<EditIconButton tooltip={t(row.status === 'waiting-for-acceptance' ? 'Weryfikuj' : 'Edytuj')} />
							</Link>
						}
						{(canRemoveAll || (canRemoveOwn && row.status === 'draft')) &&
							<DeleteIconButton tooltip={t('Usuń')} onClick={handleDelete(row.id)} />
						}
					</Box>
				</TableCell>
			</TableRow>
		))
	);

	return (
		<Card className={classes.card}>
			<Header
				className={classes.header}
				title={type === 'type_a' ? t('Raport A') : t('Raport B')}
				titleVariant="h3"
				createTitle={type === 'type_a' ? t('Dodaj Raport A') : t('Dodaj Raport B')}
				createPath={canCreate ? Routes.ResearchTasks.Reports.Create(task_id, type) : null}
				perPageCount={params.limit}
				onChangeCount={handleChangePerPage}
			/>
			<Typography className={classes.description}>{description}</Typography>
			{!data
				? <Progress status={true} />
				: <PaginatedTable
					columns={columns}
					totalPagesCount={totalPagesCount}
					onChangeFilters={handleChangeParams}
					renderRows={renderRows}
					filterRow={<FilterRow onChange={handleChangeFilters} />}
					containerClassName={classes.table}
				/>
			}
			<CommentModal
				openModal={!!comments}
				comments={comments}
				onClose={() => setComments(null)}
			/>
		</Card>
	);
};

export default ReportsList;

