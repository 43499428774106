import React, { useState, useContext, useEffect } from 'react';
import { Box, TableCell, TableRow, makeStyles, Divider, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import PaginatedTable from 'components/PaginatedTable';
import Progress from 'components/Main/Progress';
import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import IpCardContext from 'context/IpCardContext';

import FormInput from 'components/Form/FormInput';
import { useTranslation } from 'react-i18next';
import Validator, { Required, validate, Email } from 'utils/Validator';

import FilterRow from './FilterRow';
import EditingRow from './EditingRow';
import useCheckPermissions from 'utils/useCheckPermissions';

import FilePickModal from 'components/Main/FilePickModal';
import API from 'apis/API';

const IpCardAuthors = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { selectedLanguage, formData, onChange, errors } = useContext(IpCardContext);
	const classes = useStyles();
	const columns = [
		{ title: 'LP.', name: 'id', disableSort: true },
		{ title: t('Imię i nazwisko'), name: 'name', disableSort: true },
		{ title: t('ADRES ZAMIESZKANIA'), name: 'address', disableSort: true },
		{ title: t('NR TELEFONU'), name: 'phone_number', disableSort: true },
		{ title: t('ADRES E-MAIL'), name: 'email', disableSort: true },
		{ title: t('Deklarowany udział we wkładzie twórczym [%]'), name: 'percent_of_participation', disableSort: true, width: 200 },
		{ title: t('Rodzaj umowy z Jednostką'), name: 'agreement_type', disableSort: true },
		{ title: t('Nazwa Jednostki'), name: 'unit_name', disableSort: true },
		{ title: t('AKCJE'), name: 'action', width: 100, disableSort: true, },
	];
	const [openModal, setOpenModal] = useState(false);
	const [clickAdd, setClickAdd] = useState(false);
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const [data, setData] = useState(null);
	const [editingData, setEditingData] = useState(null);
	const [authorErrors, setAuthorErrors] = useState(null);
	const Validators = {
		name: new Validator(Required),
		address: new Validator(Required),
		phone_number: new Validator(Required),
		email: new Validator(Required, Email),
		percent_of_participation: new Validator(Required),
		// agreement_type: new Validator(Required),
		// unit_name: new Validator(Required),
	};

	useEffect(() => {
		if (!editingData) {
			setAuthorErrors(null);
			return;
		}

		let _errors = validate(editingData, Validators);
		setAuthorErrors(_errors);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editingData]);

	useEffect(() => {
		setData(formData.ip_card_authors);
	}, [formData.ip_card_authors]);

	const handleAdd = (addData) => {
		let _errors = validate(addData, Validators);
		setAuthorErrors(_errors);

		if (editingData || _errors) return;

		const newItem = { ...addData, index: data.length };

		setData(prev => {
			onChange('ip_card_authors', [...prev, newItem]);
			return ([...prev, newItem]);
		});

		setClickAdd(false);
	};

	const handleEdit = index => () => {
		if (editingData && authorErrors) return;
		setEditingData({ ...data[index], index });
	};

	const handleChange = e => {
		const { name, value } = e.target;
		if (name === 'agreement_type' || name === 'unit_name') {
			let prevData = {...editingData};
			prevData[name][selectedLanguage] = value;
			setEditingData(() => ({ ...prevData }));
		} else {
			setEditingData(prev => ({ ...prev, [name]: value }));
		}
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('ip_card_authors', prev);
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const fileDelete = (name, id) => {
		const prevData = [...(formData?.general_info?.[name] || [])];
		const fileIndex = prevData.findIndex(a => a.id === id);
		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1);
		}

		onChange('general_info', {
			...formData.general_info,
			[name]: prevData
		});
	};

	const handleDownload = (data) => (e) => {
		e.preventDefault();

		API.files.view(data?.uuid).then(res => {
			const url = window.URL.createObjectURL(
				new Blob([res.data])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.name);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// // Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	};

	const handleSave = () => {
		if (authorErrors) return;
		setData(prev => {
			prev[editingData.index] = editingData;
			onChange('ip_card_authors', prev);
			return [...prev];
		});
		setEditingData(null);
	};

	const selectedFile = (file) => {
		const prevData = [...(formData?.general_info?.['attachments'] || [])];
		const fileIndex = prevData.findIndex(a => a.id === file.id);

		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1, file);
		} else {
			prevData.push(file);
		}

		onChange('general_info', {
			...formData.general_info,
			['attachments']: prevData
		});
	};

	const renderRows = () => (
		data?.map((item, index) => (
			index === editingData?.index
				? <EditingRow
					key={index}
					item={editingData}
					onChange={handleChange}
					onSave={handleSave}
					onDelete={handleDelete(index)}
					errors={authorErrors}
				/>
				: <TableRow key={index}>
					<TableCell>{index + 1}</TableCell>
					<TableCell>{item.name}</TableCell>
					<TableCell>{item.address}</TableCell>
					<TableCell>{item.phone_number}</TableCell>
					<TableCell>{item.email}</TableCell>
					<TableCell>{item.percent_of_participation}</TableCell>
					<TableCell>{(selectedLanguage === 'en' && item.agreement_type?.[selectedLanguage] === null) ? item.agreement_type.pl : item.agreement_type?.[selectedLanguage]}</TableCell>
					<TableCell>{(selectedLanguage === 'en' && item.unit_name?.[selectedLanguage] === null) ? item.unit_name.pl : item.unit_name?.[selectedLanguage]}</TableCell>
					<TableCell>
						{!isPreview &&
							<Box display="flex">
								<EditIconButton
									tooltip={t('Edytuj')}
									onClick={handleEdit(index)}
								/>
								<DeleteIconButton
									tooltip={t('Usuń')}
									hideModal
									onClick={handleDelete(index)}
								/>
							</Box>
						}
					</TableCell>
				</TableRow>
		)));

	return (
		<>
			<Box display={window.isMobile ? 'block' : 'flex'} alignItems="center">
				<Typography variant="h3">{t('Lista Twórców Własności Intelektualnej')}</Typography>
				{!isPreview && canCreateIpCard &&
					<PrimaryButton
						style={{ margin: window.isMobile ? '10px 0 0 0' : '0 0 0 20px' }}
						variant="outlined"
						onClick={() => setClickAdd(!clickAdd)}
						disabled={selectedLanguage === 'en'}
					>
						{clickAdd ? t('Anuluj dodawanie') : t('Dodaj twórcę')}
					</PrimaryButton>
				}
			</Box>

			{!data
				? <Progress status={true} />
				: (
					<div id="authors">
						<PaginatedTable
							containerClassName={classes.padding}
							columns={columns}
							renderRows={renderRows}
							filterRow={
								clickAdd ? <FilterRow
									errors={authorErrors}
									onAddClick={handleAdd} /> : <></>
							}
						/>
					</div>
				)
			}

			<Box>
				<Grid
					container
					alignItems="flex-start"
					spacing={1}
					className={classes.gutterBottom}
				>
					<Grid item xs={12} sm={4}>
						<Box display="flex" alignItems="center">
							<Typography variant="bodyM" component="p">
								{selectedLanguage === 'pl' ? 'Załączniki' : 'Attachments'}
							</Typography>
						</Box>
					</Grid>
					<Grid item xs={12} sm={8}>
						{formData?.general_info?.['attachments']?.map((a, index) => {
							return (
								<Box key={index}>
									<Box display="flex" alignItems="center" justifyContent="space-between">
										<Typography>
											<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
												{index + 1}. {a.name}
											</a>
										</Typography>
										<DeleteIconButton
											hideModal
											tooltip="Usuń"
											onClick={() => fileDelete('attachments', a.id)}
											title="Czy na pewno chcesz usunąć tę kartę?"
										/>
									</Box>

									<Divider className={classes.divider} />
								</Box>
							);
						})}
						<PrimaryButton onClick={() => setOpenModal(true)} disabled={selectedLanguage === 'en'} >
							{selectedLanguage === 'pl' ? 'DODAJ ZałącznikI' : 'Add attachment'}
						</PrimaryButton>
					</Grid>
				</Grid>
				<FormInput
					title={t('Imię i nazwisko Pełnomocnika Twórców')}
					helperText="Imię i nazwisko Pełnomocnika Twórców do kontaktu w sprawie Własności Intelektualnej"
					name="name_of_authors_representive"
					value={formData.general_info?.name_of_authors_representive}
					onChange={(e) => onChange('general_info', { ...formData.general_info, name_of_authors_representive: e.target.value })}
					error={errors?.general_info?.name_of_authors_representive}
					disabled={isPreview || !canCreateIpCard || selectedLanguage === 'en'}
				/>
			</Box>
			<FilePickModal
				openModal={openModal}
				handleClose={() => setOpenModal(false)}
				selectedFile={selectedFile}
			/>
		</>
	);
};

const useStyles = makeStyles((theme) => ({
	padding: {
		paddingLeft: 0,
		paddingRight: 0
	},
	divider: {
		marginBlock: theme.spacing(3),
	},
	gutterBottom: {
		marginBottom: 20,
	},
	downloadLink: {
		color: '#002852',
		textDecoration: 'underline'
	},
}));

export default IpCardAuthors;
