import React, { useState, useEffect, useContext } from 'react';
import { makeStyles, Box, Divider, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';
import Typography from 'components/Main/Typography';
import AddressForm from 'components/Main/AddressForm';
import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ContactForm from 'components/Main/ContactForm';

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	divider: {
		marginBlock: theme.spacing(2),
	},
}));

const GeneralInfoTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { researchTask, mainInfo } = useContext(ResearchTaskReportContext);
	const [leader, setLeader] = useState(researchTask?.entities?.leader);

	useEffect(() => {
		setLeader(researchTask?.entities?.leader);
	}, [researchTask?.entities?.leader]);

	return (
		<Box className={classes.root}>
			<Typography variant="h3">{t('Informacje ogólne')}</Typography>
			<FormInput
				title={t('Podmiot Zarządzający WIB')}
				value={researchTask.wib_manager.name}
				disabled
			/>
			<AddressForm
				title={t('Adres siedziby głównej')}
				prefix="headquarters"
				data={researchTask.wib_manager}
				disabled
			/>
			<FormInput
				title={t('Nr umowy o finansowanie')}
				value={researchTask?.financial_agreement_number}
				disabled
			/>
			<DateInput
				title={t('Data podpisania umowy o finansowanie')}
				value={researchTask?.financial_agreement_signing_date || null}
				disabled
			/>
			<Divider className={classes.divider} />
			<Grid container>
				<Grid item xs={window.isMobile ? 12 : 4}>
					<Typography variant="h3">{t('Jednostka naukowa')}</Typography>
					<Typography>{t('Podmiot, w którym działalność naukową prowadzi Lider Zespołu Badawczego')}</Typography>
				</Grid>
				<Grid item xs={window.isMobile ? 12 : 8}>
					<FormInput
						title={t('Pełna nazwa podmiotu zgodna z odpowiednim rejestrem/KRS/CEIDG/przepisem prawa')}
						value={leader?.entity_name}
						disabled
						vertical
					/>
					<FormInput
						title={t('Skrócona nazwa podmiotu')}
						value={leader?.entity_short_name}
						disabled
						vertical
					/>
					<FormInput
						title={t('Imię i nazwisko, stanowisko służbowe osoby kierującej podmiotem')}
						value={`${leader?.entity_manager_firstname} ${leader?.entity_manager_surname}, ${leader?.entity_manager_position}`}
						disabled
						vertical
					/>
					<AddressForm
						title={t('Adres siedziby głównej')}
						prefix="headquarters"
						data={leader}
						disabled
						vertical
					/>
					<FormInput
						title={t('Numer telefonu/faksu')}
						name="phone_number"
						value={leader?.phone_number}
						disabled
						vertical
					/>
					<FormInput
						title={t('Adres strony internetowej')}
						name="website_address"
						value={leader?.website_address}
						disabled
						vertical
					/>
					<FormInput
						title={t('Adres poczty elektronicznej')}
						name="email_address"
						value={leader?.email_address}
						disabled
						vertical
					/>
					<FormInput
						title={t('Adres Elektronicznej Skrzynki Podawczej ESP (ePUAP) – jeżeli dotyczy')}
						name="esp_address"
						value={leader?.esp_address}
						disabled
						vertical
					/>
					<AddressForm
						title={t('Adres do korespondencji, jeżeli inny niż adres siedziby głównej')}
						prefix="correspondence"
						data={leader}
						disabled
						vertical
					/>
					<ContactForm
						title={t('Osoba uprawniona do reprezentowania - Lidera Konsorcjum/Wnioskodawcy')}
						data={researchTask.representative}
					/>
					<ContactForm
						title={t('Lider zespołu badawczego')}
						data={researchTask.team_leader}
					/>
					<ContactForm
						title={t('Osoba odpowiedzialna za sporządzenie raportu')}
						data={mainInfo?.author}
						inputs={[['firstname', 'surname'], 'phone_number', 'email']}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default GeneralInfoTab;
