import React, { useContext, useEffect } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import IntellectualTabs from './IntellectualTabs';
import IpCard from './IpCard';
import Publications from './Publications';
import Commercializations from './Commercializations';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import IpCardContext from 'context/IpCardContext';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCheckPermissions from 'utils/useCheckPermissions';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(2, 4, 5),
		overflowY: 'scroll',
		height: '100%',
	},
}));

const FormContainer = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const { id } = useParams();
	const classes = useStyles();

	//const canFullyManageEntities = useCheckPermissions('can_fully_manage_entites', true);
	const canViewAllIpCard = useCheckPermissions('can_view_all_ip_card', true);
	const canFillComercializationIpCard = useCheckPermissions('can_fill_comercialization_ip_card', true);
	const canFillSomeFields = useCheckPermissions([
		'can_fill_field_date_of_receipt_ip_card',
		'can_fill_field_registration_number_ip_card',
		'can_fill_field_swot_ip_card'
	], true);

	const tabs = [
		{
			value: 'ip_card',
			children: <IpCard />,
			label: t('Karta WI'),
			title: id ? (isPreview ? t('Podgląd Kartę') : t('Edytuj Kartę')) : t('Dodaj Kartę')
		},
		{
			value: 'publications',
			children: <Publications />,
			label: 'Lista publikacji',
			title: id ? (isPreview ? t('Podgląd listę publikacji') : t('Edytuj listę publikacji')) : t('Dodaj listę publikacji')
		},
		{
			value: 'commercializations',
			children: <Commercializations />,
			label: 'Ochrona i komercjalizacja',
			disable: !canFillComercializationIpCard && !canViewAllIpCard,
			title: `${id ? (isPreview ? 'Podgląd' : 'Edytuj') : 'Dodaj'} informacje dot. ochrony i komercjalizacji własności intelektualnej`
		},
	];
	const { formData, onCancel, onSave, saving, onPublish } = useContext(IpCardContext);

	useEffect(() => {
		if (isPreview) return document.title = 'WIB - Podgląd Własności Intelektualnej WIB';

		if (id) return document.title = 'WIB - Edycja Własności Intelektualnej WIB';

		document.title = 'WIB - Dodawanie Własności Intelektualnej WIB';
	}, []);

	return (
		<>
			<Box className={classes.content}>
				<IntellectualTabs tabs={tabs} />
			</Box>

			<ControlButtonGroup
				onSave={() => onSave()}
				onClickPrimary={() => onPublish()}
				onCancel={onCancel}
				saving={saving}
				disabledSaveButton={(formData.general_info?.status === 'published' || (isPreview && !canFillComercializationIpCard && !canFillSomeFields))/* && !canFullyManageEntities*/}
				disabledPrimaryButton={isPreview && !canFillComercializationIpCard && !canFillSomeFields}
				primaryTitle={t('Zatwierdź')}
			/>
		</>
	);
};

export default withRouter(FormContainer);
