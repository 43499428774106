import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import Select from 'components/Form/Select';

const Row = ({ onChange, values, lastColumn, errors }) => {
	const options = [
		{ name: 'Autor wiodący' },
		{ name: 'Autor korespondencyjny' },
		{ name: 'Pierwszy autor' },
		{ name: 'Drugi autor' },
		{ name: 'Trzeci autor' },
		{ name: 'Czwarty autor' },
		{ name: 'Piąty autor' },
		{ name: 'Inne' },
	];

	return (
		<TableRow>
			<TableCell>{values?.index || ''}</TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder="Wpisz tytuł"
					name="title"
					value={values.title}
					onChange={onChange}
					error={errors?.title}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					fullWidth
					name="publication_date"
					value={values.publication_date}
					onChange={onChange}
					error={errors?.publication_date}
					hideErrorText
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell></TableCell>
			<TableCell>
				<BaseInput
					hideErrorText
					fullWidth
					placeholder="Wpisz imię i nazwisko"
					name="name"
					value={values?.authors?.[0]?.name}
					onChange={onChange}
					error={errors?.authors?.[0]?.name}
				/>
			</TableCell>
			<TableCell>
				<Select
					gutterBottom={false}
					name="role"
					options={options}
					valueField="name"
					labelField="name"
					hideErrorText
					displayEmpty
					emptyLabel={'Wybierz'}
					value={values?.authors?.[0]?.role}
					onChange={onChange}
				/>
			</TableCell>
			<TableCell>
				{lastColumn}
			</TableCell>
		</TableRow>
	);
};

export default Row;
