import axios from 'axios';
import Storage from 'utils/Storage';

const API = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT_URI
});

API.interceptors.request.use(config => {
	const token = Storage.get('access_token');
	if (token) config.headers.Authorization = `Bearer ${token}`;
	if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_XDEBUG_SESSION) {
		config.params = config.params || {};
		config.params.XDEBUG_SESSION = process.env.REACT_APP_XDEBUG_SESSION;
	}
	return config;
}, error => Promise.reject(error));

/**
 * Auth
 */
API.auth = {
	login: data => API.post('/auth/login', data),
	logout: () => API.post('/auth/logout'),
	forgot: email => API.post('/auth/forgot_password', { email }),
	resetPassword: data => API.post('/auth/reset_password', data),
	validateToken: () => API.get('/auth/validate_token'),
	refreshToken: () => API.get('/auth/refresh_token'),
	updatePassword: data => API.post('/auth/update_password', data),
};

API.permissions = {
	all: () => API.get('/permissions/all')
};

API.logs = {
	index: params => API.get('/log_activities', { params })
};

/**
 * Role
 */
API.roles = {
	index: params => API.get('/roles', { params }),
	all: () => API.get('/roles/all'),
	show: id => API.get(`/roles/${id}`),
	store: data => API.post('/roles', data),
	update: (data, id) => API.put(`/roles/${id}`, data),
	delete: id => API.delete(`/roles/${id}`)
};

/**
 * Users
 */
API.users = {
	index: params => API.get('/users', { params }),
	all: () => API.get('/users/all'),
	show: id => API.get(`/users/${id}`),
	store: data => API.post('/users', data),
	update: (data, id) => API.put(`/users/${id}`, data),
	delete: id => API.delete(`/users/${id}`)
};

/**
 * Research tasks
 */
API.researchTasks = {
	index: params => API.get('/research_tasks', { params }),
	all: () => API.get('/research_tasks/all'),
	getAssignments: () => API.get('/research_tasks/get_assignments'),
	exportList: () => API.get('/research_tasks/export_list'),
	export: (type, id) => API.get(`/research_tasks/export/${type}/${id}`, {
		responseType: 'arraybuffer',
		headers: { 'Content-Type': 'blob' }
	}),
	getOptions: () => API.get('/research_tasks/get_options'),
	show: id => API.get(`/research_tasks/${id}`),
	store: data => API.post('/research_tasks', data),
	update: (data, id) => API.put(`/research_tasks/${id}`, data),
	publish: (data, id) => API.post(`/research_tasks/publish/${id}`, data),
	delete: id => API.delete(`/research_tasks/${id}`),

	report: {
		index: params => API.get('/research_tasks/report', { params }),
		getResearchTask: taskId => API.get(`/research_tasks/report/get_task/${taskId}`),
		getAcceptedReports: (taskId, reportId) => API.get(`/research_tasks/report/get_accepted_reports/${taskId}/${reportId}`),
		show: id => API.get(`/research_tasks/report/${id}`),
		sendAcceptance: (data, id) => API.put(`/research_tasks/report/send_acceptance/${id}`, data),
		accept: (data, id) => API.put(`/research_tasks/report/accept/${id}`, data),
		reject: (data, id) => API.put(`/research_tasks/report/reject/${id}`, data),
		store: data => API.post('/research_tasks/report', data),
		update: (data, id) => API.put(`/research_tasks/report/${id}`, data),
		delete: id => API.delete(`/research_tasks/report/${id}`),
		exportA: (type, id, locale) => API.get(`/research_tasks/report/export/report_a/${type}/${id}/${locale}`, {
			responseType: 'arraybuffer',
			headers: { 'Content-Type': 'blob' }
		}),
		exportB: {
			index: (type, taskId, reportId, locale) => API.get(`/research_tasks/report/export/report_b/${type}/${taskId}/${reportId}/${locale}`, {
				responseType: 'arraybuffer',
				headers: { 'Content-Type': 'blob' }
			}),
			table4: (taskId, reportId, locale) => API.get(`/research_tasks/report/export/report_b/table4/xlsx/${taskId}/${reportId}/${locale}`, {
				responseType: 'arraybuffer',
				headers: { 'Content-Type': 'blob' }
			})
		}
	}
};

/**
 * Translations
 */
API.translations = {
	index: params => API.get('/translations', { params }),
	all: (locale = 'pl') => API.get(`/translations/${locale}`),
	modules: () => API.get('/translations/modules'),
	store: data => API.post('/translations', data),
};

/**
 * Folders
 */
API.folders = {
	index: params => API.get('/folders', { params }),
	getOne: (id) => API.get(`/folders/${id}`),
	store: data => API.post('/folders', data),
	update: (data, id) => API.put(`/folders/${id}`, data),
	delete: id => API.delete(`/folders/${id}`),
	fetchSubfoldersAndFiles: (id, filter) => API.get(`/folders/${id}/files`, { params: filter })
};

/**
 * Files
 */
API.files = {
	getOne: id => API.get(`/files/${id}/show`),
	view: (id) => API.get(`/files/${id}`, { responseType: 'blob' }),
	store: data => API.post('/files', data),
	update: (data, id) => API.put(`/files/${id}`, data),
	delete: (id) => API.delete(`/files/${id}`),
};

/**
 * IpCards
 */
API.ipCards = {
	index: params => API.get('/ip_cards', { params }),
	exportList: () => API.get('/ip_cards/export_list'),
	export: (type, id, tab, locale) => API.get(`/ip_cards/${id}/${tab}/export/${type}/${locale}`, {
		responseType: 'arraybuffer',
		headers: { 'Content-Type': 'blob' }
	}),
	show: id => API.get(`/ip_cards/${id}`),
	publish: (data, id) => API.post(`/ip_cards/publish/${id}`, data),
	store: data => API.post('/ip_cards', data),
	update: (data, id) => API.put(`/ip_cards/${id}`, data),
	delete: (id) => API.delete(`/ip_cards/${id}`),
	status: (id) => API.get(`/ip_cards/${id}/status`),
};

/**
 * Shared Folder
 */
API.sharedFolders = {
	index: params => API.get('shared_folders', { params }),
	store: data => API.post('/shared_folders', data),
};


/**
 * Management
 */
API.management = {
	detailedInformations: {
		getResearchTasks: () => API.get('/management/detailed_informations/research_tasks'),
		index: params => API.get('/management/detailed_informations', { params }),
		show: id => API.get(`/management/detailed_informations/${id}`),
		published: id => API.get(`/management/detailed_informations/published/${id}`),
		store: data => API.post('/management/detailed_informations', data),
		update: (data, id) => API.put(`/management/detailed_informations/${id}`, data),
		publish: (data, id) => API.post(`/management/detailed_informations/publish${id ? `/${id}` : ''}`, data),
		delete: id => API.delete(`/management/detailed_informations/${id}`),
		export: (type, id) => API.get(`/management/detailed_informations/export/${type}/${id}`, {
			responseType: 'arraybuffer',
			headers: { 'Content-Type': 'blob' }
		}),
		exportList: () => API.get('/management/detailed_informations/export_list'),
	},
	applications: {
		getResearchTasksAndEntities: () => API.get('/management/applications/research_tasks_entities'),
		index: params => API.get('/management/applications', { params }),
		show: id => API.get(`/management/applications/${id}`),
		store: data => API.post('/management/applications', data),
		update: (data, id) => API.put(`/management/applications/${id}`, data),
		publish: (data, id) => API.put(`/management/applications/publish/${id}`, data),
		delete: id => API.delete(`/management/applications/${id}`),
		export: (type, id) => API.get(`/management/applications/export/${type}/${id}`, {
			responseType: 'arraybuffer',
			headers: { 'Content-Type': 'blob' }
		}),
		exportList: () => API.get('/management/applications/export_list'),
	},
	settlements: {
		getResearchTasks: () => API.get('/management/settlements/research_tasks'),
		index: params => API.get('/management/settlements', { params }),
		show: id => API.get(`/management/settlements/${id}`),
		store: informationId => API.post(`/management/settlements/${informationId}`),
		update: (data, id) => API.put(`/management/settlements/${id}`, data),
		publish: (data, id) => API.put(`/management/settlements/publish/${id}`, data),
		delete: id => API.delete(`/management/settlements/${id}`),
		exportList: () => API.get('/management/settlements/export_list'),
	},
};

/**
 * Pages
 */
API.pages = {
	all: () => API.get('/pages'),
	get: id => API.get(`/pages/${id}`),
	update: (data, id) => API.put(`/pages/${id}`, data)
};

export default API;
