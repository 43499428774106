import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Box, SvgIcon, Grid } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import UploadButton from 'components/Buttons/UploadButton';
import { PlusIcon } from 'assets/svg';
import SearchForm from './SearchForm';
import FolderContext from 'context/FolderContext';
import AuthContext from 'context/AuthContext';
import FileView from 'components/Main/FileView';
import { toast } from 'react-toastify';
import API from 'apis/API';
import DeleteModal from 'components/Main/DeleteModal';
import FileFormModal from './FormModal';
import FolderFormModal from 'pages/Folders/FormModal';
import ShareModal from 'pages/Folders/ShareModal';
import _ from 'lodash';
import FilePickModal from './FilePickModal';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import useCheckPermissions from 'utils/useCheckPermissions';

const defaultFilter = {
	search: '',
	type: '',
	sort: ''
};

const defaultBreadcrumb = [
	{ title: 'Dokumenty', folderId: undefined },
];

const FilesList = (props) => {
	const { onFileSelected, refreshFileManager } = props;
	const { user } = useContext(AuthContext);
	const {
		folder,
		folderId,
		filesList,
		filters,
		filterStatus,
		setFilters,
		foldersListWithoutChildren,
		setFolder,
		setFolderId,
		setBreadcrumbsArr,
		foldersForBreadcrumbs,
		loadFilteredFolders,
		fetchFoldersAndFiles,
		debouncedChangeHandler,
	} = useContext(FolderContext);
	const classes = useStyles();
	const [uploading, setUploading] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [folderModalVisible, setFolderModalVisible] = useState(false);
	const [fileModalVisible, setFileModalVisible] = useState(false);
	const [shareModalVisible, setShareModalVisible] = useState(false);
	const [fileId, setFileId] = useState(null);
	const [fId, setFid] = useState(null);
	const [item, setItem] = useState(null);
	const [openPickModal, setOpenPickModal] = useState(false);
	const [breadcrumbs, setBreadcrumbs] = useState([]);
	const [isCreate, setIsCreate] = useState(false);

	const canCreateFolders = useCheckPermissions('can_create_folders', true);
	const canShareFolders = useCheckPermissions('can_share_folders', true);
	const canFullyManageEntities = useCheckPermissions('can_fully_manage_entities', true);
	const canUploadFiles = useCheckPermissions('can_upload_files', true);

	const handleCapture = (e) => {
		const files = e.target.files;

		const formData = new FormData();
		formData.append('folder_id', folderId);

		for (const file of files) {
			if (file.size === 0) {
				toast.error(`${file.name}: Nie możesz dodać tego pliku!`);
				continue;
			}

			formData.append('files[]', file);
		}

		setUploading(true);
		API.files.store(formData).then(res => {
			if (res?.data?.code === 200) {
				fetchFoldersAndFiles(folderId, filters, true);
			} else if(res?.response?.data?.message) {
				toast.error(res.response.data.message);
			} else if(res?.message) {
				toast.error(res.message);
			}
			setUploading(false);
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	const handleEdit = (data) => {
		if (_.has(data, 'parent_id')) {
			setFolderModalVisible(true);
			setFid(data.id);
			setIsCreate(false);
		} else {
			setFileModalVisible(true);
			setFileId(data.id);
		}
	};

	const handleClickShare = (data) => {
		setShareModalVisible(true);
		setFid(data?.id);
	};

	const handleDelete = (data) => {
		setDeleteModalVisible(true);
		setItem(data);
	};

	const handleDeleteItem = () => {
		if (_.has(item, 'parent_id')) {
			API.folders.delete(item.id).then(res => {
				if (res?.data?.code === 200) {
					loadFilteredFolders();
					fetchFoldersAndFiles(folderId, filters, true);
					return;
				}
				toast.error(res.data.message);
			});
		} else {
			API.files.delete(item.id).then(res => {
				if (res?.data?.code === 200) {
					loadFilteredFolders();
					fetchFoldersAndFiles(folderId, filters, true);
					return;
				}
				toast.error(res.data.message);
			});
		}
	};

	const handleDownload = (data) => {
		if (!_.has(data, 'parent_id')) {
			API.files.view(data?.uuid).then(res => {
				const url = window.URL.createObjectURL(
					new Blob([res.data])
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', data.name);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// // Clean up and remove the link
				link.parentNode.removeChild(link);
			});
		}
	};

	const handleClickBreadcrumb = (item) => {
		if (!item?.folderId) {
			refreshFileManager();
			return;
		}

		setFid(item.folderId);
		setFolderId(item.folderId);
		setFolder(foldersListWithoutChildren[item.folderId]);
		fetchFoldersAndFiles(item.folderId);
		setBreadcrumbsArr(foldersListWithoutChildren[item.folderId]);
	};

	const handleClickPlus = () => {
		setFid(folderId);
		setFolderModalVisible(true);
		setIsCreate(true);
	};

	useEffect(() => {
		debouncedChangeHandler(folderId, filters);
	}, [filters]);

	useEffect(() => {
		setFilters(defaultFilter);
		debouncedChangeHandler(folderId, defaultFilter);
	}, [folderId]);

	useEffect(() => {
		const newBreadcrumbs = [...defaultBreadcrumb];
		foldersForBreadcrumbs?.forEach(row => {
			newBreadcrumbs.push({
				title: row.name,
				folderId: row.id,
			});
		});

		setBreadcrumbs(newBreadcrumbs);
	}, [folderId, foldersForBreadcrumbs]);

	return (
		<>
			<Box className={classes.section} mb={2.5}>
				<Box display='flex' alignItems='center' justifyContent='space-between'>
					<Breadcrumbs
						breadcrumbs={breadcrumbs}
						isShowHome={false}
						style={{ marginBottom: 20 }}
						handleClick={handleClickBreadcrumb}
					/>
				</Box>

				<Box className={classes.item}>
					<Typography variant="h3">{foldersListWithoutChildren?.[folderId]?.name || 'Dokumenty'}</Typography>

					{((canCreateFolders && (folder?.user_id === user?.id)) || canFullyManageEntities || (canCreateFolders && !folderId) || (canCreateFolders && !folder?.read_only && _.has(folder, 'read_only'))) &&
						<PrimaryButton
							onClick={handleClickPlus}
							style={{ margin: window.isMobile ? '10px 0 0 0' : '0 0 0 20px' }}
						>
							<SvgIcon color="secondary" viewBox="0 0 20 20" style={{ marginRight: 10 }}><PlusIcon /></SvgIcon>
							Dodaj FOLDER
						</PrimaryButton>
					}

					{(canUploadFiles && (folder?.user_id === user?.id) || canFullyManageEntities || (canUploadFiles && !folder?.read_only && _.has(folder, 'read_only'))) &&
						<UploadButton
							acceptFiles="image/png, .jpg, .pdf, .doc, .docx, .xls, .xlsx"
							handleCapture={handleCapture}
							style={{ margin: window.isMobile ? '10px 0 0 0' : '0 0 0 20px', display: !folderId ? 'none' : '' }}
							disabled={uploading}
						>
							<SvgIcon viewBox="0 0 20 20" style={{ marginRight: 10 }}><PlusIcon /></SvgIcon>
							Dodaj PLIKI{uploading ? '...' : ''}
						</UploadButton>
					}
				</Box>

				<SearchForm
					style={{ marginBottom: '4px' }}
					filters={filters}
					onChange={handleChange}
				/>

				<Grid container>
					{
						filesList.map(item =>
							<FileView
								key={_.has(item, 'parent_id')
									? `${item.id}_folder`
									: `${item.id}_file`}
								data={item}
								onClickShare={handleClickShare}
								onEdit={handleEdit}
								onDelete={handleDelete}
								onDownload={handleDownload}
								onFileSelected={onFileSelected}
							/>
						)
					}
				</Grid>

				<Typography className={classes.fontStyle}>
					{(filesList.length === 0 && !filterStatus) && 'Ten folder jest pusty.'}
					{(filesList.length === 0 && filterStatus) && 'Brak elementów odpowiadających Twojemu wyszukiwaniu.'}
				</Typography>
			</Box>

			<DeleteModal
				title='Czy na pewno chcesz usunąć?'
				openModal={deleteModalVisible}
				handleClose={() => setDeleteModalVisible(false)}
				handleDelete={handleDeleteItem}
			/>

			<FileFormModal
				fileId={fileId}
				openModal={fileModalVisible}
				handleClose={() => setFileModalVisible(false)}
			/>

			<FolderFormModal
				fId={fId}
				isCreate={isCreate}
				openModal={folderModalVisible}
				handleClose={() => {
					setFolderModalVisible(false);
					setIsCreate(true);
				}}
			/>

			{canShareFolders && (
				<ShareModal
					fId={fId}
					openModal={shareModalVisible}
					handleClose={() => setShareModalVisible(false)}
				/>
			)}

			<FilePickModal
				openModal={openPickModal}
				handleClose={() => setOpenPickModal(false)}
			/>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	section: {
		background: theme.palette.white,
		padding: theme.spacing(window.isMobile ? 2 : 4),
	},
	item: {
		alignItems: 'center',
		display: window.isMobile ? 'block' : 'flex',
		marginBottom: 15
	},
	fontStyle: {
		marginTop: 10,
		opacity: 0.7
	}
}));

export default FilesList;
