import moment from 'moment';

export default function isValidDate(dateString) {
	const potentialFormats = [
		moment.ISO_8601,
		'DD/MM/YYYY',
		'MM/DD/YYYY',
		'YYYY-MM-DD',
		// Add more formats as needed
	];

	return potentialFormats.some((format) =>
		moment(dateString, format, true).isValid()
	);
}