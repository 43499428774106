import React, { useContext, useEffect, useState } from 'react';
import {
	TableRow,
	TableCell,
	Box,
} from '@material-ui/core';
import _ from 'lodash';

import NumberText from 'components/Main/NumberText';
import Typography from 'components/Main/Typography';
import NumberInput from 'components/Form/NumberInput';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import ResearchTaskReportContext from 'context/ResearchTaskReportContext';

const keys = ['title', 'col_2', 'col_3', 'col_4', 'col_5', 'col_6', 'col_7', 'col_8', 'col_9', 'col_10'];
const staticKeys = ['title', 'col_7', 'col_8', 'col_9', 'col_10'];

const Estimates = ({ dataKey, readOnly }) => {
	const { researchTask } = useContext(ResearchTaskReportContext);
	const { formData, onChange, getBudgetColVal, selectedLanguage } = useContext(ResearchTaskReportBContext);
	const changedInformation = formData[selectedLanguage].changed_information;
	const [estimates, setEstimates] = useState([]);

	useEffect(() => {
		setEstimates(changedInformation[dataKey]);
	}, []);

	useEffect(() => {
		if (dataKey !== 'budget_after') return;
		setEstimates(changedInformation.budget_after);
	}, [changedInformation.budget_after]);

	useEffect(() => {
		if (dataKey !== 'budget_offset') return;
		onChange('pl.changed_information.budget_offset', estimates);
		onChange('en.changed_information.budget_offset', estimates);
	}, [estimates]);

	const handleChange = (rowIndex, editingKey) => e => {
		setEstimates(prev => {
			let editingRow = prev[rowIndex];
			editingRow[editingKey] = e.target.value;
			['col_7', 'col_8', 'col_9', 'col_10'].map(key => {
				editingRow[key] = getBudgetColVal(key, editingRow, researchTask.flat_rate);
			});

			prev[rowIndex] = editingRow;
			return [...prev];
		});
	};

	return (
		estimates.map((item, rowIndex) => (
			<TableRow key={rowIndex}>
				{_.keys(_.pick(item, keys)).map((key, i) => {
					return (
						<TableCell key={i}>
							{readOnly || _.includes(staticKeys, key)
								? <Box minHeight={41} display="flex" alignItems="center">
									{_.isNumber(item[key])
										? <NumberText fixedDecimalScale={true} value={item[key]} />
										: <Typography>{item[key]}</Typography>
									}
								</Box>
								: <NumberInput
									value={item[key]}
									onChange={handleChange(rowIndex, key)}
									disabled={readOnly}
								/>
							}
						</TableCell>
					);
				})}
			</TableRow>
		))
	);
};

export default Estimates;
