import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box, TableRow, TableCell, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Validator, { Email, Required, Boolean, validate, Unique } from 'utils/Validator';
import PaginatedTable from 'components/PaginatedTable';
import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import Active from 'components/Main/Active';
import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import SearchInput from 'components/Form/SearchInput';
import Select from 'components/Form/Select';

import ResearchTaskContext from 'context/ResearchTaskContext';
import FilterRow from './FilterRow';
import EditingRow from './EditingRow';
import Messages from 'utils/Messages';

const useStyles = makeStyles(() => ({
	header: {
		borderBottom: 'none',
	},
	table: {
		position: 'relative',
		padding: 0,
		paddingRight: 100,
		border: '1px solid #D0D0D0',
		'& .MuiTableContainer-root': {
			borderWidth: 0,
			borderRightWidth: 1,
		},
		'& .MuiTableCell-root:last-child': {
			width: 100,
			height: '100%',
			position: 'absolute',
			right: 0,
			background: '#EBEBEB',
			'&.MuiTableCell-body': {
				background: '#fff',
				borderTop: '1px solid #D0D0D0',
			},
		},
		'& .MuiTableHead-root .MuiTableRow-root:first-child .MuiTableCell-root:last-child': {
			marginTop: -1,
		},
		'& .MuiTableHead-root .MuiTableRow-root:last-child .MuiTableCell-root:last-child': {
			borderTop: '2px solid #fff',
			marginTop: -2,
		},
		'& .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-root:last-child': {
			marginTop: -1,
		},
	}
}));

const TeamTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const columns = [
		{
			title: 'Ordinal',
			name: 'ordinal',
			width: 100,
			disableSort: true
		},
		{
			title: t('Personel'),
			name: 'member_type_id',
			width: 200,
			disableSort: true
		},
		{
			title: (
				<>
					<TableLabel>{t('Imię i nazwisko/wakat')}</TableLabel>
					<Typography variant="inputLabel">{t('Tytuł naukowy/stopień naukowy/tytuł zawodowy')}</Typography>
				</>
			),
			name: 'name',
			disableSort: true,
		},
		{
			title: t('Rola w zespole badawczym'),
			name: 'role_id',
			disableSort: true
		},
		{
			title: t('Aktywny'),
			name: 'is_active',
			disableSort: true,
			width: 100,
		},
		{
			title: (
				<>
					<TableLabel>{t('PESEL')}</TableLabel>
					<Typography variant="inputLabel">{t('Inny numer identyfikacyjny')}</Typography>
				</>
			),
			name: 'pesel',
			disableSort: true,
		},
		{
			title: (
				<>
					<TableLabel>{t('Podmiot Lidera/Członka Zespołu')}</TableLabel>
					<Typography variant="inputLabel">({t('Nazwa skrócona')})-{t('Miejsce zatrudnienia/podwykonawca')}</Typography>
				</>
			),
			name: 'leader_name',
			disableSort: true,
		},
		{
			title: 'stanowisko służbowe w podmiocie',
			name: 'position_name',
			disableSort: true
		},
		{
			title: 'dziedzina nauki i dyscyplina naukowa',
			name: 'science_discipline',
			disableSort: true
		},
		{
			title: (
				<>
					<TableLabel>{t('Numer ORCID')}</TableLabel>
					<Typography variant="inputLabel">({t('Jeżeli posiada')})</Typography>
				</>
			),
			name: 'orcid_number',
			disableSort: true,
		},
		{
			title: t('Numer telefonu'),
			name: 'phone_number',
			disableSort: true
		},
		{
			title: t('Adres poczty elektronicznej'),
			name: 'email',
			disableSort: true
		},
		{
			title: t('Akcje'),
			name: 'action',
			width: 100,
			disableSort: true
		},
	];
	const classes = useStyles();
	const { researchTeamMemberTypes, researchTeamMemberRoles, onChange, formData } = useContext(ResearchTaskContext);
	const [entities, setEntities] = useState([]);
	const [data, setData] = useState(formData.team_members);
	const [filteredData, setFilteredData] = useState(formData.team_members);
	const [editingData, setEditingData] = useState(null);
	const [errors, setErrors] = useState(null);
	const [adding, setAdding] = useState(false);
	const [filters, setFilters] = useState({
		name: '',
		entity_id: '',
		role: '',
	});
	const Validators = {
		ordinal: new Validator(Required),
		member_type_id: new Validator(Required),
		name: new Validator(Required),
		role_id: new Validator(Required),
		is_active: new Validator(Boolean),
		pesel: new Validator(Required, Unique(data, 'pesel')),
		entity_id: new Validator(Required),
		position_name: new Validator(Required),
		science_discipline: new Validator(Required),
		email: new Validator(Email),
	};

	useEffect(() => {
		setEntities(() => {
			let _entities = [];
			let leader = formData.entities.leader;
			if (!leader.entity_name) return _entities;

			_entities.push({ value: 'leader', label: leader.entity_name });
			formData.entities.consortium_member.map(member => {
				if (member.entity_name) {
					_entities.push({ value: member.temp_id, label: member.entity_name });
				}
			});
			return _entities;
		});
	}, [formData.entities]);

	useEffect(() => {
		if (!editingData) {
			setErrors(null);
			return;
		}
		let _errors = validate(editingData, Validators);
		_errors = { ...(_errors || {}), ...validateMemberType(editingData) };
		setErrors(_errors);
	}, [editingData]);

	useEffect(() => {
		let _filters = _.pickBy(filters);
		setFilteredData(() => {
			if (_.isEmpty(_filters)) return formData.team_members;
			let filtered = _.filter(formData.team_members, _.omit(_filters, 'name'));
			if (_.has(_filters, 'name')) {
				filtered = _.filter(filtered, member => _.includes(member.name, _.toLower(_filters.name)));
			}
			return filtered;
		});
	}, [data]);

	const filterData = () => {
		let _filters = _.pickBy(filters);

		setFilteredData(() => {
			if (_.isEmpty(_filters)) return formData.team_members;
			let filtered = _.filter(formData.team_members, _.omit(_filters, 'name'));
			if (_.has(_filters, 'name')) {
				filtered = _.filter(filtered, member => _.includes(member.name, _.toLower(_filters.name)));
			}
			return filtered;
		});
	};

	const handleSetAdding = () => {
		if (editingData) return;
		setAdding(!adding);
	};

	const handleEdit = index => () => {
		if ((editingData && errors) || adding) return;
		setEditingData({ index, ...data[index] });
	};

	const handleAdd = addData => {
		let _errors = validate(addData, Validators);
		_errors = { ...(_errors || {}), ...validateMemberType(addData) };
		setErrors(_errors);
		if (editingData || !_.isEmpty(_errors)) return;
		setData(prev => {
			prev.push({ ...addData, index: data.length });
			onChange('team_members', [...prev]);
			return [...prev];
		});
		setAdding(false);
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setEditingData(prev => ({ ...prev, [name]: value }));
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('team_members', prev);
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleSave = () => {
		let _errors = validate(editingData, Validators);
		_errors = { ...(_errors || {}), ...validateMemberType(editingData) };
		setErrors(_errors);
		if (!_.isEmpty(_errors)) return;
		setData(prev => {
			prev[editingData.index] = editingData;
			onChange('team_members', prev);
			return [...prev];
		});
		setEditingData(null);
	};

	const handleChangeFilters = e => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	const validateMemberType = item => {
		if (item.member_type_id !== 1) return {};
		const teamLeader = _.find(data, { member_type_id: 1 });
		if (teamLeader && !((item?.index && teamLeader?.index === item?.index) || (item?.id && teamLeader?.id === item?.id))) {
			return { member_type_id: Messages.MustBeUnique };
		}
	};

	const renderRows = () => (
		(_.pickBy(filters) ? filteredData : data)?.map((item, index) => (
			index === editingData?.index
				? <EditingRow
					key={index}
					item={editingData}
					onChange={handleChange}
					onSave={handleSave}
					onDelete={handleDelete(index)}
					errors={errors}
					entities={entities}
				/>
				: <TableRow key={index}>
					<TableCell>{item.ordinal}</TableCell>
					<TableCell style={{ whiteSpace: 'nowrap' }}>{researchTeamMemberTypes.find(type => type.id === item.member_type_id)?.name}</TableCell>
					<TableCell>{item.name}</TableCell>
					<TableCell>{researchTeamMemberRoles.find(role => role.id === item.role_id)?.name}</TableCell>
					<TableCell style={{ textAlign: 'center' }}><Active active={item.is_active} /></TableCell>
					<TableCell>{item.pesel}</TableCell>
					<TableCell>{entities.find(entity => entity.value === item.entity_id)?.label}</TableCell>
					<TableCell>{item.position_name}</TableCell>
					<TableCell>{item.science_discipline}</TableCell>
					<TableCell>{item.orcid_number}</TableCell>
					<TableCell>{item.phone_number}</TableCell>
					<TableCell>{item.email}</TableCell>
					<TableCell>
						{!isPreview &&
							<Box display="flex">
								<EditIconButton
									tooltip={t('Edytuj')}
									onClick={handleEdit(index)}
									disabled={isPreview}
								/>
								<DeleteIconButton
									tooltip={t('Usuń')}
									onClick={handleDelete(index)}
									disabled={isPreview}
								/>
							</Box>
						}
					</TableCell>
				</TableRow>
		)));

	return (
		<>
			<Box display={window.isMobile ? 'block' : 'flex'} alignItems="center" mb={2}>
				<Typography variant="h3">{t('Zespół badawczy')}</Typography>
				<PrimaryButton
					style={{ margin: window.isMobile ? '10px 0 0 0' : '0 0 0 20px' }}
					variant="outlined"
					onClick={handleSetAdding}
					disabled={isPreview}
				>
					{adding ? t('Anuluj dodawanie') : t('Dodaj osobę')}
				</PrimaryButton>
			</Box>
			<Grid container spacing={1} alignItems="center" style={{ marginBlock: 10 }}>
				<Grid item xs={window.isMobile ? 12 : 2}>
					<SearchInput
						fullWidth
						placeholder="Wpisz imię i nazwisko"
						name="name"
						value={filters.name}
						onChange={handleChangeFilters}
					/>
				</Grid>
				<Grid item xs={window.isMobile ? 12 : 2}>
					<Select
						options={entities}
						emptyLabel={t('Wybierz podmiot')}
						displayEmpty
						name="entity_id"
						value={filters.entity_id}
						onChange={handleChangeFilters}
						gutterBottom={false}
					/>
				</Grid>
				<Grid item xs={window.isMobile ? 12 : 2}>
					<Select
						formControlClassName={classes.select}
						options={researchTeamMemberRoles}
						displayEmpty
						emptyLabel={t('Wybierz rolę')}
						name="role_id"
						valueField="id"
						labelField="name"
						value={filters.role_id}
						onChange={handleChangeFilters}
						gutterBottom={false}
					/>
				</Grid>
				<Grid item>
					<PrimaryButton onClick={filterData}>{t('Filtruj')}</PrimaryButton>
				</Grid>
			</Grid>
			<PaginatedTable
				containerClassName={classes.table}
				columns={columns}
				renderRows={renderRows}
				filterRow={
					adding ? <FilterRow errors={errors} onAddClick={handleAdd} entities={entities} /> : <></>
				}
			/>
		</>
	);
};

export default TeamTab;
