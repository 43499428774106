import React, { useContext, useCallback } from 'react';
import { makeStyles, Box, SvgIcon } from '@material-ui/core';
import Typography from 'components/Main/Typography';
import { BlackPlusIcon, MinusIcon } from 'assets/svg';
import SearchInput from 'components/Form/SearchInput';
import CustomTreeView from 'components/Main/CustomTreeView';
import FolderContext from 'context/FolderContext';
import API from 'apis/API';
import { toast } from 'react-toastify';
import _debounce from 'lodash/debounce';

const useStyles = makeStyles(theme => ({
	section: {
		background: theme.palette.white,
		padding: theme.spacing(window.isMobile ? 2 : 4),
		width: 350
	},
	item: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
		marginBottom: '15px'
	}
}));

const FolderTree = () => {
	const {
		foldersList,
		setBreadcrumbsArr,
		loadFilteredFolders,
		fetchFoldersAndFiles,
		folderId,
		setFolder,
		setFolderId } = useContext(FolderContext);
	const classes = useStyles();

	const handleSearch = (e) => {
		loadFilteredFolders(e.target.value);
	};

	const debouncedChangeHandler = useCallback(_debounce(handleSearch, 500), []);

	const handleDelete = (nodeId) => {
		API.folders.delete(nodeId).then(res => {
			if (res?.data?.code === 200) {
				loadFilteredFolders();
				fetchFoldersAndFiles(folderId, null, true);

				if (Number(folderId) === Number(nodeId)) {
					setFolderId(null);
					setBreadcrumbsArr(null);
				}

				toast.success('Folder został usunięty!');
				return;
			}

			toast.error(res.data.message);
		});
	};

	const handleClickTreeItem = (data) => {
		fetchFoldersAndFiles(data.id);
		setBreadcrumbsArr(data);
		setFolder(data);
	};

	return (
		<>
			<Box className={classes.section} mb={2.5}>
				<Box className={classes.item}>
					<Typography variant="h3">Foldery</Typography>
				</Box>

				<SearchInput
					fullWidth
					placeholder="Znajdź folder"
					onChange={debouncedChangeHandler}
				/>

				{
					<CustomTreeView
						handleDelete={handleDelete}
						collapseIcon={<SvgIcon viewBox="0 0 14 1"><MinusIcon /></SvgIcon>}
						expandIcon={<SvgIcon viewBox="0 0 14 14"><BlackPlusIcon /></SvgIcon>}
						data={foldersList}
						handleClickTreeItem={handleClickTreeItem}
					/>
				}
			</Box>
		</>
	);
};

export default FolderTree;
