import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAutosave } from 'react-autosave';
import _ from 'lodash';
import Routes from 'router/Routes';
import API from 'apis/API';
import useCheckPermissions from 'utils/useCheckPermissions';

const ResearchTaskContext = React.createContext(null);

export const Provider = ({ children }) => {
	const history = useHistory();
	const { id } = useParams();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const hasPermissions = useCheckPermissions([
		'can_add_research_task_drafts',
		'can_publish_research_task'
	], isPreview);
	// This is data for select components in form.
	const [data, setData] = useState({
		organizationStatuses: [],
		researchTeamMemberTypes: [],
		researchTeamMemberRoles: [],
		planTypes: [],
	});
	const [formData, setFormData] = useState({
		general_info: {
			title: '',
			acronym: '',
			number: '',
			science_field: '',
			oecd_science_field: '',
			keywords: '',
			research_problem: '',
			funding_amount: 0,
			is_consortium: null,
			management_unit_name: 'Sieć Badawcza Łukasiewicz - PORT Polski Ośrodek Rozwoju Technologii',
			funding_number: '',
			financial_agreement_number: '',
			financial_agreement_signing_date: '',
			leader_name: '',
			account_number: '',
			flat_rate: 0,
		},
		entities: {
			leader: {
				entity_name: '',
				entity_short_name: '',
				organization_status_id: null,
				basic_legal_form: '',
				specific_legal_form: '',
				ownership_form: {
					treasury: '',
					state_legal_persons: '',
					local_government_units: '',
					domestic_natural_persons: '',
					other_national_private_entities: '',
					foreign_people: '',
				},
				main_research_activity_in_poland: null,
				principal_activity_number_pkd2007: '',
				principal_activity_number_nace_rev2: '',
				entity_manager_firstname: '',
				entity_manager_surname: '',
				entity_manager_position: '',
				headquarters_street: '',
				headquarters_building_number: '',
				headquarters_apartment_number: '',
				headquarters_postcode: '',
				headquarters_city: '',
				phone_number: '',
				website_address: '',
				email_address: '',
				esp_address: '',
				nip: '',
				regon: '',
				is_correspondence_address_different: null,
				correspondence_street: '',
				correspondence_building_number: '',
				correspondence_apartment_number: '',
				correspondence_postcode: '',
				correspondence_city: '',
			},
			consortium_member: [],
		},
		contacts: {
			representative: {
				name: '',
				phone_number: '',
				email: '',
				contact_type: 'representative',
			},
			contact: [],
		},
		team_members: [],
		task_plans: [],
		task_estimates: [
			{
				title: 'Podmiot Lidera',
				entity_name: '',
				col_2: 0,
				col_3: 0,
				col_4: 0,
				col_5: 0,
				col_6: 0,
				col_7: 0,
				col_8: 0,
			}
		],
		task_estimate_sum: {
			col_2: 0,
			col_3: 0,
			col_3b: 0,
			col_4: 0,
			col_5: 0,
			col_6: 0,
			col_7: 0,
			col_8: 0,
		},
		payments_schedule: {
			total: {
				value: 0,
			},
			values: {},
		},
	});
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(isPreview);
	const [taskStatus, setTaskStatus] = useState('draft');

	useEffect(() => {
		if (!hasPermissions && !isPreview) return;
		API.researchTasks.getOptions().then(res => setData(res.data));

		if (!id) return;
		API.researchTasks.show(id).then(res => {
			if (res.data?.code === 400) {
				toast.error('Nie znaleziono');
				return onCancel();
			}

			setFormData(res.data?.data);

			setTaskStatus(res.data?.data?.general_info?.status);
		});
	}, []);

	useEffect(() => {
		onChange('general_info.funding_amount', formData.task_estimate_sum.col_8);
	}, [formData.task_estimate_sum]);

	const onChange = (key, value, customizer) => {
		setFormData(prev => ({ ..._.setWith(prev, key, value, customizer) }));
	};

	const onCancel = () => history.push(Routes.ResearchTasks.List);

	const saveCallback = (isPublish = false) => res => {
		setSaving(false);
		if(res.data?.code === 400 && res.data?.message === 'not_found') {
			toast.error('Nie znaleziono');
			return onCancel();
		}
		if (!_.isEmpty(res.data?.errors)) {
			if (isPublish) {
				toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
			} else {
				toast.success('Wersja robocza została zapisana!');
			}
			setErrors(prev => {
				prev = {};
				_.mapKeys(res.data?.errors, (value, key) => {
					_.set(prev, key, value);
				});
				return prev;
			});

			return;
		}

		setErrors(null);
		toast.success('Zadanie badawcze zostało zapisane!');
		if(isPublish) onCancel();
	};

	const onSave = () => {
		if (saving) return;
		setSaving(true);
		API.researchTasks.update(formData, id).then(saveCallback());
	};

	const onPublish = () => {
		if (saving) return;
		setSaving(true);
		API.researchTasks.publish(formData, id).then(saveCallback(true));
	};

	const onAutoSave = useCallback((data, creating) => {
		// When unmount component, react-autosave send request.
		// So implement to prevent saving as draft what published task in the backend.
		if (saving || taskStatus === 'published' || (!id && !creating)) return;
		setSaving(true);
		(id ? API.researchTasks.update : API.researchTasks.store)(data, id).then(res => {
			setSaving(false);
			if (res.data?.code !== 200 || id) return;
			history.push(Routes.ResearchTasks.Edit(res.data?.data?.id));
		});
	}, []);

	useAutosave({ data: formData, onSave: onAutoSave, interval: 300000, saveOnUnmount: false });

	// When creating new row, save empty data and edit it.
	useEffect(() => {
		if (id) return;
		onAutoSave(formData, true);
	}, []);

	const value = {
		...data,
		formData,
		errors,
		saving,
		taskStatus,
		onChange,
		onSave,
		onPublish,
		onCancel,
	};

	return (
		<ResearchTaskContext.Provider value={value}>
			{children}
		</ResearchTaskContext.Provider>
	);
};

export const ResearchTaskContextProvider = withRouter(Provider);

export default ResearchTaskContext;
