import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';
import { Box, Divider, makeStyles, Grid } from '@material-ui/core';
import IpCardContext from 'context/IpCardContext';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import FilePickModal from 'components/Main/FilePickModal';
import TableLabel from 'components/Main/TableLabel';
import Checkbox from 'components/Form/Checkbox';
import { useTranslation } from 'react-i18next';
import API from 'apis/API';
import useCheckPermissions from 'utils/useCheckPermissions';

const DevelopmentDirections = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const canFillFieldSwotIpCard = useCheckPermissions('can_fill_field_swot_ip_card', true);

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności - opis' });
	const classes = useStyles();
	const { selectedLanguage, formData, onChange, errors } = useContext(IpCardContext);
	const [attachments, setAttachments] = useState([]);
	const [openModal, setOpenModal] = useState(false);

	const handleChange = (e) => {
		const target = e.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		if (target.type === 'checkbox') {
			onChange('ip_card_description', { ...formData.ip_card_description, [target.name]: value });
		} else {
			if (formData.ip_card_description[target.name] == null) {
				formData.ip_card_description[target.name] = {'en' : null, 'pl' : ''};
			}
			formData.ip_card_description[target.name][selectedLanguage] = value;
			onChange('ip_card_description', { ...formData.ip_card_description });
		}
	};

	const selectedFile = (file) => {
		const prevData = [...attachments];
		const fileIndex = attachments.findIndex(a => a.id === file.id);

		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1, file);
		} else {
			prevData.push(file);
		}

		setAttachments(prevData);

		onChange('ip_card_description', {
			...formData.ip_card_description,
			swot_analysis_attachments: prevData
		});
	};

	const handleDelete = id => {
		const prevData = [...attachments];
		const fileIndex = attachments.findIndex(a => a.id === id);
		if (fileIndex !== -1) {
			prevData.splice(fileIndex, 1);
		}

		setAttachments(prevData);
		onChange('ip_card_description', {
			...formData.ip_card_description,
			swot_analysis_attachments: prevData
		});
	};

	const handleDownload = (data) => (e) => {
		e.preventDefault();

		API.files.view(data?.uuid).then(res => {
			const url = window.URL.createObjectURL(
				new Blob([res.data])
			);
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', data.name);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// // Clean up and remove the link
			link.parentNode.removeChild(link);
		});
	};

	useEffect(() => {
		setAttachments(formData?.ip_card_description?.swot_analysis_attachments || []);
	}, [formData]);

	const formInputs = [
		{ title: t('SPRZEDAŻ PRAW WYŁĄCZNYCH'), name: 'pfoc_sell_rights' },
		{ title: t('LICENCJA'), name: 'pfoc_license' },
		{ title: t('SPIN-oFF'), name: 'pfoc_spinoff' },
		{ title: t('INNE'), name: 'pfoc_others', children: { title: t('OPIS'), name: 'pfoc_others_description' } },
	];

	return (
		<Box mt={4}>
			<Box>
				<Typography variant="h4" style={{ marginBottom: 10 }}>
					{t('Ocena słabych i mocnych stron oraz kierunku rozwoju')}
				</Typography>

				<Box className={classes.root}>
					<FormInput
						title={t('Ocena Podmiotu Zarządzającego')}
						name="swot_analysis"
						value={(selectedLanguage === 'en' && formData?.ip_card_description?.swot_analysis?.[selectedLanguage] === null) ? formData?.ip_card_description?.swot_analysis?.pl : formData?.ip_card_description?.swot_analysis?.[selectedLanguage]}
						onChange={handleChange}
						placeholder="Wpisz opis"
						multiline={true}
						rows={9}
						alignItems="flex-start"
						error={errors?.ip_card_description?.swot_analysis}
						disabled={canFillFieldSwotIpCard ? false : true}
					/>

					<Divider className={classes.divider} />

					<Grid
						container
						alignItems="flex-start"
						spacing={1}
					>
						<Grid item xs={12} sm={4}>
							<Box display="flex" alignItems="center">
								<Typography variant="bodyM" component="p">
									{t('Załączniki')}
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={8}>
							{attachments?.map((a, index) => {
								return (
									<Box key={index}>
										<Box display="flex" alignItems="center" justifyContent="space-between">
											<Typography>
												<a href="#" onClick={handleDownload(a)} className={classes.downloadLink}>
													{index + 1}. {a.name}
												</a>
											</Typography>

											{!isPreview && canCreateIpCard &&
												<DeleteIconButton
													hideModal
													tooltip="Usuń"
													onClick={() => handleDelete(a.id)}
													title="Czy na pewno chcesz usunąć tę kartę?"
												/>
											}
										</Box>

										<Divider className={classes.divider} />
									</Box>
								);
							})}

							{!isPreview && canCreateIpCard &&
								<PrimaryButton onClick={() => setOpenModal(true)} disabled={selectedLanguage === 'en'}>
									{t('DODAJ ZałącznikI')}
								</PrimaryButton>
							}
						</Grid>
					</Grid>
				</Box>
				<Divider className={classes.divider} />
			</Box>

			<Box>
				<Typography variant="h4" style={{ marginBottom: 10 }}>
					{t('Preferowana forma komercjalizacji')}
				</Typography>

				{formInputs.map((input, index) => (
					<Grid container key={index} alignItems="center" className={classes.row}>
						<Grid item xs={5} className={classes.header}>
							<TableLabel>{input.title}</TableLabel>
						</Grid>
						<Grid item xs={1} style={{ paddingRight: 16, paddingLeft: 16 }}>
							<Checkbox
								name={input.name}
								value={formData?.ip_card_description?.[input.name]}
								error={errors?.ip_card_description?.[input.name]}
								onChange={handleChange}
								disabled={isPreview || !canCreateIpCard || selectedLanguage === 'en'}
							/>
						</Grid>
						{input?.children &&
							<>
								<Grid item xs={1} className={classes.header}>
									<TableLabel>{input?.children?.title}</TableLabel>
								</Grid>
								<Grid item xs={5} style={{ paddingLeft: 16 }}>
									<FormInput
										fullWidth
										name={input?.children?.name}
										value={(selectedLanguage === 'en' && formData?.ip_card_description?.[input?.children?.name]?.[selectedLanguage] === null) ? formData?.ip_card_description?.[input?.children?.name]?.pl : formData?.ip_card_description?.[input?.children?.name]?.[selectedLanguage]}
										onChange={handleChange}
										error={errors?.ip_card_description?.[input?.children?.name]}
										gutterBottom={false}
										multiline={true}
										rows={2}
										hideErrorText
										disabled={isPreview || !canCreateIpCard}
									/>
								</Grid>
							</>
						}
					</Grid>
				))}
			</Box>

			<FilePickModal
				openModal={openModal}
				handleClose={() => setOpenModal(false)}
				selectedFile={selectedFile}
			/>
		</Box>
	);
};

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
	divider: {
		marginBlock: theme.spacing(4),
	},
	header: {
		background: '#EEEEEE',
		padding: theme.spacing(2),
	},
	row: {
		borderBottom: '1px solid #d0d0d0',
	},
	input: {
		'& .MuiOutlinedInput-input': {
			paddingRight: '0 !important',
		},
	},
	downloadLink: {
		color: '#002852',
		textDecoration: 'underline'
	}
}));

export default DevelopmentDirections;
