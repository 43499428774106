import secureLocalStorage  from  'react-secure-storage';

const Storage = {
	set: (key, value) => secureLocalStorage.setItem(key, JSON.stringify(value)),
	get: key => JSON.parse(secureLocalStorage.getItem(key)),
	remove: key => secureLocalStorage.removeItem(key),
	clear: () => secureLocalStorage.clear(),
};

export default Storage;
