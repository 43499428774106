import React, { useEffect, useState } from 'react';
import { Box, Grid, InputAdornment, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import FormInput from 'components/Form/FormInput';
import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import InputTooltip from 'components/Main/InputTooltip';

const useStyles = makeStyles(theme => ({
	container: {
		border: '1px solid #D0D0D0',
		borderRadius: 3,
	},
	header: {
		background: '#EEEEEE',
		padding: theme.spacing(2),
	},
	row: {
		padding: theme.spacing(1, 2),
		borderBottom: '1px solid #D0D0D0',
		'&:last-child': {
			borderBottom: 'none',
		},
	},
	input: {
		'& .MuiOutlinedInput-input': {
			paddingRight: '0 !important',
		},
	}
}));

const OwnershipForm = ({ values, error, onChange, disabled }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();
	const formInputs = [
		{ name: 'treasury', title: t('Skarbu Państwa') },
		{ name: 'state_legal_persons', title: t('Państwowych osób prawnych') },
		{ name: 'local_government_units', title: t('Jednostek samorządu terytorialnego lub samorządowych osób prawnych') },
		{ name: 'domestic_natural_persons', title: t('Krajowych osób fizycznych') },
		{ name: 'other_national_private_entities', title: t('Pozostałych krajowych jednostek prywatnych') },
		{ name: 'foreign_people', title: t('Osób zagranicznych') },
	];
	const [data, setData] = useState(values);

	useEffect(() => {
		onChange({ target: { name: 'ownership_form', value: data } });
	}, [data]);

	const handleChange = e => {
		let { name, value } = e.target;
		value = value > -1 ? value : 0;
		value = value > 100 ? 100 : value;
		setData(prev => {
			prev = { ...prev, [name]: +value };
			if (_.sum(_.values(prev)) >= 100) {
				prev = _.assignWith({}, prev, (_, value) => value === '' ? 0 : value);
			} else if (_.isEmpty(value)) {
				prev[name] = '';
			}

			return prev;
		});
	};

	return (
		<Grid container style={{marginBottom: 10}}>
			<Grid item xs={12} sm={4}>
				<Box display="flex" alignItems="center">
					<Typography component="p">{t('Forma własności')}</Typography>
					<InputTooltip title={t('Forma własności (zgodnie z ww. rozporządzeniem)')} />
				</Box>
			</Grid>
			<Grid item xs={12} sm={8} className={classes.container}>
				<Grid container className={classes.header}>
					<Grid item xs={3}>
						<TableLabel>{t('Procent')}</TableLabel>
						{error &&
							<Typography variant="inputStatus" color="secondary">{error}</Typography>
						}
					</Grid>
					<Grid item xs={9}>
						<TableLabel>{t('Własność')}</TableLabel>
					</Grid>
				</Grid>
				{formInputs.map((input, index) => (
					<Grid container key={index} alignItems="center" className={classes.row}>
						<Grid item xs={3} style={{ paddingRight: 16 }}>
							<FormInput
								name={input.name}
								value={data?.[input.name]}
								onChange={handleChange}
								type="number"
								placeholder={t('Wpisz')}
								inputClassName={classes.input}
								gutterBottom={false}
								error={error}
								hideErrorText
								InputProps={{
									endAdornment: <InputAdornment position="end">%</InputAdornment>,
								}}
								disabled={disabled}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs={9}>
							<Typography>{input.title}</Typography>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Grid>
	);
};

export default OwnershipForm;
