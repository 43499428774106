import React, { useContext, useEffect, useState } from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import NumberInput from 'components/Form/NumberInput';

import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';

const YearTable = ({ year }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const columns = ['I Q', 'II Q', 'III Q', 'IV Q', t('Razem')];
	const { formData, onChange, selectedLanguage, disabledInput } = useContext(ResearchTaskReportBContext);
	const yearData = formData[selectedLanguage].changed_information?.payments_schedule?.values?.[year];
	const [totalValue, setTotalValue] = useState(yearData?.total?.value || 0);
	const [values, setValues] = useState({});

	useEffect(() => {
		setValues(yearData?.values || { 1: 0, 2: 0, 3: 0, 4: 0 });
	}, []);

	useEffect(() => {
		const total = _.sumBy(_.keys(values), key => +values[key]);
		setTotalValue(total);
		if (!year) return;
		const formattedValues = _.mapValues(values, val => +val);
		let data = { total: { value: total }, values: formattedValues };
		onChange(`pl.changed_information.payments_schedule.values.${year}`, _.cloneDeep(data), Object);
		onChange(`en.changed_information.payments_schedule.values.${year}`, _.cloneDeep(data), Object);
	}, [values]);

	const handleChange = editingQuarter => e => {
		setValues(prev => {
			prev[editingQuarter] = e.target.value;
			return { ...prev };
		});
	};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<TableLabel>{t('Rok')}</TableLabel>
					</TableCell>
					<TableCell colSpan={4}>
						<TableLabel>{year}</TableLabel>
					</TableCell>
				</TableRow>
				<TableRow>
					{columns.map((col, i) => (
						<TableCell key={i}>
							<Typography variant="inputLabel">{col}</Typography>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					{_.keys(values)?.map(key => (
						<TableCell key={key} width="20%">
							<NumberInput
								value={values[key]}
								onChange={handleChange(key)}
								disabled={disabledInput || selectedLanguage === 'en'}
							/>
						</TableCell>
					))}
					<TableCell width="20%">
						<NumberText fixedDecimalScale={true} value={totalValue} />
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

export default YearTable;
