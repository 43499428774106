import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const FilterRow = ({ onAddClick, errors, index }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });

	const [data, setData] = useState({
		index,
		stage_number: '',
		start_date: '',
		end_date: '',
		number_of_months: '',
		title: '',
		plan_type_id: '',
	});

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	return (
		<Row
			onChange={handleChange}
			values={data}
			errors={errors}
			lastColumn={
				<IconButton onClick={() => onAddClick(data)} tooltip={t('Zatwierdź')}>
					<CircleCheckIcon />
				</IconButton>
			}
		/>
	);
};

export default FilterRow;
