import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles, Box, TableCell, TableRow } from '@material-ui/core';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { ManagementAddRed, PlusIcon, VisibleIcon } from 'assets/svg';
import API from 'apis/API';
import Routes from 'router/Routes';
import { formatDate } from 'utils/formatters';
import Progress from 'components/Main/Progress';
import PaginatedTable from 'components/PaginatedTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import IconButton, { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import useCheckPermissions from 'utils/useCheckPermissions';

import FilterRow from './FilterRow';

const useStyles = makeStyles(() => ({
	table: {
		paddingInline: 0,
	}
}));

const DetailedInformations = () => {
	const classes = useStyles();
	const history = useHistory();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const canView = useCheckPermissions('can_view_finances', true);
	const canManage = useCheckPermissions('can_manage_finances', true);
	const columns = [
		{ title: t('Zakres od'), name: 'date_from', width: 300 },
		{ title: t('Zakres do'), name: 'date_to', width: 300 },
		{ title: t('Status'), name: 'status' },
		{ title: t('Suma kontrolna'), name: 'controlsum' },
		{
			title: t('akcje'),
			name: 'action',
			width: canManage ? 150 : 50,
			disableSort: true
		},
	];
	const [loading, setLoading] = useState(false);
	const [params, setParams] = useState({
		page: 1,
		column: 'date_to',
		direction: 'desc',
		filters: {}
	});
	const [totalPagesCount, setTotalPagesCount] = useState(1);
	const [data, setData] = useState(null);

	useEffect(() => loadFilteredData(), [params]);

	const handleChangeParams = _params => {
		const picked = _.pick(params, ['column', 'direction', 'page']);
		if (_.isEqual(picked, _params)) return;

		setParams({ ...params, ..._params });
	};

	const handleChangeFilters = filters => {
		setParams(prev => {
			if (_.isEqual(prev.filters, _.pickBy(filters))) return prev;

			return { ...prev, filters };
		});
	};

	const loadFilteredData = () => {
		if (loading) return;
		setLoading(true);
		API.management.detailedInformations.index(params).then(res => {
			setLoading(false);
			setData(res.data?.data);
			setTotalPagesCount(res.data?.last_page);
		});
	};

	const handleDelete = id => () => {
		API.management.detailedInformations.delete(id).then(res => {
			if (res.data?.code === 200) {
				loadFilteredData();
				return toast.success(t('Zadanie badawcze zostało usunięte!'));
			}

			toast.error(t('Nie znaleziono zadanie badawcze'));
		});
	};

	const handleAddSettlement = id => () => {
		if (loading) return;
		setLoading(true);
		API.management.settlements.store(id).then(res => {
			setLoading(false);
			if (res.data.code !== 200) {
				return toast('Error');
			}
			history.push(Routes.Management.Settlements.Edit(res.data?.data?.id));
		});
	};

	const renderRows = () => (
		data.map((row, index) => (
			<TableRow key={index}>
				<TableCell>{formatDate(row.date_from)}</TableCell>
				<TableCell>{formatDate(row.date_to)}</TableCell>
				<TableCell>{row.status === 'published' ? t('Zapisany') : t('Szkic')}</TableCell>
				<TableCell>{row.controlsum}</TableCell>
				<TableCell>
					<Box display="flex">
						{canView &&
							<Link to={Routes.Management.DetailedInformations.Edit(row.id, true)}>
								<IconButton tooltip={t('Podgląd')}>
									<VisibleIcon alt="Ikona podglądu wiersza" />
								</IconButton>
							</Link>
						}
						{canManage &&
							<>
								<IconButton onClick={handleAddSettlement(row.id)} tooltip={t('Dodaj rozliczenie środków')}>
									<ManagementAddRed alt={t('Ikona dodania rozliczenia środków')} />
								</IconButton>
								<Link to={Routes.Management.DetailedInformations.Edit(row.id)}>
									<EditIconButton tooltip={t('Edytuj informacje')} />
								</Link>
								<DeleteIconButton tooltip={t('Usuń')} onClick={handleDelete(row.id)} />
							</>
						}
					</Box>
				</TableCell>
			</TableRow>
		))
	);

	return (
		<>
			{canManage &&
				<PrimaryButton
					component={Link}
					to={Routes.Management.DetailedInformations.Create}
				>
					<PlusIcon style={{ marginRight: 10 }} />
					{t('Dodaj szczegółowe informacje')}
				</PrimaryButton>
			}
			{!data
				? <Progress status={true} />
				: <PaginatedTable
					containerClassName={classes.table}
					columns={columns}
					exportEndpoint={API.management.detailedInformations.exportList}
					filename="Management"
					totalPagesCount={totalPagesCount}
					onChangeFilters={handleChangeParams}
					defaultFilters={_.pick(params, ['column', 'direction', 'page'])}
					renderRows={renderRows}
					filterRow={<FilterRow onChange={handleChangeFilters} />}
				/>}
		</>
	);
};

export default DetailedInformations;
