import React from 'react';
import {
	makeStyles,
	TableContainer,
	Table as MuiTable,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';

import Estimates from './Estimates';
import EstimateSum from './EstimateSum';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
}));

const BudgetTable = props => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const { title, dataKey, readOnly = false } = props;
	const classes = useStyles();

	return (
		<TableContainer className={classes.root}>
			<MuiTable>
				<TableHead>
					<TableRow>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Nazwa Podmiotu Lidera/Członka Zespołu Badawczego')}</TableLabel>
						</TableCell>
						<TableCell colSpan={9}>
							<TableLabel align="center">{title}</TableLabel>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TableLabel align="center">{t('Wynagrodzenia')}</TableLabel>
							<Typography>({t('Zespół badawczy')})</Typography>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Wynagrodzenia')}</TableLabel>
							<Typography>({t('Personel administracyjny')})</Typography>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Koszty aparatury naukowo-badawczej i WNiP')}</TableLabel>
							<Typography>({t('w tym leasing, odpisy amortyzacyjne i koszty odpłatnego korzystania')})</Typography>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Podwykonawstwo i usługi obce')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Inne koszty bezpośrednie')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Razem koszty bezpośrednie')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Stopa ryczałtu')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Koszty pośrednie')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel align="center">{t('Razem koszty kwalifikowane')}</TableLabel>
							<Typography>({t('Finansowanie z Funduszu')})</Typography>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>1</TableCell>
						<TableCell>2</TableCell>
						<TableCell>3</TableCell>
						<TableCell>4</TableCell>
						<TableCell>5</TableCell>
						<TableCell>6</TableCell>
						<TableCell>7 = (2+3+4+5+6)</TableCell>
						<TableCell>8</TableCell>
						<TableCell style={{whiteSpace: 'nowrap'}}>9 = ((2+3+6)*8)</TableCell>
						<TableCell>10 = (7+9)</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					<Estimates dataKey={dataKey} readOnly={readOnly} />
					<EstimateSum dataKey={dataKey} />
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default BudgetTable;
