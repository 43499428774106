import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/Form/FormInput';
import Select from 'components/Form/Select';
import ResearchTaskContext from 'context/ResearchTaskContext';
import OwnershipForm from './OwnershipForm';
import Typography from 'components/Main/Typography';
import AddressForm from 'components/Main/AddressForm';

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
}));

const Form = ({ data, errors, onChange }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const { organizationStatuses } = useContext(ResearchTaskContext);
	const booleanOptions = [
		{ value: true, label: t('Tak') },
		{ value: false, label: t('Nie') },
	];
	return (
		<Box className={classes.root}>
			<FormInput
				title={t('Pełna nazwa podmiotu')}
				helperText={t('Pełna nazwa podmiotu zgodna z odpowiednim rejestrem/ KRS/CEIDG/przepisem prawa')}
				name="entity_name"
				value={data.entity_name}
				onChange={onChange}
				error={errors?.entity_name}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Skrócona nazwa podmiotu')}
				name="entity_short_name"
				value={data.entity_short_name}
				onChange={onChange}
				error={errors?.entity_short_name}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<Select
				title={t('Status organizacyjny podmiotu')}
				helperText={t('Status organizacyjny podmiotu (o wspieraniu działalności naukowej z Funduszu Polskiej Nauki)')}
				name="organization_status_id"
				options={organizationStatuses}
				displayEmpty
				valueField="id"
				labelField="name"
				value={data.organization_status_id}
				onChange={onChange}
				error={errors?.organization_status_id}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Forma prawna - podstawowa')}
				helperText={t('Forma prawna (podstawowa i szczególna, zgodnie z rozporządzeniem Rady Ministrów z dnia 30 listopada 2015 r)')}
				name="basic_legal_form"
				value={data.basic_legal_form}
				onChange={onChange}
				error={errors?.basic_legal_form}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Forma prawna - szczególna')}
				helperText={t('Forma prawna (podstawowa i szczególna, zgodnie z rozporządzeniem Rady Ministrów z dnia 30 listopada 2015 r)')}
				name="specific_legal_form"
				value={data.specific_legal_form}
				onChange={onChange}
				error={errors?.specific_legal_form}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<OwnershipForm
				values={data.ownership_form}
				onChange={onChange}
				error={errors?.ownership_form?.[0]}
				disabled={isPreview}
			/>
			<Select
				title={t('Główna działalność naukowa prowadzona w Polsce')}
				name="main_research_activity_in_poland"
				options={booleanOptions}
				value={data.main_research_activity_in_poland}
				onChange={onChange}
				error={errors?.main_research_activity_in_poland}
				displayEmpty
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Nr głównej działalności podmiotu PKD2007')}
				name="principal_activity_number_pkd2007"
				value={data.principal_activity_number_pkd2007}
				onChange={onChange}
				error={errors?.principal_activity_number_pkd2007}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Nr głównej działalności podmiotu Nace Rev 2')}
				name="principal_activity_number_nace_rev2"
				value={data.principal_activity_number_nace_rev2}
				onChange={onChange}
				error={errors?.principal_activity_number_nace_rev2}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={4}>
					<Typography>{t('Osoba kierująca podmiotem')}</Typography>
				</Grid>
				<Grid item xs={12} sm={8}>
					<Grid container spacing={1}>
						<Grid item xs={12} sm>
							<FormInput
								placeholder={t('Imię')}
								name="entity_manager_firstname"
								value={data.entity_manager_firstname}
								onChange={onChange}
								error={errors?.entity_manager_firstname}
								disabled={isPreview}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs={12} sm>
							<FormInput
								placeholder={t('Nazwisko')}
								name="entity_manager_surname"
								value={data.entity_manager_surname}
								onChange={onChange}
								error={errors?.entity_manager_surname}
								disabled={isPreview}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
						<Grid item xs={12} sm>
							<FormInput
								placeholder={t('Stanowisko')}
								name="entity_manager_position"
								value={data.entity_manager_position}
								onChange={onChange}
								error={errors?.entity_manager_position}
								disabled={isPreview}
								inputProps={{ 'aria-required': true }}
							/>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<AddressForm
				title={t('Adres siedziby głównej')}
				prefix="headquarters"
				data={data}
				errors={errors}
				onChange={onChange}
				disabled={isPreview}
			/>
			<FormInput
				title={t('Numer telefonu/faksu')}
				name="phone_number"
				value={data.phone_number}
				onChange={onChange}
				error={errors?.phone_number}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Adres strony internetowej')}
				name="website_address"
				value={data.website_address}
				onChange={onChange}
				error={errors?.website_address}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Adres poczty elektronicznej')}
				name="email_address"
				value={data.email_address}
				onChange={onChange}
				error={errors?.email_address}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('Adres ESP (ePUAP)')}
				name="esp_address"
				value={data.esp_address}
				onChange={onChange}
				error={errors?.esp_address}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('NIP')}
				name="nip"
				value={data.nip}
				onChange={onChange}
				error={errors?.nip}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<FormInput
				title={t('REGON')}
				name="regon"
				value={data.regon}
				onChange={onChange}
				error={errors?.regon}
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			<Select
				title={t('Adres do korespondencji inny niż podstawowy')}
				name="is_correspondence_address_different"
				options={booleanOptions}
				value={data.is_correspondence_address_different}
				onChange={onChange}
				error={errors?.is_correspondence_address_different}
				displayEmpty
				disabled={isPreview}
				inputProps={{ 'aria-required': true }}
			/>
			{data.is_correspondence_address_different &&
				<AddressForm
					title={t('Adres do korespondencji')}
					prefix="correspondence"
					data={data}
					errors={errors}
					onChange={onChange}
					disabled={isPreview}
				/>
			}
		</Box>
	);
};

export default Form;
