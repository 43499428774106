import React, { useContext, useEffect, useState } from 'react';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Routes from 'router/Routes';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import Tabs from 'components/Main/Tabs';
import Accordions from 'components/Main/Accordions';

import AuthContext from 'context/AuthContext';
import ResearchTaskContext from 'context/ResearchTaskContext';
import GeneralInfoTab from './GeneralInfoTab';
import EntitiesTab from './EntitiesTab';
import ContactsTab from './ContactsTab';
import TeamTab from './TeamTab';
import TaskTab from './TaskTab';
import Exports from './Exports';

import TaskPlans from './TaskTab/TaskPlans';
import TaskEstimations from './TaskTab/TaskEstimations';
import PaymentSchedule from './TaskTab/PaymentSchedule';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%',
	},
	accordionBG: {
		backgroundColor: '#FAFAFA',
		padding: '0 15px',
	}
}));

const FormContainer = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { id } = useParams();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const classes = useStyles();
	const [breadcrumbs, setBreadcrumbs] = useState([
		{ title: t('Zadania badawcze'), to: Routes.ResearchTasks.List },
		{ title: '', to: '' },
		{ title: t(id ? 'Edytuj zadanie badawcze' : 'Dodaj zadanie badawcze') }
	]);
	const tabs = [
		{
			value: 'general_info',
			children: <GeneralInfoTab />,
			label: t('a.1 Informacje ogólne')
		},
		{
			value: 'entities',
			children: <EntitiesTab />,
			label: t('a.2 Podmioty')
		},
		{
			value: 'contacts',
			children: <ContactsTab />,
			label: t('a.3 Osoby kontaktowe')
		},
		{
			value: 'research_team',
			children: <TeamTab />,
			label: t('b Zespół badawczy')
		},
		{
			value: 'research_task',
			children: <TaskTab />,
			label: t('c Zadanie badawcze')
		}
	];

	const items = [
		{
			child: <GeneralInfoTab />,
			title: t('a.1 Informacje ogólne').toUpperCase()
		},
		{
			child: <EntitiesTab />,
			title: t('a.2 Podmioty').toUpperCase()
		},
		{
			child: <ContactsTab />,
			title: t('a.3 Osoby kontaktowe').toUpperCase()
		},
		{
			child: <TeamTab />,
			title: t('b Zespół badawczy').toUpperCase()
		},
		{
			child: (
				<>
					<TaskPlans />
					<TaskEstimations />
					<PaymentSchedule />
				</>
			),
			title: t('c Zadanie badawcze').toUpperCase()
		}
	];

	const { onCancel, onSave, onPublish, saving, taskStatus, formData } = useContext(ResearchTaskContext);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (isPreview) return document.title = 'WIB - podgląd Zadania Badawczego';

		document.title = 'WIB - Edycja Zadania Badawczego';
	}, []);

	useEffect(() => {
		setBreadcrumbs(prev => {
			prev[1].title = formData.general_info.acronym;
			return [...prev];
		});
	}, [formData.general_info.acronym]);
	return (
		<>
			<Box className={classes.content}>
				<Header title={t(taskStatus === 'published' ? 'Edytuj zadanie' : 'Dodaj zadanie')} />
				<Breadcrumbs breadcrumbs={breadcrumbs} />

				{window.isMobile
					? <Accordions TransitionProps={{ unmountOnExit: true }} items={items} className={classes.accordionBG} />
					: <Tabs tabs={tabs} />
				}

				<Exports />
			</Box>

			<ControlButtonGroup
				onSave={onSave}
				onClickPrimary={onPublish}
				onCancel={onCancel}
				saving={saving || isPreview}
				disabledSaveButton={taskStatus === 'published'}
				primaryTitle={t(taskStatus === 'published' ? 'Zapisz zmiany' : 'Dodaj zadanie')}
				hidePrimaryButton={_.intersection([
					'can_publish_research_task',
					'can_fully_manage_entities'
				], user?.permissions).length < 1
				}
			/>
		</>
	);
};

export default withRouter(FormContainer);
