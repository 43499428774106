// /* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
	Box,
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

import NumberInput from 'components/Form/NumberInput';
import NumberText from 'components/Main/NumberText';
import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import InputTooltip from 'components/Main/InputTooltip';

import useCheckPermissions from 'utils/useCheckPermissions';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import CustomCell, { CategoryCell } from './components/CustomCell';
import CategoryTable from './CategoryTable';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import UploadButton from 'components/Buttons/UploadButton';

import API from 'apis/API';
import moment from 'moment-timezone';
import excelDateToJSDate from 'utils/excelDateToJSDate';
import isValidDate from 'utils/isValidDate';

const useStyles = makeStyles(() => ({
	root: {
		height: 'calc(100vh - 240px)',
		marginTop: 30,
		'& .MuiTableHead-root .MuiTableCell-stickyHeader': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
	row: {
		'& .MuiTableCell-head': {
			borderBottom: '1px solid #17253F',
			top: 51,
		},
	},
	button: {
		marginRight: 10,
	},
}));

const ExpensesStatements = () => {
	const { t } = useTranslation(null, {
		keyPrefix: 'Zadania badawcze - Raport B',
	});
	const { task_id: taskId, id: reportId } = useParams();
	const classes = useStyles();
	const canCreate = useCheckPermissions('can_create_report_b', true);
	const { formData, onChange, errors, selectedLanguage, disabledInput } =
		useContext(ResearchTaskReportBContext);

	const expensesStatements = formData[selectedLanguage].expenses_statements;
	const sum = useMemo(
		() => expensesStatements?.sum,
		[expensesStatements?.sum]
	);
	const [totalInterest, setTotalInterest] = useState(0);
	const [isExporting, setIsExporting] = useState(false);
	let importError = false;

	useEffect(() => {
		if (!canCreate) return;
		setTotalInterest(expensesStatements.sum.total_interest);
	}, [expensesStatements.sum.total_interest]);

	const handleChange = (e) => {
		let { value } = e.target;
		setTotalInterest(value);
		onChange('pl.expenses_statements.sum.total_interest', +value);
		onChange('en.expenses_statements.sum.total_interest', +value);
	};

	const exportTable4Xlsx = async () => {
		setIsExporting(true);
		try {
			const res = await API.researchTasks.report.exportB.table4(
				taskId,
				reportId,
				selectedLanguage
			);

			// If you want to download file automatically using link attribute.
			const link = document.createElement('a');
			const url = URL.createObjectURL(new Blob([res.data]));
			link.href = url;
			link.setAttribute('download', `${t('Raport B')}.xlsx`);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toast.error('Sorry, something went wrong.');
		}
		setIsExporting(false);
	};

	const importTable4Xlsx = async (e) => {
		setIsExporting(true);
		importError = false;

		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				const data = new Uint8Array(e.target.result);
				const workbook = XLSX.read(data, { type: 'array' });
				const sheetName = workbook.SheetNames[0];
				const sheet = workbook.Sheets[sheetName];
				const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

				let subTableSectionArray = [];
				let currentSubArray = [];
				let indexFlag = false;

				for (const row of jsonData) {
					if (row.length > 2 && isEntity(row[2])) indexFlag = true;
					if (isEntity(row[2]) && !row[13]) {
						importError = true;
						break;
					}
					if (!indexFlag) continue;
					if (row[1] != undefined) {
						subTableSectionArray.push(currentSubArray);
						currentSubArray = [];
					}
					currentSubArray.push(row);
				}

				subTableSectionArray.push(currentSubArray);
				subTableSectionArray = subTableSectionArray.slice(1);

				if (subTableSectionArray.length !== 4 || importError) {
					toast.error('Nieprawidłowy format pliku z danymi');
					return;
				}

				const plSalaryData = gettingSalaryData(
					subTableSectionArray[0],
					true
				);
				const enSalaryData = gettingSalaryData(
					subTableSectionArray[0],
					false
				);

				const plEquipmentCosts = gettingOthersData(
					subTableSectionArray[1],
					true
				);
				const enEquipmentCosts = gettingOthersData(
					subTableSectionArray[1],
					false
				);

				const plExternalServiceCosts = gettingOthersData(
					subTableSectionArray[2],
					true
				);
				const enExternalServiceCosts = gettingOthersData(
					subTableSectionArray[2],
					false
				);

				const plOtherDirectCosts = gettingOthersData(
					subTableSectionArray[3].slice(0, -5),
					true
				);
				const enOtherDirectCosts = gettingOthersData(
					subTableSectionArray[3].slice(0, -5),
					false
				);
				const expensesStatementsSum = gettingTotalSumData(
					subTableSectionArray[3].slice(-5)
				);

				const plExpensesStatements = {
					sum: expensesStatementsSum,
					salary: plSalaryData,
					equipment_costs: plEquipmentCosts,
					external_service_costs: plExternalServiceCosts,
					other_direct_costs: plOtherDirectCosts,
				};

				const enExpensesStatements = {
					sum: expensesStatementsSum,
					salary: enSalaryData,
					equipment_costs: enEquipmentCosts,
					external_service_costs: enExternalServiceCosts,
					other_direct_costs: enOtherDirectCosts,
				};
				
				onChange('pl.expenses_statements', plExpensesStatements);
				onChange('en.expenses_statements', enExpensesStatements);
			};
			reader.readAsArrayBuffer(file);
		}

		setIsExporting(false);
	};

	function gettingSalaryData(salaryData, flag) {
		const salary = { entities: [], sum: {} };
		for (const row of salaryData) {
			const entityIndex = Math.max(salary.entities.length - 1, 0);
			const memberIndex =
				salary.entities.length > 0
					? Math.max(
						salary.entities[entityIndex].members.length - 1,
						0
					)
					: 0;
			if (isEntity(row[2])) salary.entities.push(makeEntityFromRow(row));
			else if (isMember(row[2]) > 0) {
				salary.entities[salary.entities.length - 1].members.push(
					makeMemberFromRow(row, isMember(row[2]))
				);
			} else if (isData(row[2]) === 0) {
				salary.entities[entityIndex].members[memberIndex].data.push(
					makeDataFromRow(row, flag)
				);
			} else if (isData(row[2]) === 1) {
				salary.entities[entityIndex].members[memberIndex].sum =
					makeMemberSumFromRow(row);
			} else if (isData(row[2]) === 2) {
				salary.entities[entityIndex].sum = makeEntitySumFromRow(row);
			} else if (isData(row[2]) === 3) {
				salary.sum = makeTotalSumFromRow(row);
			}
		}
		return salary;
	}

	function gettingOthersData(otherData, flag) {
		const equipmentCosts = { entities: [], sum: {} };
		for (const row of otherData) {
			const entityIndex = Math.max(equipmentCosts.entities.length - 1, 0);

			if (isEntity(row[2]))
				equipmentCosts.entities.push(makeEntityFromRow(row));
			else if (isData(row[2]) === 0) {
				equipmentCosts.entities[entityIndex].data.push(
					makeDataFromRow(row, flag)
				);
			} else if (isData(row[2]) === 2) {
				equipmentCosts.entities[entityIndex].sum =
					makeEntitySumFromRow(row);
			} else if (isData(row[2]) === 3) {
				equipmentCosts.sum = makeTotalSumFromRow(row);
			}
		}
		return equipmentCosts;
	}

	function gettingTotalSumData(sumData) {
		return {
			total_direct_cost: sumData[0][7]
				? parseFloat(
					sumData[0][7]
						.toString()
						.replace(/\s/g, '')
						.replace(',', '.')
				)
				: 0,
			flat_rate: sumData[1][7]
				? parseFloat(
					sumData[1][7]
						.toString()
						.replace(/\s/g, '')
						.replace(',', '.')
				)
				: 0,
			total_indirect_cost: sumData[2][7]
				? parseFloat(
					sumData[2][7]
						.toString()
						.replace(/\s/g, '')
						.replace(',', '.')
				)
				: 0,
			total: sumData[3][7]
				? parseFloat(
					sumData[3][7]
						.toString()
						.replace(/\s/g, '')
						.replace(',', '.')
				)
				: 0,
			total_interest: sumData[4][7]
				? parseFloat(
					sumData[4][7]
						.toString()
						.replace(/\s/g, '')
						.replace(',', '.')
				)
				: 0,
		};
	}

	function makeEntityFromRow(row) {
		return {
			entity_name: row[7],
			entity_id: row[13],
			members: [],
			data: [],
			sum: {},
		};
	}

	function makeMemberFromRow(row, member_type_id) {
		return { id: row[13], member_type_id, name: row[7], data: [], sum: {} };
	}

	function makeDataFromRow(row, flag) {
		return {
			document_number: row[3] === undefined ? '' : row[3],
			registration_number: row[4] === undefined ? '' : row[4],
			document_date:
				row[5] === undefined
					? ''
					: moment(
						isValidDate(row[5])
							? row[5]
							: excelDateToJSDate(row[5])
					).format('YYYY-MM-DD'),
			payment_date:
				row[6] === undefined
					? ''
					: moment(
						isValidDate(row[6])
							? row[6]
							: excelDateToJSDate(row[6])
					).format('YYYY-MM-DD'),
			netto_amount:
				row[7] === undefined
					? ''
					: parseFloat(
						row[7]
							.toString()
							.replace(/\s/g, '')
							.replace(',', '.')
					),
			vat_amount:
				row[8] === undefined
					? ''
					: parseFloat(
						row[8]
							.toString()
							.replace(/\s/g, '')
							.replace(',', '.')
					),
			eligible_amount:
				row[9] === undefined
					? ''
					: parseFloat(
						row[9]
							.toString()
							.replace(/\s/g, '')
							.replace(',', '.')
					),
			service_description:
				row[flag ? 10 : 11] === undefined ? '' : row[flag ? 10 : 11],
			document_issuer: row[12] === undefined ? '' : row[12],
		};
	}

	function makeMemberSumFromRow(row) {
		return {
			netto_amount: row[7]
				? parseFloat(
					row[7].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
			vat_amount: row[8]
				? parseFloat(
					row[8].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
			eligible_amount: row[9]
				? parseFloat(
					row[9].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
		};
	}

	function makeEntitySumFromRow(row) {
		return {
			netto_amount: row[7]
				? parseFloat(
					row[7].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
			vat_amount: row[8]
				? parseFloat(
					row[8].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
			eligible_amount: row[9]
				? parseFloat(
					row[9].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
		};
	}

	function makeTotalSumFromRow(row) {
		return {
			netto_amount: row[7]
				? parseFloat(
					row[7].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
			vat_amount: row[8]
				? parseFloat(
					row[8].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
			eligible_amount: row[9]
				? parseFloat(
					row[9].toString().replace(/\s/g, '').replace(',', '.')
				)
				: 0,
		};
	}

	function isEntity(value) {
		if (!value) return false;

		return (
			value.toString().toLowerCase() === 'NAZWA WNIOSKODAWCY (PODMIOT LIDERA)'.toLowerCase() ||
			value.toString().toLowerCase() === 'NAZWA INNEJ JEDNOSTKI'.toLowerCase()
		);
	}

	function isMember(value) {
		if (!value) {
			return 0;
		}

		if (value.toString().toLowerCase() === 'IMIĘ I NAZWISKO CZŁONKA ZESPOŁU'.toLowerCase()) return 2;
		else if (value.toString().toLowerCase() === 'IMIĘ I NAZWISKO LIDERA'.toLowerCase()) return 1;
		return 0;
	}

	function isData(value) {
		if (!value) {
			return 0;
		}

		if (value.toString().toLowerCase() === 'Suma dla osoby'.toLowerCase()) return 1;
		else if (value.toString().toLowerCase() === 'Suma dla podmiotu'.toLowerCase()) return 2;
		else if (
			value.toString().toLowerCase() === 'Suma kosztów wynagrodzeń'.toLowerCase() ||
			value.toString().toLowerCase() ===
				'Suma kosztów aparatury naukowo-badawczej, środków trwałych i wartości niematerialnych i prawnych'.toLowerCase() ||
			value.toString().toLowerCase() === 'Suma kosztów podwykonawstwa i usług obcych'.toLowerCase() ||
			value.toString().toLowerCase() === 'Suma innych kosztów bezpośrednich'.toLowerCase()
		)
			return 3;
		return 0;
	}

	return (
		<div id="research-task-report-b">
			<Box
				display={'flex'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<Typography variant="h4" color="primary">
					{t(
						'Tabela nr 4 - Zestawienie poniesionych wydatków związanych z realizacją Zadania Badawczego'
					)}
				</Typography>

				<Box>
					{reportId && (
						<PrimaryButton
							variant="outlined"
							className={classes.button}
							onClick={exportTable4Xlsx}
							disabled={isExporting}
						>
							Export
						</PrimaryButton>
					)}

					<UploadButton
						acceptFiles=".xlsx"
						handleCapture={importTable4Xlsx}
						disabled={isExporting}
						variant="outlined"
					>
						Import
					</UploadButton>
				</Box>
			</Box>
			<TableContainer className={classes.root}>
				<Table stickyHeader>
					<TableHead>
						<TableRow>
							<CategoryCell rowSpan={2}>
								<TableLabel>{t('Kategoria kosztu')}</TableLabel>
							</CategoryCell>
							<TableCell colSpan={11}>
								<TableLabel>
									{t(
										'Koszty kwalifikowane zadania badawczego - dokumenty w podziale na kategorie kosztów'
									)}
								</TableLabel>
							</TableCell>
						</TableRow>
						<TableRow className={classes.row}>
							<TableCell>
								<TableLabel>{t('LP.')}</TableLabel>
							</TableCell>
							<TableCell>
								<TableLabel>{t('Nr dokumentu')}</TableLabel>
							</TableCell>
							<TableCell>
								<TableLabel>
									{t('Nr księgowy lub ewidencyjny')}
								</TableLabel>
							</TableCell>
							<TableCell style={{ minWidth: 130 }}>
								<TableLabel>{t('Data wystawienia')}</TableLabel>
							</TableCell>
							<TableCell style={{ minWidth: 130 }}>
								<TableLabel>{t('Data zapłaty')}</TableLabel>
							</TableCell>
							<TableCell>
								<TableLabel>{t('Kwota netto')}</TableLabel>
							</TableCell>
							<TableCell style={{ minWidth: 100 }}>
								<Box
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<TableLabel>{t('VAT')}</TableLabel>
									<InputTooltip
										title={t(
											'VAT a w przypadku kategorii 1 Wynagrodzenia - Narzuty na wynagrodzenia'
										)}
									/>
								</Box>
							</TableCell>
							<TableCell>
								<TableLabel>
									{t(
										'Razem koszty kwalifikowane (Finansowanie z Funduszu)'
									)}
								</TableLabel>
							</TableCell>
							<TableCell>
								<TableLabel>
									{t(
										'Opis wydatku (nazwa towaru lub usługi)'
									)}
								</TableLabel>
							</TableCell>
							<TableCell>
								<TableLabel>
									{t('Wystawca dokumentu')}
								</TableLabel>
							</TableCell>
							<TableCell width="100">
								<TableLabel>{t('Akcje')}</TableLabel>
							</TableCell>
						</TableRow>
					</TableHead>
					<CategoryTable
						categoryTitle={t('1. Wynagrodzenia')}
						categorySumTitle={t('Suma kosztów wynagrodzeń')}
						tableKey="salary"
						showMember
					/>
					<CategoryTable
						categoryTitle={t(
							'2. Koszty aparatury naukowo-badawczej, środków trwałych i wartości niematerialnych i prawnych (w tym leasing, odpisy amortyzacyjne i koszty odpłatnego korzystania)'
						)}
						categorySumTitle={t(
							'Suma kosztów aparatury naukowo-badawczej, środków trwałych i wartości niematerialnych i prawnych'
						)}
						tableKey="equipment_costs"
					/>
					<CategoryTable
						categoryTitle={t('3. Podwykonawstwo i usługi obce')}
						categorySumTitle={t(
							'Suma kosztów podwykonawstwa i usług obcych'
						)}
						tableKey="external_service_costs"
					/>
					<CategoryTable
						categoryTitle={t('4. Inne koszty bezpośrednie')}
						categorySumTitle={t(
							'Suma innych kosztów bezpośrednich'
						)}
						tableKey="other_direct_costs"
					/>
					<TableBody>
						<TableRow>
							<TableCell rowSpan={0}></TableCell>
							<CustomCell type="total" colSpan={5}>
								<TableLabel align="right">
									{t(
										'Koszty bezpośrednie razem (kat.1 + kat.2 + kat.3 + kat.4)'
									)}
								</TableLabel>
							</CustomCell>
							<CustomCell type="total" colSpan={3}>
								<NumberText
									fixedDecimalScale={true}
									value={sum?.total_direct_cost}
								/>
							</CustomCell>
						</TableRow>
						<TableRow>
							<CustomCell type="total" colSpan={5}>
								<TableLabel align="right">
									{t('Przyznana stopa ryczałtu (%)')}
								</TableLabel>
							</CustomCell>
							<CustomCell type="total" colSpan={3}>
								<NumberText
									fixedDecimalScale={true}
									value={sum?.flat_rate}
								/>
							</CustomCell>
						</TableRow>
						<TableRow>
							<CustomCell type="total" colSpan={5}>
								<TableLabel align="right">
									{t(
										'Koszty pośrednie razem (kat.1 + kat.4) x przyznana stopa ryczałtu'
									)}
								</TableLabel>
							</CustomCell>
							<CustomCell type="total" colSpan={3}>
								<NumberText
									fixedDecimalScale={true}
									value={sum?.total_indirect_cost}
								/>
							</CustomCell>
						</TableRow>
						<TableRow>
							<CustomCell type="entity" colSpan={5}>
								<TableLabel align="right">
									{t('Razem')}
								</TableLabel>
							</CustomCell>
							<CustomCell type="entity" colSpan={3}>
								<NumberText
									fixedDecimalScale={true}
									value={sum?.total}
								/>
							</CustomCell>
						</TableRow>
						<TableRow>
							<CustomCell type="total" colSpan={5}>
								<TableLabel align="right">
									{t(
										'Suma odsetek uzyskanych od kwoty finansowania zadania badawczego w całym okresie realizacji'
									)}
								</TableLabel>
							</CustomCell>
							<CustomCell type="total" colSpan={3}>
								{canCreate ? (
									<NumberInput
										value={totalInterest}
										onChange={handleChange}
										fullWidth
										error={
											errors?.expenses_statements?.sum
												?.total_interest
										}
										disabled={
											disabledInput ||
											selectedLanguage === 'en'
										}
									/>
								) : (
									<NumberText
										fixedDecimalScale={true}
										value={sum?.total_interest}
									/>
								)}
							</CustomCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
};

export default ExpensesStatements;
