import React, { useEffect, useState } from 'react';
import { makeStyles, Box, Card, TableCell, TableRow } from '@material-ui/core';
import { toast } from 'react-toastify';
import _ from 'lodash';

import API from 'apis/API';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import PaginatedTable from 'components/PaginatedTable';
import ControlButtonGroup from 'components/Buttons/ControlButtonGroup';
import { EditIconButton } from 'components/Buttons/IconButtons';

import FilterRow from './FilterRow';
import EditingRow from './EditingRow';

const useStyles = makeStyles(theme => ({
	content: {
		margin: theme.spacing(-5, -4, 0),
		padding: theme.spacing(5, 4),
		overflowY: 'scroll',
		height: '100%',
	},
}));

const Translations = () => {
	const classes = useStyles();
	const breadcrumbs = [
		{ title: 'Zarządzanie treścią', to: '' },
		{ title: 'Tłumaczenia' },
	];
	const columns = [
		{ title: 'Moduł', name: 'module', width: 250 },
		{ title: 'Wersja polska', name: 'pl', width: '30%' },
		{ title: 'Wersja angielska', name: 'en', width: '30%' },
		{ title: 'Akcje', name: 'action', disableSort: true, width: 50, },
	];
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
		column: 'id',
		direction: 'desc',
		filters: {}
	});
	const [modules, setModules] = useState([]);
	const [totalPagesCount, setTotalPagesCount] = useState(0);
	const [data, setData] = useState([]);
	const [editingData, setEditingData] = useState(null);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		document.title = 'WIB - Tłumaczenia';
		API.translations.modules().then(res => setModules(res.data.data || []));
	}, []);

	useEffect(() => loadData(), [params]);

	const loadData = () => {
		setEditingData(null);
		API.translations.index(params).then(res => {
			let _data = res.data;
			setData(_data.data);
			setTotalPagesCount(_data.last_page);
		});
	};

	const handleChangeParams = _params => {
		if (_.isEqual(_params, _.pick(params, ['column', 'direction', 'page']))) return;

		setParams({ ...params, ..._params });
	};

	const handleChangePerPage = e => {
		setParams(prev => ({ ...prev, limit: e.target.value }));
	};

	const handleChangeFilters = filters => {
		setParams(prev => {
			if (_.isEqual(prev.filters, filters)) return prev;
			return { ...prev, filters };
		});
	};

	const handleEdit = id => () => {
		setEditingData(_.find(data, { id }));
	};

	const handleSaveEditingData = item => {
		setData(prev => {
			let index = _.findIndex(prev, { id: item.id });
			prev[index] = item;
			return [...prev];
		});
		setEditingData(null);
	};

	const handleSave = () => {
		setSaving(true);
		API.translations.store(data).then(() => {
			setSaving(false);
			setEditingData(null);
			toast.success('Zatwierdź zmiany');
		});
	};

	const renderRows = () => (
		data?.map((item, index) => (
			item.id === editingData?.id
				? <EditingRow
					key={index}
					item={editingData}
					onSave={handleSaveEditingData}
				/>
				: <TableRow key={index}>
					<TableCell>{item.module}</TableCell>
					<TableCell>{item.pl}</TableCell>
					<TableCell>{item.en}</TableCell>
					<TableCell>
						<EditIconButton onClick={handleEdit(item.id)} />
					</TableCell>
				</TableRow>
		)));

	return (
		<>
			<Box className={classes.content}>
				<Header
					title="Tłumaczenia"
					perPageCount={params.limit}
					onChangeCount={handleChangePerPage}
				/>
				<Breadcrumbs breadcrumbs={breadcrumbs} />
				<Card>
					<PaginatedTable
						columns={columns}
						renderRows={renderRows}
						filterRow={<FilterRow modules={modules} onChange={handleChangeFilters} />}
						totalPagesCount={totalPagesCount}
						onChangeFilters={handleChangeParams}
					/>
				</Card>
			</Box>
			<ControlButtonGroup
				primaryTitle="Zatwierdź zmiany"
				onClickPrimary={handleSave}
				onCancel={loadData}
				saving={saving}
				hideSaveButton
			/>
		</>
	);
};

export default Translations;
