import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import IconButton, { DeleteIconButton } from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const EditingRow = ({ item, onChange, onSave, onDelete, errors }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });

	return (
		<Row
			onChange={onChange}
			values={item}
			errors={errors}
			lastColumn={
				<Box display="flex">
					<IconButton onClick={onSave} tooltip={t('Zatwierdź')}>
						<CircleCheckIcon />
					</IconButton>
					{onDelete ? <DeleteIconButton hideModal onClick={onDelete} tooltip={t('Usuń')} /> : ''}
				</Box>
			}
		/>
	);
};

export default EditingRow;
