import React from 'react';
import { Box, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { HeadCell } from '../CustomCell';
import NumberText from 'components/Main/NumberText';
import Typography from 'components/Main/Typography';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import BaseInput from 'components/Form/BaseInput';
import NumberInput from 'components/Form/NumberInput';


const useStyles = makeStyles(() => ({
	table: {
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
}));

const Task = ({ task, onDelete, onChange, disabled }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - rozliczenie' });
	const rows = [
		{ name: 'research_team', label: t('Zespół badawczy') },
		{ name: 'commercialization', label: t('Komercjalizacja') }
	];
	const classes = useStyles();

	return (
		<Grid container>
			<Grid item sm={3}>
				<Box display="flex">
					<Typography style={{ marginRight: 16 }}>{t('Zespół badawczy')} {task.research_task_acronym}</Typography>
					<DeleteIconButton
						onClick={onDelete}
						tooltip={t('Usuń')}
						disabled={disabled}
					/>
				</Box>
			</Grid>
			<Grid item sm={9}>
				<TableContainer className={classes.table}>
					<Table>
						<TableBody>
							<TableRow>
								<HeadCell>
									<Typography variant="tableLabel">{t('Kwota orzymana ogółem')}</Typography>
								</HeadCell>
								<HeadCell width="200">
									<NumberText fixedDecimalScale={true} value={task.total_amount} />
								</HeadCell>
								<HeadCell>
									<Typography variant="tableLabel">{t('Wydatki ogółem')}</Typography>
								</HeadCell>
								<HeadCell>
									<Typography variant="tableLabel">{t('Procent wydatków')}</Typography>
								</HeadCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={4}>{t('w tym')}</TableCell>
							</TableRow>
							{rows.map(row => (
								<TableRow key={row.name}>
									<TableCell>{row.label}</TableCell>
									<TableCell>
										{row.name == 'commercialization' ? <NumberInput
											name={`table1.${row.name}.col_1`}
											value={task.table1[row.name].col_1}
											onChange={onChange}
											disabled={disabled}
										/> : <NumberText fixedDecimalScale={true} value={task.table1[row.name].col_1} />}
									</TableCell>
									<TableCell>
										<NumberText fixedDecimalScale={true} value={task.table1[row.name].col_2} />
									</TableCell>
									<TableCell>
										<NumberText fixedDecimalScale={true} value={task.table1[row.name].col_3 * 100} suffix="%" />
									</TableCell>
								</TableRow>
							))}
							<TableRow>
								<HeadCell>
									<Typography variant="tableLabel">{t('Kwota orzymana ZGODNIE Z WNIOSKIEM NR')}</Typography>
									<BaseInput
										fullWidth
										name="application_number"
										value={task.application_number}
										onChange={onChange}
										style={{ marginTop: 10 }}
										disabled={disabled}
									/>

								</HeadCell>
								<HeadCell>
									<NumberText fixedDecimalScale={true} value={task.received_amount} />
								</HeadCell>
								<HeadCell>
									<Typography variant="tableLabel">{t('Wydatki w okresie sprawozdawczym')}</Typography>
								</HeadCell>
								<HeadCell>
									<Typography variant="tableLabel">{t('Procent wydatków w okresie sprawozdawczym (%)')}</Typography>
								</HeadCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={4}>{t('w tym')}</TableCell>
							</TableRow>
							{rows.map(row => (
								<TableRow key={row.name}>
									<TableCell>{row.label}</TableCell>
									<TableCell>
										<NumberInput
											name={`table2.${row.name}.col_1`}
											value={task.table2[row.name].col_1}
											onChange={onChange}
										/>
									</TableCell>
									<TableCell>
										<NumberText fixedDecimalScale={true} value={task.table2[row.name].col_2} />
									</TableCell>
									<TableCell>
										<NumberText fixedDecimalScale={true} value={task.table2[row.name].col_3 * 100} suffix="%" />
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
		</Grid>
	);
};

export default Task;
