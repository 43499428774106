import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/formatters';
import TableLabel from 'components/Main/TableLabel';
import Typography from 'components/Main/Typography';
import DateInput from 'components/Form/DateInput';
import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
			'& .MuiTableCell-head': {
				verticalAlign: 'top',
			},
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
		},
	},
}));

const PhaseImplementationPeriod = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { researchTask } = useContext(ResearchTaskReportContext);
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	const taskPlans = formData[selectedLanguage].research_team.task_plans ?? researchTask?.task_plans;
	const phaseImplementationPeriod = formData[selectedLanguage].research_team?.phase_implementation_period;
	const [data, setData] = useState([]);

	useEffect(() => {
		setData(prev => (
			_.map(taskPlans, plan => (
				_.find(phaseImplementationPeriod || prev, { id: plan.id }) || { id: plan.id, start_date: plan.start_date, end_date: plan.end_date }
			))
		));
	}, [taskPlans]);

	useEffect(() => {
		onChange('pl.research_team.task_plans', taskPlans);
		onChange('en.research_team.task_plans', taskPlans);
	}, [taskPlans]);

	useEffect(() => {
		if (_.isEmpty(phaseImplementationPeriod)
		|| _.isEqual(phaseImplementationPeriod, data)) return;
	}, [phaseImplementationPeriod]);

	useEffect(() => {
		onChange('en.research_team.phase_implementation_period', data);
		onChange('pl.research_team.phase_implementation_period', data);
	}, [data]);

	const handleChange = (index, field) => e => {
		setData(prev => {
			prev[index][field] = e.target.value;
			return [...prev];
		});
	};

	return (
		<TableContainer className={classes.root}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Numer etapu')}</TableLabel>
						</TableCell>
						<TableCell rowSpan={2}>
							<TableLabel>{t('Tytuł etapu')}</TableLabel>
						</TableCell>
						<TableCell colSpan={2}>
							<TableLabel>{t('Planowana realizacja etapów')}</TableLabel>
						</TableCell>
						<TableCell colSpan={2}>
							<TableLabel>{t('Rzeczywista realizacja etapów')}</TableLabel>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TableLabel>{t('Data rozpoczęcia')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>{t('Data zakończenia')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>{t('Data rozpoczęcia')}</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>{t('Data zakończenia')}</TableLabel>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{taskPlans?.map((item, index) => (
						<TableRow key={index}>
							<TableCell width="10%">
								<Typography>{index + 1}</Typography>
							</TableCell>
							<TableCell>
								<Typography>{item.title}</Typography>
							</TableCell>
							<TableCell width="15%">
								<Typography>{formatDate(item.start_date)}</Typography>
							</TableCell>
							<TableCell width="15%">
								<Typography>{formatDate(item.end_date)}</Typography>
							</TableCell>
							{['start_date', 'end_date'].map((field, i) => (
								<TableCell width="15%" key={i}>
									<DateInput
										value={data?.[index]?.[field] || item[field]}
										onChange={handleChange(index, field)}
										gutterBottom={false}
										error={errors?.research_team?.phase_implementation_period?.[index]?.[field]}
										disabled={disabledInput || selectedLanguage === 'en'}
										inputProps={{ 'aria-required': true }}
									/>
								</TableCell>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default PhaseImplementationPeriod;
