import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import Select from 'components/Form/Select';
import {
	CheckRedIconButton,
	CollapseCircleIconButton,
	DeleteIconButton,
	ExpandCircleIconButton,
	SaveIconButton,
} from 'components/Buttons/IconButtons';
import Validator, { Required, validate } from 'utils/Validator';

const EditingRow = ({ row, order, expandIndex, handleClickReadMore, onSave, onDelete }) => {
	const options = [
		{ name: 'Autor wiodący' },
		{ name: 'Autor korespondencyjny' },
		{ name: 'Pierwszy autor' },
		{ name: 'Drugi autor' },
		{ name: 'Trzeci autor' },
		{ name: 'Czwarty autor' },
		{ name: 'Piąty autor' },
		{ name: 'Inne' },
	];

	const [model, setModel] = useState({
		id: null,
		index: null,
		title: '',
		publication_date: '',
		authors: []
	});

	const [errors, setErrors] = useState(null);

	const [author, setAuthor] = useState({
		name: '',
		role: ''
	});
	const classes = useStyles();

	const Validators = {
		title: new Validator(Required),
		publication_date: new Validator(Required),
	};

	const handleChange = (index) => (e) => {
		const { name, value } = e.target;
		if (name === 'title' || name === 'publication_date') {
			setModel(prev => ({ ...prev, [name]: value }));
		} else {
			setModel(prev => {
				const authors = [...prev.authors];
				authors[index][name] = value;
				return { ...prev, authors };
			});
		}
	};

	const handleDeleteOwner = index => () => {
		setModel(prev => {
			prev.authors.splice(index, 1);
			return { ...prev };
		});
	};

	const handleChangeAddedAuthor = e => {
		setAuthor(prev => {
			return { ...prev, [e.target.name]: e.target.value };
		});
	};

	const handleAddAuthor = () => {
		setModel(prev => {
			return { ...prev, authors: [...prev.authors, author] };
		});
		setAuthor({
			name: '',
			role: ''
		});
	};

	const handleSave = () => {
		if (errors) {
			return;
		}
		onSave(model);
	};

	useEffect(() => {
		setModel(JSON.parse(JSON.stringify(row)));
	}, [row]);

	useEffect(() => {
		if (!model) {
			setErrors(null);
			return;
		}

		let _errors = validate(model, Validators);
		setErrors(_errors);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [model]);

	return (
		<>
			<TableRow>
				<TableCell rowSpan={(model.authors?.length + 1) || 1}>{order}</TableCell>
				<TableCell rowSpan={(model.authors?.length + 1) || 1}>
					<BaseInput
						hideErrorText
						fullWidth
						placeholder="Wpisz tytuł"
						name="title"
						value={model.title}
						onChange={handleChange()}
						error={errors?.title}
					/>
				</TableCell>
				<TableCell rowSpan={(model.authors?.length + 1) || 1}>
					<DateInput
						fullWidth
						name="publication_date"
						value={model.publication_date || null}
						onChange={handleChange()}
						error={errors?.publication_date}
						hideErrorText
						gutterBottom={false}
					/>
				</TableCell>
				{model.authors?.length === 0
					? <>
						<TableCell></TableCell>
						<TableCell>
							<BaseInput
								hideErrorText
								fullWidth
								placeholder="Wpisz imię i nazwisko"
								name="name"
								value={author.name}
								onChange={handleChangeAddedAuthor}
							/>
						</TableCell>
						<TableCell>
							<Box display="flex">
								<Select
									gutterBottom={false}
									name="role"
									options={options}
									valueField="name"
									labelField="name"
									hideErrorText
									displayEmpty
									emptyLabel={'Wybierz'}
									value={author.role}
									onChange={handleChangeAddedAuthor}
								/>
								<SaveIconButton
									tooltip="Zatwierdź"
									style={{ marginLeft: 15 }}
									onClick={handleAddAuthor}
								/>
							</Box>
						</TableCell>
					</>
					: <>
						<TableCell>
							{model.authors?.[0] ? 1 : ''}
						</TableCell>
						<TableCell>
							<BaseInput
								hideErrorText
								fullWidth
								placeholder="Wpisz imię i nazwisko"
								name="name"
								value={model.authors?.[0]?.name}
								onChange={handleChange(0)}
							/>
						</TableCell>
						<TableCell>
							<Box display="flex">
								<Select
									gutterBottom={false}
									name="role"
									options={options}
									valueField="name"
									labelField="name"
									hideErrorText
									displayEmpty
									emptyLabel={'Wybierz'}
									value={model.authors?.[0]?.role}
									onChange={handleChange(0)}
								/>
								<DeleteIconButton tooltip="Usuń" style={{ marginLeft: 15 }} onClick={handleDeleteOwner(0)} />
							</Box>
						</TableCell>
					</>
				}

				<TableCell rowSpan={(model.authors?.length + 1) || 1}>
					<Box display="flex">
						{expandIndex !== row?.index
							? <ExpandCircleIconButton
								disabled={row.authors?.length < 4}
								component={row.authors?.length < 4 ? 'div' : undefined}
								className={row.authors?.length < 4 ? classes.disabled : ''}
								tooltip="Rozwiń"
								onClick={() => handleClickReadMore(row?.index)}
							/>
							: <CollapseCircleIconButton
								tooltip="Zwiń"
								onClick={() => handleClickReadMore(null)}
							/>
						}
						<CheckRedIconButton tooltip="Zatwierdź" onClick={handleSave} />
						<DeleteIconButton tooltip="Usuń" hideModal onClick={() => onDelete(row?.index)} />
					</Box>
				</TableCell>
			</TableRow>

			{model.authors?.length > 1 &&
				model.authors?.slice(1).map((author, authorIndex) => (
					<TableRow key={authorIndex + '_author'}>
						<TableCell>{authorIndex + 2}</TableCell>
						<TableCell>
							<BaseInput
								hideErrorText
								fullWidth
								placeholder="Wpisz imię i nazwisko"
								name="name"
								value={author.name}
								onChange={handleChange(authorIndex + 1)}
							/>
						</TableCell>
						<TableCell>
							<Box display="flex">
								<Select
									gutterBottom={false}
									name="role"
									options={options}
									valueField="name"
									labelField="name"
									hideErrorText
									displayEmpty
									emptyLabel={'Wybierz'}
									value={author.role}
									onChange={handleChange(authorIndex + 1)}
								/>
								<DeleteIconButton tooltip="Usuń" style={{ marginLeft: 15 }} onClick={handleDeleteOwner(authorIndex + 1)} />
							</Box>
						</TableCell>
					</TableRow>
				))
			}

			{model.authors?.length > 0 &&
				<TableRow>
					<TableCell></TableCell>
					<TableCell>
						<BaseInput
							hideErrorText
							fullWidth
							placeholder="Wpisz imię i nazwisko"
							name="name"
							value={author.name}
							onChange={handleChangeAddedAuthor}
						/>
					</TableCell>
					<TableCell>
						<Box display="flex">
							<Select
								gutterBottom={false}
								name="role"
								options={options}
								valueField="name"
								labelField="name"
								hideErrorText
								displayEmpty
								emptyLabel={'Wybierz'}
								value={author.role}
								onChange={handleChangeAddedAuthor}
							/>
							<SaveIconButton tooltip="Zatwierdź" style={{ marginLeft: 15 }} onClick={handleAddAuthor} />
						</Box>
					</TableCell>
				</TableRow>
			}
		</>
	);
};

const useStyles = makeStyles(() => ({
	disabled: {
		'&.Mui-disabled': {
			'& path': {
				fill: 'gray'
			}
		}
	}
}));

export default EditingRow;
