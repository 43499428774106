import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import useCheckPermissions from 'utils/useCheckPermissions';

import AuthContext from 'context/AuthContext';
import { ResearchTaskReportContextProvider } from 'context/ResearchTaskReportContext';
import { ResearchTaskReportAContextProvider } from 'context/ResearchTaskReportAContext';
import { ResearchTaskReportBContextProvider } from 'context/ResearchTaskReportBContext';

import ReportA from './ReportA';
import ReportB from './ReportB';

const Form = () => {
	const { task_id, type } = useParams();
	const { user, logOut } = useContext(AuthContext);
	const canViewAllTasks = useCheckPermissions('can_view_all_research_tasks', true);
	const canViewAssignedTasks = useCheckPermissions('can_view_assigned_research_tasks', true);

	if (!canViewAllTasks && !canViewAssignedTasks) {
		logOut();
		return <></>;
	}
	if (!canViewAllTasks && canViewAssignedTasks && !_.includes(user.assigned_tasks, +task_id)) {
		logOut();
		return <></>;
	}

	return (
		<ResearchTaskReportContextProvider>
			{type === 'type_a' &&
				<ResearchTaskReportAContextProvider>
					<ReportA />
				</ResearchTaskReportAContextProvider>
			}
			{type === 'type_b' &&
				<ResearchTaskReportBContextProvider>
					<ReportB />
				</ResearchTaskReportBContextProvider>
			}
		</ResearchTaskReportContextProvider>
	);
};

export default Form;
