import React, { useState } from 'react';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';

import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import NumberInput from 'components/Form/NumberInput';
import { DeleteIconButton, SaveIconButton } from 'components/Buttons/IconButtons';
import Validator, { FloatNumber, Required, validate } from 'utils/Validator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	row: {
		'& .MuiTableCell-body:not(:first-child):not(:last-child)': {
			padding: 0,
		},
		'& .MuiTableCell-body': {
			'&:first-child, &:last-child': {
				paddingBlock: 6
			}
		}
	}
}));

const EditingRow = ({ item, index, onDelete, onSave, selectedLanguage }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });

	const classes = useStyles();
	const [data, setData] = useState({
		document_number: '',
		registration_number: '',
		document_date: '',
		payment_date: '',
		netto_amount: 0,
		vat_amount: 0,
		eligible_amount: 0,
		service_description: '',
		document_issuer: '',
		...item
	});
	const [errors, setErrors] = useState(null);
	const Validators = {
		document_number: new Validator(Required),
		registration_number: new Validator(Required),
		document_date: new Validator(Required),
		payment_date: new Validator(Required),
		// netto_amount: new Validator(Required),
		vat_amount: new Validator(FloatNumber),
		// eligible_amount: new Validator(Required),
		service_description: new Validator(Required),
		document_issuer: new Validator(Required),
	};

	const handleChange = e => {
		let { name, value, type } = e.target;
		setData(prev => ({ ...prev, [name]: type === 'number' ? parseFloat(value) : value }));
	};

	const handleSave = () => {
		let _errors = validate(data, Validators);
		setErrors(_errors);
		if (_errors) return;
		onSave(data);
	};

	return (
		<TableRow className={classes.row}>
			<TableCell>{index + 1}.</TableCell>
			<TableCell>
				<BaseInput
					name="document_number"
					value={data.document_number}
					onChange={handleChange}
					fullWidth
					error={errors?.document_number}
					hideErrorText
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					name="registration_number"
					value={data.registration_number}
					onChange={handleChange}
					fullWidth
					error={errors?.registration_number}
					hideErrorText
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					name="document_date"
					value={data.document_date}
					onChange={handleChange}
					fullWidth
					error={errors?.document_date}
					hideErrorText
					gutterBottom={false}
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					name="payment_date"
					value={data.payment_date}
					onChange={handleChange}
					fullWidth
					error={errors?.payment_date}
					hideErrorText
					gutterBottom={false}
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<NumberInput
					name="netto_amount"
					value={data.netto_amount}
					onChange={handleChange}
					fullWidth
					error={errors?.netto_amount}
					hideErrorText
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<NumberInput
					name="vat_amount"
					value={data.vat_amount}
					onChange={handleChange}
					fullWidth
					error={errors?.vat_amount}
					hideErrorText
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<NumberInput
					name="eligible_amount"
					value={data.eligible_amount}
					onChange={handleChange}
					fullWidth
					error={errors?.eligible_amount}
					hideErrorText
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					name="service_description"
					value={data.service_description}
					onChange={handleChange}
					fullWidth
					error={errors?.service_description}
					hideErrorText
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					name="document_issuer"
					value={data.document_issuer}
					onChange={handleChange}
					fullWidth
					error={errors?.document_issuer}
					hideErrorText
					disabled={selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
			</TableCell>
			<TableCell>
				<Box display="flex" alignItems="center">
					<SaveIconButton onClick={handleSave} tooltip={t('Zatwierdź')} />
					{selectedLanguage !== 'en' && <DeleteIconButton onClick={onDelete(index)} tooltip={t('Usuń')} />}
				</Box>
			</TableCell>
		</TableRow>
	);
};

export default EditingRow;
