import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box, TableRow, TableCell, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { formatDate } from 'utils/formatters';
import Validator, { Required, ValidISOString, AfterOrEqual, validate, Integer, Unique } from 'utils/Validator';
import PaginatedTable from 'components/PaginatedTable';
import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import ResearchTaskContext from 'context/ResearchTaskContext';
import FilterRow from './FilterRow';
import EditingRow from './EditingRow';
import Typography from 'components/Main/Typography';

const useStyles = makeStyles(theme => ({
	table: {
		padding: 0,
	},
	divider: {
		marginBlock: theme.spacing(2),
	},
}));

const TaskPlans = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const columns = [
		{ title: t('Numer etapu'), name: 'stage_number', disableSort: true, width: 150 },
		{ title: t('Data rozpoczęcia'), name: 'start_date', disableSort: true, },
		{ title: t('Data zakończenia'), name: 'end_date', disableSort: true, },
		{ title: t('Liczba miesięcy realizacji etapu'), name: 'number_of_months', disableSort: true, },
		{ title: t('Tytuł etapu'), name: 'title', disableSort: true, },
		{ title: t('Rodzaj prowadzonych badań/prac w etapie'), name: 'plan_type_id', disableSort: true, },
		{ title: t('Akcje'), name: 'action', disableSort: true, width: 100, },
	];
	const classes = useStyles();
	const { planTypes, onChange, formData } = useContext(ResearchTaskContext);
	const [data, setData] = useState(formData.task_plans);
	const [editingData, setEditingData] = useState(null);
	const [errors, setErrors] = useState(null);
	const [adding, setAdding] = useState(false);
	const Validators = {
		stage_number: new Validator(Required, Integer, Unique(data, 'stage_number')),
		start_date: new Validator(Required, ValidISOString()),
		end_date: new Validator(Required, ValidISOString(), AfterOrEqual('start_date')),
		number_of_months: new Validator(Required, Integer),
		title: new Validator(Required),
		plan_type_id: new Validator(Required),
	};

	useEffect(() => {
		if (!editingData) return setErrors(null);

		let _errors = validate(editingData, Validators);
		setErrors(_errors);
	}, [editingData]);

	const handleSetAdding = () => {
		if (editingData) return;
		setAdding(!adding);
	};

	const handleAdd = addData => {
		let _errors = validate(addData, Validators);
		setErrors(_errors);
		if (_errors) return;
		setData(prev => {
			prev.push(addData);
			onChange('task_plans', [...prev]);
			return [...prev];
		});
		setAdding(false);
	};

	const handleEdit = index => () => {
		if ((editingData && errors) || adding) return;
		setEditingData({ index, ...data[index] });
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setEditingData(prev => ({ ...prev, [name]: value }));
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('task_plans', prev);
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleSave = () => {
		if (errors) return;
		setData(prev => {
			prev[editingData.index] = editingData;
			onChange('task_plans', prev);
			return [...prev];
		});
		setEditingData(null);
	};

	const renderRows = () => (
		data?.map((item, index) => (
			index === editingData?.index
				? <EditingRow
					key={index}
					item={editingData}
					onChange={handleChange}
					onSave={handleSave}
					onDelete={handleDelete(index)}
					errors={errors}
				/>
				: <TableRow key={index}>
					<TableCell>{item.stage_number}</TableCell>
					<TableCell>{formatDate(item.start_date)}</TableCell>
					<TableCell>{formatDate(item.end_date)}</TableCell>
					<TableCell>{item.number_of_months}</TableCell>
					<TableCell>{item.title}</TableCell>
					<TableCell>{planTypes.find(type => type.id === item.plan_type_id)?.name}</TableCell>
					<TableCell>
						{!isPreview &&
							<Box display="flex">
								<EditIconButton
									tooltip={t('Edytuj')}
									onClick={handleEdit(index)}
									disabled={isPreview}
								/>
								<DeleteIconButton
									onClick={handleDelete(index)}
									disabled={isPreview}
									tooltip={t('Usuń')}
								/>
							</Box>
						}
					</TableCell>
				</TableRow>
		)));

	return (
		<>
			<Box display={window.isMobile ? 'block' : 'flex'} alignItems="center" mb={2}>
				{window.isMobile &&
					<Typography variant="h3" style={{ marginBottom: '10px' }}>{t('Plan badawczy')}</Typography>
				}
				<PrimaryButton
					variant="outlined"
					onClick={handleSetAdding}
					disabled={isPreview}
				>
					{adding ? t('Anuluj dodawanie') : t('Dodaj etap')}
				</PrimaryButton>
			</Box>
			<PaginatedTable
				containerClassName={classes.table}
				columns={columns}
				renderRows={renderRows}
				filterRow={
					adding ? <FilterRow errors={errors} onAddClick={handleAdd} index={data.length} /> : <></>
				}
			/>

			{window.isMobile &&
				<Divider className={classes.divider} />
			}
		</>
	);
};

export default TaskPlans;
