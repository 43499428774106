import React, { useContext, useEffect } from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';
import NumberInput from 'components/Form/NumberInput';
import useCheckPermissions from 'utils/useCheckPermissions';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import Table from './Table';

const Expenses = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const canAccept = useCheckPermissions('can_accept_reject_report_b', true);
	const { formData, onChange, errors, isPreview, selectedLanguage } = useContext(ResearchTaskReportBContext);
	const expenses = formData[selectedLanguage].expenses;

	useEffect(() => {
		if(selectedLanguage === 'en') return;
		let eligibleCost = expenses.data.manage_entity_cost?.total_eligible_cost;
		onChange('pl.expenses.manage_entity_total_eligible_cost', eligibleCost);
		onChange('en.expenses.manage_entity_total_eligible_cost', eligibleCost);
	}, [expenses.data.manage_entity_cost?.total_eligible_cost]);

	const handleChange = e => {
		const {name, value} = e.target;

		if (name == 'user_ineligible_costs') {
			onChange(`en.expenses.${name}`, value, null, true);
			onChange(`pl.expenses.${name}`, value, null, true);
		} else {
			onChange(`${selectedLanguage}.expenses.${name}`, value, null, true);
		}
	};

	return (
		<>
			<Typography variant="h4" color="primary" style={{ marginBottom: 16 }}>
				{t('Tabela nr 1 - Wydatki poniesione w okresie sprawozdawczym')}
			</Typography>
			<Table />
			<Divider style={{ marginBlock: 16 }} />
			<NumberInput
				title={t('Poniesione koszty niekwalifikowalne')}
				value={expenses.user_ineligible_costs || 0}
				name="user_ineligible_costs"
				onChange={handleChange}
				inputType="form"
				disabled={isPreview || selectedLanguage === 'en'}
				vertical
			/>
			<Divider style={{ marginBlock: 16 }} />
			<FormInput
				title={t('Adnotacje Podmiotu Zarządzającego')}
				value={expenses.annotations || ''}
				name="annotations"
				onChange={handleChange}
				vertical
				multiline
				minRows={10}
				maxRows={10}
				alignItems="flex-start"
				error={errors?.expenses?.annotations}
				disabled={isPreview || !canAccept}
				inputProps={{ 'aria-required': true }}
			/>
			<NumberInput
				title={t('Koszty uznane przez podmiot zarządzający za kwalifikowalne')}
				value={expenses.manage_entity_total_eligible_cost}
				inputType="form"
				disabled
				vertical
			/>
			<NumberInput
				title={t('Suma kosztów niekwalifikowalnych')}
				value={expenses.ineligible_cost}
				inputType="form"
				disabled
				vertical
			/>

		</>
	);
};

export default Expenses;
