import React, { useEffect, useState, useCallback } from 'react';
import { useHistory, useLocation, useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useAutosave } from 'react-autosave';

import API from 'apis/API';
import Routes from 'router/Routes';
import Progress from 'components/Main/Progress';

const ManagementSettlementsContext = React.createContext(null);

export const Provider = ({ children }) => {
	const history = useHistory();
	const { id } = useParams();
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const [researchTasks, setResearchTasks] = useState([]);
	const [detailedInformation, setDetailedInformation] = useState(null);
	const [formData, setFormData] = useState({
		settlement_date: new Date(),
		date_from: new Date(),
		date_to: new Date(),
		general_settlements: {
			total_amount: 0,
			max_amount: 0,
			running_amount: 0,
			amount_percentage: 0,
			application_number: 0,
			received_amount: 0,
			table1: {
				research_team: {
					col_1: 0,
					col_2: 0,
					col_3: 0
				},
				commercialization: {
					col_1: 0,
					col_2: 0,
					col_3: 0
				},
				management_costs: {
					col_1: 0,
					col_2: 0,
					col_3: 0
				}
			},
			table2: {
				research_team: {
					col_1: 0,
					col_2: 0,
					col_3: 0
				},
				commercialization: {
					col_1: 0,
					col_2: 0,
					col_3: 0
				},
				management_costs: {
					col_1: 0,
					col_2: 0,
					col_3: 0
				}
			}
		},
		general_settlements_comment: '',
		detailed_settlements: [],
		detailed_settlements_comment: '',
		author_name: '',
		filled_date: new Date(),
		status: 'draft',
	});
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			const [researchTasksRes, settlement] = await Promise.all([
				API.management.settlements.getResearchTasks().then(res => res.data?.data || []),
				API.management.settlements.show(id).then(res => res.data?.data)
			]);

			setResearchTasks(researchTasksRes);

			API.management.detailedInformations.show(settlement.information_id).then(res => {
				let _data = res.data?.data;
				setFormData(prev => ({
					...prev,
					..._.pickBy(settlement),
					date_from: _data.date_from,
					date_to: _data.date_to
				}));
				setDetailedInformation(_data);
				setLoading(false);
			});
		};
		if (id) fetchData();
	}, [id]);

	useEffect(() => {
		if (!detailedInformation || loading) return;

		let generalSettlements = { ...formData.general_settlements };

		// table1
		generalSettlements.table1.research_team = getResearchTeamRow('table1');
		generalSettlements.table1.commercialization = getCommercializationRow('table1', 'total_application_measures', 'total_expenses');
		generalSettlements.table1.management_costs = getManagementCostsRow('table1', 'total_application_measures', 'total_expenses');

		generalSettlements.running_amount = generalSettlements.table1.commercialization.col_2 + generalSettlements.table1.management_costs.col_2;
		generalSettlements.amount_percentage = +generalSettlements.running_amount / (+generalSettlements.max_amount || 1);
		generalSettlements.total_amount = _.sumBy(_.keys(generalSettlements.table1), key => +generalSettlements.table1[key].col_1);

		// table 2
		generalSettlements.table2.research_team = getResearchTeamRow('table2');
		generalSettlements.table2.commercialization = getCommercializationRow('table2', 'applied_measures', 'expenditure_incurred');
		generalSettlements.table2.management_costs = getManagementCostsRow('table2','applied_measures', 'expenditure_incurred');

		generalSettlements.received_amount = _.sumBy(_.keys(generalSettlements.table2), key => +generalSettlements.table2[key].col_1);

		onChange('general_settlements', generalSettlements);
	}, [loading,
		formData.general_settlements.max_amount,
		formData.general_settlements.table2.research_team.col_1,
		formData.general_settlements.table2.commercialization.col_1,
		formData.general_settlements.table2.management_costs.col_1
	]);

	const getResearchTeamRow = table => {
		const row = {
			col_1: formData.general_settlements[table].research_team.col_1 || _.sumBy(formData.detailed_settlements, item => +item[table].research_team.col_1),
			col_2: _.sumBy(formData.detailed_settlements, item => +item[table].research_team.col_2),
			col_3: 0
		};
		row.col_3 = getCol3Val(row);
		return row;
	};

	const getCommercializationRow = (table, col1Key, col2Key) => {
		const row = {
			col_1: formData.general_settlements[table].commercialization.col_1 || detailedInformation.table2.including_commercialization[col1Key],
			col_2: detailedInformation.table2.including_commercialization[col2Key],
			col_3: 0
		};
		row.col_3 = getCol3Val(row);
		return row;
	};

	const getManagementCostsRow = (table, col1Key, col2Key) => {
		const row = {
			col_1: formData.general_settlements[table].management_costs.col_1 || (+detailedInformation.table2.financial[col1Key] - +detailedInformation.table2.including_commercialization[col1Key]),
			col_2: +detailedInformation.table2.financial[col2Key] - +detailedInformation.table2.including_commercialization[col2Key],
			col_3: 0
		};
		row.col_3 = getCol3Val(row);
		return row;
	};

	const getCol3Val = data => data.col_2 / (data.col_1 || 1);

	const onChange = (key, value, customizer) => {
		setFormData(prev => ({ ..._.setWith(prev, key, value, customizer) }));
	};

	const onCancel = () => history.push(Routes.Management.List('settlements'));

	const saveCallback = (isPublish = false) => res => {
		setSaving(false);
		if(res.data?.code === 400) {
			toast.error('Nie znaleziono');
			return onCancel();
		}
		if (res.data?.code !== 200 || !_.isEmpty(res.data?.errors)) {
			if (isPublish) {
				toast.error('Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.');
			} else {
				toast.success('Wersja robocza została zapisana!');
				if (!id) return onCancel();
			}
			setErrors(prev => {
				prev = {};
				_.mapKeys(res.data?.errors, (value, key) => {
					_.set(prev, key, value);
				});
				return prev;
			});

			return;
		}

		if (isPublish) {
			onCancel();
		}

		setErrors(null);
		toast.success('Zadanie badawcze zostało zapisane!');
	};

	const onSave = () => {
		if (saving || isPreview) return;
		setSaving(true);
		API.management.settlements.update(formData, id).then(saveCallback());
	};

	const onPublish = () => {
		if (saving || isPreview) return;
		setSaving(true);
		API.management.settlements.publish(formData, id).then(saveCallback(true));
	};

	const onAutoSave = useCallback((data, creating) => {
		if (saving || isPreview || data?.status === 'published' || (!id && !creating)) return;
		setSaving(true);
		(id ? API.management.settlements.update : API.management.settlements.store)(data, id).then(res => {
			setSaving(false);
			if (res.data?.code !== 200 || id) return;
			history.push(Routes.Management.Settlements.Edit(res.data?.data?.id));
		});
	}, []);

	useAutosave({ data: formData, onSave: onAutoSave, interval: 300000, saveOnUnmount: false });

	// When creating new row, save empty data and edit it.
	useEffect(() => {
		if (id) return;
		onAutoSave(formData, true);
	}, []);

	const value = {
		researchTasks,
		detailedInformation,
		formData,
		errors,
		saving,
		loading,
		isPreview,
		onChange,
		onSave,
		onAutoSave,
		onPublish,
		onCancel,
	};

	return (
		<ManagementSettlementsContext.Provider value={value}>
			{loading
				? <Progress status={true} />
				: children}
		</ManagementSettlementsContext.Provider>
	);
};

export const ManagementSettlementsContextProvider = withRouter(Provider);

export default ManagementSettlementsContext;
