import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';
import _ from 'lodash';
import PaginatedTable from 'components/PaginatedTable';
import IpCardContext from 'context/IpCardContext';
import { EditIconButton, DeleteIconButton, CollapseCircleIconButton, CheckRedIconButton, ExpandCircleIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import FilterRow from './FilterRow';
import Detail from './Detail';
import Form from './Form';
import useCheckPermissions from 'utils/useCheckPermissions';
import { useTranslation } from 'react-i18next';

const Others = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const { selectedLanguage, formData, onChange } = useContext(IpCardContext);
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const [data, setData] = useState(formData.ip_card_background_others);
	const [expand, setExpand] = useState(false);
	const [editingData, setEditingData] = useState(null);
	const [showForm, setShowForm] = useState(false);

	const classes = useStyles();
	const columns = [
		{ title: t('LP.'), width: 70, disableSort: true },
		{ title: t('Typ'), name: 'type', disableSort: true },
		{ title: t('Rodzaj narzędzia'), name: 'type_of_tool', disableSort: true },
		{ title: t('Forma prawna IP'), name: 'legal_form', disableSort: true },
		{ title: t('Nazwa'), name: 'name', disableSort: true },
		{ title: t('Źródło IP'), name: 'ip_source', disableSort: true },
		{ title: t('Dane właściciela'), name: 'owner', disableSort: true },
		{ title: t('AKCJE'), name: 'action', width: 100, disableSort: true },
	];

	const handleClickReadMore = (index) => {
		setExpand(index);
		setEditingData(null);
	};

	const handleEdit = index => {
		setExpand(null);
		setEditingData({ ...data[index], index });
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('ip_card_background_others', prev);
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleChangeFilters = filters => {
		Object.keys(filters).forEach(key => {
			if (!filters[key]) delete filters[key];
		});
		let filtered = formData.ip_card_background_others?.filter(item => (
			_.keys(filters).every(key => {
				if (key === 'legal_form' || key === 'name' || key === 'ip_source') {
					return item[key]?.[selectedLanguage]?.toString()?.toLowerCase()?.indexOf(filters[key]?.toString()?.toLowerCase()) > -1;
				} else {
					return item[key]?.toString()?.toLowerCase()?.indexOf(filters[key]?.toString()?.toLowerCase()) > -1;
				}
			})
		));

		setData(filtered);
	};

	const renderRows = () => (
		data.map((row, index) => (
			<React.Fragment key={index}>
				<TableRow>
					<TableCell>{index + 1}</TableCell>
					<TableCell>{t(row.type)}</TableCell>
					<TableCell>{row.type_of_tool}</TableCell>
					<TableCell>{(selectedLanguage === 'en' && row.legal_form?.[selectedLanguage] === null) ? row.legal_form?.pl : row.legal_form?.[selectedLanguage]}</TableCell>
					<TableCell>{(selectedLanguage === 'en' && row.name?.[selectedLanguage] === null) ? row.name?.pl : row.name?.[selectedLanguage]}</TableCell>
					<TableCell>{(selectedLanguage === 'en' && row.ip_source?.[selectedLanguage] === null) ? row.ip_source?.pl : row.ip_source?.[selectedLanguage]}</TableCell>
					<TableCell>{typeof(row.owner) === 'string' ? row.owner : (selectedLanguage === 'en' && row.owner[selectedLanguage] === null) ? row.owner.pl : row.owner[selectedLanguage]}</TableCell>
					<TableCell>
						<Box display="flex">
							{expand !== index
								? <ExpandCircleIconButton tooltip="Rozwiń" onClick={() => handleClickReadMore(index)} />
								: <CollapseCircleIconButton tooltip="Zwiń" onClick={() => handleClickReadMore(null)} />
							}
							{!isPreview && canCreateIpCard &&
								<>
									{editingData?.index !== index
										? <EditIconButton tooltip="Edytuj" onClick={() => handleEdit(index)} />
										: <CheckRedIconButton tooltip="Zatwierdź" />
									}
									<DeleteIconButton
										tooltip="Usuń"
										title="Czy jesteś pewien, że chcesz usunąć ten wpis"
										onClick={handleDelete(index)}
									/>
								</>
							}
						</Box>
					</TableCell>
				</TableRow>

				{expand === index &&
					<TableRow>
						<TableCell colSpan={8}>
							<Detail row={row} />
						</TableCell>
					</TableRow>
				}

				{editingData?.index === index &&
					<TableRow>
						<TableCell colSpan={8}>
							<div id="others-table">
								<Form onSave={handleSave} values={editingData} />
							</div>
						</TableCell>
					</TableRow>
				}
			</React.Fragment >
		))
	);

	const handleSave = (item) => {
		if (_.isNull(item.index)) {
			const newItem = { ...item, index: data.length };
			setData(prev => {
				const arr = [...prev, newItem];
				onChange('ip_card_background_others', arr);
				return arr;
			});

			setShowForm(false);
		} else {
			const { index } = editingData;
			setData(prev => {
				prev[editingData.index] = { index, ...item };
				onChange('ip_card_background_others', prev);
				return [...prev];
			});
			setEditingData(null);
		}
	};

	useEffect(() => {
		setData(formData.ip_card_background_others);
	}, [formData.ip_card_background_others]);

	return (
		<Box>
			{formData.ip_card_background?.other_ip_requires_additional_rights &&
				<>
					<FilterRow onChange={handleChangeFilters} />
					<PaginatedTable
						columns={columns}
						renderRows={renderRows}
						containerClassName={classes.table}
					/>
				</>
			}

			{!showForm && formData.ip_card_background?.other_ip_requires_additional_rights && !isPreview && canCreateIpCard &&
				<PrimaryButton
					onClick={() => setShowForm(!showForm)}
					disabled={selectedLanguage === 'en'}
				>
					{t('Dodaj inne IP')}
				</PrimaryButton>
			}

			{showForm && !isPreview && canCreateIpCard &&
				<div id="others-form">
					<Form onSave={handleSave} />
				</div>
			}
		</Box>
	);
};

const useStyles = makeStyles(() => ({
	table: {
		paddingTop: 0,
		paddingLeft: 0,
		paddingRight: 0
	}
}));

export default Others;
