import React, { useState } from 'react';
import { TableCell, TableRow } from '@material-ui/core';

import Validator, { Required, validate } from 'utils/Validator';
import IconButton from 'components/Buttons/IconButtons';
import BaseInput from 'components/Form/BaseInput';
import { CircleCheckIcon } from 'assets/svg';

const EditingRow = ({ item, onSave }) => {
	const [data, setData] = useState(item);
	const [errors, setErrors] = useState(null);
	const Validators = {
		en: new Validator(Required),
		pl: new Validator(Required),
	};

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	const handleSave = () => {
		let _errors = validate(data, Validators);
		setErrors(_errors);
		if (_errors) return;

		onSave(data);
	};

	return (
		<TableRow>
			<TableCell>{data.module}</TableCell>
			{['pl', 'en'].map((key, i) => (
				<TableCell key={i}>
					<BaseInput
						name={key}
						value={data[key]}
						onChange={handleChange}
						fullWidth
						error={errors?.[key]}
						hideErrorText
						inputProps={{'aria-required': true}}
					/>
				</TableCell>
			))}
			<TableCell>
				<IconButton onClick={handleSave}>
					<CircleCheckIcon />
				</IconButton>
			</TableCell>
		</TableRow>
	);
};

export default EditingRow;
