import React from 'react';
import { Box, makeStyles, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import Table from './Table';

const useStyles = makeStyles(theme => ({
	divider: {
		marginBlock: theme.spacing(2),
	},
}));

const CostSummary = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();

	return (
		<Box width="100%">
			{window.isMobile &&
				<Typography variant="h3" style={{ marginBottom: '10px' }}>{t('Kosztorys zadania badawczego')}</Typography>
			}

			<Typography style={{ marginBottom: 30 }}>
				{t('Kosztorys sumaryczny według kategorii wydatków w podziale na Podmiot Lidera (Wnioskodawcy) i Inne Jednostki (Konsorcjantów)')}
			</Typography>

			<Table />

			{window.isMobile &&
				<Divider className={classes.divider} />
			}
		</Box>
	);
};

export default CostSummary;