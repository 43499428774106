import React, { useEffect, useState } from 'react';
import { makeStyles, Tabs as MuiTabs, Tab, Box } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
	tabs: {
		marginTop: theme.spacing(2),
		'& .MuiTabs-flexContainer': {
			justifyContent: 'flex-end',
			borderBottom: '1px solid #D0D0D0',
			paddingBottom: 10,
			display: window.isMobile ? 'block' : 'flex',
			'& .MuiButtonBase-root': {
				display: window.isMobile ? 'block' : 'flex',
				paddingLeft: window.isMobile && 0
			}
		},
		'& .MuiTab-root': {
			minWidth: 0,
			maxWidth: 'unset',
			textTransform: 'none',
			'&.Mui-selected': {
				'&::before': {
					content: '""',
					position: 'absolute',
					bottom: 5,
					left: window.isMobile ? 0 : 13,
					width: window.isMobile ? '100%' : 25,
					height: window.isMobile ? 2 : 3,
					transform: 'translate(0, -50%)',
					background: theme.palette.secondary.main,
					borderRadius: '0px 3px 3px 0px'
				}
			},
			'&.MuiTab-textColorSecondary.Mui-disabled': {
				color: 'black'
			}
		},
		'& .MuiTabs-indicator': {
			display: 'none',
		},
	},
	section: {
		'& .MuiTabPanel-root': {
			padding: 0,
		},
	},
	title: {
		marginRight: 'auto',
		fontSize: window.isMobile ? 20 : 28,
		fontStyle: 'normal',
		fontFamily: 'Ubuntu',
		fontWeight: 'bold',
		lineHeight: 1,
		color: '#000',
		paddingLeft: 0,
		textAlign: 'left'
	}
}));

const Tabs = props => {
	const search = useLocation().search;
	const tabType = new URLSearchParams(search).get('tab');

	const classes = useStyles();
	const { tabs, className, variant = 'standard' } = props;

	const [tab, setTab] = useState(tabType || tabs[0].value);
	const [header, setHeader] = useState('');

	const handleTabChange = (e, newVal) => setTab(newVal);

	useEffect(() => {
		const oTab = tabs.find(t => t.value === tab)?.title;
		setHeader(oTab);
	}, [tab]);

	return (
		<TabContext value={tab}>
			<MuiTabs
				className={clsx(classes.tabs, className)}
				textColor="secondary"
				value={tab}
				onChange={handleTabChange}
				variant={variant}
			>
				<Tab label={header} className={classes.title} disabled />

				{tabs.map((t, i) => !t.disable && <Tab key={i} label={t.label} value={t.value} />)}
			</MuiTabs>
			<Box className={classes.section}>
				{tabs.map((tab, i) => <TabPanel key={i} value={tab.value}>{tab.children}</TabPanel>)}
			</Box>
		</TabContext>
	);
};

export default Tabs;
