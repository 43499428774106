import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, makeStyles, TableCell, TableRow } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import { formatDate } from 'utils/formatters';
import IconButton, { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import Progress from 'components/Main/Progress';
import PaginatedTable from 'components/PaginatedTable';
import useCheckPermissions from 'utils/useCheckPermissions';
import { VisibleIcon } from 'assets/svg';

import FilterRow from './FilterRow';

const useStyles = makeStyles(() => ({
	table: {
		paddingInline: 0,
	}
}));

const Settlements = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - rozliczenie' });
	const canView = useCheckPermissions('can_view_finances', true);
	const canManage = useCheckPermissions('can_manage_finances', true);
	const columns = [
		{ title: t('Rozliczenie z dnia'), name: 'settlement_date', width: 300 },
		{ title: t('Zakres od'), name: 'date_from', width: 300 },
		{ title: t('Za okres do'), name: 'date_to' },
		{ title: t('Status'), name: 'status' },
		{ title: t('Suma kontrolna'), name: 'controlsum' },
		{
			title: t('Akcje'),
			name: 'action',
			width: canManage ? 100 : 50,
			disableSort: true
		},
	];

	const [loading, setLoading] = useState(false);
	const [params, setParams] = useState({
		page: 1,
		column: 'date_to',
		direction: 'desc',
		filters: {}
	});
	const [totalPagesCount, setTotalPagesCount] = useState(1);
	const [data, setData] = useState(null);

	useEffect(() => loadFilteredData(), [params]);

	const handleChangeParams = _params => {
		const picked = _.pick(params, ['column', 'direction', 'page']);
		if (_.isEqual(picked, _params)) return;

		setParams({ ...params, ..._params });
	};

	const handleChangeFilters = filters => {
		setParams(prev => {
			if (_.isEqual(prev.filters, _.pickBy(filters))) return prev;

			return { ...prev, filters };
		});
	};

	const loadFilteredData = () => {
		if (loading) return;
		setLoading(true);
		API.management.settlements.index(params).then(res => {
			setLoading(false);
			setData(res.data?.data);
			setTotalPagesCount(res.data?.last_page);
		});
	};

	const handleDelete = id => () => {
		API.management.settlements.delete(id).then(res => {
			if (res.data?.code === 200) {
				loadFilteredData();
				return toast.success(t('Zadanie badawcze zostało usunięte!'));
			}

			toast.error(t('Nie znaleziono zadanie badawcze'));
		});
	};

	const renderRows = () => (
		data.map((row, index) => (
			<TableRow key={index}>
				<TableCell>{formatDate(row.settlement_date)}</TableCell>
				<TableCell>{formatDate(row.date_from)}</TableCell>
				<TableCell>{formatDate(row.date_to)}</TableCell>
				<TableCell>{row.status === 'published' ? t('Zapisany') : t('Szkic')}</TableCell>
				<TableCell>{row.controlsum}</TableCell>
				<TableCell>
					<Box display="flex">
						{canView &&
							<Link to={Routes.Management.Settlements.Edit(row.id, true)}>
								<IconButton tooltip={t('Podgląd')}>
									<VisibleIcon alt="Ikona podglądu wiersza" />
								</IconButton>
							</Link>
						}
						{canManage &&
							<>
								<Link to={Routes.Management.Settlements.Edit(row.id)}>
									<EditIconButton tooltip={t('Edytuj rozliczenie')} />
								</Link>
								<DeleteIconButton tooltip={t('Usuń')} onClick={handleDelete(row.id)} />
							</>
						}
					</Box>
				</TableCell>
			</TableRow>
		))
	);

	return (
		<>
			{!data
				? <Progress status={true} />
				: <PaginatedTable
					containerClassName={classes.table}
					columns={columns}
					exportEndpoint={API.management.settlements.exportList}
					filename="Management"
					totalPagesCount={totalPagesCount}
					onChangeFilters={handleChangeParams}
					defaultFilters={_.pick(params, ['column', 'direction', 'page'])}
					renderRows={renderRows}
					filterRow={<FilterRow onChange={handleChangeFilters} />}
				/>
			}
		</>
	);
};

export default Settlements;
