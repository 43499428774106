import React, { useState } from 'react';
import IconButton from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const FilterRow = ({ onAddClick, errors }) => {

	const [addData, setAddData] = useState({
		title: '',
		publication_date: null,
		authors: [{ name: '', role: '' }]
	});

	const handleChange = e => {
		const { name, value } = e.target;

		setAddData(prev => {
			if (name === 'name' || name === 'role') {
				let authors = prev.authors;
				authors[0][name] = value;
				return { ...prev, authors };
			}

			return { ...prev, [name]: value };
		});
	};

	return (
		<Row
			onChange={handleChange}
			values={addData}
			errors={errors}
			lastColumn={
				<IconButton tooltip="Zatwierdź" onClick={() => onAddClick(addData)}>
					<CircleCheckIcon />
				</IconButton>
			}
		/>
	);
};

export default FilterRow;
