import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import IconButton, {
	DeleteIconButton,
	ExpandCircleIconButton,
} from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';
import Row from './Row';

const EditingRow = ({ model, item, onChange, onSave, onDelete, errors, options, option_label, option_name, table, handleClickReadMore }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Wniosek' });
	return (
		<Row
			model={model}
			options={options}
			option_name={option_name}
			option_label={option_label}
			onChange={onChange}
			values={item}
			errors={errors}
			lastColumn={
				<Box display="flex">
					<ExpandCircleIconButton
						tooltip={t('Rozwiń')}
						onClick={() => handleClickReadMore(item?.index, table)}
					/>
					<IconButton onClick={onSave} tooltip={t('Zatwierdź')}>
						<CircleCheckIcon />
					</IconButton>
					<DeleteIconButton hideModal onClick={onDelete} tooltip={t('Usuń')} />
				</Box>
			}
		/>
	);
};

export default EditingRow;
