import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
	makeStyles,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import NumberInput from 'components/Form/NumberInput';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import Typography from 'components/Main/Typography';
import ResearchTaskContext from 'context/ResearchTaskContext';

const useStyles = makeStyles(() => ({
	totalCell: {
		background: '#EEEEEE',
	},
}));

const YearTable = ({ year }) => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const columns = ['I Q', 'II Q', 'III Q', 'IV Q', t('Razem')];
	const { formData, onChange } = useContext(ResearchTaskContext);
	const yearData = useMemo(() => formData.payments_schedule?.values?.[year], [formData.payments_schedule?.values]);
	const [totalValue, setTotalValue] = useState(yearData?.total?.value || 0);
	const [values, setValues] = useState({});

	useEffect(() => {
		setValues(_.isEmpty(yearData?.values) ? { 1: 0, 2: 0, 3: 0, 4: 0 } : yearData?.values);
	}, [year]);

	useEffect(() => {
		const total = _.sumBy(_.keys(values), key => +values[key]);
		setTotalValue(total);
		if (!year) return;
		const formattedValues = _.mapValues(values, val => +val);
		onChange(`payments_schedule.values.${year}`, { total, values: formattedValues }, Object);
	}, [values]);

	const handleChange = editingQuarter => e => {
		setValues(prev => {
			prev[editingQuarter] = e.target.value;
			return { ...prev };
		});
	};

	return (
		<Table>
			<TableHead>
				<TableRow>
					<TableCell>
						<TableLabel>{t('Rok')}</TableLabel>
					</TableCell>
					<TableCell colSpan={4}>
						<TableLabel>{year}</TableLabel>
					</TableCell>
				</TableRow>
				<TableRow>
					{columns.map((col, i) => (
						<TableCell key={i}>
							<Typography variant={i === columns.length - 1 ? 'tableLabel' : 'inputLabel'}>
								{col}
							</Typography>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
			<TableBody>
				<TableRow>
					{_.keys(values)?.map(key => (
						<TableCell key={key} width="20%" >
							<NumberInput
								value={values[key]}
								onChange={handleChange(key)}
								disabled={isPreview}
							/>
						</TableCell>
					))}
					<TableCell width="20%" classes={{ root: classes.totalCell }}>
						<NumberText fixedDecimalScale={true} value={totalValue} />
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};

export default YearTable;
