import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';
import { Grid, makeStyles } from '@material-ui/core';
import IpCardContext from 'context/IpCardContext';
import Select from 'components/Form/Select';
import { useTranslation } from 'react-i18next';

const OtherIpsForm = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';

	const { selectedLanguage, onChange, formData, errors } = useContext(IpCardContext);
	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });
	const [data, setData] = useState(formData.ip_card_background);
	const classes = useStyles();

	const booleanOptions = [
		{ value: true, label: t('Tak') },
		{ value: false, label: t('Nie') },
	];

	const handleChange = (e, target = null) => {
		const { name, value } = e.target;
		if (target !== null && target === 'select') {
			setData(prev => ({ ...prev, [name]: value }));
		} else {
			let prevData = {...data};
			prevData[name][selectedLanguage] = value;
			setData(() => ({ ...prevData }));
		}
	};

	useEffect(() => {
		setData(formData.ip_card_background);
	}, [formData.ip_card_background]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => onChange('ip_card_background', data), [data]);

	return (
		<Grid container>
			<Grid item xs={window.isMobile ? 12 : 2} style={{ marginRight: 100 }}>
				<Typography variant="h3">
					{t('inne IP')}
				</Typography>
			</Grid>

			<Grid item xs={window.isMobile ? 12 : 7}>
				<Grid item xs={window.isMobile ? 12 : 11}>
					<Select
						name="other_ip_requires_additional_rights"
						title={t('background_ip_inne_flag')}
						options={booleanOptions}
						value={data.other_ip_requires_additional_rights}
						onChange={e => handleChange(e, 'select')}
						vertical={true}
						error={errors?.ip_card_background?.other_ip_requires_additional_rights}
						disabled={isPreview || selectedLanguage === 'en'}
					/>
					{data.other_ip_requires_additional_rights &&
						<>
							<FormInput
								titleClassName={classes.fontWeight600}
								title={t('Opis')}
								name="other_ip_requires_additional_rights_description"
								value={(selectedLanguage === 'en' && data.other_ip_requires_additional_rights_description?.[selectedLanguage] === null) ? data.other_ip_requires_additional_rights_description?.pl : data.other_ip_requires_additional_rights_description?.[selectedLanguage]}
								onChange={handleChange}
								vertical={true}
								multiline={true}
								rows={6}
								error={errors?.ip_card_background?.other_ip_requires_additional_rights_description}
								disabled={isPreview}
							/>
							<FormInput
								titleClassName={classes.fontWeight600}
								title={t('W jakiej formie prawnej (licencja, sprzedaż praw, inne) inne IP zostanie formalnie udostępnione przez Właściciela do realizacji Zadania Badawczego?')}
								name="other_ip_legal_form"
								value={(selectedLanguage === 'en' && data.other_ip_legal_form?.[selectedLanguage] === null)? data.other_ip_legal_form?.pl : data.other_ip_legal_form?.[selectedLanguage]}
								onChange={handleChange}
								vertical={true}
								multiline={true}
								rows={6}
								error={errors?.ip_card_background?.other_ip_legal_form}
								disabled={isPreview}
							/>
							<FormInput
								titleClassName={classes.fontWeight600}
								title={t('Czy inne IP będzie stanowiło przeszkodę w Komercjalizacji efektów Zadania Badawczego?')}
								name="other_ip_obstacles"
								value={(selectedLanguage === 'en' && data.other_ip_obstacles?.[selectedLanguage] === null)? data.other_ip_obstacles?.pl : data.other_ip_obstacles?.[selectedLanguage]}
								onChange={handleChange}
								vertical={true}
								multiline={true}
								rows={6}
								error={errors?.ip_card_background?.other_ip_obstacles}
								disabled={isPreview}
							/>
						</>
					}
				</Grid>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles(() => ({
	fontWeight600: {
		fontWeight: 600
	}
}));

export default OtherIpsForm;
