import React, { useEffect, useState, useCallback } from 'react';
import _debounce from 'lodash/debounce';

import API from 'apis/API';
import useCheckPermissions from 'utils/useCheckPermissions';

const FolderContext = React.createContext(null);

export const FolderContextProvider = ({ children }) => {
	const defaultFilter = {
		search: '',
		type: '',
		sort: ''
	};

	const [foldersList, setFoldersList] = useState([]);
	const [filesList, setFilesList] = useState([]);
	const [folderId, setFolderId] = useState(null);
	const [preFolderId, setPreFolderId] = useState(null);
	const [folder, setFolder] = useState(null);
	const [filters, setFilters] = useState(defaultFilter);
	const [filterStatus, setFilterStatus] = useState(false);
	const [foldersListWithoutChildren, setFoldersListWithoutChildren] = useState(null);
	const [foldersForBreadcrumbs, setFoldersForBreadcrumbs] = useState([]);
	const [roles, setRoles] = useState([]);
	const [users, setUsers] = useState([]);
	const [expanded, setExpanded] = useState([]);

	const canShareFolders = useCheckPermissions('can_share_folders', true);

	const loadFilteredFolders = (search) => {
		API.folders.index({ search }).then(res => {
			if (res.data?.code === 200) {
				setFoldersList(res.data.data);
			}
		});
	};

	const fetchFoldersAndFiles = (folderId, filters, force = false) => {
		if (preFolderId === folderId && !force) return;
		setPreFolderId(folderId);
		setFolderId(folderId);

		API.folders.fetchSubfoldersAndFiles(folderId, filters).then(res => {
			if (res?.data?.code === 200) {
				setFilesList(res.data.data);
				setFilterStatus(res.data.search);
			}
		});
	};

	// create folders array without children
	const arrangeFolders = (folders, result = {}) => {
		folders?.forEach(folder => {
			const { children, ...other } = folder;
			result[folder.id] = other;
			if (children) {
				arrangeFolders(children, result);
			}
		});

		return result;
	};

	// set target folder to parent one to generate breadcrumbs
	const setBreadcrumbsArr = (obj) => {
		let res = [];
		if (obj) {
			for (; ;) {
				res.push(obj);
				if (obj?.parent_id === null) break;
				obj = foldersListWithoutChildren[obj?.parent_id];
			}
			res.reverse();
		}

		setFoldersForBreadcrumbs(res);
	};

	useEffect(() => {
		loadFilteredFolders();
		fetchFoldersAndFiles();

		if (canShareFolders) {
			API.roles.all().then(res => {
				setRoles(res?.data?.data);
			});
			API.users.all().then(res => {
				setUsers(res?.data?.data);
			});
		}
	}, []);

	useEffect(() => {
		const list = arrangeFolders(foldersList);

		setFoldersListWithoutChildren(list);
	}, [foldersList]);

	const debouncedChangeHandler = useCallback(_debounce((folderId, filters) => fetchFoldersAndFiles(folderId, filters), 500), []);

	return (
		<FolderContext.Provider value={{
			foldersList,
			filesList,
			folderId,
			filters,
			filterStatus,
			foldersListWithoutChildren,
			foldersForBreadcrumbs,
			roles,
			users,
			expanded,
			folder,
			setFolder,
			setExpanded,
			setBreadcrumbsArr,
			setFilters,
			setFolderId,
			setFilesList,
			loadFilteredFolders,
			fetchFoldersAndFiles,
			debouncedChangeHandler,
		}}>
			{children}
		</FolderContext.Provider>
	);
};

export default FolderContext;
