import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import Table from './Table';

const PaymentSchedule = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	return (
		<Box width="100%" mb={2}>
			<Typography style={{ marginBottom: 30 }}>
				{t('II. Zmiana harmonogramu płatności wymagająca pisemnej zgody Podmiotu Zarządzającego ponieważ zmiana ta jest uzależniona od dostępności środków')}
			</Typography>
			<Typography variant="subtitle2" style={{ marginBottom: 30 }}>
				{t('Harmonogram płatności - zapotrzebowanie na środki finansowe (po zmianie)')}
			</Typography>

			<Table />
		</Box>
	);
};

export default PaymentSchedule;