import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { toast } from 'react-toastify';
import Typography from 'components/Main/Typography';
import { DownloadIcon } from 'assets/svg';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import API from 'apis/API';

const useStyles = makeStyles((theme) => ({
	button: {
		paddingBlock: theme.spacing(0.5),
		marginRight: theme.spacing(1),
		background: theme.palette.white,
	},
}));

const Exports = ({ fileName, tab, locale }) => {
	const { id } = useParams();
	const classes = useStyles();
	const [isExporting, setIsExporting] = useState({});

	const exportXlsx = async (type) => {
		setIsExporting((prev) => ({ ...prev, [type]: true }));
		try {
			const res = await API.ipCards.export(type, id, tab, locale);

			// If you want to download file automatically using link attribute.
			const link = document.createElement('a');
			const url = URL.createObjectURL(new Blob([res.data]));
			link.href = url;
			link.setAttribute(
				'download',
				`${fileName || 'Funding request Form'}.${type}`
			);
			document.body.appendChild(link);
			link.click();
			link.remove();
		} catch (err) {
			toast.error('Sorry, something went wrong.');
		}
		setIsExporting((prev) => ({ ...prev, [type]: false }));
	};

	return (
		<Box
			display={window.isMobile ? 'block' : 'flex'}
			alignItems="center"
			mt={4}
		>
			<Typography variant="bodyS" style={{ marginRight: 10 }}>
				Generuj:
			</Typography>

			<PrimaryButton
				variant="outlined"
				startIcon={<DownloadIcon />}
				className={classes.button}
				onClick={() => exportXlsx('pdf')}
				disabled={Boolean(isExporting?.pdf)}
			>
				pdf
			</PrimaryButton>
			<PrimaryButton
				variant="outlined"
				startIcon={<DownloadIcon />}
				className={classes.button}
				onClick={() => exportXlsx('xlsx')}
				disabled={Boolean(isExporting?.xlsx)}
			>
				xlsx
			</PrimaryButton>
		</Box>
	);
};

export default Exports;
