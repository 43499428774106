import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordions from 'components/Main/Accordions';

import Results from './Results';
import CommercializationPotential from './CommercializationPotential';
import FinalInfo from './FinalInfo';

const FinalInfoTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const items = [
		{ title: t('1. Wyniki o charakterze aplikacyjnym'), child: <Results />, defaultExpanded: true },
		{ title: t('2. Potencjał komercjalizacyjny'), child: <CommercializationPotential /> },
		{ title: t('3. Informacje Końcowe'), child: <FinalInfo /> },
	];

	return (
		<Accordions items={items} />
	);
};

export default FinalInfoTab;