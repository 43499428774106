import React, { useEffect, useState } from 'react';
import { Box, TableCell, TableRow, makeStyles } from '@material-ui/core';

import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import {
	CheckRedIconButton,
	SaveIconButton,
	CollapseCircleIconButton,
	DeleteIconButton,
	ExpandCircleIconButton
} from 'components/Buttons/IconButtons';

const EditingRow = ({ row, order, expandIndex, handleClickReadMore, onSave, authors }) => {

	const [model, setModel] = useState({
		index: null,
		subentries: []
	});

	const [subentry, setSubentry] = useState({
		payment_period_from: '',
		payment_period_to: '',
		payment_amount: 0
	});
	const classes = useStyles();

	const handleChange = (index) => (e) => {
		const { name, value } = e.target;

		setModel(prev => {
			const subentries = [...prev.subentries];
			subentries[index][name] = value;
			return { ...prev, subentries };
		});
	};

	const handleDeleteSubentry = index => () => {
		setModel(prev => {
			prev.subentries.splice(index, 1);
			return { ...prev };
		});
	};

	const handleChangeAddedSubentry = e => {
		setSubentry(prev => {
			return { ...prev, [e.target.name]: e.target.value };
		});
	};

	const handleAddSubentry = () => {
		setModel(prev => {
			return { ...prev, subentries: [...prev.subentries, subentry] };
		});
		setSubentry({
			payment_period_from: '',
			payment_period_to: '',
			payment_amount: 0
		});
	};

	const handleSave = () => {
		onSave(model);
	};

	useEffect(() => {
		setModel(JSON.parse(JSON.stringify(row)));
	}, [row]);

	useEffect(() => {
		if (!model) {
			return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [model]);

	return (
		<>
			<TableRow>
				<TableCell rowSpan={(model.subentries?.length + 1) || 1}>{order}</TableCell>
				<TableCell rowSpan={(model.subentries?.length + 1) || 1}>
					{authors?.find(author => author.id === model?.author_id)?.name}
				</TableCell>
				<TableCell rowSpan={(model.subentries?.length + 1) || 1}>
					{authors?.find(author => author.id === model?.author_id)?.percent_of_participation}
				</TableCell>
				{model.subentries?.length === 0
					? <>
						<TableCell></TableCell>
						<TableCell>
							<DateInput
								fullWidth
								name="payment_period_from"
								value={subentry.payment_period_from || null}
								onChange={handleChangeAddedSubentry}
								hideErrorText
								gutterBottom={false}
							/>
						</TableCell>
						<TableCell>
							<DateInput
								fullWidth
								name="payment_period_to"
								value={subentry.payment_period_to || null}
								onChange={handleChangeAddedSubentry}
								hideErrorText
								gutterBottom={false}
							/>
						</TableCell>
						<TableCell>
							<Box display="flex">
								<BaseInput
									hideErrorText
									fullWidth
									type="number"
									placeholder="Wpisz kwotę"
									name="payment_amount"
									value={subentry.payment_amount}
									onChange={handleChangeAddedSubentry}
								/>
								<SaveIconButton tooltip="Zatwierdź" style={{ marginLeft: 15 }} onClick={handleAddSubentry} />
							</Box>
						</TableCell>
					</>
					: <>
						<TableCell>
							{model.subentries?.[0] ? 1 : ''}
						</TableCell>
						<TableCell>
							<DateInput
								fullWidth
								name="payment_period_from"
								value={model.subentries?.[0]?.payment_period_from || null}
								onChange={handleChange(0)}
								hideErrorText
								gutterBottom={false}
							/>
						</TableCell>
						<TableCell>
							<DateInput
								fullWidth
								name="payment_period_to"
								value={model.subentries?.[0]?.payment_period_to || null}
								onChange={handleChange(0)}
								hideErrorText
								gutterBottom={false}
							/>
						</TableCell>
						<TableCell>
							<Box display="flex">
								<BaseInput
									hideErrorText
									fullWidth
									type="number"
									placeholder="Wpisz kwotę"
									name="payment_amount"
									value={model.subentries?.[0]?.payment_amount}
									onChange={handleChange(0)}
								/>
								<DeleteIconButton tooltip="Usuń" hideModal style={{ marginLeft: 15 }} onClick={handleDeleteSubentry(0)} />
							</Box>
						</TableCell>
					</>
				}


				<TableCell rowSpan={(model.subentries?.length + 1) || 1}>
					{
						model?.subentries?.map(s => s.payment_amount).reduce((accumVariable, curValue) => {
							return Number(accumVariable) + Number(curValue);
						}, 0)
					}
				</TableCell>


				<TableCell rowSpan={(model.subentries?.length + 1) || 1}>
					<Box display="flex">
						{expandIndex !== row?.index
							? <ExpandCircleIconButton
								disabled={row.subentries?.length < 2}
								component={row.subentries?.length < 2 ? 'div' : undefined}
								className={row.subentries?.length < 2 ? classes.disabled : ''}
								tooltip="Rozwiń"
								onClick={() => handleClickReadMore(row?.index)}
							/>
							: <CollapseCircleIconButton
								tooltip="Zwiń"
								onClick={() => handleClickReadMore(null)}
							/>
						}
						<CheckRedIconButton tooltip="Zatwierdź" onClick={handleSave} />
					</Box>
				</TableCell>
			</TableRow>

			{model.subentries?.length > 1 &&
				model.subentries?.slice(1).map((entry, entryIndex) => (
					<TableRow key={entryIndex + '_entry'}>
						<TableCell>{entryIndex + 2}</TableCell>
						<TableCell>
							<DateInput
								fullWidth
								name="payment_period_from"
								value={entry.payment_period_from || null}
								onChange={handleChange(entryIndex + 1)}
								hideErrorText
								gutterBottom={false}
							/>
						</TableCell>
						<TableCell>
							<DateInput
								fullWidth
								name="payment_period_to"
								value={entry.payment_period_to || null}
								onChange={handleChange(entryIndex + 1)}
								hideErrorText
								gutterBottom={false}
							/>
						</TableCell>
						<TableCell>
							<Box display="flex">
								<BaseInput
									hideErrorText
									fullWidth
									type="number"
									placeholder="Wpisz kwotę"
									name="payment_amount"
									value={entry.payment_amount}
									onChange={handleChange(entryIndex + 1)}
								/>
								<DeleteIconButton tooltip="Usuń" hideModal style={{ marginLeft: 15 }} onClick={handleDeleteSubentry(entryIndex + 1)} />
							</Box>
						</TableCell>
					</TableRow>
				))
			}

			{model.subentries?.length > 0 &&
				<TableRow>
					<TableCell></TableCell>
					<TableCell>
						<DateInput
							fullWidth
							name="payment_period_from"
							value={subentry.payment_period_from || null}
							onChange={handleChangeAddedSubentry}
							hideErrorText
							gutterBottom={false}
						/>
					</TableCell>
					<TableCell>
						<DateInput
							fullWidth
							name="payment_period_to"
							value={subentry.payment_period_to || null}
							onChange={handleChangeAddedSubentry}
							hideErrorText
							gutterBottom={false}
						/>
					</TableCell>
					<TableCell>
						<Box display="flex">
							<BaseInput
								hideErrorText
								fullWidth
								type="number"
								placeholder="Wpisz tytuł"
								name="payment_amount"
								value={subentry.payment_amount}
								onChange={handleChangeAddedSubentry}
							/>
							<SaveIconButton tooltip="Zatwierdź" style={{ marginLeft: 15 }} onClick={handleAddSubentry} />
						</Box>
					</TableCell>
				</TableRow>
			}
		</>
	);
};

const useStyles = makeStyles(() => ({
	disabled: {
		'&.Mui-disabled': {
			'& path': {
				fill: 'gray'
			}
		}
	}
}));

export default EditingRow;
