import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import ResearchTaskContext from 'context/ResearchTaskContext';
import FormGroup from './FormGroup';
import Typography from 'components/Main/Typography';
import { DeleteIconButton } from 'components/Buttons/IconButtons';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
	representative: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
	contact: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiBox-root': {
			position: 'relative',
			'& > .MuiTypography-body1': {
				marginTop: 11,
				marginRight: 16,
			},
			'& .MuiIconButton-root': {
				position: 'absolute',
				right: -40,
				top: 5,
			},
		},
	},
	subtitle: {
		marginBottom: theme.spacing(4),
	},
	divider: {
		marginBlock: theme.spacing(4),
	}
}));

const ContactsTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const classes = useStyles();
	const { formData, errors, onChange } = useContext(ResearchTaskContext);
	const [representative, setRepresentative] = useState(formData.contacts.representative);
	const [contact, setContact] = useState(formData.contacts.contact);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => onChange('contacts.representative', representative), [representative]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => onChange('contacts.contact', contact), [contact]);

	const handleChangeRepresentative = e => {
		const { name, value } = e.target;
		setRepresentative(prev => ({ ...prev, [name]: value }));
	};

	const handleChangeContact = index => e => {
		const { name, value } = e.target;
		setContact(prev => {
			prev[index][name] = value;
			return [...prev];
		});
	};

	const handleAdd = () => {
		setContact(prev => {
			prev.push({
				name: '',
				phone_number: '',
				email: '',
				contact_type: 'contact',
			});
			return [...prev];
		});
	};

	const handleDelete = index => () => {
		setContact(prev => {
			prev.splice(index, 1);
			return [...prev];
		});
	};

	return (
		<Box>
			<Typography variant="subtitle1" className={classes.subtitle}>{t('Osoba uprawniona do reprezentowania - Lidera Konsorcjum/Wnioskodawcy')}</Typography>
			<Box className={classes.representative}>
				<FormGroup
					data={representative}
					errors={errors?.contacts?.representative}
					onChange={handleChangeRepresentative}
					disabled={isPreview}
				/>
			</Box>
			<Divider className={classes.divider} />
			<Typography variant="subtitle1" className={classes.subtitle}>{t('Osoby upoważnione do kontaktów roboczych')}</Typography>
			<Box className={classes.contact}>
				{contact.map((contact, index) => (
					<Box key={index} display="flex" alignItems="flex-start">
						<Typography variant="subtitle2">{index + 1}</Typography>
						<Box flex="1">
							<FormGroup
								data={contact}
								errors={errors?.contacts?.contact?.[index]}
								onChange={handleChangeContact(index)}
								disabled={isPreview}
							/>
							<Divider className={classes.divider} />
						</Box>
						<DeleteIconButton
							onClick={handleDelete(index)}
							disabled={isPreview}
						/>
					</Box>
				))}
			</Box>
			<PrimaryButton onClick={handleAdd} disabled={isPreview}>
				{t('Dodaj osobę')}
			</PrimaryButton>
		</Box>
	);
};

export default ContactsTab;
