import React, { useContext, useEffect, useMemo } from 'react';
import {
	makeStyles,
	TableContainer,
	Table as MuiTable,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
	headerCell: {
		background: '#EEEEEE'
	}
}));

const keys = ['entity_name', 'salary', 'equipment_costs', 'external_service_costs', 'other_direct_costs', 'total_indirect_cost', 'flat_rate', 'indirect_cost', 'total_eligible_cost'];

const Table = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const { researchTask } = useContext(ResearchTaskReportContext);
	const { acceptedReports, selectedLanguage, formData, onChange } = useContext(ResearchTaskReportBContext);
	const data = formData.pl;

	const getSumOfAcceptedReports = (key, entityId) => {
		return _.sumBy(acceptedReports, report => {
			let entity = report.data.pl?.expenses?.data?.entities?.find(e => e.entity_id === entityId);
			return entity?.[key] || 0;
		});
	};

	const entities = useMemo(() => {
		return data.expenses.data.entities.map(entity => {
			let _entity = {
				...entity,
				salary: entity.salary + getSumOfAcceptedReports('salary', entity.entity_id),
				equipment_costs: entity.equipment_costs + getSumOfAcceptedReports('equipment_costs', entity.entity_id),
				external_service_costs: entity.external_service_costs + getSumOfAcceptedReports('external_service_costs', entity.entity_id),
				other_direct_costs: entity.other_direct_costs + getSumOfAcceptedReports('other_direct_costs', entity.entity_id),
			};
			_entity.total_indirect_cost = _entity.salary + _entity.equipment_costs + _entity.external_service_costs + _entity.other_direct_costs;
			_entity.flat_rate = researchTask.flat_rate;
			_entity.indirect_cost = (_entity.salary + _entity.other_direct_costs) * _entity.flat_rate / 100;
			_entity.total_eligible_cost = _entity.total_indirect_cost + _entity.indirect_cost;
			return _entity;
		});
	}, [data.expenses.data.entities, acceptedReports]);

	const sum = useMemo(() => {
		let _sum = _.omit(_.mapValues(entities[0], (val, key) => _.sumBy(entities, item => item[key])),
			['entity_id', 'entity_name']
		);

		_sum.flat_rate = researchTask.flat_rate;

		for( let key in _sum){
			_sum[key] = !isNaN(_sum[key]) ? Math.round((_sum[key] + Number.EPSILON) * 100) / 100 : _sum[key];
		}

		return _sum;
	}, [entities]);

	const sumData = {
		col_2: 0,
		col_4: 0,
		col_5: 0,
		col_6: 0,
		col_7: 0,
		col_8: 0,
	};

	let taskEstimates = data.task_estimates ?? researchTask.task_estimates;

	taskEstimates.map(obj => {
		sumData.col_2 += obj.col_2 + obj.col_3;
		sumData.col_4 += obj.col_4;
		sumData.col_5 += obj.col_5;
		sumData.col_6 += obj.col_6;
		sumData.col_7 += obj.col_7;
		sumData.col_8 += obj.col_8;
	});

	sumData.col_sum = sumData.col_2 + sumData.col_4 + sumData.col_5 + sumData.col_6;
	// sumData.flat_rate_val = ((sumData.col_2 + sumData.col_6) * sum.flat_rate / 100).toFixed(2) * 1;
	sumData.flat_rate_val = sumData.col_7;

	sumData.col_2_percent = (sum.salary === 0 || sumData.col_2 === 0) ? 0 : (sum.salary / sumData.col_2 * 100).toFixed(2);
	sumData.col_4_percent = (sum.equipment_costs === 0 || sumData.col_4 === 0) ? 0 : (sum.equipment_costs / sumData.col_4 * 100).toFixed(2);
	sumData.col_5_percent = (sum.external_service_costs === 0 || sumData.col_5 === 0) ? 0 : (sum.external_service_costs / sumData.col_5 * 100).toFixed(2);
	sumData.col_6_percent = (sum.other_direct_costs === 0 || sumData.col_6 === 0) ? 0 : (sum.other_direct_costs / sumData.col_6 * 100).toFixed(2);
	sumData.col_sum_percent = (sum.total_indirect_cost === 0 || sumData.col_sum === 0) ? 0 : (sum.total_indirect_cost / sumData.col_sum * 100).toFixed(2);
	sumData.flat_rate_val_percent = (sum.indirect_cost === 0 || sumData.col_sum === 0) ? 0 : (sum.indirect_cost / sumData.flat_rate_val * 100).toFixed(2);
	sumData.col_8_percent = (sum.total_eligible_cost === 0 || sumData.col_sum === 0) ? 0 : (sum.total_eligible_cost / sumData.col_8 * 100).toFixed(2);

	useEffect(() => {
		if (_.isEqual(sum, data.cumulative_expenses.sum) || selectedLanguage === 'en') return;

		let cumulativeExpenses = {
			entities: _.cloneDeep(entities),
			sum: _.cloneDeep(sum),
		};
		onChange('pl.cumulative_expenses', _.cloneDeep(cumulativeExpenses));
		onChange('en.cumulative_expenses', _.cloneDeep(cumulativeExpenses));

		onChange('pl.task_estimates', data.task_estimates);
		onChange('en.task_estimates', data.task_estimates);
	}, [sum]);

	return (
		<TableContainer className={classes.root}>
			<MuiTable>
				<TableHead>
					<TableRow>
						<TableCell rowSpan={2}>
							<TableLabel>
								{t('Nazwa Podmiotu Lidera/Członka Zespołu Badawczego')}
							</TableLabel>
						</TableCell>
						<TableCell colSpan={8}>
							<TableLabel align="center">
								{t('Koszty kwalifikowane zadania badawczego')}
							</TableLabel>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>
							<TableLabel>
								{t('Wynagrodzenia')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Koszty aparatury naukowo-badawczej i WNiP (w tym leasing, odpisy amortyzacyjne i koszty odpłatnego korzystania)')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Podwykonawstwo i usługi obce')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Inne koszty bezpośrednie')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Razem koszty bezpośrednie')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Stopa ryczałtu')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Koszty pośrednie (bez podwykonawstwa/usług obcych, aparatury naukowo badawczej, środków trwałych i WNiP)')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<TableLabel>
								{t('Razem koszty kwalifikowane (Finansowanie z Funduszu)')}
							</TableLabel>
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell>1</TableCell>
						<TableCell>2</TableCell>
						<TableCell>3</TableCell>
						<TableCell>4</TableCell>
						<TableCell>5</TableCell>
						<TableCell style={{ whiteSpace: 'nowrap' }}>6 = (2+3+4+5)</TableCell>
						<TableCell>7</TableCell>
						<TableCell>8 = ((2+5)*7)</TableCell>
						<TableCell>9 = (6+8)</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{_.map(entities, (entity, i) => (
						<TableRow key={i}>
							{_.map(keys, key => (
								<TableCell key={key}>
									{key === 'entity_name'
										? entity[key]
										: <NumberText fixedDecimalScale={true} value={entity[key] || 0} />
									}
								</TableCell>
							))}
						</TableRow>
					))}
					<TableRow style={{ height: 10 }} />
					<TableRow>
						<TableCell className={classes.headerCell}>
							<TableLabel>
								{t('Budżet Zadania Badawczego (zgodnie z aktualną wersją Wniosku o finansowanie)')}
							</TableLabel>
						</TableCell>
						{_.map(_.drop(keys, 1), key => (
							<TableCell key={key}>
								<NumberText fixedDecimalScale={true} value={sum?.[key] || 0} />
							</TableCell>
						))}
					</TableRow>
					<TableRow>
						<TableCell className={classes.headerCell}>
							<TableLabel>
								{t('Budżet Zadania Badawczego zgodnie z aktualnym kosztorysem')}
							</TableLabel>
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['col_2']} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['col_4']} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['col_5']} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['col_6']} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['col_sum']} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sum.flat_rate} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['flat_rate_val']} />
						</TableCell>
						<TableCell>
							<NumberText fixedDecimalScale={true} value={sumData['col_8']} />
						</TableCell>
					</TableRow>
					<TableRow>
						<TableCell className={classes.headerCell}>
							<TableLabel>
								{t('Procent wykorzystania')}
							</TableLabel>
						</TableCell>
						<TableCell>
							{sumData.col_2_percent} %
						</TableCell>
						<TableCell>
							{sumData.col_4_percent} %
						</TableCell>
						<TableCell>
							{sumData.col_5_percent} %
						</TableCell>
						<TableCell>
							{sumData.col_6_percent} %
						</TableCell>
						<TableCell>
							{sumData.col_sum_percent} %
						</TableCell>
						<TableCell>
							-
						</TableCell>
						<TableCell>
							{sumData.flat_rate_val_percent} %
						</TableCell>
						<TableCell>
							{sumData.col_8_percent} %
						</TableCell>
					</TableRow>
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default Table;
