import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/Buttons/IconButtons';
import { CircleCheckIcon } from 'assets/svg';

import Row from './Row';

const AddingRow = ({ errors, onAddClick }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });

	const [data, setData] = useState({
		research_apparatus_name: '',
		position_nr: '',
		total_cost: '',
	});

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	return (
		<Row
			onChange={handleChange}
			values={data}
			errors={errors}
			lastColumn={
				<IconButton onClick={() => onAddClick(data)} tooltip={t('Zatwierdź')}>
					<CircleCheckIcon />
				</IconButton>
			}
		/>
	);
};

export default AddingRow;
