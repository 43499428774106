import React, { useEffect, useState } from 'react';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import FileManager from './FileManager';

const Folders = () => {
	const breadcrumbs = [
		{ title: 'Dokumenty', to: '' },
		{ title: 'Lista dokumentów' },
	];

	const handleSelectedFile = (file) => {
		console.log(file);
	};
	const [fileManagerKey, setFileManagerKey] = useState((new Date()).getTime());

	useEffect(() => document.title = 'WIB - Dokumenty', []);

	// define event handler
	const resetFileManager = (e) => {
		if (e.detail === '/folders') {
			setFileManagerKey((new Date()).getTime());
		}
	};

	const refreshFileManager = () => {
		setFileManagerKey((new Date()).getTime());
	};

	useEffect(() => {
		// bind event
		window.addEventListener('selectSidebarNavItem', resetFileManager, false);

		return () => {
			// remove event
			window.removeEventListener('selectSidebarNavItem', resetFileManager);
		};
	}, []);

	return (
		<>
			<Header
				title="Lista dokumentów"
			/>
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<FileManager key={fileManagerKey} onFileSelected={handleSelectedFile} refreshFileManager={refreshFileManager} />
		</>
	);
};

export default Folders;
