import React, { useContext, useEffect, useState } from 'react';
import { Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';

import ManagementSettlementsContext from 'context/ManagementSettlementsContext';
import AddModal from './AddModal';
import Task from './Task';

const useStyles = makeStyles(() => ({
	charCount: {
		textAlign: 'right',
	},
}));

const DetailedSettlements = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - rozliczenie' });
	const { formData, researchTasks, detailedInformation, onChange, onAutoSave, errors, loading, isPreview } = useContext(ManagementSettlementsContext);
	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		if (loading) return;

		let _tasks = (formData.detailed_settlements || []).map(settlement => {
			let task = getNewTask(settlement.research_task_id, settlement);
			task.application_number = settlement.application_number;
			return task;
		});

		setTasks(_tasks);
		onChange('detailed_settlements', _tasks);
		onAutoSave({ ...formData, detailed_settlements: _tasks });
	}, [loading]);

	const handleAddTask = taskId => {
		setTasks(prev => {
			prev.push(getNewTask(taskId));
			onChange('detailed_settlements', prev);
			return [...prev];
		});
	};

	const handleChange = index => e => {
		let { name, value } = e.target;
		setTasks(prev => {
			let _task = prev[index];
			_.set(_task, name, value);
			_task.received_amount = (_task?.table2?.research_team?.col_1 || 0) + (_task?.table2?.commercialization?.col_1 || 0);
			_task.table1.commercialization.col_3 = _task.table1.commercialization.col_2 / (_task.table1.commercialization.col_1 || 1);
			prev[index] = _task;
			onChange('detailed_settlements', prev);
			return [...prev];
		});
	};

	const handleDelete = index => () => {
		setTasks(prev => {
			prev.splice(index, 1);
			onChange('detailed_settlements', prev);
			return [...prev];
		});
	};

	const getNewTask = (taskId, settlement) => {
		const researchTask = _.find(researchTasks, (t => +t.id === +taskId));
		let data = {
			research_task_id: researchTask.id,
			research_task_acronym: researchTask.acronym,
			total_amount: 0,
			application_number: 0,
			received_amount: 0,
			table1: {
				research_team: {
					col_1: 0,
					col_2: 0,
					col_3: 0,
				},
				commercialization: {
					col_1: 0,
					col_2: 0,
					col_3: 0,
				},
			},
			table2: {
				research_team: {
					col_1: 0,
					col_2: 0,
					col_3: 0,
				},
				commercialization: {
					col_1: 0,
					col_2: 0,
					col_3: 0,
				},
			}
		};
		const detailedInformationTable1Task = _.find(detailedInformation.table1.research_tasks, t => t.research_task_id === taskId);
		const detailedInformationTable3Task = _.find(detailedInformation.table3.commercialization.research_tasks, t => t.research_task_id === taskId);

		data.table1.research_team = getResearchTeamRow(detailedInformationTable1Task, 'total_fund_paid', 'total_approved_expenditure');
		data.table1.commercialization = getCommercializationRow(detailedInformationTable3Task, 'total_application_measures', 'total_expenses', settlement);

		// data.table2.research_team = getResearchTeamRow(detailedInformationTable1Task, 'funds_paid', 'district_approved_expenditure');
		// data.table2.commercialization = getCommercializationRow(detailedInformationTable3Task, 'applied_measures', 'approved_expenses');
		data.table2.research_team = getResearchTeamRow(detailedInformationTable1Task, 'funds_paid', 'district_approved_expenditure', settlement);
		data.table2.commercialization = getCommercializationRow(detailedInformationTable3Task, 'applied_measures', 'approved_expenses', settlement);

		data.total_amount = data.table1.research_team.col_1 + data.table1.commercialization.col_1;
		data.received_amount = data.table2.research_team.col_1 + data.table2.commercialization.col_1;
		return data;
	};

	const getResearchTeamRow = (detailedInformation, col1Key, col2Key, settlement) => {
		const row = {
			col_1: +detailedInformation?.sum?.[col1Key] || 0,
			col_2: +detailedInformation?.sum?.[col2Key] || 0,
			col_3: 0
		};

		if (col1Key === 'funds_paid') {
			row.col_1 = settlement?.table2?.research_team?.col_1 || 0;
		}


		row.col_3 = getCol3Val(row);
		return row;
	};

	const getCommercializationRow = (detailedInformation, col1Key, col2Key, settlement) => {
		const row = {
			col_1: +detailedInformation?.[col1Key] || 0,
			col_2: +detailedInformation?.[col2Key] || 0,
			col_3: 0
		};


		if (col1Key === 'applied_measures') {
			row.col_1 = settlement?.table2?.commercialization?.col_1 || 0;
		}

		if (col1Key == 'total_application_measures') {
			row.col_1 = settlement?.table1?.commercialization?.col_1 || 0;
			console.log('here');
		}

		row.col_3 = getCol3Val(row);
		return row;
	};

	const getCol3Val = row => row.col_2 / (row.col_1 || 1);

	return (
		<>
			<Typography variant="h3" style={{ marginBottom: 16 }}>{t('ROZLICZENIE SZCZEGÓŁOWE Zespoły Badawcze')}</Typography>

			{tasks.map((task, i) =>
				<React.Fragment key={i}>
					<Task
						task={task}
						onChange={handleChange(i)}
						onDelete={handleDelete(i)}
						errors={errors?.detailed_settlements?.[i]}
						disabled={isPreview}
					/>
					{i < tasks.length - 1 &&
						<Divider style={{ marginBlock: 16 }} />
					}
				</React.Fragment>
			)}
			<AddModal
				excludingIds={_.map(tasks, 'research_task_id')}
				onAdd={handleAddTask}
				disabled={isPreview}
			/>

			<FormInput
				title={t('Komentarz Podmiotu Zarządzającego')}
				value={formData.detailed_settlements_comment}
				onChange={e => onChange('detailed_settlements_comment', e.target.value)}
				multiline
				minRows={10}
				maxRows={10}
				sm={3}
				alignItems="flex-start"
				inputProps={{ maxLength: 8000, 'aria-required': true }}
				FormHelperTextProps={errors?.detailed_settlements_comment
					? null
					: {
						error: false,
						classes: { root: classes.charCount }
					}
				}
				errorState={!!errors?.detailed_settlements_comment}
				error={errors?.detailed_settlements_comment || `${formData.detailed_settlements_comment?.length || 0}/8000(max)`}
				disabled={isPreview}
			/>
		</>
	);
};

export default DetailedSettlements;
