import React, { forwardRef, Fragment, useContext } from 'react';
import { NavLink as RouterLink, useLocation, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, List, ListItem, Collapse } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import AuthContext from 'context/AuthContext';
import Typography from 'components/Main/Typography';
import Tooltip from 'components/Main/Tooltip';
import { useState } from 'react';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
	label: {
		padding: theme.spacing(1, 4),
	},
	listItem: {
		paddingTop: 0,
		paddingBottom: 0
	},
	button: {
		padding: theme.spacing(1, 4),
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		borderRadius: 0,
		'& .MuiButton-startIcon': {
			width: 45,
			height: 45,
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}
	},
	active: {
		color: theme.palette.secondary.main,
		position: 'relative',
		'& .MuiButton-startIcon': {
			borderRadius: 3,
			backgroundColor: `${theme.palette.secondary.main}20`,
			'& svg': {
				fill: theme.palette.secondary.main
			}
		},
		'&::before': {
			content: '""',
			position: 'absolute',
			left: 0,
			top: '50%',
			width: 3,
			height: 25,
			transform: 'translate(0, -50%)',
			background: theme.palette.secondary.main,
			borderRadius: '0px 3px 3px 0px'
		}
	},
	subListItem: {
		paddingLeft: '20px',
		paddingBottom: 2,
		paddingTop: 2,
	},
	ellipsis: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		width: window.isMobile ? 142 : 148
	},
	tooltip: {
		marginBottom: 0,
		background: '#17253FE5',
		borderRadius: 0,
		fontSize: 12
	},
	subListItemHeight: {
		height: 45,
		paddingTop: 0,
		paddingBottom: 0,
	}
}));

const CustomRouterLink = forwardRef((props, ref) => (
	<div ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink {...props} />
	</div>
));

CustomRouterLink.displayName = 'CustomRouterLink';

const SidebarNav = props => {
	const { pages, className, ...rest } = props;
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const userPermissions = authContext.user?.permissions || [];
	
	const tabStr = new URLSearchParams(location.search).get('tab');

	const [expandObj, setExpandObj] = useState({});

	useEffect(() => {
		const expanded = {};
		pages.forEach(page => {
			page?.children?.forEach(child => {
				if (child?.subList && checkPermission(page?.subList?.[0])) {
					expanded[child.href] = false;
				}

				if (child?.subList && child?.href === '/research_tasks' && checkPermission(page?.subList?.[0])) {
					child?.subList?.forEach(list => {
						expanded[list.href] = false;
					});
				}
			});
		});
		setExpandObj(expanded);
	}, []);

	const handleClick = page => {
		const event = new CustomEvent('selectSidebarNavItem', { detail: page.href });
		window.dispatchEvent(event);

		if (page?.subList && checkPermission(page?.subList?.[0])) {
			setExpandObj(prev => {
				prev[page.href] = !prev[page.href];
				return prev;
			});
		}

		history.push(page.href);
	};

	const checkIsMatch = item => {
		const pathname = location.pathname;
		const baseHref = item?.baseHref || item?.href?.split('/')?.[1];
		const isCreatePage = _.isArray(baseHref)
			? _.find(baseHref, href => pathname === `/${href}/create`)
			: pathname === `/${baseHref}/create`;
		const isEditPage = _.isArray(baseHref)
			? _.find(baseHref, href => pathname.search(new RegExp(`/${href}/[0-9]+/edit`)) > -1)
			: pathname.search(new RegExp(`/${baseHref}/[0-9]+/edit`)) > -1;
		let isMatch = pathname.indexOf(item.href) > -1;
		
		if (item?.withoutForm) isMatch = isMatch && !isCreatePage && !isEditPage;
		if (item?.isForm) isMatch = isCreatePage || isEditPage;

		if (item?.exactMatch) isMatch = pathname === item.href;

		if (tabStr) isMatch = `${pathname}?tab=${tabStr}` === item.href;
		if (isEditPage && location.search) isMatch = `${pathname}?preview=true` === item.href;
		if (pathname.search(new RegExp('/research_tasks/[0-9]+/reports')) > -1) isMatch = pathname === item.href;
		
		return isMatch;
	};

	const checkPermission = item => {
		if (!item?.permission) return true;
		let permissions = _.isString(item.permission) ? [item.permission] : item.permission;

		if (_.indexOf(userPermissions, 'can_fully_manage_entities') > 0) {
			return true;
		}
		return _.intersection(permissions, userPermissions).length > 0;
	};

	const renderList = list => list.map((item, index) => {
		const isMatch = checkIsMatch(item);
		if (item?.children) {
			if (item.children.filter(checkPermission).length < 1) {
				return <Fragment key={index}></Fragment>;
			}
		}

		return (
			item.children ?
				<Fragment key={index}>
					<Typography key={index} variant="menuLabel" className={classes.label}>
						&gt;&gt;{' '}{item.title}
					</Typography>
					{renderList(item.children)}
				</Fragment>
				:
				(
					!checkPermission(item)
						? <Fragment key={index}></Fragment>
						: (
							<Fragment key={index}>
								<ListItem
									className={classes.listItem}
									disableGutters
									onClick={() => handleClick(item)}
								>
									<Button
										className={clsx({
											[classes.button]: true, [classes.active]: isMatch, [classes.subListItemHeight]: item?.isSubList
										})}
										component={CustomRouterLink}
										to={item.href}
										startIcon={item?.icon}
									>
										{item.tooltip ? (
											<Tooltip
												title={item.title}
												placement="top"
												classes={{ tooltip: classes.tooltip }}
											>
												<Typography variant="h4" className={item.ellipsis && classes.ellipsis}>
													{item.title}
												</Typography>
											</Tooltip>
										) :
											<Typography variant="h4" className={item.ellipsis && classes.ellipsis}>
												{item.title}
											</Typography>
										}
									</Button>
									{item?.subList && checkPermission(item?.subList?.[0]) &&
										(
											expandObj[item.href] ? <ExpandLess /> : <ExpandMore />
										)
									}
								</ListItem>
								{item.subList && checkPermission(item?.subList?.[0]) &&
									<Collapse in={expandObj[item.href]} timeout="auto" unmountOnExit>
										<List className={classes.subListItem}>
											{renderList(item.subList)}
										</List>
									</Collapse>
								}
							</Fragment>
						)
				)
		);
	});

	return (
		<List
			{...rest}
			className={clsx(classes.root, className)}
			style={{
				height: `calc(100vh - 85px${authContext.gFooterClosed ? '' : ' - 75px'})`,
				overflowY: 'auto'
			}}
		>
			{renderList(pages)}
		</List>
	);
};

SidebarNav.propTypes = {
	className: PropTypes.string,
	pages: PropTypes.array.isRequired,
};

export default SidebarNav;
