import React, { useEffect, useState, useCallback } from 'react';
import { makeStyles, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import Validator, { ValidISOString, AfterOrEqual, validate } from 'utils/Validator';
import DateInput from 'components/Form/DateInput';
import BaseInput from 'components/Form/BaseInput';
import Select from 'components/Form/Select';

const useStyles = makeStyles(() => ({
	dateInput: {
		width: 250,
	},
}));

const FilterRow = ({ onChange }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const classes = useStyles();
	const statuses = [
		{value: 'draft', label: t('Szkic')},
		{value: 'accepted', label: t('Zaakceptowany')},
		{value: 'rejected', label: t('Zwrócony')},
		{value: 'waiting-for-acceptance', label: t('Wysłany')},
	];
	const [filters, setFilters] = useState({
		period_from: '',
		period_to: '',
		status: '',
		controlsum: '',
	});
	const [errors, setErrors] = useState(null);
	const Validators = {
		period_from: new Validator(ValidISOString()),
		period_to: new Validator(ValidISOString(), AfterOrEqual('period_from')),
	};

	useEffect(() => {
		let _errors = validate(filters, Validators);
		setErrors(_errors);
		if (_errors) return;

		setErrors(null);
		handleChangeSearch(filters);
	}, [filters]);

	const handleChange = e => {
		const target = e.target;
		setFilters(prev => ({ ...prev, [target.name]: target.value }));
	};

	const handleChangeSearch = useCallback(_debounce((filters) => onChange(filters), 500), []);

	return (
		<TableRow>
			<TableCell width="250">
				<DateInput
					containerClassName={classes.dateInput}
					name="period_from"
					value={filters.period_from}
					onChange={handleChange}
					error={errors?.period_from}
					placeholder={t('Data od')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<DateInput
					containerClassName={classes.dateInput}
					name="period_to"
					value={filters.period_to}
					onChange={handleChange}
					error={errors?.period_to}
					placeholder={t('Data do')}
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<Select
					options={statuses}
					name="status"
					value={filters?.status}
					onChange={handleChange}
					displayEmpty
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					placeholder="Wpisz"
					name="controlsum"
					value={filters.controlsum}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell />
		</TableRow>
	);
};

export default FilterRow;
