import React, { useContext } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import FormInput from 'components/Form/FormInput';
import DateInput from 'components/Form/DateInput';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: window.isMobile ? theme.spacing(0) : theme.spacing(0, 45, 0, 4),
		[theme.breakpoints.down('md')]: {
			paddingRight: theme.spacing(window.isMobile ? 0 : 4),
		},
	},
}));

const FinalInfo = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { mainInfo } = useContext(ResearchTaskReportContext);
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	const data = formData[selectedLanguage].results;

	const handleChange = e => {
		const { name, value } = e.target;
		if (name === 'relevant_information') {
			onChange(`${selectedLanguage}.results.${name}`, value);
		} else {
			onChange(`en.results.${name}`, value);
			onChange(`pl.results.${name}`, value);
		}
	};

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12} sm={4}></Grid>
			<Grid item xs={12} sm={8}>
				<FormInput
					title={t('Osoba sporządzająca Raport')}
					value={`${mainInfo?.author?.firstname} ${mainInfo?.author?.surname}`}
					vertical
					disabled
					inputProps={{ 'aria-required': true }}
				/>
				<FormInput
					title={t('Główny Księgowy/Kwestor Podmiotu Lidera Zespołu Badawczego')}
					vertical
					name="quaestor"
					value={data.quaestor || ''}
					onChange={handleChange}
					error={errors?.results?.quaestor}
					disabled={disabledInput || selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
				<FormInput
					title={t('Lider zespołu badawczego')}
					vertical
					name="leader"
					value={data.leader || ''}
					error={errors?.results?.leader}
					onChange={handleChange}
					disabled={disabledInput || selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
				<FormInput
					title={t('Osoba posiadająca prawo reprezentacji Jednostki Naukowej')}
					vertical
					name="representative"
					value={data.representative || ''}
					error={errors?.results?.representative}
					onChange={handleChange}
					disabled={disabledInput || selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
				<DateInput
					title={t('Data wypełnienia')}
					name="complete_date"
					value={data.complete_date || null}
					onChange={handleChange}
					error={errors?.results?.complete_date}
					fullWidth
					vertical
					disabled={disabledInput || selectedLanguage === 'en'}
					inputProps={{ 'aria-required': true }}
				/>
				<FormInput
					title={t('Inne istotne informacje dotyczące realizacji Zadania Badawczego')}
					name="relevant_information"
					value={data.relevant_information || ''}
					onChange={handleChange}
					multiline={true}
					rows={9}
					alignItems="flex-start"
					error={errors?.results?.relevant_information}
					vertical
					disabled={disabledInput}
				/>
			</Grid>
		</Grid>
	);
};

export default FinalInfo;