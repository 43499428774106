import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SearchInput from 'components/Form/SearchInput';
import Select from 'components/Form/Select';

const SearchForm = (props) => {
	const { onChange, filters, ...rest } = props;
	const fileTypes = [
		{ value: 'pdf', label: 'pdf' },
		{ value: 'doc', label: 'doc' },
		{ value: 'docx', label: 'docx' },
		{ value: 'xls', label: 'xls' },
		{ value: 'xlsx', label: 'xlsx' },
		{ value: 'jpg', label: 'jpg' },
		{ value: 'png', label: 'png' },
	];
	const sortTypes = [
		{ value: 'name', label: 'Nazwa A-Z' },
		{ value: 'created_at', label: 'Data dodania' },
	];

	return (
		<>
			<Grid container spacing={3} {...rest}>
				<Grid item xs={window.isMobile ? 12 : 6}>
					<SearchInput
						fullWidth
						placeholder="Znajdź plik"
						name="search"
						value={filters.search}
						onChange={onChange}
					/>
				</Grid>

				<Grid item xs={window.isMobile ? 12 : 3}>
					<Select
						fullWidth
						options={fileTypes}
						displayEmpty
						emptyLabel="Wybierz format pliku"
						name="type"
						value={filters.type}
						onChange={onChange}
					/>
				</Grid>
				<Grid item xs={window.isMobile ? 12 : 3}>
					<Select
						fullWidth
						options={sortTypes}
						displayEmpty
						emptyLabel="Sortuj według"
						name="sort"
						value={filters.sort}
						onChange={onChange}
					/>
				</Grid>
			</Grid>
		</>
	);
};

SearchForm.propTypes = {
	onChange: PropTypes.func
};

export default SearchForm;