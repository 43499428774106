import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Box, TableRow, TableCell } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Validator, { Required, validate } from 'utils/Validator';
import PaginatedTable from 'components/PaginatedTable';
import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';

import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import AddingRow from './AddingRow';
import EditingRow from './EditingRow';

const useStyles = makeStyles(() => ({
	table: {
		padding: 0,
	},
	tableFooter: {
		backgroundColor: '#EBEBEB !important',
	}
}));

const ResearchEquipmentList = () => {
	const { formData, onChange, disabledInput, selectedLanguage } = useContext(ResearchTaskReportBContext);
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const columns = [
		{ title: 'LP.', name: 'index', disableSort: true, },
		{ title: (selectedLanguage === 'en') ? 'NAME OF THE RESEARCH EQUIPMENT AND FIXED AND INTANGIBLE ASSETS' : 'Nazwa aparatury badawczej i WNip', name: 'research_apparatus_name', disableSort: true, },
		{
			title: <>
				<TableLabel>{t('Nr pozycji Kosztorysu Kategoria')}</TableLabel>
				<Typography>({t('Zgodnie z aktualną wersją wniosku o finansowanie')})</Typography>
			</>,
			name: 'position_nr',
			disableSort: true,
			width: 285,
		},
		{ title: t('Koszt ogółem'), name: 'total_cost', disableSort: true },
		{ title: t('Akcje'), name: 'action', disableSort: true, width: 100, },
	];
	const classes = useStyles();
	const researchEquipmentList = formData[selectedLanguage].research_equipment_list;
	const [totalCost, setTotalCost] = useState(0);
	const [data, setData] = useState(researchEquipmentList.data);
	const [errors, setErrors] = useState(null);
	const [editingData, setEditingData] = useState(null);
	const [adding, setAdding] = useState(false);
	const Validators = {
		research_apparatus_name: new Validator(Required),
		position_nr: new Validator(Required),
		total_cost: new Validator(Required),
	};

	useEffect(() => {
		if (_.isEqual(data, researchEquipmentList.data)) return;
		setData(researchEquipmentList.data);
		setTotalCost(researchEquipmentList.total_cost);
	}, [researchEquipmentList.data]);

	useEffect(() => {
		let sum = _.sumBy(data, item => +item.total_cost);
		setTotalCost(sum);
		onChange('pl.research_equipment_list.total_cost', sum);
		onChange('en.research_equipment_list.total_cost', sum);
	}, [data]);

	useEffect(() => {
		if (!editingData) {
			setErrors(null);
			return;
		}

		let _errors = validate(editingData, Validators);
		setErrors(_errors);
	}, [editingData]);

	const handleSetAdding = () => {
		if (editingData) return;
		setAdding(!adding);
	};

	const handleChangeEditingData = e => {
		const { name, value } = e.target;
		setEditingData(prev => ({ ...prev, [name]: value }));
	};

	const handleAdd = addData => {
		let _errors = validate(addData, Validators);
		setErrors(_errors);
		if (editingData || _errors) return;
		setData(prev => {
			prev.push({ ..._.cloneDeep(addData), index: data.length });
			onChange(`${selectedLanguage}.research_equipment_list.data`, prev);

			const enData = formData.en.research_equipment_list.data;
			enData.push({ ..._.cloneDeep(addData), index: enData.length });
			onChange('en.research_equipment_list.data', enData);
			return [...prev];
		});
		setAdding(false);
	};

	const handleEdit = index => () => {
		if ((editingData && errors) || adding) return;
		setEditingData({ ...data[index], index });
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange(`${selectedLanguage}.research_equipment_list.data`, prev);

			const enData = formData.en.research_equipment_list.data;
			enData.splice(index, 1);
			onChange('en.research_equipment_list.data', enData);

			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const handleSave = () => {
		if (errors) return;
		setData(prev => {
			prev[editingData.index] = editingData;
			onChange(`${selectedLanguage}.research_equipment_list.data`, prev, null, true);
			
			if(selectedLanguage === 'pl') {

				const enData = formData.en.research_equipment_list.data;
				enData[editingData.index] = {
					...enData[editingData.index],
					..._.omit(editingData, ['research_apparatus_name'])
				};
				onChange('en.research_equipment_list.data', enData);
			}
			return [...prev];
		});
		setEditingData(null);
	};

	const renderRows = () => {
		let rows = data?.map((item, index) => (
			index === editingData?.index
				? <EditingRow
					key={index}
					item={editingData}
					onChange={handleChangeEditingData}
					onSave={handleSave}
					onDelete={handleDelete(index)}
					errors={errors}
					selectedLanguage={selectedLanguage}
				/>
				: <TableRow key={index}>
					<TableCell>{index + 1}.</TableCell>
					<TableCell>{item.research_apparatus_name}</TableCell>
					<TableCell>{item.position_nr}</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={item.total_cost} />
					</TableCell>
					<TableCell>
						<Box display="flex">
							{!disabledInput &&
								<EditIconButton
									tooltip={t('Edytuj')}
									onClick={handleEdit(index)}
									disabled={disabledInput}
								/>
							}
							{!disabledInput && selectedLanguage !== 'en' &&
								<DeleteIconButton
									tooltip={t('Usuń')}
									onClick={handleDelete(index)}
									disabled={disabledInput || selectedLanguage === 'en'}
								/>
							}
						</Box>
					</TableCell>
				</TableRow>
		));
		rows.push(<TableRow key={rows.length} className={classes.tableFooter}>
			<TableCell colSpan={3}>
				<TableLabel style={{ textAlign: 'right' }}>{t('Razem')}</TableLabel>
			</TableCell>
			<TableCell colSpan={2}>
				<NumberText fixedDecimalScale={true} value={totalCost} />
			</TableCell>
		</TableRow>);
		return rows;
	};

	return (
		<>
			<PrimaryButton
				style={{ marginBottom: 20 }}
				variant="outlined"
				onClick={handleSetAdding}
				disabled={disabledInput || selectedLanguage === 'en'}
			>
				{adding ? t('Anuluj dodawanie') : t('Dodaj aparaturę')}
			</PrimaryButton>

			<div id="research-task-equipment">
				<PaginatedTable
					containerClassName={classes.table}
					columns={columns}
					renderRows={renderRows}
					filterRow={
						adding ? <AddingRow errors={errors} onAddClick={handleAdd} selectedLanguage={selectedLanguage} /> : <></>
					}
				/>
			</div>
		</>
	);
};

export default ResearchEquipmentList;