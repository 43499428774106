const Routes = {
	Home: '/home',
	Auth: {
		Login: '/login',
		Register: '/register',
		ForgotPassword: '/forgot_password',
		ResetPassword: '/reset_password',
	},
	Users: {
		List: '/users',
		Create: '/users/create',
		Edit: (id = ':id') => `/users/${id}/edit`,
		Profile: '/users/profile',
	},
	Role: {
		List: '/roles',
		Create: '/roles/create',
		Edit: (id = ':id') => `/roles/${id}/edit`,
	},
	Logs: {
		List: '/logs'
	},
	ResearchTasks: {
		List: '/research_tasks',
		Create: '/research_tasks/create',
		Edit: (id = ':id', preview) => `/research_tasks/${id}/edit${preview ? `?preview=${preview}` : ''}`,
		EditPublished: (id = ':id', preview) => `/research_tasks/${id}/published${preview ? `?preview=${preview}` : ''}`,
		Reports: {
			List: (taskId = ':task_id') => `/research_tasks/${taskId}/reports`,
			Create: (taskId = ':task_id', type = ':type') => `/research_tasks/${taskId}/reports/${type}/create`,
			Edit: (taskId = ':task_id', type = ':type', id = ':id', preview) =>
				`/research_tasks/${taskId}/reports/${type}/${id}/edit${preview ? `?preview=${preview}` : ''}`,
		},
	},
	Translations: {
		List: '/translations',
	},
	Folders: {
		List: '/folders'
	},
	IpCards: {
		List: '/ip_cards',
		Create: '/ip_cards/create',
		Edit: (id = ':id', preview) => `/ip_cards/${id}/edit${preview ? `?preview=${preview}` : ''}`,
	},
	Management: {
		List: tab => `/management${tab ? `?tab=${tab}` : ''}`,
		DetailedInformations: {
			Create: '/management/detailed_informations/create',
			Edit: (id = ':id', preview) => `/management/detailed_informations/${id}/edit${preview ? `?preview=${preview}` : ''}`,
		},
		Applications: {
			Create: '/management/applications/create',
			Edit: (id = ':id', preview) => `/management/applications/${id}/edit${preview ? `?preview=${preview}` : ''}`,
		},
		Settlements: {
			Edit: (id = ':id', preview) => `/management/settlements/${id}/edit${preview ? `?preview=${preview}` : ''}`,
		}
	},
	Pages: {
		List: '/pages',
		Preview: (id = ':id') => `/pages/preview/${id}`,
		Edit: (id = ':id') => `/pages/${id}/edit`,
		EditHome: '/pages/edit-home',
		EditLogin: '/pages/edit-login',
		Help: '/pages/help',
		PrivacyPolicy: '/privacy-policy',
		Regulations: '/regulations',
		AccessibilityDeclaration: '/accessibility-declaration',
		InformationClause: '/information-clause',
	},
	Others: {
		NotFound: '/not-found',
		HasProblem: '/has-problem',
	},
};

export default Routes;
