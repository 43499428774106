import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles, TableBody, TableCell, TableRow } from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import NumberText from 'components/Main/NumberText';
import BaseInput from 'components/Form/BaseInput';
import TableLabel from 'components/Main/TableLabel';

import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';
import { CategoryCell, HeadCell } from '../CustomCell';
import PairCell from '../PairCell';
import AddModal from '../AddModal';
import SimpleRow from './SimpleRow';
import TaskRow from './TaskRow';

const useStyles = makeStyles(() => ({
	lastRow: {
		'& .MuiTableCell-body': {
			borderBottom: '1px solid #17253F !important',
		}
	},
	hiddenCell: {
		padding: 0,
		border: 'none !important'
	},
	charCount: {
		textAlign: 'right',
	},
}));

const Table3 = () => {
	const classes = useStyles();
	const { id } = useParams();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const { loading, publishedInformations, researchTasks, formData, errors, onChange, isPreview } = useContext(ManagementDetailedInformationsContext);
	const firstRows = [
		{ name: 'job_contract', label: t('a.1. Umowy o pracę') },
		{ name: 'law_contract', label: t('a.2. Umowy cywilno-prawne (dzieło, zlecenia)') },
		{ name: 'organization', label: t('b. Organizacja i przeprowadzenie otwartego konkursu ofert na wyłonienie zespołów badawczych') },
		{ name: 'financing_agreements', label: t('c. Przygotowanie, zawarcie Umów o finansowanie, realizacja postanowień tych umów, przepisów prawa oraz prowadzonego nadzoru w tym zakresie') },
		{ name: 'wib_management', label: t('d. Przygotowanie i obsługa systemu informatycznego utworzonego na potrzeby zarządzania WIB') },
	];
	const secondRows = [
		{ name: 'stamp_duties', label: t('f. Opłaty skarbowe, opłaty sądowe dot. pkt. a-e') },
		{ name: 'external_services', label: t('g. Koszty podwykonawstwa/usług obcych') },
		{ name: 'information_activities', label: t('h. Działania informacyjno-promocyjne') },
		{ name: 'other_direct_costs', label: t('i. Inne koszty bezpośrednie') },
		{ name: 'indirect_costs', label: t('j. Koszty pośrednie (15% sumy kosztów bezpośrednich z wyłączeniem lit. g - kosztów podwykonawstwa/usług obcych)') },
	];
	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		if (!id || loading || _.isEqual(tasks, formData.table3.commercialization.research_tasks)) return;
		setTasks([...(formData.table3.commercialization.research_tasks || [])]);
	}, [loading]);

	useEffect(() => {
		if (_.isEqual(tasks, formData.table3.commercialization.research_tasks)) return;
		onChange('table3.commercialization.research_tasks', tasks);
	}, [tasks]);

	// Get sum of research task rows
	useEffect(() => {
		let sum = {
			total_application_measures: 0,
			applied_measures: 0,
			total_expenses: 0,
			expenditure_incurred: 0,
			total_approved: 0,
			approved_expenses: 0,
		};
		_.map(_.keys(sum), key => {
			sum[key] = getResearchTasksTotalSum(key);
		});
		onChange('table3.commercialization.sum', sum);
	}, [
		tasks,
		formData.table3.commercialization.agreement_costs.total_application_measures,
		formData.table3.commercialization.agreement_costs.total_expenses,
		formData.table3.commercialization.agreement_costs.total_approved,
	]);

	const getResearchTasksTotalSum = key => {
		return _.sumBy(tasks, task => +task[key]) + +formData.table3.commercialization.agreement_costs[key];
	};

	const handleAddTask = taskId => {
		const task = _.find(researchTasks, (t => +t.id === +taskId));
		setTasks(prev => {
			prev.push({
				total_application_measures: getSumByKey('applied_measures', taskId),
				applied_measures: 0,
				total_expenses: getSumByKey('expenditure_incurred', taskId),
				expenditure_incurred: 0,
				total_approved: getSumByKey('approved_expenses', taskId),
				approved_expenses: 0,
				research_task_id: task.id,
				research_task_acronym: task.acronym,
			});
			return [...prev];
		});
	};

	const getSumByKey = (key, taskId) => {
		let sum = 0;
		_.map(publishedInformations, info => {
			let task = _.find(info.table3.commercialization.research_tasks, task => +task.research_task_id === +taskId);
			sum += +task?.[key] || 0;
		});
		return sum;
	};

	const handleChangeTask = i => task => {
		setTasks(prev => {
			prev[i] = task;
			return [...prev];
		});
	};

	const handleDeleteTask = i => () => {
		setTasks(prev => {
			prev.splice(i, 1);
			return [...prev];
		});
	};

	return (
		<>
			<TableBody>
				<TableRow>
					<CategoryCell rowSpan={0} style={{ borderBlockWidth: 0 }}>
						<TableLabel>B</TableLabel>
					</CategoryCell>
					<CategoryCell rowSpan={0} style={{ borderBottomWidth: 0 }}>
						<TableLabel>3</TableLabel>
					</CategoryCell>
					<TableCell colSpan={9}>
						<TableLabel>{t('Kategorie wydatków przeznaczonych na pokrycie Kosztów zarządzania przez Podmiot zarządzający')}</TableLabel>
					</TableCell>
				</TableRow>
				{/* a row */}
				<TableRow>
					<TableCell>{t('a. Wynagrodzenia, w tym')}</TableCell>
					<HeadCell rowSpan={0}></HeadCell>
					<HeadCell rowSpan={6}></HeadCell>
					<HeadCell rowSpan={6}></HeadCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.total_remuneration.total_expenses} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.total_remuneration.expenditure_incurred} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.total_remuneration.total_approved} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.total_remuneration.approved_expenses} />
					</TableCell>
					<HeadCell rowSpan={0} style={{ borderBottom: 0 }}></HeadCell>
				</TableRow>
				{/* a.1 - d rows */}
				{firstRows.map(row => <SimpleRow key={row.name} row={row} disabled={isPreview} />)}

				{/* e row commercialization.sum */}
				<TableRow>
					<TableCell>{t('e. Komercjalizacja, w tym')}</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.commercialization.sum.total_application_measures} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.commercialization.sum.applied_measures} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.commercialization.sum.total_expenses} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.commercialization.sum.expenditure_incurred} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.commercialization.sum.total_approved} />
					</TableCell>
					<TableCell>
						<NumberText fixedDecimalScale={true} value={formData.table3.commercialization.sum.approved_expenses} />
					</TableCell>
				</TableRow>
				{/* e1 row commercialization.agreement_costs */}
				<TableRow>
					<TableCell>{t('e.1. Koszty zgodnie z art. 2 ust. 1 lit. e, i-x Zał. nr 2 do Umowy WIB')}</TableCell>
					<PairCell
						sumName="table3.commercialization.agreement_costs.total_application_measures"
						inputName="table3.commercialization.agreement_costs.applied_measures"
						disabled={isPreview}
					/>
					<PairCell
						sumName="table3.commercialization.agreement_costs.total_expenses"
						inputName="table3.commercialization.agreement_costs.expenditure_incurred"
						disabled={isPreview}
					/>
					<PairCell
						sumName="table3.commercialization.agreement_costs.total_approved"
						inputName="table3.commercialization.agreement_costs.approved_expenses"
						disabled={isPreview}
					/>
				</TableRow>
				{_.map(tasks, (task, i) =>
					<TaskRow
						key={i}
						index={i}
						task={task}
						onChange={handleChangeTask(i)}
						onDelete={handleDeleteTask(i)}
						getSumByKey={getSumByKey}
						disabled={isPreview}
					/>
				)}
			</TableBody>
			<TableBody>
				<TableRow>
					<CategoryCell style={{ borderBlock: 0 }}></CategoryCell>
					<CategoryCell style={{ borderBlock: 0 }}></CategoryCell>
					<TableCell colSpan={9}>
						<AddModal
							excludingIds={_.map(tasks, 'research_task_id')}
							onAdd={handleAddTask}
							disabled={isPreview}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
			{/* f - j rows */}
			<TableBody>
				<TableRow>
					<CategoryCell rowSpan={0} style={{ borderBlock: 0 }}></CategoryCell>
					<CategoryCell rowSpan={0} style={{ borderBlock: 0 }}></CategoryCell>
					<TableCell className={classes.hiddenCell}></TableCell>
					<HeadCell rowSpan={0}></HeadCell>
					<HeadCell rowSpan={0}></HeadCell>
					<HeadCell rowSpan={0}></HeadCell>
					<TableCell className={classes.hiddenCell}></TableCell>
					<TableCell className={classes.hiddenCell}></TableCell>
					<TableCell className={classes.hiddenCell}></TableCell>
					<TableCell className={classes.hiddenCell}></TableCell>
					<HeadCell rowSpan={0} style={{ borderTop: 0 }}></HeadCell>
				</TableRow>
				{secondRows.map(row => <SimpleRow key={row.name} row={row} disabled={isPreview} />)}
			</TableBody>

			{/* Comment Row */}
			<TableBody>
				<TableRow className={classes.lastRow}>
					<CategoryCell style={{ borderTopWidth: 0 }}></CategoryCell>
					<CategoryCell style={{ borderTopWidth: 0 }}></CategoryCell>
					<TableCell>
						{t('Komentarz')}
					</TableCell>
					<TableCell colSpan={8}>
						<BaseInput
							fullWidth
							placeholder={t('Wpisz Komentarz')}
							value={formData.table3.comment}
							onChange={e => onChange('table3.comment', e.target.value)}
							multiline
							minRows={10}
							maxRows={10}
							inputProps={{ maxLength: 8000, 'aria-required': true }}
							FormHelperTextProps={errors?.table3?.comment && !formData.table3.comment
								? null
								: {
									error: false,
									classes: { root: classes.charCount }
								}
							}
							errorState={Boolean(errors?.table3?.comment && !formData.table3.comment)}
							error={!formData.table3.comment && errors?.table3?.comment
								? errors?.table3?.comment
								: `${formData.table3.comment?.length || 0}/8000 (max)`
							}
							disabled={isPreview}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</>
	);
};

export default Table3;
