import React from 'react';
import { useTranslation } from 'react-i18next';

import Accordions from 'components/Main/Accordions';
import TaskPlans from './TaskPlans';
import TaskEstimations from './TaskEstimations';
import PaymentSchedule from './PaymentSchedule';

const TaskTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const items = [
		{ title: t('Plan badawczy'), child: <TaskPlans />, defaultExpanded: true },
		{ title: t('Kosztorys zadania badawczego'), child: <TaskEstimations /> },
		{
			title: t('Harmonogram płatności (zapotrzebowanie na środki finansowe w okresie realizacji Zadania Badawczego)'),
			child: <PaymentSchedule />
		},
	];

	return (
		<Accordions items={items} />
	);
};

export default TaskTab;
