import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import PrimaryButton from 'components/Buttons/PrimaryButton';

import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';
import Item from './Item';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
	},
}));

const AchievementsWorkDescription = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);

	const [data, setData] = useState([]);
	const [selectedPlans, setSelectedPlans] = useState([]);

	useEffect(() => {
		setData(_.clone(formData[selectedLanguage].research_team.stage_descriptions));
	}, [selectedLanguage, formData.en?.research_team?.stage_descriptions]);

	const handleChangeData = _data => {
		onChange(`${selectedLanguage}.research_team.stage_descriptions`, _.clone(_data));

		setSelectedPlans(prev => {
			prev = _.map(_data, 'research_task_plan_id');
			return [...prev];
		});
	};

	const handleAdd = () => {
		setData(prev => {
			let newData = {
				research_task_plan_id: '',
				stage_description: '',
				achieved_description: '',
				impact_description: '',
			};
			prev.push(_.clone(newData));
			handleChangeData(prev);

			let enData = _.clone(formData.en.research_team.stage_descriptions);
			enData.push(_.clone(newData));
			onChange('en.research_team.stage_descriptions', enData);
			return [...prev];
		});
	};

	const handleChange = index => e => {
		const { name, value } = e.target;

		setData(prev => {
			prev[index][name] = value;
			handleChangeData(prev);

			if(selectedLanguage === 'pl' && name === 'research_task_plan_id') {
				let enData = _.clone(formData.en.research_team.stage_descriptions);
				enData[index][name] = value;
				onChange('en.research_team.stage_descriptions', enData);
			}
			return [...prev];
		});
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			handleChangeData(prev);

			let enData = _.clone(formData.en.research_team.stage_descriptions);
			enData.splice(index, 1);
			onChange('en.research_team.stage_descriptions', enData);
			return [...prev];
		});
	};

	return (
		<Box className={classes.root}>
			{data?.map((item, i) =>
				<Item
					key={i}
					data={item}
					errors={errors?.research_team?.stage_descriptions?.[i]}
					onChange={handleChange(i)}
					onDelete={handleDelete(i)}
					selectedPlans={selectedPlans}
					disabled={disabledInput}
					selectedLanguage={selectedLanguage}
				/>
			)}
			<PrimaryButton onClick={handleAdd} disabled={disabledInput || selectedLanguage === 'en'}>
				{t('Dodaj etap')}
			</PrimaryButton>
		</Box>
	);
};

export default AchievementsWorkDescription;