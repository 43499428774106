import React from 'react';
import {Box} from '@material-ui/core';

import Subjects from './Subjects';
import OtherIpsForm from './OtherIpsForm';
import Others from './Others';

const BackgroundIp = () => {
	return (
		<Box>
			<Subjects />

			<OtherIpsForm />

			<Others />
		</Box>
	);
};

export default BackgroundIp;