import React, { useEffect, useState, useCallback } from 'react';
import { Grid, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _debounce from 'lodash/debounce';

import Messages from 'utils/Messages';
import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import Select from 'components/Form/Select';

const FilterRow = ({ onChange }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const [filters, setFilters] = useState({
		id: '',
		acronym: '',
		title: '',
		status: '',
		start_date: '',
		end_date: '',
	});
	const [dateError, setDateError] = useState(null);
	const statuses = [
		{ value: 'draft', label: t('Szkic') },
		{ value: 'published', label: t('Zapisany') },
	];

	useEffect(() => {
		if ((!!filters.start_date && !!filters.end_date) && (filters.start_date > filters.end_date)) {
			setDateError(prev => ({ ...prev, end_date: Messages.AfterStartDate }));
			return;
		}
		setDateError(null);
		handleChangeSearch(filters);
	}, [filters]);

	const handleChange = e => {
		const target = e.target;
		setFilters(prev => ({ ...prev, [target.name]: target.value }));
	};

	const handleChangeSearch = useCallback(_debounce((filters) => onChange(filters), 500), []);

	return (
		<TableRow>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz')}
					name="id"
					value={filters.id}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz akronim')}
					name="acronym"
					value={filters.acronym}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<BaseInput
					fullWidth
					placeholder={t('Wpisz nazwę')}
					name="title"
					value={filters.title}
					onChange={handleChange}
				/>
			</TableCell>
			<TableCell>
				<Select
					options={statuses}
					name="status"
					value={filters.status}
					onChange={handleChange}
					displayEmpty
					emptyLabel="Wszystkie"
					gutterBottom={false}
				/>
			</TableCell>
			<TableCell>
				<Grid container spacing={1}>
					<Grid item xs={12} sm={6}>
						<DateInput
							name="start_date"
							value={filters.start_date}
							onChange={handleChange}
							placeholder={t('Data od')}
							gutterBottom={false}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<DateInput
							name="end_date"
							value={filters.end_date}
							onChange={handleChange}
							error={dateError?.end_date}
							placeholder={'Data do'}
							gutterBottom={false}
						/>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell></TableCell>
		</TableRow>
	);
};

export default FilterRow;
