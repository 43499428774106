import React, { useEffect, useState } from 'react';
import {
	makeStyles,
	Modal,
	Backdrop,
	Fade,
	Card,
	Box,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import FormInput from 'components/Form/FormInput';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 600,
		boxShadow: theme.shadows[5],
		padding: '25px',
		outline: 'none',
	},
}));

const CommentModal = props => {
	const { openModal, onClose, onSave } = props;
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const classes = useStyles();
	const [comment, setComment] = useState('');

	useEffect(() => {
		if(!openModal) return;
		setComment('');
	}, [openModal]);
	
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={openModal}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}} >
			<Fade in={openModal}>
				<Card className={classes.paper}>
					<Typography variant="h3" style={{ marginBottom: 16 }}>
						{t('Dodaj adnotacje')}
					</Typography>
					<FormInput
						title={t('Poniżej wpisz adnotacje dotyczące raportu')}
						placeholder={t('Wpisz')}
						value={comment}
						onChange={e => setComment(e.target.value)}
						vertical
						multiline
						minRows={10}
						maxRows={10}
						inputProps={{ 'aria-required': true }}
					/>
					
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						<PrimaryButton onClick={onClose} variant="outlined">
							{t('Anuluj')}
						</PrimaryButton>
						<PrimaryButton onClick={() => onSave(comment)} style={{ marginLeft: 16 }}>
							{t('Wyślij')}
						</PrimaryButton>
					</Box>
				</Card>
			</Fade>
		</Modal>
	);
};

export default CommentModal;
