import React, { useContext } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import FormInput from 'components/Form/FormInput';

import ResearchTaskReportAContext from 'context/ResearchTaskReportAContext';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		padding: window.isMobile ? theme.spacing(0) : theme.spacing(0, 45, 0, 4),
		[theme.breakpoints.down('md')]: {
			paddingRight: theme.spacing(window.isMobile ? 0 : 4),
		},
	},
	charCount: {
		textAlign: 'right',
	},
}));

const CommercializationPotential = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport A' });
	const classes = useStyles();
	const { formData, onChange, errors, disabledInput, selectedLanguage } = useContext(ResearchTaskReportAContext);
	const data = formData[selectedLanguage];
	const handleChange = e => {
		onChange(`${selectedLanguage}.results.achieved_description`, e.target.value);
	};

	return (
		<Box className={classes.root}>
			<FormInput
				title={t('2.1. Opis zastosowania osiągniętych wyników (maks. 5000 znaków)')}
				name="achieved_description"
				value={data.results.achieved_description}
				onChange={handleChange}
				multiline
				minRows={10}
				maxRows={10}
				alignItems="flex-start"
				inputProps={{ maxLength: 5000, 'aria-required': true }}
				FormHelperTextProps={errors?.results?.achieved_description
					? null
					: {
						error: false,
						classes: { root: classes.charCount }
					}
				}
				errorState={!!errors?.results?.achieved_description}
				error={errors?.results?.achieved_description || `${data.results.achieved_description?.length || 0}/5000`}
				disabled={disabledInput}
			/>
		</Box>
	);
};

export default CommercializationPotential;