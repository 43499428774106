import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ value = '', onChange }) => {
	return (
		<>
			<ReactQuill
				theme="snow"
				value={value}
				onChange={onChange}
				style={{ minHeight: '44vh' }}
				modules={{
					toolbar: [
						[{ 'header': [false, 1, 2, 3, 4, 5, 6] }],
						['bold', 'italic', 'underline', 'strike', 'blockquote', { 'color': [
							'#000000', '#FF0000', '#008000', '#0000FF', '#b41730', '#17253f'],
						}, 'code', 'clean'],
						['link'],
						[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
						[{ 'list': 'ordered' }, { 'list': 'bullet' }],
						[{ 'indent': '-1' }, { 'indent': '+1' }],
					],
				}}
				formats={[
					'header',
					// 'font',
					'list',
					'bullet',
					'indent',
					'align',
					'bold',
					'italic',
					'underline',
					'strike',
					'blockquote',
					'code',
					'color',
					'link'
				]}
			/>
		</>
	);
};

export default Editor;
