import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { DeleteIconButton } from 'components/Buttons/IconButtons';

import ResearchTaskContext from 'context/ResearchTaskContext';
import Form from './Form';

const useStyles = makeStyles(theme => ({
	root: {
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(2),
		},
	},
}));

const ConsortiumMember = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const { formData, errors, onChange } = useContext(ResearchTaskContext);
	const classes = useStyles();
	const [data, setData] = useState(formData.entities.consortium_member);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => onChange('entities.consortium_member', data), [data]);

	const handleAdd = () => {
		setData(prev => {
			prev.push({
				temp_id: new Date().getTime(),
				entity_name: '',
				entity_short_name: '',
				organization_status_id: null,
				basic_legal_form: '',
				specific_legal_form: '',
				ownership_form: {
					treasury: '',
					state_legal_persons: '',
					local_government_units: '',
					domestic_natural_persons: '',
					other_national_private_entities: '',
					foreign_people: '',
				},
				main_research_activity_in_poland: null,
				principal_activity_number_pkd2007: '',
				principal_activity_number_nace_rev2: '',
				entity_manager_firstname: '',
				entity_manager_surname: '',
				entity_manager_position: '',
				headquarters_street: '',
				headquarters_building_number: '',
				headquarters_apartment_number: '',
				headquarters_postcode: '',
				headquarters_city: '',
				phone_number: '',
				website_address: '',
				email_address: '',
				esp_address: '',
				nip: '',
				regon: '',
				is_correspondence_address_different: null,
				correspondence_street: '',
				correspondence_building_number: '',
				correspondence_apartment_number: '',
				correspondence_postcode: '',
				correspondence_city: '',
			});
			return [...prev];
		});
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			return [...prev];
		});
	};

	const handleChange = index => e => {
		const { name, value } = e.target;
		setData(prev => {
			prev[index][name] = value;
			if(name === 'is_correspondence_address_different' && !value) {
				prev[index].correspondence_apartment_number = '';
				prev[index].correspondence_building_number = '';
				prev[index].correspondence_city = '';
				prev[index].correspondence_postcode = '';
				prev[index].correspondence_street = '';
			}
			return [...prev];
		});
	};

	return (
		<Box className={classes.root}>
			<Typography variant="h3">{t('Konsorcjum')}</Typography>
			{data.map((item, index) => (
				<React.Fragment key={index}>
					<Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
						<Typography variant="h4">{t('Konsorcjant')} {index + 1}</Typography>
						{!isPreview &&
							<DeleteIconButton
								onClick={handleDelete(index)}
								disabled={isPreview}
							/>
						}
					</Box>
					<Form
						data={item}
						onChange={handleChange(index)}
						errors={errors?.entities?.consortium_member?.[index]}
					/>
					<Divider style={{marginBottom: 16}} />
				</React.Fragment>
			))}
			{!isPreview &&
				<PrimaryButton onClick={handleAdd} disabled={isPreview}>
					{t('Dodaj Konsorcjanta')}
				</PrimaryButton>
			}
		</Box>
	);
};

export default ConsortiumMember;

