import React, { useContext, useEffect, useMemo } from 'react';
import {
	makeStyles,
	TableRow,
	TableCell,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import TableLabel from 'components/Main/TableLabel';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import NumberText from 'components/Main/NumberText';

const useStyles = makeStyles(() => ({
	grayBackground: {
		background: '#EEEEEE',
	}
}));

const EstimateSum = ({ dataKey }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const { formData, onChange, getBudgetSum, selectedLanguage } = useContext(ResearchTaskReportBContext);

	const estimateSum = useMemo(() =>
		getBudgetSum(formData[selectedLanguage].changed_information?.[dataKey])
	, [formData[selectedLanguage].changed_information?.[dataKey]]);

	useEffect(() => {
		onChange(`pl.changed_information.${dataKey}_sum`, estimateSum);
		onChange(`en.changed_information.${dataKey}_sum`, estimateSum);
	}, [estimateSum]);

	return (
		<TableRow>
			<TableCell className={classes.grayBackground}>
				<TableLabel>{t('Ogółem w Zadaniu Badawczym')}</TableLabel>
			</TableCell>
			{_.map(_.keys(estimateSum), key => (
				<TableCell key={key}>
					<NumberText fixedDecimalScale={true} value={estimateSum?.[key] || 0} />
				</TableCell>
			))}
		</TableRow>
	);
};

export default EstimateSum;
