import React from 'react';
import {
	makeStyles,
	Modal,
	Backdrop,
	Fade,
	Card,
	Box,
	CardContent,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import Typography from 'components/Main/Typography';
import PrimaryButton from 'components/Buttons/PrimaryButton';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 600,
		boxShadow: theme.shadows[5],
		padding: '25px',
		outline: 'none',
	},
	cardContent: {
		maxHeight: 500,
		overflowY: 'auto',
		padding: 0,
		marginBottom: theme.spacing(2)
	}
}));

const CommentModal = ({ openModal, onClose, comments }) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport' });
	const classes = useStyles();
	const statuses = {
		accepted: t('Zaakceptowany'),
		rejected: t('Zwrócony'),
	};
	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={openModal}
			onClose={onClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}} >
			<Fade in={openModal}>
				<Card className={classes.paper}>
					<Typography variant="h3" style={{ marginBottom: 16 }}>
						{t('Adnotacje do raportu')}
					</Typography>
					<CardContent className={classes.cardContent}>
						{comments?.map(comment => (
							<Box key={comment.id} mb={4}>
								<Typography variant="bodyL">
									{comment.author.firstname} {comment.author.surname}{' '}
									- {statuses[comment.status]}{' '}
									({moment(comment.created_at).format('DD.MM.YYYY')})
								</Typography>
								<Typography style={{ wordBreak: 'break-all' }}>{comment.comments}</Typography>
							</Box>
						))}
					</CardContent>
					<Box display="flex" alignItems="center" justifyContent="flex-end">
						<PrimaryButton onClick={onClose} variant="outlined">
							{t('Zamknij')}
						</PrimaryButton>
					</Box>
				</Card>
			</Fade>
		</Modal>
	);
};

export default CommentModal;
