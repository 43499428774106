import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import {
	Box,
	makeStyles,
	TableContainer,
	Table as MuiTable,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Grid,
} from '@material-ui/core';
import _, { debounce } from 'lodash';

import TableLabel from 'components/Main/TableLabel';
import FormInput from 'components/Form/FormInput';
import Select from 'components/Form/Select';
import IpCardContext from 'context/IpCardContext';
import {
	EditIconButton,
	DeleteIconButton,
	CollapseCircleIconButton,
	ExpandCircleIconButton,
	CheckRedIconButton
} from 'components/Buttons/IconButtons';
import Detail from './Detail';
import Form from './Form';
import useCheckPermissions from 'utils/useCheckPermissions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
			verticalAlign: 'top',
		},
	},
}));

const Table = () => {
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const canCreateIpCard = useCheckPermissions('can_create_ip_card', true);

	const { t } = useTranslation(null, { keyPrefix: 'Karty własności' });

	const classes = useStyles();
	const { selectedLanguage, formData, onChange } = useContext(IpCardContext);
	const [data, setData] = useState(formData.ip_card_background_subjects);
	const [expand, setExpand] = useState(null);
	const [editingData, setEditingData] = useState(null);
	const [filters, setFilters] = useState({
		type: '',
		start_priority_date: '',
		end_priority_date: '',
		title: '',
		owner_name: '',
	});

	const subjectTypes = [
		{ value: 'Zgłoszenie patentowe', label: t('Zgłoszenie patentowe') },
		{ value: 'Patent', label: t('Patent') },
		{ value: 'Wzór użytkowy', label: t('Wzór użytkowy') },
		{ value: 'Wzór przemysłowy', label: t('Wzór przemysłowy') },
		{ value: 'Udokumentowane know-how', label: t('Udokumentowane know-how') },
		{ value: 'Inne', label: t('Inne') },
	];

	const handleEdit = index => {
		setExpand(null);
		setEditingData({ ...data[index], index });
	};

	const handleSave = (item) => {
		const { index } = editingData;
		setData(prev => {
			prev[editingData.index] = { index, ...item };
			onChange('ip_card_background_subjects', prev);
			return [...prev];
		});
		setEditingData(null);
	};

	const handleClickReadMore = (index) => {
		setExpand(index);
		setEditingData(null);
	};

	const handleDelete = index => () => {
		setData(prev => {
			prev.splice(index, 1);
			onChange('ip_card_background_subjects', prev);
			return [...prev];
		});

		setEditingData(prev => {
			if (index === prev?.index) return null;

			if (index < prev?.index) {
				prev.index -= 1;
				return { ...prev };
			}
			return prev;
		});
	};

	const renderRows = () => {
		return data.map((row, index) => {
			if (_.isEmpty(row.owners)) {
				return (
					<React.Fragment key={index}>
						<TableRow>
							<TableCell>{index + 1}</TableCell>
							<TableCell>{row.type}</TableCell>
							<TableCell>{row.priority_date}</TableCell>
							<TableCell>{row.number}</TableCell>
							<TableCell>{row.title_pl ? `${row.title_pl}/` : ''}{row.title_en}</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell>
								<Box display="flex">
									{expand !== index
										? <ExpandCircleIconButton tooltip="Rozwiń" onClick={() => handleClickReadMore(index)} />
										: <CollapseCircleIconButton tooltip="Zwiń" onClick={() => handleClickReadMore(null)} />
									}
									{!isPreview && canCreateIpCard &&
										<>
											{editingData?.index !== index
												? <EditIconButton tooltip="Edytuj" onClick={() => handleEdit(index)} />
												: <CheckRedIconButton tooltip="Zatwierdź" />
											}
											<DeleteIconButton tooltip="Usuń" title="Czy jesteś pewien, że chcesz usunąć ten wpis" onClick={handleDelete(index)} />
										</>
									}
								</Box>
							</TableCell>
						</TableRow>
						{expand === index &&
							<TableRow key={index + '_detail'}>
								<TableCell colSpan={9}>
									<Detail subject={row} />
								</TableCell>
							</TableRow>
						}
						{editingData?.index === index &&
							<TableRow key={index + '_edit'}>
								<TableCell colSpan={9}>
									<Form
										onSave={handleSave}
										values={row}
									/>
								</TableCell>
							</TableRow>
						}
					</React.Fragment>
				);
			} else {
				let groupComponent = [];
				for (let i = 0; i < row.owners.length; i++) {
					if (i === 0) {
						groupComponent.push(<TableRow key={i}>
							<TableCell rowSpan={row.owners.length}>{index + 1}</TableCell>
							<TableCell rowSpan={row.owners.length}>{row.type === null ? '' : t(row.type)}</TableCell>
							<TableCell rowSpan={row.owners.length}>{row.priority_date}</TableCell>
							<TableCell rowSpan={row.owners.length}>{row.number}</TableCell>
							<TableCell rowSpan={row.owners.length}>{row.title_pl ? `${row.title_pl}/` : ''}{row.title_en}</TableCell>
							<TableCell>{row.owners[i].name}</TableCell>
							<TableCell>{row.owners[i].acceptance ? 'Tak' : 'Nie'}</TableCell>
							<TableCell>{(typeof(row.owners[i].legal_form) === 'string' || row.owners[i].legal_form === null) ? row.owners[i].legal_form : (selectedLanguage === 'en' && row.owners[i].legal_form[selectedLanguage] === null) ? row.owners[i].legal_form.pl : row.owners[i].legal_form[selectedLanguage]}</TableCell>
							<TableCell rowSpan={row.owners.length}>
								<Box display="flex">
									{expand !== index
										? <ExpandCircleIconButton tooltip="Rozwiń" onClick={() => handleClickReadMore(index)} />
										: <CollapseCircleIconButton tooltip="Zwiń" onClick={() => handleClickReadMore(null)} />
									}
									{!isPreview && canCreateIpCard &&
										<>
											{editingData?.index !== index
												? <EditIconButton tooltip="Edytuj" onClick={() => handleEdit(index)} />
												: <CheckRedIconButton tooltip="Zatwierdź" onClick={() => { }} />
											}
											<DeleteIconButton tooltip="Usuń" title="Czy jesteś pewien, że chcesz usunąć ten wpis" onClick={handleDelete(index)} />
										</>
									}
								</Box>
							</TableCell>
						</TableRow>);
					} else {
						groupComponent.push(<TableRow key={i}>
							<TableCell>{row.owners[i].name}</TableCell>
							<TableCell>{row.owners[i].acceptance ? 'Tak' : 'Nie'}</TableCell>
							<TableCell>{(selectedLanguage === 'en' && row.owners[i].legal_form[selectedLanguage] === null) ? row.owners[i].legal_form.pl : row.owners[i].legal_form[selectedLanguage]}</TableCell>
						</TableRow>);
					}
				}
				if (expand === index) {
					groupComponent.push(
						<TableRow key={index + '_detail'}>
							<TableCell colSpan={9}>
								<Detail subject={row} />
							</TableCell>
						</TableRow>
					);
				}
				if (editingData?.index === index) {
					groupComponent.push(
						<TableRow key={index + '_edit'}>
							<TableCell colSpan={9}>
								<Form
									onSave={handleSave}
									values={row}
								/>
							</TableCell>
						</TableRow>
					);
				}

				return groupComponent;
			}
		});
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	useEffect(() => {
		setData(formData.ip_card_background_subjects);
	}, [formData.ip_card_background_subjects]);

	useEffect(() => {
		let _filters = Object.fromEntries(
			Object.entries(filters).filter(([value]) => !!value));
		handleChangeSearch(_filters);
	}, [filters]);

	const includes = (string, searchString) => (
		string?.toString()?.toLowerCase()?.indexOf(searchString?.toString()?.toLowerCase()) > -1
	);

	const handleChangeSearch = useCallback(debounce((filters) => {
		Object.keys(filters).forEach(key => {
			if (!filters[key]) delete filters[key];
		});

		let filtered = formData.ip_card_background_subjects?.filter(item => (
			_.keys(filters).every(key => {
				let isIncludes = false;
				switch (key) {
				case 'title':
					isIncludes = includes(item?.title_pl, filters[key]) ||
						includes(item?.title_en, filters[key]);
					break;
				case 'owner_name':
					isIncludes = item.owners?.find(o => includes(o.name, filters[key]));
					break;
				case 'start_priority_date':
					isIncludes = (new Date(item['priority_date']) >= new Date(filters[key]));
					break;
				case 'end_priority_date':
					isIncludes = (new Date(item['priority_date']) <= new Date(filters[key]));
					break;
				default:
					isIncludes = includes(item[key], filters[key]);
				}
				return isIncludes;
			})
		));

		setData(filtered);
	}, 500), []);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={ window.isMobile ? 12 : 3 }>
					<Select
						fullWidth
						name="type"
						options={subjectTypes}
						hideErrorText
						displayEmpty
						emptyLabel={t('Wybierz typ')}
						value={filters.type}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={ window.isMobile ? 12 : 3 }>
					<FormInput
						fullWidth
						name="title"
						placeholder={selectedLanguage === 'pl' ? 'Wpisz tytuł' : 'Enter the title'}
						value={filters.title}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={ window.isMobile ? 12 : 3 }>
					<FormInput
						fullWidth
						name="owner_name"
						placeholder={selectedLanguage === 'pl' ? 'Wpisz autora' : 'Enter the author'}
						value={filters.owner_name}
						onChange={handleChange}
					/>
				</Grid>

			</Grid>
			<div id="background-table">
				<TableContainer className={classes.root}>
					<MuiTable>
						<TableHead>
							<TableRow>
								<TableCell rowSpan={2} width={60}>
									<TableLabel>{t('LP.')}</TableLabel>
								</TableCell>
								<TableCell rowSpan={2}>
									<TableLabel>{t('Typ')}</TableLabel>
								</TableCell>
								<TableCell rowSpan={2}>
									<TableLabel>{t('Data pierwszeństwa')}</TableLabel>
								</TableCell>
								<TableCell rowSpan={2}>
									<TableLabel>{t('Numer')}</TableLabel>
								</TableCell>
								<TableCell rowSpan={2}>
									<TableLabel>{t('TYTUŁ PL/EN')}</TableLabel>
								</TableCell>
								<TableCell colSpan={3}>
									<TableLabel>{t('Właściciel Background IP')}</TableLabel>
								</TableCell>
								<TableCell rowSpan={2} width={100}>
									<TableLabel>{t('AKCJE')}</TableLabel>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>
									<TableLabel>{t('Imię i nazwisko')}</TableLabel>
								</TableCell>
								<TableCell>
									<TableLabel>{t('ZGODA')}</TableLabel>
								</TableCell>
								<TableCell>
									<TableLabel>{t('Forma prawna')}</TableLabel>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{renderRows()}
						</TableBody>
					</MuiTable>
				</TableContainer>
			</div>
		</>
	);
};

export default Table;
