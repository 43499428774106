import React, { useEffect, useState, useCallback } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import _, { debounce } from 'lodash';
import PropTypes from 'prop-types';

import BaseInput from 'components/Form/BaseInput';
import DateInput from 'components/Form/DateInput';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { SearchIcon } from 'assets/svg';

const Filter = ({ data, onSearchResult }) => {
	const [searchResult, setSearchResult] = useState([]);

	const [filters, setFilters] = useState({
		title: '',
		author_name: '',
		start_publication_date: '',
		end_publication_date: '',
	});

	useEffect(() => {
		let _filters = Object.fromEntries(
			Object.entries(filters).filter(([value]) => !!value));
		handleChangeSearch(_filters);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFilters(prev => ({ ...prev, [name]: value }));
	};

	const includes = (string, searchString) => (
		string?.toString()?.toLowerCase()?.indexOf(searchString?.toString()?.toLowerCase()) > -1
	);

	const handleChangeSearch = useCallback(debounce((filters) => {
		Object.keys(filters).forEach(key => {
			if (!filters[key]) delete filters[key];
		});

		let filtered = data?.filter(item => (
			_.keys(filters).every(key => {
				let isIncludes = false;
				switch (key) {
				case 'title':
					isIncludes = includes(item?.title, filters[key]);
					break;
				case 'author_name':
					isIncludes = item.authors?.find(o => includes(o.name, filters[key]));
					break;
				case 'start_publication_date':
					isIncludes = (new Date(item['publication_date']) >= new Date(filters[key]));
					break;
				case 'end_publication_date':
					isIncludes = (new Date(item['publication_date']) <= new Date(filters[key]));
					break;
				default:
					isIncludes = includes(item[key], filters[key]);
				}
				return isIncludes;
			})
		));

		setSearchResult(filtered);
	}, 500), []);

	useEffect(() => {
		setSearchResult(data);
	}, []);

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder="Wpisz tytuł publikacji"
					name="title"
					value={filters.title}
					onChange={handleChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<BaseInput
					fullWidth
					placeholder="Wpisz imię i nazwisko twórcy"
					name="author_name"
					value={filters.author_name}
					onChange={handleChange}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<DateInput
					fullWidth
					name="start_publication_date"
					value={filters.start_publication_date}
					onChange={handleChange}
					maxDate={filters.end_publication_date}
					gutterBottom={false}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 2}>
				<DateInput
					fullWidth
					name="end_publication_date"
					value={filters.end_publication_date}
					onChange={handleChange}
					minDate={filters.start_publication_date}
					gutterBottom={false}
				/>
			</Grid>
			<Grid item xs={window.isMobile ? 12 : 1}>
				<PrimaryButton onClick={() => onSearchResult(searchResult)}>
					FILTRUJ
				</PrimaryButton>
			</Grid>
		</Grid >
	);
};

Filter.propTypes = {
	data: PropTypes.array,
	onSearchResult: PropTypes.func,
};

export default Filter;
