import React, { useContext, useEffect, useState } from 'react';
import {
	makeStyles,
	useMediaQuery,
	Table as MuiTable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import Typography from 'components/Main/Typography';
import TableLabel from 'components/Main/TableLabel';
import NumberText from 'components/Main/NumberText';

import ResearchTaskReportContext from 'context/ResearchTaskReportContext';
import ResearchTaskReportBContext from 'context/ResearchTaskReportBContext';
import YearTable from './YearTable';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-head': {
			padding: theme.spacing(2),
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
		},
	},
	thead: {
		'& .MuiTableCell-head:first-child': {
			borderRightWidth: 2,
		},
		'& .MuiTableCell-head:last-child': {
			borderLeftWidth: 3,
		},
	}
}));

const Table = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze - Raport B' });
	const classes = useStyles();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { researchTask } = useContext(ResearchTaskReportContext);
	const { formData, onChange, errors, selectedLanguage } = useContext(ResearchTaskReportBContext);

	const [totalValue, setTotalValue] = useState(0);
	const [years, setYears] = useState([]);

	useEffect(() => {
		let paymentsSchedule = researchTask.payments_schedule;

		setTotalValue(paymentsSchedule.total.value);
		setYears(_.keys(paymentsSchedule.values));
	}, [researchTask]);

	useEffect(() => {
		let paymentsSchedule = formData[selectedLanguage].changed_information.payments_schedule;
		if (!paymentsSchedule?.values) return;

		let total = _.sum(_.values(_.mapValues(paymentsSchedule.values, value => value?.total?.value)));
		if (paymentsSchedule?.total?.value === total) return;
		total = _.isNumber(total) ? parseFloat(total.toFixed(2)) : 0;
		setTotalValue(total);
		onChange('pl.changed_information.payments_schedule.total.value', total);
		onChange('en.changed_information.payments_schedule.total.value', total);
	}, Object.values(formData[selectedLanguage].changed_information?.payments_schedule?.values || {}).map(v => v.values));

	const renderTableCell = (year, colSpan = 1) => (
		<TableCell colSpan={colSpan} width="50%">
			<YearTable year={year} />
		</TableCell>
	);

	return (
		<TableContainer className={classes.root}>
			<MuiTable padding="none">
				<TableHead className={classes.thead}>
					<TableRow>
						<TableCell>
							<TableLabel>{t('Ogółem')}</TableLabel>
						</TableCell>
						<TableCell>
							<NumberText variant="tableLabel" value={totalValue} />
							<Typography variant="inputStatus" color="secondary">{errors?.changed_information?.payments_schedule?.total?.value[0]}</Typography>
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{(isMobile ? years : _.chunk(years, 2)).map((year, i) => (
						<TableRow key={i}>
							{isMobile
								? renderTableCell(year, 2)
								: <>
									{renderTableCell(year?.[0])}
									{year?.[1] && renderTableCell(year?.[1])}
								</>
							}
						</TableRow>
					))}
				</TableBody>
			</MuiTable>
		</TableContainer>
	);
};

export default Table;
