import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
	makeStyles,
	useMediaQuery,
	Table as MuiTable,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import _debounce from 'lodash/debounce';

import Typography from 'components/Main/Typography';
import NumberText from 'components/Main/NumberText';

import ResearchTaskContext from 'context/ResearchTaskContext';
import YearTable from './YearTable';
import YearRangePicker from './YearRangePicker';

const useStyles = makeStyles(theme => ({
	root: {
		'& .MuiTableHead-root': {
			background: '#EEEEEE',
		},
		'& .MuiTableCell-head': {
			padding: theme.spacing(2),
		},
		'& .MuiTableCell-root': {
			border: '1px solid #D0D0D0',
		},
	},
	thead: {
		'& .MuiTableCell-head:first-child': {
			borderRightWidth: 2,
		},
		'& .MuiTableCell-head:last-child': {
			borderLeftWidth: 3,
		},
	}
}));

const Table = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const classes = useStyles();
	const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));
	const { formData, onChange, errors } = useContext(ResearchTaskContext);
	const [totalValue, setTotalValue] = useState(formData.payments_schedule.total?.value || 0);
	const [years, setYears] = useState([]);

	const updateTotal = useCallback(_debounce((paymentsSchedule) => {
		if (!paymentsSchedule.values) return;
		let total = _.sum(_.values(_.mapValues(paymentsSchedule.values, value => value.total)));
		if (paymentsSchedule.total?.value === total) return;
		total = _.isNumber(total) ? parseFloat(total.toFixed(2)) : 0;
		setTotalValue(total);
		onChange('payments_schedule.total.value', total);
	}, 100), []);

	useEffect(() => {
		updateTotal(formData.payments_schedule);
	}, [formData]);

	const handleChangeYearRange = (startYear, endYear) => {
		let years = _.range(startYear, endYear + 1);
		setYears(years);
		onChange('payments_schedule.values', _.pick(formData.payments_schedule.values, years));
	};

	const renderTableCell = (year, colSpan = 1) => (
		<TableCell colSpan={colSpan} width="50%">
			<YearTable year={year} />
		</TableCell>
	);

	return (
		<>
			<YearRangePicker onChange={handleChangeYearRange} />
			<TableContainer className={classes.root}>
				<MuiTable padding="none">
					<TableHead className={classes.thead}>
						<TableRow>
							<TableCell>
								<Typography variant="tableLabel">{t('Ogółem')}</Typography>
							</TableCell>
							<TableCell>
								<NumberText variant="tableLabel" value={totalValue} />
								<Typography variant="inputStatus" color="secondary">{errors?.payments_schedule?.total?.value[0]}</Typography>
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{(isMobile ? years : _.chunk(years, 2)).map((year, i) => (
							<TableRow key={i}>
								{isMobile
									? renderTableCell(year, 2)
									: <>
										{renderTableCell(year?.[0])}
										{year?.[1] && renderTableCell(year?.[1])}
									</>
								}
							</TableRow>
						))}
					</TableBody>
				</MuiTable>
			</TableContainer>
		</>
	);
};

export default Table;
