import React, { useContext, useEffect, useState } from 'react';
import {
	makeStyles,
	Modal,
	Backdrop,
	Fade,
	Card,
	Box,
} from '@material-ui/core';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import Typography from 'components/Main/Typography';
import Select from 'components/Form/Select';
import PrimaryButton from 'components/Buttons/PrimaryButton';

import ManagementDetailedInformationsContext from 'context/ManagementDetailedInformationsContext';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: 600,
		boxShadow: theme.shadows[5],
		padding: '25px',
		outline: 'none',
	},
}));

const AddModal = props => {
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const { onAdd, excludingIds, disabled } = props;
	const classes = useStyles();
	const { researchTasks } = useContext(ManagementDetailedInformationsContext);
	const [openModal, setOpenModal] = useState(false);
	const [taskId, setTaskId] = useState('');

	useEffect(() => {
		if (!openModal) return;
		setTaskId('');
	}, [openModal]);

	const handleSave = () => {
		onAdd(taskId);
		handleClose();
	};

	const handleClose = () => setOpenModal(false);

	return (
		<>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				open={openModal}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}} >
				<Fade in={openModal}>
					<Card className={classes.paper}>
						<Typography variant="h3" style={{ marginBottom: 16 }}>
							{t('Wybierz Zespół Badawczy')}
						</Typography>
						<Select
							title={t('Wybierz z listy zespołów')}
							options={_.dropWhile(researchTasks, (task => _.includes(excludingIds, task.id)))}
							value={taskId}
							onChange={e => setTaskId(e.target.value)}
							valueField="id"
							labelField="acronym"
							displayEmpty
							hideErrorText
							error={!taskId}
							inputProps={{ 'aria-required': true }}
						/>
						<Box display="flex" alignItems="center" justifyContent="flex-end">
							<PrimaryButton onClick={handleClose} variant="outlined">
								{t('Anuluj')}
							</PrimaryButton>
							<PrimaryButton onClick={handleSave} style={{ marginLeft: 16 }}>
								{t('Dodaj zespół badawczy')}
							</PrimaryButton>
						</Box>
					</Card>
				</Fade>
			</Modal>
			<PrimaryButton onClick={() => setOpenModal(true)} disabled={disabled}>
				{t('Dodaj zespół badawczy')}
			</PrimaryButton>
		</>
	);
};

export default AddModal;
